import React, {useState, useEffect} from "react";
import styles from "../cssFiles/topdash.module.css";
import axios from 'axios'

const TopDash = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>Radhika's Dashboard</div>
        <div style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}>
          <div className={styles.eachbox}>
            <div className={styles.number}>
              <img
                src="https://idesign-office.s3.amazonaws.com/trending_up.svg"
                alt=""
                style={{width:'1.7rem'}}
              />
              4
            </div>
            <div className={styles.task}>New Tasks</div>
          </div>
          <div className={styles.eachbox2}>
            <div className={styles.number}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/task_alt.svg"
                alt=""
                style={{width:'1.6rem'}}
              />
              4
            </div>
            <div className={styles.task}>Pending Approvals</div>
          </div>
          <div className={styles.eachbox3}>
            <div className={styles.number}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/folder.svg"
                alt=""
                style={{width:'1.5rem'}}
              />
              4
            </div>
            <div className={styles.task}>Pendencies</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDash;
