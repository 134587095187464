import React, { useEffect, useState } from 'react'
import { Accordion, Carousel, Modal } from 'react-bootstrap';
import bedroom2 from './3dImages/bedroom2.svg'
import uncoloredStar from './3dImages/uncoloredStar.svg'
import coloredStar from './3dImages/coloredStar.svg'
import Completed3dMob from './Completed3dMob';
import Ordered3dMob from './Ordered3dMob';
import { useNavigate } from 'react-router-dom';
import LandingMobHeader from './LandingMobHeader';
import axios from 'axios';
import { useSelector } from 'react-redux';
import reviews from './3dImages/reviews.svg'
import img1 from './3dImages/images3d/1.jpeg'
import img2 from './3dImages/images3d/2.jpeg'
import img3 from './3dImages/images3d/3.jpeg'
import img4 from './3dImages/images3d/4.jpeg'
import img5 from './3dImages/images3d/5.jpeg'
import img6 from './3dImages/images3d/6.jpeg'
import img7 from './3dImages/images3d/7.jpeg'
import img8 from './3dImages/images3d/8.jpeg'
import img9 from './3dImages/images3d/9.jpeg'
import img10 from './3dImages/images3d/10.jpeg'
import next from './3dImages/images3d/next.png'
import close from './3dImages/close.svg'
import sidebar from './3dImages/sidebar.svg'
import { constants } from '../constants';

const Final3dPageMob = () => {
    const [ordered, setOrdered] = useState(true)
    const [completed, setCompleted] = useState(false)
    const payM = useSelector((state) => state.threedReducer.payMethod)
    const [userData, setUserData] = useState([])
    const [scroll_, setScroll_] = useState(0)
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);

    const handleFinal = (tabType) => {
        if (tabType === "tab1") {
            setOrdered(true)
            setCompleted(false)
        }
        if (tabType === "tab2") {
            setCompleted(true)
            setOrdered(false)
        }
    }
    const navigate = useNavigate()
    useEffect(async () => {
        const res3 = await axios.get(`${constants.threeDApi}/api/room/req-designs?designerId=${localStorage.getItem("userId")}`)
        // console.log(res3)
        setUserData(res3.data)
    }, [])

    // console.log(userData)
    // console.log(payM)
    useEffect(async () => {
        if (payM.money === "full") {
            userData.map(async (item) => {
                const amountPaid = item.amountToBePaid
                if (item.amountToBePaid) {
                const res4 = await axios.put(`${constants.threeDApi}/api/room/save-paid-amount?id=${item._id}`, { amountPaid: amountPaid }).then(() => {
                    window.location.reload()
                }).catch((err) => console.warn(err))
            }
            })
        
        }

        else if (payM.money === "half") {
            userData.map(async (item) => {
                const amountPaid = (item.amountToBePaid) / 2
                if (item.amountToBePaid) {
                const res4 = await axios.put(`${constants.threeDApi}/api/room/save-paid-amount?id=${item._id}`, { amountPaid: amountPaid }).then(() => {
                    window.location.reload()
                }).catch((err) => console.warn(err))
            }
            })
        }

    }, [userData])


    const [data, setData] = useState([])
    useEffect(async () => {
        const res = await axios.get(`${constants.threeDApi}/api/room/inProcess-designs?designerId=${localStorage.getItem("userId")}`)
        setData(res.data)
    }, [])

    var scroll = window.scrollY
    window.onscroll = () => {
        scroll = window.scrollY
        setScroll_(scroll)
    }
    const faqs = [
        {
            no: 1,
            faq: "For which kind of Spaces do you provide 3Ds ?",
            faqa: "We provide 3Ds for interior spaces of Home Interiors. We are currently not providing 3Ds for commercial spaces or facades",
        },
        {
            no: 2,
            faq: "Do you make 3Ds of furniture as per drawing or use library ?",
            faqa: "We use 3DMax or Sketch up library to provide the closest furniture to the reference image shared by you. Our team does some customized visualization also if its limited in nature.",
        },
        {
            no: 3,
            faq: "Is my ACAD Drawings safe with iDesign?",
            faqa: "We place highest amount of emphasis on data security and your ACAD drawings and 3Ds will be your property and never shared with any third party and will never be used by iDesign for any purpose other than providing you the service",
        },
        {
            no: 4,
            faq: "What if I am not satisfied with the Final 3D ?",
            faqa: "We will be showing you the 3D block and low renders before the final render and hence the final render is done only on your approval. If incase you are not happy with the final render, we will be happy to give you a fresh render. Also, we take only 50% payment as advance,so you pay the balance 50% only when you are totally comfortable with the process.",
        },
    ];
    const handleStart = () => {
        navigate("/3dUploadCad")
    }
    const images = [
        {
            no: 1,
            imgName: img1,

        },
        {
            no: 2,
            imgName: img2,

        },
        {
            no: 3,
            imgName: img3,

        },
        {
            no: 4,
            imgName: img4,

        },
        {
            no: 5,
            imgName: img5,

        },
        {
            no: 6,
            imgName: img6,

        },
        {
            no: 7,
            imgName: img7,

        },
        {
            no: 8,
            imgName: img8,

        },
        {
            no: 9,
            imgName: img9,
        },
        {
            no: 10,
            imgName: img10,
        },

    ];

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [showId, setShowId] = useState()
    const [ofArr, setOfArr] = useState([])
    let [left, setLeft] = useState(0)

    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    }
    useEffect(() => {
        const b = images.filter((currElem) => {
            return currElem.no == showId
        })
        setOfArr(b)
    }, [showId])

    const [filteredArr, setFilteredArr] = useState([])

    useEffect(() => {
        const a = images.filter((currElem) => {
            return currElem.no !== showId
        })
        setFilteredArr(a)
    }, [showId])
    // console.log(showId)

    // const [filteredArr, setFilteredArr] = useState([])

    useEffect(() => {
        const a = images.filter((currElem) => {
            return currElem.no !== showId
        })
        setFilteredArr(a)
    }, [showId])

    return (
        <>
            <div className='d-block d-md-none'>
                <Modal show={show2} onHide={handleClose2} background={{ background: 'none', overflow: "hidden" }} size="sm" centered>
                    <img src={close} alt="" onClick={handleClose2} className="float-end" style={{
                        filter: "brightness(100)", cursor: "pointer", position: " absolute",
                        right: "0.5rem",
                        top: "-10rem", width: "18px", height: "18px"
                    }} />
                    <Modal.Body >
                        {/* <img src={ofArr[0]?.imgName} alt="" style={{ width: '100%' }} /> */}
                        <Carousel variant="dark">
                            <Carousel.Item>
                                <div style={{ display: "flex", width: "fit-content" }}>
                                    <div style={{
                                        width: "22rem", height: "18rem", marginRight: "10px"
                                    }}>
                                        <img src={ofArr[0]?.imgName} alt="" style={{ width: '100%', height: "100%" }} />
                                    </div>
                                </div>
                            </Carousel.Item>
                            {filteredArr.map((item, index) => {
                                return (
                                    <Carousel.Item>
                                        <div style={{ display: "flex", width: "fit-content" }}>
                                            <div style={{
                                                width: "22rem", height: "18rem", marginRight: "10px"
                                            }}>
                                                <img src={item?.imgName} alt="" style={{ width: '100%', height: "100%" }} />
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                        </Modal.Body>
                </Modal>
                <LandingMobHeader />
                <div style={{ background: "White", display: "flex" }}>
                    <div className="landing_main">
                        <div className="landing_main2">
                            <div className="landing_top">
                                <h3 className="landing_header">Get quick and high quality 3D views just at {profileInfo[0]?.data?.data?.planId?.name === "Free" ? <b style={{ color: "#3B5998" }}> ₹1200/- </b> : <b style={{ color: "#3B5998" }}> ₹1000/- </b>} per view. </h3>
                                <p className="landing_p">iDesign provides on time and  value for money 3Ds to over
                                    300 Designer and Architects across India</p>
                                <button className="landing_started" onClick={() => navigate("/3dUploadCad")}>Get Started</button>
                                {/* <span className='mt-2' style={{ fontSize: "12px", color: "#888888", fontWeight: "400" }}>only for Residential Interiors</span> */}
                            </div>
                        </div>
                        <div>
                            <div className="final_tabs d-flex mt-5">
                                <button className={ordered ? "final_tab_active" : "final_tab_unactive"} onClick={() => { handleFinal("tab1") }}>Ordered 3Ds</button>
                                {/* <button className={completed ? "final_tab_active" : "final_tab_unactive"} onClick={() => { handleFinal("tab2") }} style={{pointerEvents: "none"}}>Completed 3Ds</button> */}
                            </div>
                            <Ordered3dMob />
                        </div>
                        {profileInfo[0]?.data?.data?.planId?.name === "Free" ? <div className="disc-offer mt-5">
                            <button className="dis_prem" onClick={() => navigate("/plans")}>Upgrade to premium</button>
                        </div> : ""}
                        {/* <div className="disc-offer mt-5">
                            <button className="dis_prem" onClick={() => navigate("/plans")}>Upgrade to premium</button>
                        </div> */}
                        <div className="landing_images_secs mt-5 mb-3 d-flex flex-column">
                            <h3 className='img_lan_header mt-5'>Want to see some of our designs?</h3>

                            {/* <div className="d-flex">
                                    <div className="landing_img_main d-flex justify-content-start" style={{ overflowX: "scroll" }}>
                                        <Carousel variant="dark" style={{
                                            border: "none"
                                        }}>
                                            {images.map((item, index) => (<Carousel.Item style={{ border: "none", padding: "0" }}>
                                                <img src={item.imgName} alt="" className="img1" style={{
                                                    objectFit: "cover", width: "19rem", height: "11rem", cursor: "pointer", borderRadius: "8px"
                                                }} onClick={() => handleShow2(item.no)} />
                                            </Carousel.Item>))}
                                        </Carousel>
                                    </div>
 
                                </div> */}

                            <div className="d-flex">
                                <img src={sidebar} alt="" className="dimag" style={{ transform: "rotate(180deg)", cursor: "pointer", position: "relative", left: "0.7rem" }} onClick={() => {
                                    let element_ = document.querySelector('.landing_img_main1')
                                    if (element_.scrollLeft > 0) {
                                        element_.scrollLeft -= 298
                                    }
                                }} />

                                <div className="landing_img_main1 d-flex justify-content-start" style={{ overflowX: "scroll", }}>
                                    <div className='s_m' style={{ display: "flex", width: "fit-content" }}>
                                        {images.map((item, index) => (
                                            <div className='s_i' style={{
                                                width: "18rem", height: "10rem", marginRight: "10px"
                                            }}>
                                                <img src={item.imgName} alt="" className="img1" style={{ height: "100%", width: "100%", marginRight: "10px", cursor: "pointer", borderRadius: "8px" }} onClick={() => {
                                                    handleShow2(item.no)
                                                }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <img src={sidebar} alt="" className="dimag" style={{ marginLeft: "auto", cursor: "pointer", position: "relative", right: "0.7rem" }} onClick={() => {
                                    let element_ = document.querySelector('.landing_img_main1')

                                    if (element_.scrollLeft != 781.5999755859375) {
                                        element_.scrollLeft += 298
                                    }
                                }} />
                            </div>
                        </div>

                        {/* <div className="side_carousel d-flex flex-column mt-5">
                  <h3 className="img_lan_header mt-5">Reviews</h3>
                  <div className="carouselComp_start mt-2">
                      <Carousel variant="dark">
                          <Carousel.Item>
                              <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                              <div className="caption_reviwes d-flex">
                                  <div className="caption_name d-flex flex-column">
                                      <p className="cap_byline">Rohit Rathod</p>
                                      <span className="cap_span">Interior Designer</span>
                                  </div>
                                  <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                                      <img src={coloredStar} alt="" className="starColor" />
                                      <img src={coloredStar} alt="" className="starColor" />
                                      <img src={coloredStar} alt="" className="starColor" />
                                      <img src={uncoloredStar} alt="" className="starColor" />
                                      <img src={uncoloredStar} alt="" className="starColor" />
                                  </div>
                              </div>
                          </Carousel.Item>
                          <Carousel.Item>
                              <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                              <div className="caption_reviwes d-flex">
                                  <div className="caption_name d-flex flex-column">
                                      <p className="cap_byline">Rohit Rathod</p>
                                      <span className="cap_span">Interior Designer</span>
                                  </div>
                                  <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                                      <img src={coloredStar} alt="" className="starColor" />
                                      <img src={coloredStar} alt="" className="starColor" />
                                      <img src={coloredStar} alt="" className="starColor" />
                                      <img src={uncoloredStar} alt="" className="starColor" />
                                      <img src={uncoloredStar} alt="" className="starColor" />
                                  </div>
                              </div>
                          </Carousel.Item>
                      </Carousel>
                  </div>
              </div>              */}
                        {/* <div className="side_carousel mt-4">
                          <h3 className="img_lan_header mb-3 mt-5">Reviews</h3>
                          <div className="d-flex flex-column w-100 justify-content-center" style={{
                              height: "26vh", border: "1px solid #DFDFDF",
                              borderRadius: "8px"
                          }}>
                              <img src={reviews} style={{ height: "38px" }} alt="" />
                              <p style={{
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  color: "black",
                                  textAlign: "center"
                              }}>No reviews yet</p>
                          </div>
                      </div> */}
                        <div className="landing_faqs mb-5">
                            <p className="faqs_header mt-3">FAQs</p>
                            <Accordion style={{ width: "88%" }}>
                                {faqs.map((item, i) => (<Accordion.Item eventKey={item.no}>
                                    <Accordion.Header>{item.faq}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.faqa}
                                    </Accordion.Body>
                                </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                        {/* <p>{scroll}</p> */}
                        {scroll_ > 400 ? <button className="landing_started2" onClick={handleStart}>Get Started</button> : ""}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Final3dPageMob