import React, { useEffect } from "react";
import "../App.css";
import image from "../Images/Consultation.png";
import dropImage from "../Images/removeWorking.png";
import vector from "../Images/Vector.png";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Offcanvas } from "react-bootstrap";

import { useContext } from "react";
import { OnboardingContext } from "../Context/Context";
import { useNavigate } from "react-router";

export default function Consultation() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);

  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();

  function handleChange(e) {
    setPrice(e.target.value.toString());
  }

  function handleSubmit() {
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);
      context.setData((pre) => ({ ...pre, consultationCharges: price }));
      console.log(context.data);
      localStorage.setItem("consultation", price);
      navigateTo("/feepersquarefeet");
    }
  }

  return (
    <React.Fragment>
      {showWorking && (
        <Offcanvas className="virtualWorking" show={true} placement="left">
          <Offcanvas.Header style={{ borderBottom: "none !important" }} className="offcanvasBtn">
            <button
              style={{ borderBottom: "none !important" }}
              onClick={() => {
                setShowWorking(false);
              }}
              className="removeWorking"
            >
              <img src={dropImage} />
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvasBody">
            <div>
              <p className="howZoomWorks">How Does Zoom Consultation work?</p>
            </div>
            <div>
              <ul className="offcanvasList">
                <li>
                  <b>Connect to Homeowners</b> looking for quick advice
                </li>
                <li>
                  Mostly<b> advisory service,</b> no drawings to be provided.
                </li>
                <li>
                  Meeting scheduled at<b> mutual convenience.</b>
                </li>
              </ul>
              <p className="note">Note : All prices exclusive of GST</p>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <div className="consultation-container">
        <div className="main-consultation">
          <div className="w-100 d-flex flex-column align-items-center">
            <img src={image} />
            <p>
              How much would you <br />
              charge for <b>1 Hour </b>of <br /> <b>Virtual Consultation ?</b>
            </p>
            <input className="text-center" style={showError ? { borderColor: "red" } : null} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" />
            <span style={{ color: "red" }} className={`${showError ? "" : "d-none"}`}>
              You cannot leave this field empty
            </span>
          </div>
          <div className="w-100 d-flex flex-column align-items-center">
            <div className="virtualInfo">
              <button
                className="p-0"
                onClick={() => {
                  setShowWorking(true);
                }}
                style={{ width: "1rem", height: "1rem" }}
              >
                <img style={{ width: "100%", height: "100%" }} src={vector} />
              </button>
              <span>How Does Virtual Consultation work? </span>
            </div>
            <div className="btn-container ">
              {/* <Link to="/feepersquarefeet"> */}
              <button onClick={handleSubmit} className="nextbutton w-100">
                Next
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}