import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import uncoloredStar from './3dImages/uncoloredStar.svg'
import coloredStar from './3dImages/coloredStar.svg'
import reviews from './3dImages/reviews.svg'
import { useNavigate } from 'react-router-dom'
import bedroomL from './3dImages/bedroomL.svg'
import bedroomL2 from './3dImages/bedroomL2.svg'
import bedroom from './3dImages/bedroom.svg'
import { useSelector } from 'react-redux'

const SideLandingPage = () => {
  const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);

  // console.log(profileInfo[0]?.data?.data?.planId?.name)

  const benefits = [
    {
      no: 1,
      data: "Professionally done 3Ds delivered in a timebound way",
    },
    {
      no: 2,
      data: "Low cost of views starting at Rs 850 per view",
    },
    {
      no: 3,
      data: "Large 3D backoffice with specialists to cater to multiple and large 3D demand",
    },
    {
      no: 4,
      data: "Multiple changes allowed at block and low render",
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="side_landing d-flex flex-column">
        {profileInfo[0]?.data?.data?.planId?.name === "Free" ? <div className="side_content mb-5">
          <button className="dis_prem" onClick={() => navigate("/plans")}>Upgrade to premium</button>
        </div> : ""}
        {/* <div className="side_content mb-5">
          <button className="dis_prem" onClick={() => navigate("/plans")}>Upgrade to premium</button>
        </div> */}
        {/* <div className="side_carousel d-flex flex-column">
          <h3 className="img_lan_header mb-3 mt-5">Reviews</h3>
          <div className="carouselComp_start mt-4">
            <Carousel variant="dark">
              <Carousel.Item>
                <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                <div className="caption_reviwes d-flex">
                  <div className="caption_name d-flex flex-column">
                    <p className="cap_byline">Rohit Rathod</p>
                    <span className="cap_span">Interior Designer</span>
                  </div>
                  <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                    <img src={coloredStar} alt="" className="starColor" />
                    <img src={coloredStar} alt="" className="starColor" />
                    <img src={coloredStar} alt="" className="starColor" />
                    <img src={uncoloredStar} alt="" className="starColor" />
                    <img src={uncoloredStar} alt="" className="starColor" />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                <div className="caption_reviwes d-flex">
                  <div className="caption_name d-flex flex-column">
                    <p className="cap_byline">Rohit Rathod</p>
                    <span className="cap_span">Interior Designer</span>
                  </div>
                  <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                    <img src={coloredStar} alt="" className="starColor" />
                    <img src={coloredStar} alt="" className="starColor" />
                    <img src={coloredStar} alt="" className="starColor" />
                    <img src={uncoloredStar} alt="" className="starColor" />
                    <img src={uncoloredStar} alt="" className="starColor" />
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div> */}
        {/* <div className="side_carousel">
          <h3 className="img_lan_header mb-3 mt-5">Reviews</h3>
          <div className="d-flex flex-column w-100 justify-content-center" style={{
            height: "26vh", border: "1px solid #DFDFDF",
            borderRadius: "8px"}}>
            <img src={reviews} style={{ height: "38px" }} alt="" />
            <p style={{
              fontWeight: "500",
              fontSize: "18px",
              color: "black",
              textAlign: "center"
            }}>No reviews yet</p>
          </div>
        </div> */}
        <div className="side_benefitsd-flex flex-column mt-2" style={{ width:" 80%" }}>
          <h3 className="img_lan_header mb-3">Benefits</h3>
          {benefits.map((item, i) => (
            <div className="benefit1 d-flex">
              <p className="bno1">{item.no}</p>
              <p className="bnoContent">{item.data}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SideLandingPage