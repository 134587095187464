import React from "react";

const Backed = () => {
  return (
    <>
    <div style={{paddingTop:"5rem"}}>
    <div
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
          /* identical to box height, or 125% */
           textAlign:"center",
          color: "#000000",
        }}
      >
        Backed by the best
      </div>

      <div style={{marginTop:"2rem"}}>
        <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/bridge.svg" alt="" style={{width:'100%'}}/>
      </div>
    </div>
   
    </>
  );
};

export default Backed;
