import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PhotoUpload from './PhotoUpload'
import SelectRoom from './SelectRoom'
import side from './3dImages/side.svg'
import UploadRefMain from './UploadRefMain'
import completed from './3dImages/completed.svg'
import { useSelector } from 'react-redux'

const TabButtonsMob2 = () => {
    const navigate = useNavigate()
    const handleNext = () => {
        if (localStorage.getItem("livingInput1").length > 0) {
            navigate("/3dMakePPT")
            localStorage.setItem("tick2", true)
        }
    }
    const handleBack = () => {
        navigate("/3dUploadCad")
        localStorage.removeItem("tick")
    }

    return (
        <>
            <div className="d-block d-md-none tabDisplay">
                <div className="tab_main">
                    <div className="tab_mobile">
                        <a className="d-flex align-items-center"  style={{ textDecoration: "none", color: "black" }}>
                            <span className="d-flex align-items-center">
                                <div className="me-3 d-flex align-items-center">
                                    <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                                </div>
                                <div className="page-Heading">3D Feature</div>
                            </span>
                        </a>
                    </div>

                    <div className="tabs_mob_main">
                        <div className="tab_mob1-unactive" >
                            {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : <button className="no2">1</button>}
                            <p className="no1_line d-none">Upload AutoCAD file</p>
                        </div>
                        <div className= "tab_mob1-active" >
                            <button className="no1">2</button>
                            <p className="no1_line">Select room & views</p>
                        </div>
                        <div className="tab_mob1-unactive" >
                            <button className="no2">3</button>
                            <p className="no1_line d-none">Upload reference PPT</p>
                        </div>
                    </div>
                    {<SelectRoom /> }
                     {/* <div className="tab_button">
                        <button className="tab_next" onClick={handleNext}>Next</button>
                        <button className="tab_back" onClick={handleBack}>Back</button>
                    </div>  */}
                </div>
            </div>
        </>
    )
}

export default TabButtonsMob2