import React from "react";
import "../../App.css";
import image from "../../Images/Tile22.png";
import dropImage from "../../Images/removeWorking.png";
import vector from "../../Images/Vector.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import TilesWeb from "./tilesWeb";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function Tiles() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);
  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation()

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  useEffect(() => {
    context.setData((pre) => ({ ...pre, perRoomDesignCharge: price }));
    // console.log("hi",context.data.userData)
  }, [price]);

  function handleChange(e) {
    if (e.target.value.length <= 4 && e.target.value >= 0) {
      setPrice(e.target.value);
    }
  }

  function handleSubmit() {
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);

      localStorage.setItem("tiles", price)
      navigateTo("/premiumplasticemulsion");
    }
  }

  return (
    <>
    {window.screen.width > "768" ?
    <TilesWeb />
    :
    <div className="main-falseceiling">
      <div className="falseceiling-upper d-flex flex-column align-items-center">
      <img style={{backgroundColor:"#BFD4FF"}} src={image} />
            <p style={{ fontSize: "23px", textAlign: "center", fontWeight: "500", marginTop: "2rem" }}>
      How much fees would you <br /> charge for <strong> Fixing of 2 × 2 <br /> Tiles with Cement and <br /> Sand </strong>
      </p>
            <input value={price} style={showError ? { borderColor: "red", textAlign: "center", width: "68%", marginTop: "7%" } : { textAlign: "center", width: "68%", marginTop: "7%" }} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" />
            <span style={{ color: "red", fontSize: "10px", alignSelf: "center", marginTop: "4px", alignItems: "center" }} className={`${showError ? "" : "d-none"}`}>You cannot leave this field empty</span>
      </div>
      <div  className="d-flex flex-column align-items-center">
            <div className="virtualInfo1" onClick={() => {
              setShowWorking(true);
            }}>
        <button
        >
          <img src={vector} />
        </button>
        <span style={{color:"#888888"}}>What are these Services we are <br />
         talking about? </span>
      </div>
            <div className="btn-container1" style={{ boxShadow: "none" }}>
        {/* <Link to="/addproject"> */}
        <button onClick={handleSubmit} className="nextbutton">
          Next
        </button>
        {/* </Link> */}
      </div>
      </div>
      {showWorking && (
        <Offcanvas className="virtualWorking" show={true} placement="bottom" >
          <Offcanvas.Header className="offcanvasBtn">
            <button
              onClick={() => {
                setShowWorking(false);
              }}
              className="removeWorking"
            >
              <img src={dropImage} />
            </button>
          </Offcanvas.Header>
              <Offcanvas.Body className="offcanvasBody1" style={{
                paddingRight: "16px",
                paddingLeft: " 4px"
              }}>
            <div>
              <p className="howZoomWorks">What are these Services we are talking about?</p>
            </div>
            <div>
              <ul className="offcanvasList">
                <li>
                Rates to be provided without GST. GST will be extra as applicable
                </li>
                <li>
                Rates subject to minimum order value.
                </li>
                <li>
                iDesign does not take any commission from your Business
                </li>
              </ul>
              <p className="note">Note : All prices exclusive of GST</p>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
    }
    </>
  );
}

