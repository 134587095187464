import React, { useEffect } from 'react'
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
import "./3d.css"
import { useState } from 'react';
import TabButtonsMob from './TabButtonsMob';
import completed from './3dImages/completed.svg'
import locked from './3dImages/locked.svg'
import { Link, useNavigate } from 'react-router-dom';
import PhotoUpload from './PhotoUpload';
import SelectRoom from './SelectRoom';
import UploadRefMain from './UploadRefMain';
import { useSelector } from 'react-redux';
import UploadReference0 from './UploadReference0';
import UploadReference1 from './UploadReference1';
import side from './3dImages/side.svg'


const MakeOneMob = () => {
    const autocadFile = useSelector((state) => state.threedReducer.autocadFile);
    // console.log(autocadFile)
    const pdfFile = useSelector((state) => state.threedReducer.pdfFile);
    const [boolClicked, setBoolClicked] = useState(false)
    const [boolClicked2, setBoolClicked2] = useState(false)
    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);


    const updateMedia = () => {
        setDesktop(window.innerWidth > 650);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });


    const navigate = useNavigate()



    return (
        <>
            <div className="d-block d-md-none tabDisplay">
                <div className="tab_main">
                    <div className="tab_mobile">
                        <a className="d-flex align-items-center" style={{ textDecoration: "none", color: "black" }}>
                            <span className="d-flex align-items-center">
                                <div className="me-3 d-flex align-items-center">
                                    <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                                </div>
                                <div className="page-Heading">3D Feature</div>
                            </span>
                        </a>
                    </div>

                    <div className="tabs_mob_main">
                        <div className="tab_mob1-unactive" >
                            {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : (localStorage.getItem("makeOne") || localStorage.getItem("makepdf")) ? <img src={locked} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : <button className="no2">1</button>}
                            <p className="no1_line d-none">Upload AutoCAD file</p>
                        </div>
                        <div className="tab_mob1-unactive">
                            {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : (localStorage.getItem("makeOne") || localStorage.getItem("makepdf")) ? <img src={locked} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : <button className="no2">2</button>}
                            <p className="no1_line d-none">Select room & views</p>
                        </div>
                        <div className="tab_mob1-active" >
                            <button className="no1">3</button>
                            <p className="no1_line">Upload reference PPT</p>
                        </div>
                    </div>
                        {<UploadReference1 />}
                    </div>
                </div>
           
        </>
    )
}

export default MakeOneMob