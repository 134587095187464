import React from "react";
import styles from "../Products/ProductsCss/productSec11.module.css";


const ProductSection11 = () => {
  return (
    <>
      <div className={styles.container} id="inventory">
        <div className={styles.files_contain}>
          <div className={styles.img_div}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610954.svg"
              alt=""
              className={styles.mainImg}
            />
          </div>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>Inventory</div>
            <div className={styles.file_para}>
              Efficiently manage their inventory, streamline procurement
              processes, and ensure timely availability of materials and
              resources.
            </div>

            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/alarm_smart_wake.svg" alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                Inventory Alerts and Notifications
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/hub.svg" alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                Centralized Inventory Management
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/person_book.svg" alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                Supplier <br /> Management
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection11;
