import React from 'react'
import ProfileList from '../onBoarding/ProfileList';
import ProfileMob4 from "../onboardingMob/ProfileMob4";
import HeaderMob from '../onboardingMob/HeaderMob';
import { useState,useEffect } from 'react';

const ProfileCombined = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
    <>
    {isDesktop?<ProfileList/>:
    <>
     <HeaderMob/>
     <ProfileMob4/>

    </>
    }
    </>
  )
}

export default ProfileCombined;