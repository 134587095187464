export const menuData = [
  {
    nav_name: "Features",
    link: "",
    type: "megamenu",
    full_width: true,
    sub_nav: [
      {
        title: "Project Management",
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Gear.svg",
        data: [
          {
            route: "/fileManager",
            name: "File Manager",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/taskManager",
            name: "Task Manager",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/mom",
            name: "Minutes of Meeting",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/timelineTimesheet",
            name: "Timeline and Timesheet",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/rfi",
            name: "RFI",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/materialRequest",
            name: "Material Request",
            link: "/seo-services/keyword-research",
          },
        ],
      },
      {
        title: "Cost Management",
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CurrencyDollar.svg",
        data: [
          {
            route: "/quotation",
            name: "Quotation",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/account",
            name: "Accounts",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/vendorPO",
            name: "Vendor and PO",
            link: "/seo-services/keyword-research",
          },
        ],
      },
      {
        title: "Client Management",
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/UsersThree.svg",

        data: [
          {
            route: "/newLead",
            name: "Leads",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/chat",
            name: "Chat",
            link: "/seo-services/keyword-research",
          },
        ],
      },
      {
        title: "Additional Features",
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CirclesThreePlus.svg",

        data: [
          {
            route: "/libraryTemplates",
            name: "Library and Templates",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/itemLibraryInventory",
            name: "Item Library and Inventory",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/attendance",
            name: "Attendance",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/companyManagement",
            name: "Company Management",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/teamManagement",
            name: "Team management",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/mobile",
            name: "Native Mobile App",
            link: "/seo-services/keyword-research",
          },
          {
            route: "/extras",
            name: "Extras",
            link: "/seo-services/keyword-research",
          },
        ],
      },
    ],
  },
  {
    nav_name: "Who we Serve",
    link: "",
    type: "megamenu2",
    full_width: true,
    sub_nav: [
      {
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/House.svg",
        route: "/new/design",
        title: "Interior Designers",
        descriptions:
          "Manage design to construction with intuitive features for the project journey",
      },
      {
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CompassTool.svg",
        route: "/new/architect",
        title: "Architects",
        descriptions:
          "Streamline your design process to enhance efficiency and profitability",
      },
      {
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Hammer.svg",
        route: "/new/contractor",
        title: "Construction Companies",
        descriptions: "Efficiently manage projects and teams",
      },
      {
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Buildings.svg",
        route: "/new/estate",
        title: "Real Estate Companies",
        descriptions: "Efficiently manage projects and teams",
      },
      {
        icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ShoppingCart.svg",
        route: "/new/retail",
        title: "Retail Companies",
        descriptions:
          "Get a complete overview of your stores with dynamic updates",
      },
    ],
  },
  {
    nav_name: "Pricing",
    link: "",
    type: "pricing",
    full_width: true,
    route:"/pricing"
  },
  {
    nav_name: "Blogs",
    link: "",
    type: "blogs",
    full_width: true,
    route:"https://blogs.idesign.market/"
  },
  // {
  //   nav_name: "Resources",
  //   link: "",
  //   type: "megamenu2",
  //   full_width: true,
  //   sub_nav: [
  //     {
  //       icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/House.svg",

  //       title: "Blogs",
  //       descriptions:
  //         "Stay up to date with our latest news and features update.",
  //     },
  //     {
  //       icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CompassTool.svg",

  //       title: "Case Studies",
  //       descriptions:
  //         "Explore Success Stories: Real-world Results with Our Software",
  //     },
  //   ],
  // },
];
