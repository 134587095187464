import React, {useState, useEffect, useRef} from "react";
import styles from "./Common.module.css";
import { useNavigate } from "react-router-dom";

const CommonHero=({btnHeading, heading, desc})=>{
  const navigate=useNavigate();
  const [scrollButtonVisible, setScrollButtonVisible] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const buttonRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const button = buttonRef.current;
      if (button) {
        const buttonRect = button.getBoundingClientRect();
        const isVisible =
          buttonRect.top >= 0 &&
          buttonRect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);
        setScrollButtonVisible(isVisible);
        setAlertVisible(!isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

    return(
        <>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
        <div className={styles.btnHeading}>
            <div className={styles.btnHeadingTxt}>
            {btnHeading}
            </div>
        </div>
        <div className={styles.mainheading}>{heading}</div>
        <div className={styles.description}>{desc}</div>
        <div className={styles.scheduleDemoContainer} style={{userSelect:"none"}} onClick={()=> navigate("/schedule")}  ref={buttonRef}>
            <div className={styles.scheduleDemoText}>
            Schedule a Demo
            </div>
        </div>
        </div>
        <div className={styles.rightContainer}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Feature%20video.svg" alt="hero img"
        className={styles.heroImg}
        />
        </div>
        {alertVisible && (<div className={styles.btnOuterContainer}>
          <div
            className={styles.scheduleDemoContainerSticky}
            onClick={() => navigate("/schedule")}
          >
            <div className={styles.scheduleDemoText}>Schedule a demo</div>
          </div>
        </div>)}
      </div>
        </>
    )
}

export default CommonHero;