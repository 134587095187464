import React, { useEffect } from 'react'

import "../../App.css";
import image from "../../Images/perSquareftPage.svg";
import close from "../../Images/close.png";
import vector from "../../Images/Vector.png";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Offcanvas } from "react-bootstrap";

import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';

export default function DesignerPerSqFtWeb() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation()


  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  function handleChange(e) {
    if (e.target.value.length <= 5 && e.target.value >= 0) {
      setPrice(e.target.value);
    }
  }

  function handleSubmit(e) {
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);
      localStorage.setItem("designFee", price);
      context.setData((pre) => ({ ...pre, designFeePerSqFt: price }));
      console.log(context.data);
      navigateTo("/perroomfee");
    }
  }
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      {/* {showWorking && ( */}
      <Modal className="web-virtualWorking" show={showModal} onHide={() => { setShowModal(false) }} centered>
        <Modal.Body className="offcanvasBody" style={{ paddingLeft: "0" }} >
          <img src={close} alt="" className='d-flex float-end' onClick={() => setShowModal(false)} style={{
            opacity: "0.5",
            height: "13px",
            width: "13px",
            cursor: "pointer"
          }} />
          <div>
            <p className="howZoomWorks" style={{ paddingLeft: "14px" }}>How Does Fees per Sqft Work?</p>
          </div>
          <div>
            <ul className="offcanvasList">
              <li style={{ width: "100%" }}>
                Provide a per sqft fees for all interior design services - <b>including drawings, visits, 3Ds, GFCs etc</b>

              </li>
              <li style={{ width: "100%" }}>
                Connect with Homeowners and<b> provide Quotations and Explain Offerings</b>
              </li>
            </ul>
            <p style={{ paddingLeft: "16px" }}>Note : All prices exclusive of GST</p>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <div className="main-professionContainer-web d-flex justify-content-center align-items-center">
        <div style={{ background: "white", borderRadius: "10px" }} className="main-div row">
          <div style={{ fontSize: "1.2rem", fontWeight: "500", position: "relative" }} className="chooseprodessionweb col d-flex flex-column  align-items-center">
            <img style={{ marginTop: "3rem", width: "50%", height: "32%" }} src={image} />
            <p className='onoarding_header' style={{ textAlign: "center", fontWeight: "500", marginTop: "1rem", color: "#121212" }}>
              How much would you charge <br /> for per Sqft basis for Home <br /> Interiors ?
            </p>
            {/* <input className="text-center" style={showError ? { borderColor: "red" } : null} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" /> */}


            <div style={{ position: "absolute", bottom: "2rem" }} className="designerweb-virtualInfo" onClick={() => {
              setShowWorking(true);
              setShowModal(true)
            }}>
              <button
                className="p-0"

                style={{ width: "0.7rem", height: "0.7rem" }}
              >
                <img style={{ width: "100%", height: "100%", marginTop: "3px" }} src={vector} />
              </button>
              <span style={{ cursor: "pointer" }}>How Does Fees Per Sq.ft work? </span>
            </div>

          </div>
          <div style={{ background: "#F7F7F7", position: "relative", borderRadius: "0 10px 10px 0" }} className="col d-flex justify-content-center align-items-center flex-column">
            <input className="web-consultation-charges" style={showError ? { borderColor: "red" } : null} onChange={handleChange} type="number" value={price} placeholder="Please enter the amount in INR" onKeyDown={(e) =>
              exceptThisSymbols.includes(e.key) && e.preventDefault()} />
            <span style={{ color: "red", textAlign: "center", fontSize: "12px", alignSelf: "center", marginTop: "4px" }} className={`${showError ? "" : "d-none"}`}>
              You cannot leave this field empty
            </span>
            <button onClick={handleSubmit} className="webdesigner-nextbutton">
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
