import React, { useEffect } from 'react'
import { useState } from 'react'
import residentialImg from "../../Images/Residential.png";
import officeImg from "../../Images/Office.png";
import showroomImg from "../../Images/Showrooms & Retail.png";
import retailImg from "../../Images/High End Retail.png";
import farmhouseImg from "../../Images/Farmhouse.png";
import factoryImg from "../../Images/Factory & Warehouse.png";
import hotelsImg from "../../Images/Hotels.png";
import restaurantsImg from "../../Images/Restaurants.png";
// import bluetick from "../../Images/blueTick.png";
import bluetick from "../../Images/boxtick.svg";
import { Link } from "react-router-dom";


import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';

const picArr = [
    [residentialImg, "Residential"],
    [officeImg, "Office"],
    [showroomImg, "Showrooms"],
    [retailImg, "High End Retail"],
    [farmhouseImg, "Farmhouses"],
    [factoryImg, "Factory"],
    [hotelsImg, "Hotels"],
    [restaurantsImg, "Restaurants"],
  ];

export default function InteriorServicesDesignerWeb() {
    const [currentIds, setCurrentIds] = useState([]);
    // const [list,setList] = useState([])
    const [service, SetService] = useState();
  
    const context = useContext(OnboardingContext);
    const navigateTo = useNavigate();
    const location = useLocation()


    useEffect(()=>{
      localStorage.setItem("path", location.pathname)
  },[])
  
    function handleClick(e) {
      let flag = false;
      for (let i = 0; i < currentIds.length; i++) {
        if (currentIds[i] == e.target.id) {
          flag = true;
        }
      }
      let ids = currentIds.filter((id, i) => {
        return id != e.target.id;
      });
  
      if (flag == false) {
        setCurrentIds([...currentIds, e.target.id]);
      } else {
        setCurrentIds([...ids]);
      }
      console.log(currentIds);
      context.setData((pre) => ({ ...pre, typeOfService: currentIds }));
    }
  
    // console.log(currentIds)
  
    function handleSubmit() {
      localStorage.setItem("services", currentIds);
      context.setData((pre) => ({ ...pre, typeOfService: currentIds }));
      console.log(currentIds);
      console.log(context.data);
  
      navigateTo("/feepersquarefeet");
    }
  
  return (
    <>
     <div className='main-professionContainer-web d-flex justify-content-center align-items-center'>
        <div style={{ background: "white", borderRadius: "10px" }} className='main-div row'>
        <div style={{fontSize:"30px",fontWeight:"500",textAlign:"center"}} className="chooseprofessionweb col d-flex justify-content-center align-items-center">
        Which Interior Services <br /> do you provide ? 
            </div>
          <div style={{ background: "#F7F7F7", position: "relative", borderRadius: "0 10px 10px 0" }} className='col d-flex justify-content-center align-items-center'>

        <div  className="col allblockContainer-designerweb">
        {picArr.map((pic, i) => {
              return (
                <div className={`servicesname-block  ${currentIds.includes(pic[1]) ? "block-active" : ""}`} key={pic[1]} id={pic[1]} onClick={handleClick} style={{ position: "relative", cursor: "pointer" }}>
                  <img className="services-main-pic" id={pic[1]} src={pic[0]} />
                  {currentIds.includes(pic[1]) && (
                    <img
                      className="web-bluetick"
                      src={bluetick}
                      style={{
                        position: "absolute",
                        top: "0.2rem",
                        right: "0.2rem",
                        width: "19px",
                        height: "19px"
                      }}
                    />
                  )}
                  <p className="services-name"  key={pic[1]} id={pic[1]} onClick={handleClick}>{pic[1]}</p>
                </div>
              );
            })}
        </div>
        {currentIds.length > 0 && (
          
            
            <button onClick={handleSubmit} className="services-nextbutton">
              Next
            </button>
           
          
        )}
        </div>
        </div>
     </div>
    </>
  )
}

