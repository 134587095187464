import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ScheduleADemo from "./ScheduleADemo";
import styles from "./ProductsCss/productSec5.module.css";
// import back from "./ProductsImg/reports/Rectangle 7474.png";
// import img5 from "./ProductsImg/reports/daily.svg";
import icon1 from "./ProductsImg/reports/start.svg";
import icon2 from "./ProductsImg/reports/bar_chart.svg";
import icon3 from "./ProductsImg/reports/legend_toggle.svg";

const ProductSection5 = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className={styles.container} id="report">
        <div className={styles.files_contain}>
        <div className={styles.img_div}>
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/repNew.svg" alt="" className={styles.mainImg}/>
          </div>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>DAILY PROJECT REPORT</div>
            <div className={styles.file_para}>
            Ensures that all team members give daily reports and make team aware of the current status of the project and make informed decisions.
            </div>
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Improved project <br /> accountability
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Real-time <br />progress  tracking
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img
                    src={icon3}
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>Project status <br /> updates</div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default ProductSection5;
