import React, { useEffect } from "react";
import HamburgerMenu from "../../../SaasOnboardingMobile/HamburgerMeun";
import FooterMobile from "../../../SaasOnboardingMobile/FooterMobile";
import styles from "../PricingPageNewMob/PricingNewMob.module.css";
import { useState } from "react";
import BasicMob from "./BasicMob";
import EnterpriseMob from "./EnterpriseMob";
import SecondComponent from "./SecondComponent";
import Testimonials from "../../Pricing/Testimonials";
import OtherCompanies from "../../Pricing/OtherCompanies";
import QAndA from "../../Pricing/QAndA";
import LocationComponent from "./LocationComponent";
import BasicMobDubai from "./BasicMobDubai";
import NewTestimonialsMobile from "../../../../../NewHome/NewTestimonialsMobile";
import NewFooter from "../../../../../NewHome/NewFooter";
import HamburgerPage from "../../../../../NewHome/hamburgerMenus/HamburgerPage";
import HeaderMob from "../../../../../NewHome/HeaderMob";

const PricingNewMob = () => {
  const [openHamburger, setOpenHamburger] = useState(false);
  console.log(openHamburger,"menuvalue")

  useEffect(()=>{
    localStorage.getItem("fromCountry")=="Dubai" ? localStorage.setItem("price", 699) : localStorage.getItem("fromCountry")=="Sydney" ?localStorage.setItem("price", 299) : localStorage.setItem("price", 25000)
  },[])

  const [changePrice, setchangePrice] = useState("quarter");
  const [plan, setPlan] = useState("3 months");
  
  return (
    <>
      {/* <HamburgerMenu /> */}
      {openHamburger && <HamburgerPage openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />}
      {!openHamburger &&(
      <HeaderMob openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />)
      }
      {!openHamburger && <div
        className="d-flex flex-column newpricingmain"
        style={{
          position: "relative",
          paddingTop: "3rem",
          paddingBottom: "1.5rem",
          // marginBottom: "4rem",
          background: "#F4F8FB",
        }}
      >
        <div className={styles.newpricingheader22}>
          Seamless Solutions,
          <br /> Straightforward Pricing
        </div>

        <div
          className="d-flex flex-column w-100"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         
          }}
        >
          <div
            className=" w-100 "
            style={{
              marginTop: "0.6rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "35%",
               
              }}
            >
            </div>

            <div
              className="d-flex p-1 align-items-center "
              style={{
                // background: "#FFFFFF",
                border: "1px solid #D9D9D9",
                // borderRadius: "50px",
                marginTop: "0.5rem",
                borderRadius: "3.125rem",
                background: "#538CCE",
                boxShadow: "0px 4px 12px 0px rgba(33, 33, 33, 0.20)",
                // width: "10%",
                // marginBottom:"1rem"
              }}
            >
              <div
                className={
                  plan === "3 months"
                    ? styles.toggleactive
                    : styles.toggleunactive
                }
                onClick={() => {
                  setPlan("3 months");
                  setchangePrice("quarter");
                  localStorage.setItem("paymentPlan", "Quarterly" )
                  // localStorage.setItem("price", 25000);
                  localStorage.getItem("fromCountry")=="Dubai" ? localStorage.setItem("price", 699) : localStorage.getItem("fromCountry")=="Sydney" ?localStorage.setItem("price", 299) : localStorage.setItem("price", 25000)
                }}
              >
                Quarterly billing
              </div>

              <div
                className={
                  plan === "Yearly"
                    ? styles.toggleactive
                    : styles.toggleunactive
                }
                onClick={() => {
                  setPlan("Yearly");
                  setchangePrice("yearly");
                  localStorage.setItem("paymentPlan", "Yearly")
                  // localStorage.setItem("price", 20000);
                  localStorage.getItem("fromCountry")=="Dubai" ? localStorage.setItem("price", 599) : localStorage.getItem("fromCountry")=="Sydney" ?localStorage.setItem("price", 249) : localStorage.setItem("price", 20000)
                }}
              >
                Yearly billing
              </div>
            </div>
            {changePrice === "yearly" ? (
                  <div className={styles.save25btn}>
                    <span className={styles.save25btnText}>Save 20%</span>
                  </div>
              ) : (
                ""
              )}
          </div>

          {/* box divs */}
          <div className={styles.Mainbox_div}>
            <div style={{ width: "fit-content", display: "flex", gap: "1.52rem", flexDirection:"column", justifyContent:"center", marginLeft:"1.38rem", marginRight:"1.38rem" }}>
              {/* <BasicMob change={changePrice} forScroll="#jumpto" />
              <EnterpriseMob forScroll="#jumpto" /> */}
              <LocationComponent change={changePrice} forScroll="#jumpto" />
            </div>
          </div>
        </div>
      </div>}
        {!openHamburger && <SecondComponent forScroll="jumpto" />}
       {!openHamburger && <div style={{ height: "1.5rem", background: "#F4F8FB" }}></div>}
      {!openHamburger && <NewTestimonialsMobile />}
        <div style={{ paddingTop: "2rem", background: "#F4F8FB", height:"100%", width:"100%" }}>
       {!openHamburger && <OtherCompanies />}
       {!openHamburger && <QAndA />}
        {/* <FooterMobile /> */}
       {!openHamburger && <NewFooter />}
        </div>
    </>
  );
};

export default PricingNewMob;
