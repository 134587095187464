import React, { useEffect } from "react";
import "../App.css";
import residentialImg from "../Images/Residential.svg";
import officeImg from "../Images/Office.svg";
import showroomImg from "../Images/Showrooms & Retail.svg";
import retailImg from "../Images/High End Retail.svg";
import farmhouseImg from "../Images/Farmhouse.svg";
import factoryImg from "../Images/Factory & Warehouse.svg";
import hotelsImg from "../Images/Hotels.svg";
import restaurantsImg from "../Images/Restaurants.svg";
import bluetick from "../Images/blueTick.svg";
import { Link } from "react-router-dom";
// import { updateUserProfile } from "../Apis";
import { useState } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../Context/Context";
import { useNavigate } from "react-router";
import InteriorServicesDesignerWeb from "./OnboardingWebScreens/InteriorServicesDesignerWeb";
import { useLocation } from "react-router-dom";

const picArr = [
  [residentialImg, "Residential"],
  [officeImg, "Office"],
  [showroomImg, "Showrooms"],
  [retailImg, "High End Retail"],
  [farmhouseImg, "Farmhouses"],
  [factoryImg, "Factory"],
  [hotelsImg, "Hotels"],
  [restaurantsImg, "Restaurants"],
];

export default function Comp2() {
  const [currentIds, setCurrentIds] = useState([]);
  // const [list,setList] = useState([])
  const [service, SetService] = useState();

  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation()

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  function handleClick(picName) {
    if (!currentIds.includes(picName)) {
      setCurrentIds([...currentIds, picName]);
    } else {
      setCurrentIds(currentIds.filter((curElem) => {
        return curElem !== picName;
      }));
    }
  }

  function handleSubmit() {
    localStorage.setItem("services", currentIds);
    context.setData((pre) => ({ ...pre, typeOfService: currentIds }));
    console.log(currentIds);
    console.log(context.data);

    // navigateTo("/designtype");
    navigateTo("/feepersquarefeet");
  }

  useEffect(() => {
    context.setData((pre) => ({ ...pre, typeOfService: currentIds }));
  }, [currentIds])

  return (
    <React.Fragment>
      {window.screen.width > "768" ?
        <InteriorServicesDesignerWeb />
        :
        <div className="comp2">
          <div className="interiorServices">
            <div>
              <h2>
                Which Interior Services <br /> do you provide ?
              </h2>
              <div className="allblockContainer">
                {picArr.map((pic, i) => {
                  return (
                    <div key={pic[1]} id={pic[1]} onClick={() => handleClick(pic[1])} className={`block ${currentIds.includes(pic[1]) ? "block-active" : ""}`} style={{ position: "relative" }}>
                      <img className="main-pic" id={pic[1]} src={pic[0]} />
                      {currentIds.includes(pic[1]) && (
                        <img
                          className="bluetick"
                          src={bluetick}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "6px",
                            width: "20px",
                            height: "20px"
                          }}
                        />
                      )}
                      <p>{pic[1]}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {currentIds.length > 0 && (
              <div className="my-3">
                {/* <Link to="/designtype"> */}
                <button onClick={handleSubmit} className="nextbutton">
                  Next
                </button>
                {/* </Link>  */}
              </div>
            )}
          </div>
        </div>
      }
    </React.Fragment>
  );
}
