import React from 'react';
import styles from "../onBoardingCss/welcome.module.css";
import { useNavigate } from 'react-router-dom';
import { useState ,useEffect} from 'react';
import axios from 'axios';
import CongratulationsModal from '../../Pricing/CongratulationsModal';
import {BASE_URL_ESS, constants } from '../../../../../constants';

const Welcome = () => {
  console.log(constants)
  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const [getData, setgetData] = useState([]);

  const GetName=async()=>{
    await axios.get(`${constants.B2B_BASE}/b2b/user/profile`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: authTok,
      },
    }).then(function(response){
        console.log(response?.data?.data);
        setgetData(response?.data?.data);
    }).catch(function(error){
      console.log(error);
    })
  }

  console.log(getData)
  useEffect(() => {
    GetName();
  }, [])
  
    const navigate=useNavigate();

    useEffect(() => {
      function preventBack() { 
        window.history.forward();  
      } 
      
      setTimeout(preventBack(), 0); // Pass the function reference directly
      
      window.onunload = function() { return null; }; // Assign a function reference or function body
    }, []);

  return (
    <>
    {/* <CongratulationsModal /> */}
    <div className={styles.container}>
      <div className={styles.heading}>
      Welcome {getData?.companyName}, Let’s get you Onboard
        </div>
      <div style={{display:'flex',gap:"1rem",marginTop:"2rem"}}>
        <button className={styles.btn1} onClick={()=>
        //  window.location.assign(`${constants.PRO_URL}personal-data`)
          window.location.assign(`${constants.PRO_URL}company-details?userId=${localStorage.getItem("userId")}&token=${localStorage.getItem("token")}`)
          // navigate("/personal")
          }>Let’s get started</button>
       {/* <a href={BASE_URL_ESS} className={styles.btn2}  style={{textDecoration:'none'}} >Go to Dashboard</a> */}
      </div>
    </div>
    </>
  )
}

export default Welcome