import React, { useEffect, useState } from "react";
import styles from "./Common.module.css";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const CommonSteps = ({ steps, heading }) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [showButton, setShowButton] = useState(false);
  const [showButton1, setShowButton1] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }

      if (window.scrollY > 300) {
        setShowButton1(true);
      } else {
        setShowButton1(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
      return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 200) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  
  return (
    <div className={styles?.stepsContainer}>
      {/* <div
        style={{ display: showButton ? "block" : "none" }}
        className={styles?.icon}
      >
        <img
          src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
          alt=""
          onClick={() => scrollToTop()}
        />
      
      </div>  */}
      {/* <div className={`${styles.icon} ${styles.stickyImage}`} style={{ display: showButton ? "block" : "none", height: "5.5rem" }}>
        <img
          src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
          alt="" onClick={() => scrollToTop()}
          className={styles.topIconImg}
        /></div> */}
      <div
        style={{ bottom:0, padding: '1.5rem 1.5rem', background: 'white', width: '100%', display: showButton ? "block" : "none" }}
        className={styles?.icon}
      >
        <div
          className={styles.btn2}
          style={{ width: '100%' }}
          onClick={() => {
            scrollToTop();
            navigate("/schedule");
          }}
        >
          <div> Schedule a Demo</div>
        </div></div>
        {isDesktop?
    <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton ? "block" : "none", height:"5.5rem" }}>
    <img  data-tooltip-id="my-tooltip" data-tooltip-content="Go to top"
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png" 
              alt="" onClick={() => scrollToTop()} 
              className={styles.topIconImg}
              /></div>
            :
            <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton1 ? "block" : "none", height:"3rem" }}>
  <img 
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png" 
            alt="" onClick={() => scrollToTop()} 
            className={styles.topIconImg}
            /></div>
}
<Tooltip id="my-tooltip" />
      <h3>{heading}</h3>
      <div className={styles?.stepflex}>
        {steps?.map((step, index) => (
          <div className={styles?.steps} key={index}>
            <div className={styles?.stepDetails}>
              <p className={styles?.scount}>{step?.step}</p>
              <p className={styles?.sdetail}>
                <p className={styles?.heading}>{step?.heading}</p>
                <p className={styles?.desc}>{step?.desc}</p>
                {/* <p className={styles?.desc} dangerouslySetInnerHTML={{ __html: step?.desc }}></p> */}
              </p>
            </div>
            <div className={styles?.stepImage}>
              <img src={step?.image} alt="step" />
              {/* <div style={{backgroundImage:`url('${steps?.image}')`}}></div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonSteps;
