import { postReq } from "./apis";

export function getToken() {
    const token = localStorage.getItem('token');
    if (token) {
        return "Bearer "+token;
    } else {
        return null;
    }
}

export function getLoginId() {
    const loginid = localStorage.getItem('userId');
    if (loginid) {
        return loginid;
    } else {
        return null;
    }
}

export async function uploadImages(data) {
    console.log(data)
    let imgshtml = ""
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      const payload = new FormData();
      payload.append("uploadImg", el);
      const res = await postReq("https://noteyard-backend.herokuapp.com/api/blogs/uploadImg", payload);
      if (res && !res.error) {
        console.log(res);
        imgshtml += `<img src="https://noteyard-backend.herokuapp.com/${res?.data?.url}" />`
      } else {
        return { error: "Couldn't Upload Img" };
      }
    }
  
    return imgshtml;
  }

const expf = {
    getToken,
    getLoginId,
    uploadImages
}

export default expf;