export const fileManagerArray = [
    {
      heading: "Streamlined File Hierarchy with Versioning",
      subHeading:
        "Control your files with ease, upload, manage, never miss a piece!",
      list: [
        "Upload files effortlessly",
        "Streamline version control",
        "Navigate through a structured file hierarchy",
      ],
      background: "#F0EFF6",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1fm.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/fmone.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/FileManagerOne.png",
      transform: "scale(1.08)",
      marginLeftImg:"2.8rem",
      marginRight:"7rem",
      transformOrigin:"left",
      marginBottom:"6rem"
    },
    {
      heading: "Collaborative File Review and Feedback",
      subHeading:
        "Empower collaboration, streamline feedback, effortlessly manage files, in one neat pack!",
      list: ["Create reviews", "Comment and collaborate", "Provide feedback"],
      background: "#FFF2E5",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2fm.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/fmtwo.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/FM2Mob.png",
      transform: "scale(1.06)",
      marginRightImg:"4.5rem",
      marginLeft:"7.5rem",
      transformOrigin:"right",
      marginBottom:"4rem",
      marginTopMob:"-1rem"
    },
    {
      heading: "Efficient Transmittal Creation and Management",
      subHeading:
        "Send files seamlessly, every detail. Transmittal feature, your project's sail!",
      list: [
        "Generate transmittals",
        "Include pertinent details and documents for efficient communication.",
        "Streamline file distribution and tracking within your ERP system",
      ],
      background: "#F0EFF6",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3fm.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/fmthree.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/FM3Mob.png",
      transform: "scale(1.07)",
      marginLeftImg:"4.2rem",
      marginRight:"7rem",
      transformOrigin:"left",
      marginBottom:"4rem",
      marginTopMob:"-1.8rem",
      marginBottomImgMob:"-1rem"
    },
  ];
  
  export const taskManagerArray = [
    {
      heading: "Team-Focused Progress Tracking System",
      subHeading:
        "Stay in sync, team-wide view, track progress, comment, breakthrough.",
      list: [
        "Monitor individual and team progress with detailed tracking.",
        "Access multiple views to analyse tasks from different perspectives.",
        "Collaborate effectively by commenting directly within the task manager",
      ],
      background: "#F0EFF6",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1tm.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/tmone.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TM1Mob.png",
      marginLeftImg:"4.6rem",
      marginRight:"7rem",
      transform: "scale(1.08)",
      transformOrigin:"left",
      marginTop:"3rem",
      marginBottom:"6rem"
    },
    {
      heading: "Streamlined Task Creation and Tracking",
      subHeading:
        "Tasks made simple, wherever you roam, assign, track, checklist, subtasks—bring it home!",
      list: [
        "Create tasks or issues from anywhere.",
        "Assign tasks to team members and monitor progress in real-time.",
        "Enhance with checklists and subtasks",
      ],
      background: "#FFF2E5",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/taskmtwo1.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/taskmtwo1.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TM2Mob.png",
      marginRightImg:"4.6rem",
      marginLeft:"7.5rem",
      transform: "scale(1.08)",
      transformOrigin:"right",
      marginBottom:"6rem",
      marginTopMob:"-1.8rem",
    },
    {
      heading: "Efficient Task Updates and Reporting",
      subHeading:
        "Tasks evolve, reports empower, efficiency peaks with every hour!",
      list: [
        "Update task statuses, progress, and details for real-time tracking.",
        "Generate comprehensive reports on task performance.",
        "Empower decision-making with insights derived from task data analysis.",
      ],
      background: "#E7F1F6",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/tasktmthree1.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/tasktmthree1.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TM3Mob.png",
      marginLeftImg:"2.8rem",
      marginRight:"7rem",
      transform: "scale(1.1)",
      transformOrigin:"left",
      marginBottom:"4rem",
      marginTopMob:"-1.2rem",
    },
  ];
  
  export const momArray = [
    {
      heading: "Meeting Documentation Management System",
      subHeading:
        "Meetings managed, clarity achieved, efficiency reigns, goals conceived!",
      list: [
        "Generate comprehensive MOM documents.",
        "Take and organise meeting notes.",
        "Plan and manage meeting agendas seamlessly to ensure productive discussions.",
      ],
      background: "#F0EFF6",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/momone.png",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1mom.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/MOM1Mob.png",
      transform: "scale(1.12)",
      transformOrigin:"left",
      marginRight:"9.5rem",
      marginTop:"4rem"
    },
    {
      heading: "Meeting Scheduling Tool",
      subHeading:
        "Seamless scheduling, time's at hand, meetings managed, goals well-planned!",
      list: [
        "Schedule meetings and appointments",
        "Coordinate availability and send invitations to participants.",
        "Streamline meetings and ensure punctuality with automated reminders.",
      ],
      background: "#FFF2E5",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/momtwo.png",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2mom.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/momSecondM.png",
      transform: "scale(1.09)",
      transformOrigin:"right",
      marginLeft:"8rem",
      marginBottom:"9rem",
      marginTopMob:"-4rem",
    },
    {
      heading: "Material Selection Process Integration",
      subHeading:
        "Materials sourced with just clicks. Streamlined selection, no more tricks!",
      list: [
        "Access an extensive item library for easy material selection.",
        "Streamline procurement processes by selecting materials directly.",
        "Access and choose materials during discussions.",
      ],
      background: "#E7F1F6",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/momthree.png",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3mom.png",
      mobImg:"https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/MOM3Mob.png",
      transform: "scale(1.08)",
      transformOrigin:"left",
      marginRight:"8rem",
      marginBottom:"8rem",
      marginTopMob:"-0.5rem",
      marginBottomImgMob:"-0.5rem"
    },
  ];
  
  export const materialRequestArray = [
    {
      heading: "Material Procurement Module",
      subHeading:
        "Simplify Procurement: Seamlessly Source and Secure Your Needs!",
      list: [
        "Streamlined procurement: Easily request materials directly from existing quotations for efficient sourcing.",
        "Flexible ordering options: Order items outside of quotations with ease to meet specific project requirements.",
        "Enhanced control: Maintain oversight and manage material requests and orders seamlessly.",
      ],
      background: "#F0EFF6",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1mr.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mr1.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Material1Mob.png",
      transform: "scale(1.125)",
      transformOrigin:"left",
      marginRight:"7rem",
      marginTop:"3rem",
      marginBottom:"5rem"
    },
    {
      heading: "Order History Overview",
      subHeading: "Track, Analyze, Procure: Effortless Insight into Your Orders!",
      list: [
        "Comprehensive visibility: Access detailed records of past order history for informed decision-making.",
        "Real-time tracking: Monitor pending orders to stay updated on procurement status and timelines.",
        "Enhanced efficiency: Streamline material request management by easily viewing both past orders and pending requests.",
      ],
      background: "#FFF2E5",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2mr.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mr2.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Material2Mob.png",
      transform: "scale(1.07)",
      transformOrigin:"right",
      marginLeft:"6rem",
      marginBottom:"5rem",
      marginTopMob:"-2rem",
    },
    {
      heading: "PO Management Interface",
      subHeading: "Empower Procurement: Streamline, Issue, Prosper!",
      list: [
          "Easily view requested materials and their corresponding purchase orders",
          "Efficient procurement: Streamline the process by issuing purchase orders directly from the material request management feature.", 
          "Seamless integration: Ensure smooth coordination between material requests and purchase orders for improved procurement workflow."
      ],
      background: "#F0EFF6",
      image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3mr.png",
      image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mr3.png",
      mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Material3Mob.png",
      transform: "scale(1.07)",
      transformOrigin:"left",
      marginRight:"7rem",
      marginBottom:"5rem",
      marginTopMob:"-3rem",
    },
  ];
  
  export const rfiArray=[
      {
          heading:"RFI Impact Tracker",
          subHeading:"Effortlessly Navigate Changes: RFI Mastery Unleashed!",
          list:[
              "Streamlined RFI creation: Generate multiple RFIs effortlessly within the ERP system for effective communication and documentation.",
              "Impact assessment: Easily add cost or time implications to RFIs to accurately evaluate the effects of requested changes.",
              "Empower stakeholders with clear insights into RFIs' impact on project timelines and budgets for enhanced decision-making."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1rfi.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/rfione.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/RFI1Mob.png",
         marginTop:"1rem",
         marginRight:"7rem",
          transform: "scale(1.065)",
          transformOrigin:"left"
      },
      {
          heading:"View and Update RFI",
          subHeading:"Stay Agile, Stay Ahead: RFI Management Made Simple!",
          list:[
              "Seamless access: Easily view all RFIs within the ERP system for comprehensive oversight.",
              "Real-time updates: Effortlessly update RFIs with the latest information to ensure accuracy and relevance.",
              "Facilitate smooth communication and decision-making by keeping RFIs updated and accessible to stakeholders."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2rfi.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/rfitwo.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/RFI2Mob.png",
          transform: "scale(1.07)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginTop:"5rem",
          marginBottom:"5rem",
          marginTopMob:"-1.5rem",
      }
  ];
  
  export const timelineTimesheetArray=[
      {
          heading:"Time Tracking Dashboard",
          subHeading:"Efficiency Unleashed: Navigate, Allocate, Thrive!",
          list:[
              "Flexible timesheets: Easily create timesheets to track hours worked.",
              "Efficient resource allocation: Allocate hours to specific tasks or phases for accurate project tracking.",
              "Comprehensive Overview: Monitor project timelines and progress by adding phases to timesheets for enhanced organization and analysis."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1ttm.png",
          image:"https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/ttm1.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Timeline1Mob.png",
          transform: "scale(1.1)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginBottom:"5rem",
          marginTop:"1rem",
      },
      {
          heading:"Progress Tracking Interface",
          subHeading:"Stay on Schedule: Track, Visualise, Succeed!",
          list:[
              "Versatile tracking: Monitor project progress using both list and Gantt chart views.",
              "Detailed timesheets: Create detailed timesheets to accurately record hours and tasks.",
              "Enhanced visualisation: Gain project insights with dynamic views for efficient scheduling and resource allocation."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/timeline2.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ttm2.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Timeline2Mob.png",
          transform: "scale(1.05)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"5rem",
          marginTopMob:"-2rem",
      },
      {
          heading:"Task Tracking Interface",
          subHeading:"Effortless Planning: Streamline, Collaborate, Succeed!",
          list:[
              "Comprehensive timeline creation: Easily create detailed timelines with predecessor tasks and subtasks for accurate planning.",
              "Enhanced task management: Add checklists, notes, and assignees to tasks for thorough tracking and collaboration.",
              "Streamlined project coordination: Incorporate all details into the timeline for seamless coordination and enhanced productivity."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/timeline3.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ttm3.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Timeline3Mob.png",
          transform: "scale(1.075)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginBottom:"6rem"
      },
      {
          heading:"UOM Tracking Dashboard",
          subHeading:"Precision in Progress: UOM Tracking, Log, and Visualise!",
          list:[
              "Detailed updates: Receive updates on progress unit of measurement (UOM) wise for precise tracking.",
              "Comprehensive logging: Maintain detailed logs of completed work for project activity records.",
              "Visual documentation: Enhance analysis with pictures for visual project updates."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/4ttm.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ttm4.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Timeline4Mob.png",
          transform: "scale(1)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"7rem",
          marginBottomImgMob:"-1rem"
      }
  ];
  
  export const quotationArray=[
      {
          heading:"Quotation Creation and Management",
          subHeading:"Quoting made easy, with precision, comparison, customization and making decisions!",
          list:[
              "Access an extensive item library and add images for clarity." ,
              "Import data from previous quotations and templates, reducing manual entry.",
              "Compare quotations, customize terms and conditions, and add specifications and variants for comprehensive offerings."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1quo.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/quo4.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Quo1Mob.png",
          transform: "scale(1.09)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginTop:"2rem",
          marginBottom:"7rem",
      },
      {
          heading:"Efficient Approval Matrix Management System",
          subHeading:"Streamline approvals, no delays in sight, matrix management, decisions made right!",
          list:[
              "Customizable approval workflows",
              "Appropriate reviewers and status tracking of approvals in real-time.",
              "Streamline communication and collaboration involved in the approval process."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2quo.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095405%20%282%29.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Quo2Mob.png",
          transform: "scale(1.07)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"3rem",
          marginTopMob:"-3rem",
      },
      {
          heading:"Creation of Budgets and POs",
          subHeading:"Quotation to action, budgets grow, efficiency soars, with every flow!",
          list:[
              "Convert quotations into budgets POs, or material requests.",
              "Utilise quotation data to populate relevant fields, reducing manual data entry and ensuring accuracy.",
              "Eliminate recreating information, saving time and minimizing errors. "
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3quo.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/quo3.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Quo3Mob.png",
          transform: "scale(1.09)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginBottom:"7rem",
          marginTopMob:"-2rem",
      },
      {
          heading:"Manage Change Orders and Variations with Ease",
          subHeading:"Changes tracked, orders aligned right, efficiency shines in every plight!",
          list:[
              "Track and manage change orders or variations to project tasks or specifications within the task manager.",
              "Streamline communication and approval processes",
              "Maintain comprehensive records of variations, including details such as reasons for change, approvals, and impacts on project timelines and budgets."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Quotation4.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/quo1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Quo4Mob.png",
          transform: "scale(1.08)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"8rem",
          marginBottomImgMob:"-2.5rem"
      }
  ];
  
  export const accountsArray=[
      {
          heading:"Streamlined Journal and Ledger Creation",
          subHeading:"Balance the books with ease, journals and ledgers, and financial expertise!",
          list:[
              "Create journals and project level ledgers to track financial transactions",
              "Maintain financial records by categorising entries", 
              "Enable financial analysis and reporting by accessing project-level ledgers and journals "
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1acc.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/accounts1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Accounts1Mob.png",
          transform: "scale(1.085)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginTop:"2rem",
          marginBottom:"4rem"
      },
      {
          heading:"Efficient Payment Requests and Invoicing",
          subHeading:"Requests processed, invoices clear, efficiency shines, year after year",
          list:[
              "Generate payment requests and invoices",
              "Streamline billing processes - utilise built-in templates and automation",
              "Track payment requests and invoices."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2acc.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/accounts2.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Accounts2Mob.png",
          transform: "scale(1.05)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"6rem",
          marginTopMob:"-1rem",
      },
      {
          heading:"Effortless Expense and Receipt Tracking",
          subHeading:"Every cent accounted, every receipt found. With our feature, financial clarity abounds!",
          list:[
              "Track expenses and receipts ",
              "Categorize and organize receipts for easy retrieval and analysis.",
              "Monitor expenses in real-time and generate comprehensive reports."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3acc.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/accounts3.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Accounts3Mob.png",
          transform: "scale(1.065)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginBottom:"4rem"
      },
      {
          heading:"Integrated Payment Requests with Timelines",
          subHeading:"Stay on track, payments aligned, tasks, timelines, bills, all combined!",
          list:[
              "Integrate payment requests with project timelines and tasks ",
              "Link payment requests with running bills",
              "Align payment requests with project milestones and tasks for better budget control."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/4acc.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/acccounts4.svg",
          mobImg:"https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/Accounts4Mob.png",
          transform: "scale(1.05)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"7rem",
          marginTopMob:"-2rem",
      },
  ];
  
  export const VendorPOArray=[
      {
          heading:"Vendor Integration: Efficient Management Solution",
          subHeading:"Vendor harmony, effortlessly attained. In our feature, suppliers sustain!",
          list:[
              "Add and update vendor information",
              "Manage vendor relationships and communications",
              "Enhance vendor visibility and control through centralized vendor management."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/vpo1.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/po1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/VendorPO1Mob.png",
          transform: "scale(1.08)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginBottom:"2rem",
          marginTop:"3rem"
      },
      {
          heading:"Centralized Vendor Catalog Management",
          subHeading:"Catalogs aligned, procurement refined, efficiency reigns, orders defined!",
          list:[
              "Centralize vendor product listings.",
              "Streamline procurement processes.",
              "Enhance purchasing efficiency"
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/vpo2.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/po4.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/VendorPO2MOB.png",
          transform: "scale(1.07)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"7rem",
          marginTopMob:"-2.5rem",
      },
      {
          heading:" Purchase Order Linking System",
          subHeading:"Seamless orders, linked with precision, efficiency rises, with each decision!",
          list:[
              "Create and view purchase orders.",
              "Easily link with bills of materials (BOMs) and quotations",
              "Integrate site requests with overall procurement processes."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/vpo3.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/po3.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/VendorPO3Mob.png",
          transform: "scale(1.075)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginBottom:"4rem"
      },
      {
          heading:"Site Material Request Management",
          subHeading:"From site to purchase, seamless flow. Efficiency shines, with each PO!",
          list:[
              "Streamline material request processing from site locations.",
              "Issue purchase orders based on site requests.",
              "Integrate site requests with overall procurement processes."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/vpo4.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/po2.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/VendorPO4Mob.png",
          transform: "scale(1.05)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"7rem",
          marginTopMob:"-1rem",
          marginBottomImgMob:"-1rem"
      },
  ];
  
  export const leadArray=[
      {
          heading:"Prospect client management",
          subHeading:"Add, edit, assign, and import/export lead data",
          list:[
              "Add new leads to the system and edit existing lead information.",
              "Assign leads to appropriate team members for follow-up and management.",
              "Import and export lead data for easy integration with other systems and data exchange."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1leads.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lead1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/leads1mob.png",
          transform: "scale(1.085)",
          transformOrigin:"left",
          marginRight:"7rem",
          marginTop:"2rem",
          marginBottom:"4rem"
      },
      {
          heading:"Lead Central: Holistic Management",
          subHeading:"Initiate lead processes seamlessly, with precision.",
          list:[
              "Generate and send quotations to potential leads.",
              "Create comprehensive minutes of meetings (MOM) to track discussions and decisions.",
              "Manage lead tasks effectively, ensuring timely follow-up and progress tracking."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2leads.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lead2.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Lead2Mob.png",
          transform: "scale(1.075)",
          transformOrigin:"right",
          marginLeft:"6rem",
          marginBottom:"10rem",
          marginTopMob:"-0.5rem",
      },
      {
          heading:"Lead Analytics Dashboard Access",
          subHeading:"Unlock actionable insights, drive decisions, with comprehensive lead analytics.",
          list:[
              "Gain valuable insights into lead performance and trends.",
              "Easily track key metrics and KPIs related to lead management.",
              "Make informed decisions to optimise lead generation and conversion efforts."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3leads.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lead3.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Lead3Mob.png",
          transform: "scale(1.075)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"7rem",
           marginTopMob:"-3rem",
      },
      {
          heading:"Streamlined Lead Status and Follow-up",
          subHeading:"Empower your team to effectively nurture leads and drive business growth.",
          list:[
              "Update lead status to reflect progress and current status.",
              "Track follow-up actions and communications with leads.",
              "Centralise status changes and follow-up activities"
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/4leads.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lead4.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/leads4mob.png",
          transform: "scale(1.08)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginTopMob:"-1.5rem",
      },
      {
          heading:"Social Media Integration for Leads",
          subHeading:"Connect, engage, convert: harness social media for streamlined lead management.",
          list:[
              "Sync leads from Facebook and Instagram",
              "Centralise lead management across multiple social media platforms.",
              "Enhance lead engagement and conversion through streamlined integration."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Leads5.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lead5.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Lead5Mob.png",
          transform: "scale(1.12)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"5rem"
      },
  
  ];
  
  export const chatArray=[
      {
          heading:"Chat Hub",
          subHeading:"Seamless Collaboration: Connect, Converse, and Expand Effortlessly!",
          list:[
              "Instant communication: Chat with team members and clients in real-time.",
              "Flexible options: Engage in group chats or private 1-1 conversations.",
              "Easy scalability: Add new members to chats effortlessly for enhanced collaboration."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1chat.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/chat1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Chat1Mob.png",
          transform: "scale(1.09)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"5rem"
      },
      {
          heading:"Create Channels",
          subHeading:"Channel Your Communication: Simplify, Organise, and Thrive!",
          list:[
              "Efficient organisation: Create dedicated channels for specific topics, projects, or teams.",
              "Streamlined collaboration: Centralise discussions and updates within designated channels.",
              "Enhanced focus: Keep conversations relevant and accessible, reducing clutter and improving productivity."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2chat.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/chat2.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Chat2Mob.png",
          transform: "scale(1.07)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"5rem",
           marginTopMob:"-1.5rem",
      },
      {
          heading:"Integrated Project Communication",
          subHeading:"Empower Your Workflow: Chat, Share, and Navigate Projects Seamlessly!",
          list:[
              "Effortless file sharing: Share quotations, meeting minutes (MoM), and files instantly with a simple attachment feature.",
              "Seamless project integration: Access your entire project within the chat for streamlined collaboration.",
              "On-the-go productivity: Stay connected and productive with on-the-go document sharing and access."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3chat.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095406%20%281%29.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Chat3Mob.png",
          transform: "scale(1.07)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"5rem",
           marginTopMob:"-2rem",
           marginBottomImgMob:"-0.5rem"
      },
  ];
  
  export const libraryTemplatesArray=[
      {
          heading:"Feature Template Management System",
          subHeading:"Templates tailored, processes refined, solutions designed!",
          list:[
              "Comprehensive library of templates for various features such as quotations, purchase orders.",
              "Use pre-designed templates tailored to specific needs.",
              "Utilize standardized templates"
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1library.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lib1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/LibraryTemplate1Mob.png",
          transform: "scale(1.09)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginTop:"3rem",
           marginBottom:"5rem"
      }, 
      {
          heading:"Lead Capture Form System",
          subHeading:"Leads captured, paths revealed, success beckons, fate sealed!",
          list:[
              "Capture lead information through a user-friendly form interface.",
              "Customize lead forms to gather specific details relevant to your business needs",
              "Integrating lead form data"
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2library.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lib2.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/LibraryTemplate2Mob.png",
          transform: "scale(1.05)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"4rem",
           marginTopMob:"-2rem",
      }, 
      {
          heading:"Work Method Checklist Templates",
          subHeading:"Structured workflows, tasks at hand, success planned, goals grand!",
          list:[
              "Access a variety of checklist templates for different work methods and tasks.",
              "Utilize pre-designed work method statements",
              "Following standardized checklists and work method statements"
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3library.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lib3.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/LibraryTemplate3Mob.png",
          transform: "scale(1.08)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"4rem",
           marginTopMob:"-5rem",
      }, 
      {
          heading:"MOM Template Library Access",
          subHeading:"Reports ready, just a click, tasks slick!",
          list:[
              "Access a diverse range of MOM templates including inventory reports, site kick-off reports, and dilapidation reports.",
              "Utilise pre-designed templates tailored to specific needs.",
              "Follow standardised MOM templates"
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/4library.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/lib4.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/LibraryTemplate4Mob.png",
          transform: "scale(1.05)",
           transformOrigin:"right",
           marginLeft:"6.5rem",
           marginBottom:"4rem",
           marginTopMob:"-1rem",
           marginBottomImgMob:"-2rem"
      },
  ];
  
  export const itemLibraryInventory=[
      {
          heading:"BOM Creation Tool Integration",
          subHeading:"Material lists made with ease, projects seize!",
          list:[
              "Create bills of materials (BOM)",
              "Streamline inventory management by detailing components and quantities required for production.",
              "Improve production accuracy in production processes with standardized BOM tools."
          ],
          background: "#F0EFF6",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/item1.svg",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Item1.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/itemLib1Mob.png",
          transform: "scale(1.08)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"6rem"
      },
      {
          heading:"Item and Category Management",
          subHeading:"Items organized, categories refined, inventory aligned!",
          list:[
              "Add and edit items within the item and category list",
              "Manage inventory by organising items into categories for easy navigation and tracking.",
              "Ensure up-to-date item information."
          ],
          background: "#FFF2E5",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/item2.svg",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2ili.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/itemLib2Mob.png",
          transform: "scale(1.06)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"6rem",
           marginTopMob:"-0.5rem",
      },
      {
          heading:" Inventory Item Creation Tool",
          subHeading:"Inventory stocked with simple clicks, no tricks!",
          list:[
              "Create new inventory items",
              "Streamline inventory management by specifying item details such as name, description, and quantity.",
              "Enhance inventory accuracy by ensuring all items are properly recorded and tracked."
          ],
          background: "#F0EFF6",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/item3.svg",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3ili.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Item3Mob.png",
          transform: "scale(1.11)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"4rem",
           marginTopMob:"-0.5rem",
      },
      {
          heading:"Material Library Management System",
          subHeading:"Materials managed, export with ease, tasks breeze!",
          list:[
              "Add materials to the library ",
              "Simplify data exchange by exporting material information",
              "Enhance inventory management efficiency with streamlined material library management."
          ],
          background: "#FFF2E5",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/item4.svg",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/4ili.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/itemLib4Mob.png",
          transform: "scale(1.07)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"4rem",
           marginTopMob:"-2rem",
      },
  ];
  
  export const attendanceArray=[
      {
          heading:"Geo-fenced Attendance Tracking",
          subHeading:"Track attendance anywhere, anytime, with precision and security through geofencing.",
          list:[
              "Seamless attendance marking: Users can mark attendance from anywhere, onsite or offsite.", 
              "Geo-fencing accuracy: Utilize geo-fencing for precise location-based attendance tracking.",
              "Enhanced security: Secure attendance verification system enhances accountability and transparency."
          ],
          background: "#F0EFF6",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095408%20%281%29.svg",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1att.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/attendanceMob1.png",
          transform: "scale(1.095)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginTop:"2rem",
           marginBottom:"5rem"
      },
      {
          heading:"Attendance Viewing Dashboard",
          subHeading:"Effortlessly monitor team attendance, anytime, anywhere, fostering accountability and productivity.",
          list:[
              "Comprehensive overview: Access detailed attendance reports for teams or individuals.", 
              "Real-time tracking: Monitor attendance updates in real-time, ensuring up-to-date information.",
              "Customizable views: Tailor views to specific needs, such as by department or date range."
          ],
          background: "#FFF2E5",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/att.svg",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2att.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Attendance2Mob.png",
          transform: "scale(1.05)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"5rem"
      },
      {
          heading:"Attendance Report Generation",
          subHeading:"Generate detailed reports, empowering informed decision-making and efficiency.",
          list:[
              "Automated report generation: Easily create weekly or monthly attendance reports with just a few clicks.",
              "Customizable formats: Tailor reports to specific requirements, adjusting layout, and content as needed.",
              "Data insights: Gain valuable insights into attendance patterns and trends for better resource management."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3att.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Attendance3Mob.png",
          transform: "scale(1.07)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"5rem",
           marginTopMob:"-1rem",
           marginBottomImgMob:"-1rem"
      },
  ];
  
  export const companyManagementArray=[
      {
          heading:"Location Management Module",
          subHeading:"Streamline Your Company's Infrastructure Expansion with Effortless Location Management.",
          list:[
              "Seamless location addition: Easily add new company branches and site locations.",
              "Comprehensive organisation: Maintain a structured overview of all company branches and their respective locations.",
              "Simplified management: Streamline the process of adding, updating, and managing company locations for efficient operations."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1cs.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095408%20%282%29.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Company1Mob.png",
          transform: "scale(1.095)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginTop:"2rem",
           marginBottom:"3rem",
      },
      {
          heading:"Work Schedule Management",
          subHeading:"Effortlessly Organise Work Hours and Celebrate Holidays with Ease.",
          list:[
              "Flexible scheduling: Easily set and adjust work timings according to your company's policies and requirements.",
              "Holiday management: Efficiently manage holiday schedules and track employee leave to ensure smooth operations.",
              "Enhanced productivity: Streamline work timing management to optimise productivity and ensure effective resource allocation."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2cs.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095405%20%281%29.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Company2Mob.png",
          transform: "scale(1.05)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"6rem",
           marginTopMob:"-1rem",
      },
  ];
  
  export const teamManagementArray=[
      {
          heading:"Role-Based Team Setup",
          subHeading:"Empower Your Team with Seamless Role Management and Permission Control.",
          list:[
              "Flexible role creation: Easily define roles and designations tailored to your organisation's structure and workflow.",
              "Effortless member addition: Smoothly assign team roles for seamless onboarding.",
              "Granular permission control: Fine-tune role-based permissions to safeguard sensitive data."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1tm.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/team1.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TeamManagement1Mob.png",
          transform: "scale(1.07)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"4rem",
           marginTop:"3rem"
      },
      {
          heading:"Organisational Chart Viewing",
          subHeading:"Visualise Your Team's Structure for Enhanced Collaboration and Efficiency.",
          list:[
           "Dynamic visualisation: Easily view the organisational hierarchy in an interactive and dynamic chart format.",
           "Real-time updates: Access up-to-date information on team members, roles, and reporting relationships.",
           "Hierarchical clarity: Gain organizational insight for effective decision-making and communication."   
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2tm.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/team2.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TeamManagement2Mob.png",
          transform: "scale(1.05)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"4rem",
           marginTopMob:"-1rem",
      },
      {
          heading:"Unified Entity Overview",
          subHeading:"Effortlessly Monitor Your Entire Network, Simplifying Strategic Decision-Making.",
          list:[
              "Comprehensive overview: Access a centralized dashboard for all personnel and clients.",
              "Streamlined data management: Consolidate data for streamlined tracking and analysis.",
              "Enhanced decision-making: Efficiently make informed decisions with a holistic view of your organization’s resources and relationships."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3tm.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/team3.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TeamManagement3Mob.png",
          transform: "scale(1.07)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"4rem",
           marginTopMob:"-2rem",
           marginBottomImgMob:"-3rem"
      },
  ];
  
  export const mobileArray=[
      {
          heading:"Mobile Dashboard Access",
          subHeading:"Empowering Productivity Anywhere, Anytime: Your Projects, Your Leads, Your App.",
          list:[
              "Instant access: Stay updated on project progress and lead status with real-time mobile dashboard insights.",
              "On-the-go management: Efficiently manage projects and leads remotely for enhanced productivity.",
              "Seamless integration: Seamlessly integrate mobile app with ERP for streamlined data management."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1mobile.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Mobile1.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mobile1mobile.png",
          transform: "scale(1.075)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginTop:"3rem",
           marginBottom:"3rem",
      },
      {
          heading:"Live Chat Integration",
          subHeading:"Connect Instantly, Collaborate Effortlessly: Real-Time Communication, Anywhere, Anytime.",
          list:[
              "Instant communication: Have real-time conversations for quick decision-making.",
              "Seamless collaboration: Instantly share updates, files, and feedback for efficient remote teamwork.",
              "On-the-go access: Stay productive on the go, ensuring project continuity."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2mobile.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mobile2.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mobile2mobile.png",
          transform: "scale(1.065)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"6rem",
      },
      {
          heading:"Mobile Attendance Marking",
          subHeading:"Clock In Anywhere: Easy Attendance Tracking, Anytime, Anywhere.",
          list:[
              "Convenient attendance marking: Easily record daily attendance from anywhere using your mobile device.",
              "Real-time updates: Ensure accurate attendance tracking with instant updates synced to the ERP system.",
              "On-the-go efficiency: Save time and streamline processes by marking attendance on the move, improving productivity."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/3mobile.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mobile3.png",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mobile3mobile.png",
          transform: "scale(1.07)",
           transformOrigin:"left",
           marginRight:"7rem",
           marginBottom:"3rem",
      },
      {
          heading:"On-The-Go Approvals",
          subHeading:"Empowering Swift Decisions: Approve, Feedback, Anywhere, Anytime, Seamless Productivity.",
          list:[
              "Instant approvals: Review and approve requests or documents on the fly, ensuring timely decision-making.",
              "Seamless feedback: Provide mobile-friendly feedback to keep workflows efficient.",
              "Mobile efficiency: Stay productive on the go, handling approvals and feedback tasks from your mobile."
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/4mobile.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095619.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/mobile4mobile.png",
          transform: "scale(1.075)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottom:"5rem",
      },
  ];
  
  export const extraArray=[
      {
          heading:"Platform Integration Expansion",
          subHeading:"Seamless Connectivity: Expand Your Reach, Integrate with Leading Platforms.",
          list:[
              "Streamlined workflows: Integrate with popular platforms like Zoho to streamline data exchange and enhance collaboration.",
              "Enhanced accessibility: Access data and functionalities from multiple platforms, improving accessibility and productivity.",
              "Scalability and flexibility: Integrate with diverse platforms, adapting to evolving business needs."
          ],
          background: "#F0EFF6",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/1extra.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095389.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/extrasmobile1.png",
          transform: "scale(1)",
           transformOrigin:"left",
           marginRight:"7rem",
      },
      {
          heading:"Comprehensive Training Resources",
          subHeading:"Empowerment Through Knowledge: Robust Support and Versatile Training Solutions.",
          list:[
           "Comprehensive support: Access robust support services to address any issues or queries promptly.",
           "Flexible training options: Access tailored online and offline training resources.",
           "Continuous improvement: Empower users with ongoing training to maximise utilisation and efficiency."   
          ],
          background: "#FFF2E5",
          image1:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/2extra.png",
          image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%202611720.svg",
          mobImg:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Extra2Mob.png",
          transform: "scale(1)",
           transformOrigin:"right",
           marginLeft:"6rem",
           marginBottomImgMob:"-1rem"
      },
  ];
  
  export const testimonialsArray=[
      {
          text:"iDesign Market has been a game-changer for my business. Their platform helps me stay connected with both my team members and clients. ",
          name:"Austin Knight",
          role:"Project Manager"
      },
      {
          text:"iDesign is a very user-friendly platform. It is very easy for me to track things, review orders, manage hierarchy and even maintain accounts. Love it!",
          name:"Julian Brown",
          role:"Architect"
      },
      {
          text:"It’s fairly affordable, easy to use and has all the tools required to manage my interior designing business. I can deliver on time and on budget projects because now I have iDesign.",
          name:"Camila Garcia",
          role:"Interior Designer"
      }
  ];
  