import React, { useEffect, useState } from 'react';
import loc from "./Images/location.svg"
import { Modal, Offcanvas } from 'react-bootstrap'
import makeone from '../3dComponents/3dImages/MakeOne.svg'
import uploadAPdf from '../3dComponents/3dImages/uploadAPdf.svg'
import close from '../3dComponents/3dImages/close.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { fetchProfileData } from '../Lms/Actions'
import '../3dComponents/3d.css';
import measure from "./Images/measure.svg"
import cal from "./Images/calander.svg"
import homee from "./Images/homee.svg"
import { Link } from 'react-router-dom';

export default function MeasurementHistoryMob() {
    const authtoken = localStorage.getItem("token");
    const [data, setData] = useState([]);
    const [order, setOrder] = useState([]);

    const navigate = useNavigate();

    const convertDate = (date) => {
        if (date == null) {
            return "Pls Select A date";
        }
        const dateObject = new Date(date);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        let datee = dateObject.getDate();
        const month = dateObject.getMonth();
        const year = dateObject.getFullYear();

        if (datee.length < 2) {
            datee = '0' + datee;
        }

        const realdate = datee + "-" + months[month] + "-" + year;
        return realdate;
    }

    useEffect(() => {
        console.log('measure')
        async function UserMeasurement() {
            const response = await axios
                .get("https://pro-api.idesign.market/user/getUserMeasurements", {
                    headers: {
                        authorization: `Bearer ${authtoken}`,
                    },
                })
                .then((res) => {
                    console.log(res);
                    setData(res.data.data);

                })
                .catch((err) => {
                    console.log(err);
                });
        }
        UserMeasurement();
        console.log(data);

        localStorage.setItem('token2', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVHlwZSI6MiwidXNlcklkIjoiNjJlY2VjNDQ4NTBhYjQyYjkxNTA3NmE2IiwiZGV2aWNlSWQiOiIyNTAxMDA2NDY0NTM3MzYxMDQwMDA1MzczNjU3NjgxMzY2MjQiLCJpYXQiOjE2NjE0Mjc3OTh9.K3VgDPUG337hEpH_mhKxdisUl77wVPcNN7C-r3Qgd5M')
    }, []);

    useEffect(() => {
        console.log('2')
        async function UserOrders() {
            const response = await axios
                .get("https://pro-api.idesign.market/user/getUserOrders", {
                    headers: {
                        authorization: `Bearer ${authtoken}`
                    },
                })
                .then((res) => {
                    console.log("userorder", res);
                    setOrder(res.data.data);

                })
                .catch((err) => {
                    console.log(err);
                });

        }
        UserOrders();

        console.log(order);

        localStorage.setItem('token2', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVHlwZSI6MiwidXNlcklkIjoiNjJlY2VjNDQ4NTBhYjQyYjkxNTA3NmE2IiwiZGV2aWNlSWQiOiIyNTAxMDA2NDY0NTM3MzYxMDQwMDA1MzczNjU3NjgxMzY2MjQiLCJpYXQiOjE2NjE0Mjc3OTh9.K3VgDPUG337hEpH_mhKxdisUl77wVPcNN7C-r3Qgd5M')
    }, []);

    return (
        <div style={{ overflow: 'scroll', height: "70vh" }}>

            <div className='d-flex flex-column' style={{ height: 'fitContent' }}>
                {order && order.map((element) => {
                    return (
                        <div className='w-100'>
                            <div className="ordered_main d-flex" style={{ borderBottom: "1px solid #DFDFDF", marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
                                <div style={{
                                    background: "#F0F0F0",
                                    borderRadius: "4px",
                                    margin: "7px", width: '60px',
                                    padding: '0px',
                                    height: '58px', display: 'flex', alignItems: 'center'

                                }}>
                                    <img src={measure} style={{ width: '80px', height: "33px" }} alt="" className="ordImg" />
                                </div>
                                <div className="ord_upper d-flex flex-column w-100 justify-content-between" style={{ marginLeft: '5px' }}>
                                    <div className="ord_head d-flex mt-2">
                                        <h4 className="cart_head d-flex flex-column" style={{ fontSize: '14px' }}>Mesurement Request<span className='ord_span pt-1' style={{ fontSize: '12px' }}>Requested on {element?.createdAt.slice(0, 10)}</span></h4>
                                        <div className='d-flex' style={{ marginLeft: 'auto', height: '25px', borderRadius: '50px', width: '50px' }}>
                                            {element?.paymentStatus === 2 ? <div className="ord_span2 d-flex float-end" style={{
                                                fontFamily: 'Inter', borderRadius: '50px',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                color: '#26AA63', backgroundColor: 'rgba(38, 170, 99, 0.15)',
                                                marginLeft: '8px', width: '68%', alignSelf: 'center',justifyContent:'center'
                                            }}>Paid</div> : <div className="ord_span2 d-flex float-end justify-content-center" style={{
                                                fontFamily: 'Inter', height: '23px', borderRadius: '50px', width: '68px',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                color: ' #EDA416',alignSelf:'center',
                                                marginLeft: '-12px', backgroundColor: 'rgba(237, 164, 22, 0.15)'
                                            }}> 50% Paid</div>}
                                        </div>
                                    </div>
                                    <div className="cart_file_sub2" style={{ flexDirection: 'column' }}>
                                        <div className="file2" style={{
                                            fontFamily: 'Inter',
                                            fontWeight: '400', fontSize: '12px', color: '#888888',

                                        }}><img src={loc} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{element.houseNumber},{element.projectLocation}</div>
                                        <div className="file2 mt-1" style={{
                                            fontFamily: 'Inter',
                                            fontWeight: '400', fontSize: '12px', color: '#888888',
                                        }}><img src={cal} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{convertDate(element.Date)}</div>
                                    </div>
                                    <div className="ord_en d-flex" style={{ marginTop: '5px' }}>
                                        {element.houseType && element.houseType ? <>  <div className=""><img src={homee} style={{ height: '15px' }} /></div>
                                            <div className="cart_head2 d-flex" style={{
                                                fontFamily: 'Inter',
                                                fontWeight: '400', fontSize: '12px', color: '#888888',
                                            }}>{element.houseType}</div></> : ""}

                                        <div className='mt-1' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {element.workType === "Full Home" ? <div className='ord_span3' style={{
                                                fontFamily: 'Inter', height: '21px',
                                                fontWeight: '400', fontSize: '12px', color: '#888888', background: '#F0F0F0', borderRadius: '4px',marginLeft:'5px'
                                            }}>{element.workType}</div> :

                                                element.rooms.map((room) => {
                                                    if (room.noOfRooms > 0) {
                                                        return (
                                                            <div
                                                                className=" mx-1"
                                                                style={{
                                                                    height: "20px",
                                                                    width: "fit-content",
                                                                    backgroundColor: "#F0F0F0",
                                                                    padding: "1px 0px",
                                                                    borderRadius: "4px",
                                                                    fontSize: '12px',
                                                                    marginTop: '3px'
                                                                }}
                                                            >
                                                                {room.room}
                                                            </div>
                                                        );
                                                    }
                                                })

                                            }


                                        </div>
                                    </div>
                                    {element?.paymentStatus === 2 ? <div className='download' style={{ textDecoration: 'underline', marginLeft: 'auto',marginTop:'-22px'  }}>Download File</div>
                                        : <>

                                            <div className='d-flex mt-1'>
                                                <div className='' style={{ fontSize: '14px' }}  >To download pay</div><span className='pay' style={{ marginTop: '3px' }}> ₹{element?.paymentDetails[0]?.pricePaid}/-</span>
                                                <Link to="/measurementcart" className='download' style={{ textDecoration: 'underline', fontSize: '12px',marginLeft:'5px',marginTop:'2px' }}>Pay Now</Link>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    )

                })}

            </div>

        </div >
    )
} 