import React, { useContext, useState } from "react";
import image from "../Images/addProjectHome.svg";
import f1 from "../Images/f1.png";
import f2 from "../Images/f2.png";
import f3 from "../Images/f3.png";
import "../App.css";
import AddProjectDetails from "./AddProjectDetails";
import { Link, useNavigate } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { OnboardingContext } from "../Context/Context";
import { useForm } from "react-hook-form";
import AddProjectWeb from "./OnboardingWebScreens/AddProjectWeb";
import { useDispatch } from "react-redux";
import { setProjectDetailsRedux } from "../Lms/Actions";
import client from "../Images/client.svg";
import buildTrust from "../Images/buildTrust.svg";
import portray from "../Images/portray.svg";

export default function AddProjects() {
  const dispatch = useDispatch();

  const [addProject, setAddProject] = useState(false);

  const [projectDetails, setProjectDetails] = useState({ name: "", city: "" });
  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ name: "", city: "" });

  function handleNext(data) {
    localStorage.removeItem("path");
    dispatch(setProjectDetailsRedux(data.name, data.city));
    navigateTo("/addprojectpics");
  }

  return (
    <React.Fragment>
    {window.screen.width > 768 ?
      <AddProjectWeb />
    :
    <div>
      <Modal className="addProjectModal" centered show={addProject} onHide={() => setAddProject(false)}>
        <Modal.Header className="addProjectModal-title" closeButton>
          <Modal.Title>
            <div style={{ fontSize: "18px", fontWeight: "400" }}>Add Project Details</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleNext)}>
            <div style={{ marginBottom: "16px" }}>
              <div style={{ fontSize: "15px", fontWeight: "400" }}>Project Name</div>
              <div className="my-2" >
                <Form.Control
                  style={{ fontSize: "15px", fontWeight: "400" }}
                  {...register("name", {
                    required: true,
                  })}
                  name="name"
                  type="text"
                  placeholder="Enter Project Name"
                />
              </div>
            </div>

            <div style={{ marginBottom: "16px" }}>
              <div style={{ fontSize: "15px", fontWeight: "400" }}>City</div>
              <div  className="my-2"  >
                <Form.Control
                  style={{ fontSize: "15px", fontWeight: "400" }}
                  {...register("city", {
                    required: true,
                  })}
                  name="city"
                  type="text"
                  placeholder="Enter City"
                />
              </div>
            </div>

            <div className="w-100">
              <button type="submit" style={{ width: "100%", border: "none", backgroundColor: "#3b5998", color: "white", borderRadius: "0.3rem", padding: "0.5rem" }}>
                Next
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="addProjects-container">
        <div className="main-addProject pt-5">
          <h1 style={{ fontSize: "x-large" }}>You are almost there!</h1>
          <img src={image} />

          <div className="fw-bold" style={{ fontSize: "26px", textAlign: "center" }}>
            Add Projects & Unlock <br />
            Free Access To Client <br /> Queries
          </div>
          <div className="features mt-5 pt-5">
            <div className="feature">
              <div className="imgContainer" >
                <img src={client} style={{ width: "48px", height: "48px" }} />
              </div>
              <p>
                Get <b> 3X </b>more <br /> Clients
              </p>
            </div>
            <div className="feature">
              <div className="imgContainer" >
                    <img src={portray} style={{ width: "48px", height: "48px" }} />
              </div>
              <p>
                Portray your <br />
                <b>Experience</b>
              </p>
            </div>
            <div className="feature">
              <div className="imgContainer" >
                    <img src={buildTrust} style={{ width: "48px", height: "48px" }} />
              </div>
              <p>
                Build{" "}
                <b>
                  {" "}
                  trust <br />
                  with Clients.
                </b>
              </p>
            </div>
          </div>
          {/* <button onClick={()=>{setAddProject(true)}} className="addProjectbtn">Add Project</button> */}
          <div className="mt-2">
            <Link to="/plans">
              <button onClick={localStorage.removeItem("path")} className="skipbtn me-5">Skip</button>
            </Link>
            <Link to="">
              {" "}
              <button
                onClick={() => {
                  setAddProject(true);
                }}
                className="nextbutton1"
              >
                Add Project
              </button>
            </Link>
          </div>
          {/* {addProject && <AddProjectDetails setAddProject={setAddProject} />} */}
        </div>
      </div>
    </div>
    }
    </React.Fragment>
  );
}