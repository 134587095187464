import React, {useState, useEffect} from "react";
import HeroContainer from "./HeroContainer";
import WhoWeServe from "./WhoWeServe";
import ProjectManagementContainer from "./ProjectManagementContainer";
import OtherCompanies from "./OtherCompanies";
import JoinOnMobile from "./JoinOnMobile";
import AboveFooter from "./AboveFooter";
import NewFooter from "./NewFooter";
import ArticleSection from "./ArticleSection";
import FAQ from "./FAQ";
import Header from "./Header";
import NewTestimonials from "./NewTestimonials";
import NewTestimonialsMobile from "./NewTestimonialsMobile";
import HeaderMob from "./HeaderMob";
import HamburgerPage from "./hamburgerMenus/HamburgerPage";

const MainPage=()=>{
  const [openHamburger, setOpenHamburger] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

return(
    <>
   {openHamburger && <HamburgerPage openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />}
   {!openHamburger && (isDesktop ? <Header />:<HeaderMob openHamburger={openHamburger} setOpenHamburger={setOpenHamburger}  />)}
   {!openHamburger && <HeroContainer />}
  {!openHamburger &&   <WhoWeServe />}
   {!openHamburger &&  <ProjectManagementContainer />}
  {!openHamburger &&   <OtherCompanies />}
    {/* <NewTestimonials /> */}
    {!openHamburger && (isDesktop? <NewTestimonials />: <NewTestimonialsMobile />) }
   {!openHamburger && <ArticleSection />}
   {!openHamburger &&  <FAQ />}
   {!openHamburger &&  <JoinOnMobile />}
   {!openHamburger &&  <AboveFooter />}
   {!openHamburger &&  <NewFooter />}
    </>
)
}

export default MainPage;