import React from "react";
import styles from "./myprofileCss/allProfile.module.css";
import { useState } from "react";
import PersonDetails from "./PersonDetails";
import Collab from "./Collab";
import Security from "./Security";
import { useRef } from "react";

import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";

const AllProfilePage = () => {
  const uploadRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [activetab, setActivetab] = useState("active");
  const [changeButton, setchangeButton] = useState("edit");
  console.log(activetab);
  const[cancel, setCancel] = useState(false)

  const handleCancel = () => {
    setCancel(!cancel)
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <div className={styles.container}>
        <div style={{ position: "relative" }}>
          <img
            src="https://idesign-office.s3.amazonaws.com/Frame%202612141.svg"
            alt=""
            style={{ width: "100%" }}
          />
          {activetab === "active" ? (
            <div onClick={() => uploadRef.current.click()}>
              <img
                src="https://idesign-office.s3.amazonaws.com/Frame%202612149.svg"
                alt=""
                className={styles.changeCover}
              />
            </div>
          ) : null}
        </div>

        <div className={styles.smallContainer}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "2rem",
            }}
          >
            <div style={{ display: "flex", gap: "1rem", position: "relative" }}>
              <div
                className={
                  changeButton === "notedit" ? styles.dpActive : styles.dp
                }
              >
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Ellipse%20980.svg"
                  alt=""
                  style={{ height: "100%" }}
                />
              </div>

              {changeButton === "notedit" && (
                <div className={styles.camera}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/photo_camera.svg"
                    alt=""
                  />
                </div>
              )}

              <div className={styles.name}>Light Yagami</div>
            </div>

            {activetab === "active" ? (
              changeButton === "edit" ? (
                <div style={{ paddingTop: "2rem" }}>
                  <button
                    className={styles.editBtn}
                    onClick={() => setchangeButton("notedit")}
                  >
                    Edit
                  </button>
                </div>
              ) : (
                <div style={{ paddingTop: "2rem" }}>
                  <button className={styles.btn1} onClick={() => {
                    setchangeButton("edit")
                  }}>Cancel</button>
                  <button className={styles.btn2}>Save Changes</button>
                </div>
              )
            ) : activetab === "active1" ? (
              <div style={{ paddingTop: "2rem" }}>
                <button
                  className={styles.addMemBtn}
                  onClick={() => setModalShow(true)}
                >
                  Add Member
                </button>
              </div>
            ) : activetab === "active2" ? (
              <div style={{ paddingTop: "2rem" }}>
                <button className={styles.btn1}>Cancel</button>
                <button className={styles.btn2}>Save Changes</button>
              </div>
            ) : null}
          </div>

          {/* tabs div */}
          <div className={styles.tabs_div}>
            <div
              className={
                activetab === "active" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => {
                setActivetab("active");
              }}
            >
              Personal Details
            </div>
            <div
              className={
                activetab === "active1" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => {
                setActivetab("active1");
              }}
            >
              Collaborative
            </div>
            <div
              className={
                activetab === "active2" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => {
                setActivetab("active2");
              }}
            >
              Security
            </div>
          </div>

          {activetab === "active" ? (
            <PersonDetails
              editable={changeButton === "notedit" ? true : false}
            />
          ) : activetab === "active1" ? (
            <Collab />
          ) : activetab === "active2" ? (
            <Security />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AllProfilePage;
