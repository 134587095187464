import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, ProgressBar } from "react-bootstrap";
import { MdStar } from 'react-icons/md';
import { MdOutlineReport } from 'react-icons/md'
import { useSelector } from 'react-redux';
import axios from 'axios';
import sstar from "../../../OrderHistory/Images/star.svg"
import yellow from '../../../OrderHistory/Images/yellowstar.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import three from "../../../Lms/Images/threeDots.svg";
import pencil from "../../../Lms/Images/pencil.svg";
import del from "../../../Lms/Images/delete.svg";
import MobileSideBar from './MobileSideBar';
import { useLocation, useNavigate } from 'react-router-dom';
import MobHeader from '../../LmsMobileComponents/MobHeader';
import ContsantHeaderMob from './ContsantHeaderMob';
import { BiDotsVerticalRounded } from 'react-icons/bi';



const ReviewMob = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
        setIsDesktop(window.screen.width > 767);
    };
    const navigate = useNavigate();
    const profileData = useSelector((state) => state.addToCartReducer.profileData);
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const [tab, setTab] = useState();
    const profile = useSelector((state) => state?.addToCartReducer?.profileData);

    const [isClickedReply, setIsClickedReply] = useState(false);
    const [replyString, setReplyString] = useState("");

    const [isReplied, setIsReplied] = useState(false);

    const [isReported, setIsReported] = useState(false);

    const [modalShow, setModalShow] = useState(false);

    const [reviewArray, setReviewArray] = useState([]);
    const [isDeleted, setDeleted] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [valM, setValM] = useState("");
    const [val, setVal] = useState(valM && valM);
    const [reportString, setReportString] = useState("");

    let rating_1 = 0;
    let rating_2 = 0;
    let rating_3 = 0;
    let rating_4 = 0;
    let rating_5 = 0;

    // const profileData = useSelector((state ) => state.addToCartReducer.profileData);

    // console.log(profile[0]?.data?.data?._id);
    // console.log(isEdit)

    useEffect(() => {
        // console.log(profileData);
        async function fetchreview() {
            const response = await fetch(`https://home-api.idesign.market/api/review/${localStorage.getItem("userId")}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const json = await response.json();
            setReviewArray(json);
            console.log(json);
            for (let i = 0; i < reviewArray.length; i++) {
                console.log(Object.keys(reviewArray[i].reply).length);
            }
        }
        fetchreview();
    }, [profile, isDeleted, isEdit]);

    for (let i = 0; i < reviewArray.length; i++) {
        if (Math.round(reviewArray[i].rating) === 1) {
            rating_1++;
        }
        if (Math.round(reviewArray[i].rating) === 2) {
            rating_2++;
        }
        if (Math.round(reviewArray[i].rating) === 3) {
            rating_3++;
        }
        if (Math.round(reviewArray[i].rating) === 4) {
            rating_4++;
        }
        if (Math.round(reviewArray[i].rating) === 5) {
            rating_5++;
        }
    }
    // console.log(rating_1, rating_2, rating_3, rating_4, rating_5);
    // console.log(reviewArray);

    const handlePost = async (reviewId, designerId) => {
        // console.log(replyString);

        const response = await axios
            .put(`https://home-api.idesign.market/api/review/reply-to-review?id=${reviewId}&replyBy=${designerId}&replyByName=${profile[0]?.data?.data?.companyName}`, {
                text: replyString,
            })
            .then((res) => {
                console.log(res);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });

        const json = await response.json();

        // console.log(json);

        setIsReplied(true);
        setIsClickedReply(false);
    };

    const handlePost2 = async (reviewId, designerId, event) => {
        // console.log(replyString);
        if (event.key === "Enter") {
            // alert('mo')
            event.preventDefault()
            handlePost(reviewId, designerId)
        }
    };

    const openReportModal = () => {
        setModalShow(true);
    };

    const deleteReview = async (id) => {
        const response = await axios
            .put(`https://home-api.idesign.market/api/review/delete-reply/${id}`, {})
            .then((res) => {
                console.log(res);
                setDeleted(true);
                // window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // console.log(val)

    var sum = 0;
    for (let i = 0; i < reviewArray.length; i++) {
        sum += reviewArray[i].rating;
    }
    console.log((sum / reviewArray.length).toFixed(2));
    console.log(sum.toFixed(2));

    const handleReport = async (reportId, designerId) => {
        const response = await axios
            .put(`https://home-api.idesign.market/api/review/report-review?id=${reportId}&reportedBy=${designerId}&reportedByName=${profile[0]?.data?.data?.companyName}`, {
                text: reportString,
            })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        // const profileData = useSelector((state) => state.addToCartReducer.profileData);
        console.log(response);
        setModalShow(false);
    };

    const convertDate = (date) => {
        const dateObject = new Date(date);

        let day = dateObject.getDate().toString();
        if (day.length < 2) {
            day = "0" + day;
        }
        let month = (dateObject.getMonth() + 1).toString();
        if (month.length < 2) {
            month = "0" + month;
        }
        const year = dateObject.getFullYear().toString();
        const actualdate = day + "-" + month + "-" + year;

        return actualdate;
    };

    const handleChange = (e) => {
        setReplyString(e.target.value);
    };

    const handlePostChange = (e) => {
        setReportString(e.target.value);
    };
    const EditReply = (elm, id) => {
        setIsEdit(true);
        // console.log(elm)
        setValM(elm);
        setIsClickedReply(true);
        // console.log(id)
        setReplyArr(id);
        // console.log(replyArr)
        setReplyArr2("")
        // console.log(valM)
    };

    const handleChangee = (e) => {
        setValM(e.target.value);
    };

    const reviewEdit = async (index) => {
        // console.log(index)
        // let id;
        // for (let i = 0; i < reviewArray.length; i++) {
        //     if (i === index) {
        //         id = reviewArray[i]._id;
        //     }
        // }
        // console.log(id)

        const response = await axios
            .put(`https://home-api.idesign.market/api/review/edit-reply/${replyArr}`, {
                text: valM,
            })
            .then((res) => {
                console.log(res);
                setIsEdit(false);
                setReplyArr("")
                // window.location.reload()
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const path = useLocation();
    const [replyArr, setReplyArr] = useState();
    const [replyArr2, setReplyArr2] = useState();
    const handleReply = (id) => {
        // setIsClickedReply(true)
        // console.log(id)
        setReplyArr(id);
        console.log(replyArr);
        // replyArr?.push(id)
        // console.log(replyArr)
    };

    return (
        <>
            <div style={{ overflowX: "hidden" }}>
                <div className='' style={{ position: "fixed", width: "100%", background: "white" }}>
                    <div style={{ zIndex: "1" }}>
                        <MobHeader />
                    </div>
                    <ContsantHeaderMob />
                </div>
                <div className='' style={{marginTop: "7rem"}}>
                    <div className='d-flex w-100 justify-content-between' style={{ paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                        <div className="d-flex flex-column w-10 justify-content-center align-items-center" >
                            <p className='mb-0' style={{ fontSize: "22px", fontWeight: "500", }}> {(sum / reviewArray.length).toFixed(2)}</p>
                            <span style={{ fontSize: "8px", fontWeight: "400", color: "#1A1A1A" }}>Out of 5 star</span>
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center ms-3 mt-2 mb-2' style={{ width: "80%", borderBottom: "0.5px solid #DFDFDF;" }}>
                            <div className="w-100 d-flex" style={{marginBottom: "4px"}}>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginRight: '5px',
                                    marginTop: '-5px'
                                }}> 5</label>
                                <div style={{ width: '100%', backgroundColor: "#DFDFDF", height: '0.3rem',borderRadius: "10px" }}>
                                    <ProgressBar variant="success" className="mb-3" style={{ width: `${rating_5}%`, height: "0.3rem", backgroundColor: "#84D9A2",borderRadius: "10px" }} />
                                </div>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginLeft: '5px',
                                    marginTop: '-5px'
                                }}> {rating_5}</label>
                            </div>
                            <div className="w-100 d-flex" style={{marginBottom: "4px"}}>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginRight: '5px',
                                    marginTop: '-5px'
                                }}> 4</label>
                                <div style={{ width: '100%', backgroundColor: "#DFDFDF", height: '0.3rem',borderRadius: "10px" }}>
                                    <ProgressBar variant="info" now={`${rating_4}%`} className="mb-3 " style={{ width: `${rating_4}%`, height: "0.3rem", backgroundColor: "blue",borderRadius: "10px" }} />
                                </div>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginLeft: '5px',
                                    marginTop: '-5px'
                                }}>  {rating_4}</label>
                            </div>
                            <div className="w-100 d-flex" style={{marginBottom: "4px"}}>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginRight: '5px',
                                    marginTop: '-5px'
                                }}> 3</label>
                                <div style={{ width: '100%', backgroundColor: "#DFDFDF", height: '0.3rem',borderRadius: "10px" }}>
                                    <ProgressBar variant="warning" now={`${rating_3}%`} className="mb-3 " style={{ width: `${rating_3}%`, height: "0.3rem", backgroundColor: "yellow",borderRadius: "10px" }} />
                                </div>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginLeft: '5px',
                                    marginTop: '-5px'
                                }}> {rating_3}</label>
                            </div>
                            <div className="w-100 d-flex" style={{marginBottom: "4px"}}>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginRight: '5px',
                                    marginTop: '-5px'
                                }}> 2</label>
                                <div style={{ width: '100%', backgroundColor: "#DFDFDF", height: '0.3rem',borderRadius: "10px" }}>
                                    <ProgressBar variant="success" now={`${rating_2}%`} className="mb-3 mx-2" style={{ width: `${rating_2}%`, height: "0.3rem", backgroundColor: "purple",borderRadius: "10px" }} />
                                </div>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginLeft: '5px',
                                    marginTop: '-5px'
                                }}>{rating_2}</label>
                            </div>
                            <div className="w-100 d-flex" style={{marginBottom: "4px"}}>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginRight: '5px',
                                    marginTop: '-5px'
                                }}> 1</label>
                                <div style={{ width: '100%', backgroundColor: "#DFDFDF", height: '0.3rem',borderRadius: "10px" }}>
                                    <ProgressBar variant="danger" now={`${rating_1}%`} className="mb-3" style={{ width: `${rating_1}%`, height: "0.3rem", backgroundColor: "red",borderRadius: "10px" }} />
                                </div>
                                <label htmlFor="" style={{
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    color: '#8C8C8C',
                                    fontSize: '11px',
                                    marginLeft: '5px',
                                    marginTop: '-5px'
                                }}>{rating_1}</label>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    reviewArray?.length !== 0 && reviewArray?.slice(0)?.reverse().map((review, index) => {

                        return (

                            <div className='mb-2' style={{ height: "fit-content", marginTop: '1rem' ,width: "100%", borderBottom: "1px solid #dfdfdf", paddingBottom: "1rem" }}>
                                <div className='w-100 px-3' >
                                    <div className="d-flex" style={{ height: "100%", width: "95%", marginTop: '2rem' }}>

                                        <div className='d-flex justify-content-center align-items-center' style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#BAA763', fontWeight: 'bold', color: 'white', padding: '2px 10px' }} >{review?.name?.charAt(0)?.toUpperCase()}</div>

                                        <div className="reviewer d-flex w-100">
                                            <div className="reviewer-name-section w-100">

                                                <div className="reviewer-name mx-2" style={{ fontWeight: "bold", fontSize: '14px', paddingTop: "4px" }}>{review?.name}</div>
                                                <div className="mx-2" style={{ color: 'grey', fontSize: '0.9rem' }}>
                                                    <div className=' d-flex mb-0' >
                                                        <img src={Math.round(review.rating) >= 1 ? yellow : sstar} style={{ width: '12px' }} />
                                                        <img src={Math.round(review.rating) >= 2 ? yellow : sstar} style={{ width: '12px' }} />
                                                        <img src={Math.round(review.rating) >= 3 ? yellow : sstar} style={{ width: '12px' }} />
                                                        <img src={Math.round(review.rating) >= 4 ? yellow : sstar} style={{ width: '12px' }} />
                                                        <img src={Math.round(review.rating) >= 5 ? yellow : sstar} style={{ width: '12px' }} />
                                                        <div className='mx-1 mb-0' style={{ fontSize: '12px' }}>{convertDate(review?.date)}</div>
                                                    </div>

                                                </div>

                                            </div>

                                            {/* <div className="reviewer-rating" style={{ height: '30px', width: '55px', padding: '3px 9px', borderRadius: '0.4rem', backgroundColor: '#d9d9d9' }}> <MdStar style={{ color: "#49B7CF" }} /> <span style={{ color: "#49B7CF" }}>{review.rating}</span></div> */}
                                            <Dropdown style={{ background: 'none', border: 'none' }}>
                                                <Dropdown.Toggle id="dropdown-basic" style={{ background: 'none', boxShadow: 'none', borderColor: "none", border: "none", padding: "0" }}>
                                                    <BiDotsVerticalRounded color="#888888" size={20} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                        <div className="report-reviewer" style={{ color: '#575757', fontSize: '14px', display: 'flex', justifyContent: 'flexStart', marginRight: '5px' }} role="button" onClick={openReportModal}><MdOutlineReport className="my-1" style={{ fontSize: '1rem', marginRight: '5px' }} />Report</div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                    </div>
                                </div>

                                <div className="review-content" style={{ height: " 50%", width: "100%", marginTop: '6px', color: 'grey', paddingLeft: "2.5rem" }}>

                                    <div style={{ fontSize: '13px', paddingLeft: '10px', paddingRight: "10px" }}>{review?.review}</div>
                                </div>

                                {!review.reply && review?._id !== replyArr2 ?

                                    <div className="reply-button" style={{ height: " 20%", width: "100%", marginTop: '10px', paddingLeft: "3rem" }}>
                                        <div role="button" style={{ color: '#3B5998', fontSize: '0.9rem', fontWeight: 'bold' }} onClick={() => {
                                            setReplyArr2(review?._id)
                                            setIsClickedReply(true)
                                            setReplyArr("")
                                        }}> Reply</div>
                                    </div>
                                    : !review.reply && review?._id === replyArr2 ?
                                        <Form style={{ padding: "0.5rem 0.5rem 0 3rem" }} onSubmit={(e) =>
                                            handlePost2(review?._id, review?.designerId, e)
                                        }>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Write your review..." name="reply" onChange={handleChange} required onKeyDown={(event) => handlePost2(review?._id, review?.designerId, event)} />
                                            </Form.Group>

                                            <div className="d-flex" style={{ gap: '1rem' }}>
                                                <div role="button" style={{ color: '#3B5998', fontSize: '0.9rem', fontWeight: 'bold', }} onClick={() => handlePost(review._id, review.designerId)}>Post</div>
                                                <div role="button" style={{ color: '#3B5998', fontSize: '0.9rem', fontWeight: 'bold' }} onClick={() => {
                                                    setReplyArr2("")
                                                    setIsClickedReply(false)
                                                }}>Cancel</div>
                                            </div>

                                        </Form>

                                        : review.reply ?
                                            <div className=" p-4 mt-3" style={{ width: '100%', background: "#FAFAFA" }}>
                                                <div className="d-flex" style={{ height: "30%", width: "100%" }}>
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#6ABA63', fontWeight: 'bold', color: 'white', padding: '2px 10px' }} > {review?.reply?.replyByName?.charAt(0)?.toUpperCase()} </div>
                                                    <div className="replier">
                                                        <div className="replier-name mx-3" style={{ fontWeight: "bold", fontSize: "14px" }}>{review?.reply?.replyByName}</div>
                                                        <div className="replier-detail mx-3" style={{ fontSize: "12px", color: 'grey' }}>
                                                            {profileData[0]?.data?.data?.type === 1 ? <div style={{ fontSize: '12px' }}>
                                                                Interior Designer
                                                            </div> : profileData[0]?.data?.data?.type === 2 ? <div style={{ fontSize: '12px' }}>
                                                                Contractor
                                                            </div> : <div style={{ fontSize: '12px' }}>
                                                                OEM
                                                            </div>}
                                                        </div>

                                                    </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <Dropdown style={{ background: 'none', border: 'none', zIndex: "" }}>
                                                            <Dropdown.Toggle id="dropdown-basic" style={{ background: 'none', boxShadow: 'none', position: 'relative', right: '10px', border: "none", borderColor: "none", padding: "0" }}>
                                                                <BiDotsVerticalRounded color="#888888" size={20} />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item>
                                                                    <div className='d-flex' style={{ justifyContent: 'flexStart' }}>
                                                                        <img src={pencil} style={{ marginRight: '10px' }} />
                                                                        <div onClick={() => EditReply(review?.reply?.text, review?._id)}> Edit </div>

                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item style={{ textAlign: 'center' }}>

                                                                    <div className='d-flex' style={{ justifyContent: 'flexStart' }} onClick={() => deleteReview(review?._id)}>
                                                                        {/* <img src={del} role="button" onClick={(index) => deleteReview(index)} /> */}
                                                                        <img src={del} style={{ marginRight: '10px' }} />
                                                                        <div role="button" style={{ color: '#FB5622' }}>Delete</div>

                                                                    </div>
                                                                </Dropdown.Item>

                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {isEdit === true && review?._id == replyArr ? <>
                                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "0.75rem",paddingLeft: "2.25rem" }}>
                                                        <input value={valM} onChange={handleChangee} style={{ height: "2rem", padding: "0.375rem 0.75rem", border: "1px solid #bdbdbd", borderRadius: "0.25rem", marginBottom: "0.5rem",fontSize: "13px" }} />
                                                        <div className="d-flex">
                                                            <div role="button"
                                                                style={{ color: "#3B5998", border: "none", background: "none", textAlign: "left", fontWeight: "600", fontSize: "0.85rem" }}
                                                                onClick={() => reviewEdit(index)}
                                                            >
                                                                Post
                                                            </div>
                                                            <div role="button"
                                                                style={{ color: "#3B5998", border: "none", background: "none", textAlign: "left", fontWeight: "600", fontSize: "0.85rem", marginLeft: "0.75rem" }}
                                                                onClick={() => {
                                                                    setIsEdit(false)
                                                                    setReplyArr("")
                                                                }}
                                                            >
                                                                Cancel
                                                            </div>
                                                        </div>
                                                    </div></> : <div style={{ fontSize: '12px', paddingLeft: '2.75rem' }}>{review?.reply?.text}</div>}
                                            </div>
                                            : " "}

                                <Modal className="addProjectModalPopup" centered show={modalShow} onHide={() => { setModalShow(false) }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Write a reason</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <Form >
                                            <div style={{ margin: "16px 0" }}>
                                                <div>
                                                    <textarea
                                                        className="w-100 h-50"
                                                        style={{ fontSize: "15px", fontWeight: "400", padding: '10px' }}
                                                        name="name"
                                                        rows="10"
                                                        type="text"
                                                        placeholder="Write here..." onChange={handlePostChange} required />
                                                </div>
                                            </div>
                                            <div style={{ margin: "0 0 16px 0" }}>
                                                <div role="button" style={{ width: "100%", border: "none", backgroundColor: "#176091", color: "#FFFFFF", padding: "8px 16px", borderRadius: "8px", textAlign: 'center' }} onClick={() => handleReport(review._id, review.designerId)}> Submit </div>
                                            </div>
                                        </Form>

                                    </Modal.Body>
                                </Modal>
                            </div>


                        )
                    })
                }
            </div>
        </>
    )
}

export default ReviewMob