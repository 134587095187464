import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import upload from './3dImages/upload.svg'
import uploadFinal from './3dImages/uploadFinal.svg'
import { clearAll, deletePdfFile, UploadPdf } from '../Lms/Actions/threedActions'
import { useNavigate } from 'react-router-dom';
import cross from './3dImages/cross.svg'
import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import { toast } from "react-hot-toast";
import { constants } from '../constants';

const UploadReference0 = () => {
  const dispatch = useDispatch();
  const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
  const addImageRef = useRef()
  const [uploaded, setUploaded] = useState(false)
  const [error, setError] = useState("")
  const [imageName, setImageName] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleUpload = (e) => {
    if (e.target.files[0]?.name?.includes(".pdf")) {
    const x = {
      name: e.target.files[0].name,
      path: URL.createObjectURL(e.target.files[0]),
      fullFile: e.target.files[0]
    }
    dispatch(UploadPdf(x));
      setError("")
  }
    else {
      // setError(true)
      setError("Upload a valid file")
    }
  }
  const naviagte = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem("livingInput1") && !localStorage.getItem("bedroomInput1") && !localStorage.getItem("bathroomInput1") && !localStorage.getItem("kitchenInput1") && !localStorage.getItem("dInput1") && !localStorage.getItem("bathroomInput1") && !localStorage.getItem("dInput2") && !localStorage.getItem("dInput3") && !localStorage.getItem("dInput4")) {
      // console.log("yes")
      naviagte("/3dCart")
    }
  }, [])

  const handleSubmit = async () => {
    if (pdfFile1?.name?.includes(".pdf")) {
      if (localStorage.getItem("saveLater")) {
        const file = new FormData();
        file.append("file", pdfFile1.fullFile);
        setIsLoading(true)
        await axios.put(`${constants.threeDApi}/api/room/add-pdf?id=${localStorage.getItem("apiId")}`, file).then((res) => {
          console.warn(res)
          localStorage.removeItem('key')
          localStorage.removeItem("livingInput1")
          localStorage.removeItem("bedroomInput1")
          localStorage.removeItem("bathroomInput1")
          localStorage.removeItem("kitchenInput1")
          localStorage.removeItem("dInput1")
          localStorage.removeItem("dInput2")
          localStorage.removeItem("dInput3")
          localStorage.removeItem("dInput4")
          localStorage.removeItem("saveLater")
          // localStorage.removeItem("room_ids")
          setError("")
          dispatch(clearAll())
          navigate("/3dFinal")
        }).catch((err) => setError(err))
      }
      else {
        const file = new FormData();
        file.append("file", pdfFile1.fullFile);
        setIsLoading(true)
        await axios.put(`${constants.threeDApi}/api/room/add-pdf?id=${localStorage.getItem("apiId")}`, file).then((res) => {
          console.warn(res)
          localStorage.removeItem('key')
          localStorage.removeItem("livingInput1")
          localStorage.removeItem("bedroomInput1")
          localStorage.removeItem("bathroomInput1")
          localStorage.removeItem("kitchenInput1")
          localStorage.removeItem("dInput1")
          localStorage.removeItem("dInput2")
          localStorage.removeItem("dInput3")
          localStorage.removeItem("dInput4")
          localStorage.removeItem("saveLater")
          // localStorage.removeItem("room_ids")
          setError("")
          dispatch(clearAll())
          navigate("/3dCart")
        }).catch((err) => setError(err))
      }
    }
    else {
      // alert("mo")
      setError("Upload a pdf file")
    }
  }
  // console.log(pdfFile1.fullFile)

  const handleFileDelete = (element) => {
    dispatch(deletePdfFile(element))
  }
  useEffect(() => {
    setImageName(pdfFile1?.name)
  }, [pdfFile1]);

  return (
    <>
      <div className="d-flex flex-column align-items-center">
      <div className="file_upload">
        {pdfFile1?.name ? <img src={cross} alt="" className="cross_img2 float-end" onClick={() => handleFileDelete(pdfFile1?.name)} /> : ""}
          <div className="main_file" style={error !== "" ? { border: "1px solid #A30D0D" } : pdfFile1?.name ? { border: "1px solid #176091" } : { border: "1px dashed #A7A7A7" }} onClick={() => { addImageRef.current.click() }}>
          <input ref={addImageRef} accept="application/pdf" type="file" className="fileType d-none" onChange={handleUpload} id='main_input' onClick={(event) => {
            event.target.value = null
          }} />
          <img src={pdfFile1?.name ? uploadFinal : upload} alt="image upload" className="upload_img" />
          {pdfFile1?.name ? <p className="upload_line2">{pdfFile1?.name}</p>
            : <><p className="upload_line">Select or drag & drop your file to add</p>
             </>
          }
        </div>
      </div>
      {error !== "" ? <span className='err-line'>{error}</span> : ""}
      </div>
      <div className="btn_main2">
       {isLoading ? <button className="uploadRef_submit" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </button> : <button className="uploadRef_submit" onClick={handleSubmit}>Submit</button>}
      </div>
    </>
  )
}

export default UploadReference0