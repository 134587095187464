import axios from "axios";

const auth = `Bearer ${localStorage.getItem("token")}`;
export const getLeadsData = () => {
  axios
    .get("https://pro-api.idesign.market/user/leads?pageNo=0", {
      headers: { authorization: auth },
    })
    .then((response) => response)
    .catch((error) => console.log(error))
}