import React from "react";

const UpdatesNew = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          lignItems: "center",
          padding: "1rem",
          borderBottom: "1px solid #E6E6E6",
          
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          
            <img
              src="https://idesign-office.s3.amazonaws.com/Group%202609555.svg"
              alt=""
            />
           
       
            
          <div>Client has shared feedback on Furniture Layout</div>
        </div>

      
        <div style={{ padding: "0.5rem 0 0.5rem 0" }}>
          <div style={{ fontSize: "14px" }}>2 hr ago</div>
        </div>
      </div>

      {/* sec */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          lignItems: "center",
          padding: "1rem",
          borderBottom: "1px solid #E6E6E6",
          
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202609557.svg"
              alt=""
            />
           
       
            
          <div>Client has shared feedback on Furniture Layout</div>
        </div>

      
        <div style={{ padding: "0.5rem 0 0.5rem 0" }}>
          <div style={{ fontSize: "14px" }}>2 hr ago</div>
        </div>
      </div>

      {/* third */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          lignItems: "center",
          padding: "1rem",
          borderBottom: "1px solid #E6E6E6",
          
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202609557.svg"
              alt=""
            />
           
       
            
          <div>Client has shared feedback on Furniture Layout</div>
        </div>

      
        <div style={{ padding: "0.5rem 0 0.5rem 0" }}>
          <div style={{ fontSize: "14px" }}>2 hr ago</div>
        </div>
      </div>
    </>
  );
};

export default UpdatesNew;

{/* <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
<img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202609557.svg" alt="" />
<div>Client has shared feedback on Furniture Layout</div>
</div> */}