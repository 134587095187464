import React, { useEffect, useState } from 'react'
import { Accordion, Modal } from 'react-bootstrap'
import { Carousel } from 'react-bootstrap'
import uncoloredStar from './3dImages/uncoloredStar.svg'
import coloredStar from './3dImages/coloredStar.svg'
import bedroom3 from './3dImages/bedroom3.svg'
import bedroomL from './3dImages/bedroomL.svg'
import bedroomL2 from './3dImages/bedroomL2.svg'
import reviews from './3dImages/reviews.svg'
import close from './3dImages/close.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import img1 from './3dImages/images3d/1.jpeg'
import img2 from './3dImages/images3d/2.jpeg'
import img3 from './3dImages/images3d/3.jpeg'
import img4 from './3dImages/images3d/4.jpeg'
import img5 from './3dImages/images3d/5.jpeg'
import img6 from './3dImages/images3d/6.jpeg'
import img7 from './3dImages/images3d/7.jpeg'
import img8 from './3dImages/images3d/8.jpeg'
import img9 from './3dImages/images3d/9.jpeg'
import img10 from './3dImages/images3d/10.jpeg'
import next from './3dImages/images3d/next.png'
import sidebar from './3dImages/sidebar.svg'

const SideLandingPageFinal = () => {
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);

    const navigate = useNavigate()

    const images = [
        {
            no: 1,
            imgName: img1,

        },
        {
            no: 2,
            imgName: img2,

        },
        {
            no: 3,
            imgName: img3,

        },
        {
            no: 4,
            imgName: img4,

        },
        {
            no: 5,
            imgName: img5,

        },
        {
            no: 6,
            imgName: img6,

        },
        {
            no: 7,
            imgName: img7,

        },
        {
            no: 8,
            imgName: img8,

        },
        {
            no: 9,
            imgName: img9,
        },
        {
            no: 10,
            imgName: img10,
        },

    ];

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [showId, setShowId] = useState()
    const [ofArr, setOfArr] = useState([])
    let [left, setLeft] = useState(0)

    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    }
    useEffect(() => {
        const b = images.filter((currElem) => {
            return currElem.no == showId
        })
        setOfArr(b)
    }, [showId])

    const [filteredArr, setFilteredArr] = useState([])

    useEffect(() => {
        const a = images.filter((currElem) => {
            return currElem.no !== showId
        })
        setFilteredArr(a)
    }, [showId])

    // console.log(filteredArr)

    return (
        <>
            <Modal show={show2} onHide={handleClose2} style={{ background: 'none' }} centered size="md">
                <img src={close} alt="" onClick={handleClose2} className="float-end" style={{
                    filter: "brightness(100)", cursor: "pointer", position: " absolute",
                    right: "-29rem",
                    top: "-6rem", width: "18px", height: "18px"
                }} />
                <Modal.Body>
                    {/* <img src={ofArr[0]?.imgName} alt="" style={{ width: '100%' }} /> */}
                    <Carousel variant="dark">
                        <Carousel.Item>
                            <div style={{ display: "flex", width: "fit-content" }}>
                                <div style={{
                                    width: "32rem", height: "28rem", marginRight: "10px"
                                }}>
                                    <img src={ofArr[0]?.imgName} alt="" style={{ width: '100%', height: "100%" }} />
                                </div>
                            </div>
                        </Carousel.Item>

                        {filteredArr.map((item, index) => {
                            return (
                                <Carousel.Item>
                                    <div style={{ display: "flex", width: "fit-content" }}>
                                        <div style={{
                                            width: "32rem", height: "28rem", marginRight: "10px"
                                        }}>
                                            <img src={item?.imgName} alt="" style={{ width: '100%', height: "100%" }} />
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                    </Modal.Body>
            </Modal>
            <div className="side_landing d-flex flex-column" >
                {profileInfo[0]?.data?.data?.planId?.name === "Free" ? <div className="side_content mb-5">
                    <button className="dis_prem" onClick={() => navigate("/plans")}>Upgrade to premium</button>
                </div> : ""}
                {/* <div className="side_content mb-5">
                <button className="dis_prem" onClick={() => navigate("/plans")}>Upgrade to premium</button>
            </div> */}
                {/* <div className="side_carousel d-flex flex-column">
                <h3 className="img_lan_header mb-3 mt-5">Reviews</h3>
                <div className="carouselComp_start mt-4">
                    <Carousel variant="dark">
                        <Carousel.Item>
                            <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                            <div className="caption_reviwes d-flex">
                                <div className="caption_name d-flex flex-column">
                                    <p className="cap_byline">Rohit Rathod</p>
                                    <span className="cap_span">Interior Designer</span>
                                </div>
                                <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                                    <img src={coloredStar} alt="" className="starColor" />
                                    <img src={coloredStar} alt="" className="starColor" />
                                    <img src={coloredStar} alt="" className="starColor" />
                                    <img src={uncoloredStar} alt="" className="starColor" />
                                    <img src={uncoloredStar} alt="" className="starColor" />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                            <div className="caption_reviwes d-flex">
                                <div className="caption_name d-flex flex-column">
                                    <p className="cap_byline">Rohit Rathod</p>
                                    <span className="cap_span">Interior Designer</span>
                                </div>
                                <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                                    <img src={coloredStar} alt="" className="starColor" />
                                    <img src={coloredStar} alt="" className="starColor" />
                                    <img src={coloredStar} alt="" className="starColor" />
                                    <img src={uncoloredStar} alt="" className="starColor" />
                                    <img src={uncoloredStar} alt="" className="starColor" />
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div> */}
                {/* <div className="side_carousel">
                <h3 className="img_lan_header mb-3 mt-5">Reviews</h3>
                <div className="d-flex flex-column w-100 justify-content-center" style={{
                    height: "26vh", border: "1px solid #DFDFDF",
                    borderRadius: "8px"
                }}>
                    <img src={reviews} style={{ height: "38px" }} alt="" />
                    <p style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "black",
                        textAlign: "center"
                    }}>No reviews yet</p>
                </div>
            </div> */}
                <div className="side_promotion d-flex flex-column mb-5">
                    <h3 className="img_lan_header">3Ds by iDesign</h3>
                    <div className="carouselComp_start mt-2" style={{ width: "71%", marginLeft: "-1rem" }}>
                        {/* <Carousel variant="dark" style={{
                        border: "none" }}>
                        <Carousel.Item style={{border: "none",  padding:"0"}}>
                            <div style={{height: "14rem"}}>
                            <img src={bedroom3} alt="" style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"
                            }} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{border: "none",  padding:"0"}}>
                            <div style={{height: "14rem"}}>
                                <img src={bedroomL} alt="" style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"

                            }} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{border: "none",  padding:"0"}}>
                            <div style={{height: "14rem"}}>
                                <img src={bedroomL2} alt="" style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"

                            }} />
                            </div>
                        </Carousel.Item>
                    </Carousel> */}


                        {/* <div className="d-flex">

                            <div className="landing_img_main d-flex justify-content-start" style={{ overflowX: "scroll" }}>

                                <Carousel variant="dark" style={{
                                    border: "none"
                                }}>
                                    {images.map((item, index) => (<Carousel.Item style={{ border: "none", padding: "0" }}>
                                        <img src={item.imgName} alt="" className="img1" style={{
                                            objectFit: "cover", width: "19rem", height: "11rem", cursor: "pointer", borderRadius: "8px"
                                        }} onClick={() => handleShow2(item.no)} />
                                    </Carousel.Item>))}
                                </Carousel>
                          
                            </div>
                        </div> */}
                        <div className="d-flex">
                            <img src={sidebar} alt="" className="dimag" style={{ transform: "rotate(180deg)", cursor: "pointer", position: "relative", left: "0.7rem" }} onClick={() => {
                                let element_ = document.querySelector('.landing_img_main')

                                if (element_.scrollLeft > 0) {
                                    element_.scrollLeft -= 298
                                }
                            }} />

                            <div className="landing_img_main d-flex justify-content-start" style={{ overflowX: "scroll", }}>
                                <div style={{ display: "flex", width: "fit-content" }}>
                                    {images.map((item, index) => (
                                        <div style={{
                                            width: "20.5rem", height: "12rem", marginRight: "10px"
                                        }}>
                                            <img src={item.imgName} alt="" className="img1" style={{ height: "100%", width: "100%", marginRight: "10px", cursor: "pointer", borderRadius: "8px" }} onClick={() => {
                                                handleShow2(item.no)
                                            }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <img src={sidebar} alt="" className="dimag" style={{ marginLeft: "auto", cursor: "pointer", position: "relative", right: "0.7rem" }} onClick={() => {
                                let element_ = document.querySelector('.landing_img_main')

                                if (element_.scrollLeft != 781.5999755859375) {
                                    element_.scrollLeft += 334
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideLandingPageFinal;