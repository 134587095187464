import React, { useEffect, useState } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import goBack from "../Images/gobackicon.svg";
import img3d from "./3dImages/3dimg.svg";
import cadicon from "./3dImages/cadicon.svg";
import pdficon from "./3dImages/pdficon.svg";
import Cart3dMob from "./Cart3dMob";
import check from "../Images/tickIcon.svg";
import gstDoc from "../Images/gstShowingDocIcon.svg";
import editIcon from "../Images/editingPencil.svg";
import axios from "axios";
import { fetchProfileData, setGstDetails } from "../Lms/Actions";
import { Form, Modal, Offcanvas } from "react-bootstrap";
import closeCanvasBar from "./3dImages/closeCanvasBar.svg";
import { getToken } from "../Lms/getToken";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartPayHalfMob from "./CartPayHalfMob";
import { createOrder } from "../Apis";
import { RAZOR_PAY_KEY } from "../Config";
import { toast } from "react-hot-toast";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";
const CartPayHalf = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 599);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const [pay, setPay] = useState("full");
  const handleRadio = (event) => {
    setPay(event.target.value);
  };

  const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
  const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  // console.log(profileInfo)
  // console.log(profileInfo[0]?.data?.data?.planId?._id)

  useEffect(async () => {
    const res2 = await axios.get(`https://3d-api.idesign.market/api/room/get-full-data?id=${localStorage.getItem("halfPayId")}`);
    // console.log(res2.data)
    setUserData(res2.data);
  }, []);

  useEffect(async () => {
    const res = await axios.get(`https://3d-api.idesign.market/api/room/cart-data?designerId=${localStorage.getItem("userId")}&planId=${profileInfo[0]?.data?.data?.planId?._id}`);
    setData(res.data);
  }, [profileInfo[0]?.data?.data?.planId?._id]);

  useEffect(() => {
    dispatch(fetchProfileData(authToken));
  }, []);

  console.log(userData);
  var totViews = 0;
  for (let j = 0; j < userData?.room?.length; j++) {
    totViews += userData?.room[j]?.views;
  }
  // console.log(totViews)

  const initialGstData = {
    companyName: profileInfo[0]?.data?.data?.companyName,
    gstNum: profileInfo[0]?.data?.data?.gstNumber,
  };
  const authTok = getToken() ? getToken() : "";
  const [gstOffcan, setGstOffcan] = useState(false);
  const [gstData, setGstData] = useState(initialGstData);
  const [showError, setShowError] = useState({
    companyNameError: "",
    gstNumError: "",
  });
  const gstOffcanHandler = (event) => {
    setGstOffcan(event.target.checked);
  };
  const [customCheckbox, setCustomCheckbox] = useState(profileInfo[0]?.data?.data?.planId?.name === "Free" ? false : false);
  const gstDataInputHandler = (event) => {
    const { name, value } = event.target;
    setGstData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleGstSubmit = () => {
    if (gstData.companyName.length < 1) {
      setShowError((prev) => {
        return {
          ...prev,
          companyNameError: "You cannot leave company name empty",
        };
      });
    } else if (gstData.gstNum.length < 1) {
      setShowError((prev) => {
        return {
          ...prev,
          gstNumError: "You cannot leave GST number empty",
        };
      });
    } else {
      setGstOffcan(false);
      dispatch(setGstDetails(authTok, gstData.gstNum, gstData.companyName));
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var discount = 0;
  if (profileInfo[0]?.data?.data?.planId?.name !== "Free") {
    discount = Math.round(0.166667 * ((totViews * 1200) / 2));
  }
  var gst = Math.round((18 / 100) * ((totViews * 1200) / 2 - discount));

  const placeOrderOnRazorpay = async () => {
    var amount = Math.round(userData.paidAmount);
    const res = await createOrder({ amount: parseFloat(amount.toFixed(2)) });

    if (res?.statusCode !== 200) {
      console.log(res?.message);
      return;
    }
    const option = {
      description: "iDesign payment",
      currency: "INR",
      key: RAZOR_PAY_KEY,
      amount: amount,
      name: "iDesign.market pvt. ltd.",
      order_id: res?.data?.id,
      handler: paymentHandler.bind(this, amount),
      prefill: {
        name: profileInfo[0].data.data.companyName,
        email: profileInfo[0].data.data.email,
        contact: profileInfo[0].data.data.phoneNumber,
      },
      notes: {
        address: `Payment for cart`,
      },
      theme: {
        color: "#61dafb",
      },
    };

    try {
      const paymentObject = new window.Razorpay(option);
      paymentObject.open();
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const paymentHandler = async (amount, response) => {
    const data = {
      pricePaid: amount,
      razorPaymentId: response?.razorpay_payment_id,
      razorOrderId: response?.razorpay_order_id,
      razorSignature: response?.razorpay_signature,
    };

    if (response) {
      const res = await axios
        .put(`https://3d-api.idesign.market/api/room/update-amount?id=${localStorage.getItem("halfPayId")}`, { paidAmount: userData.amountToBePaid })
        .then((res) => {
          navigate("/3dFinal");
          localStorage.removeItem("halfPayId");
        })
        .catch((err) => console.warn(err));
    }
  };

  const proceedToBuy = async () => {
    placeOrderOnRazorpay();
    // const res = await axios.put(`https://3d-api.idesign.market/api/room/update-amount?id=${localStorage.getItem("halfPayId")}`, { paidAmount: userData.amountToBePaid }).then((res) => {
    //     navigate("/3dFinal")
    //     localStorage.removeItem("halfPayId")
    // }).catch(err => console.warn(err))
  };

  return (
    <>
      <div className="d-none d-md-block">
        <Modal
          show={gstOffcan}
          onHide={() => {
            setGstOffcan(false);
          }}
          centered
        >
          <Modal.Header className="p-0" closeButton style={{ margin: "16px 16px 0 16px" }}>
            <Modal.Title>GST Information</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0" style={{ margin: "16px" }}>
            <div style={{ marginBottom: "24px" }}>
              <div>Company Name</div>
              <div>
                <input className="w-100" placeholder="Type company name" name="companyName" value={gstData.companyName} onChange={gstDataInputHandler} />
                {gstData?.companyName?.length < 1 && <span style={{ fontSize: "12px", color: "red" }}>{showError.companyNameError}</span>}
              </div>
            </div>
            <div style={{ marginBottom: "32px" }}>
              <div>Registration No.</div>
              <div>
                <input className="w-100" maxLength={15} placeholder="Enter Registration No." name="gstNum" value={gstData.gstNum} onChange={gstDataInputHandler} />
                {gstData?.gstNum?.length < 1 && <span style={{ fontSize: "12px", color: "red" }}>{showError.gstNumError}</span>}
              </div>
            </div>
            <div>
              <button className="gstModalWebButton" onClick={handleGstSubmit}>
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose} centered size="md">
          <Modal.Body className="d-flex flex-column p-4">
            <p
              style={{
                fontWeight: "400",
                fontSize: "22px",
                color: "black",
              }}
            >
              Are you sure you want to remove this?
            </p>
            <div>
              <button
                style={{
                  background: "#176091",
                  borderRadius: "4px",
                  border: "none",
                  color: "white",
                  width: "90px",
                  height: "32px",
                  cursor: "pointer",
                }}
              >
                Yes
              </button>
              <button
                className="mx-3"
                style={{
                  background: "#FFFFFF",
                  borderRadius: "4px",
                  border: "1px solid gainsboro",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
                  color: "black",
                  width: "90px",
                  height: "32px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                No
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <HeaderNav />
        <div className="tabDisplay">
          <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh" }}>
            <SidebarWebNew />
          </div>
          <div style={{ width: "78vw", padding: "2rem", height: "90vh", overflowY: "scroll" }} className="myCart-wholeContent">
            <div className="webMyCart-header" style={{ padding: "12px 0px", borderBottom: "1px solid #DFDFDF", boxShadow: "none", cursor: "pointer" }} onClick={() => navigate("/3dLanding")}>
              <div className="d-flex align-items-center" role="button">
                <img src={goBack} />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "500" }} role="button">
                My Cart
              </div>
            </div>
            <div style={{ margin: "24px 0", fontSize: "18px", fontWeight: "500" }}>My Items</div>

            <div className="cart_main">
              <div className="cart_left" style={{ height: "fit-content" }}>
                <div className="cart_img_div">
                  <img src={img3d} alt="" className="cart_img" />
                </div>
                <div className="cart_left_start">
                  <div className="cart_sub">
                    <h4 className="cart_head">3D feature</h4>
                    <div className="cart_below">
                      <div className="cart_below2">
                        {userData?.room?.map((item, index) => (
                          <button className="mapped">
                            {item.name}({item.views})
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="cart_file">
                    <div className="cart_file_sub">
                      {userData && userData?.cadFile?.fileName && (
                        <p className="file1">
                          <img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />
                          {userData?.cadFile?.fileName?.slice(0, 9)}
                          {"..."}
                        </p>
                      )}
                      {userData.pdf && userData?.pdf?.fileName ? (
                        <p className="file1" style={{ marginLeft: "10px" }}>
                          <img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />
                          {userData?.pdf?.fileName?.slice(0, 9)}
                          {"..."}
                        </p>
                      ) : (
                        <p className="file1" style={{ marginLeft: "10px" }}>
                          <img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />
                          xyz file
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="ca_ending d-flex flex-row">
                    <p className="cart_tot">₹ {(totViews * 1200) / 2}</p>
                    <span className="cart_payment">(Half Payment)</span>
                    {/* <button className='pay-btn float-end' onClick={handleShow}>Remove</button> */}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ width: "24vw", padding: "2rem", height: "60vh", paddingTop: "0" }} className="myCart-wholeContent">
                  <div className="webCart-pricingPart my-2" style={{ width: "100%" }}>
                    <div className="sections" style={{ padding: "32px 24px 16px 20px", border: "1px solid #DFDFDF" }}>
                      <div style={{ fontSize: "15px", fontWeight: "400", marginBottom: "16px" }}>Price Details</div>
                      <div className="d-flex justify-content-between">
                        <div style={{ fontSize: "13px", fontWeight: "700" }}>Total View({totViews})</div>
                        <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {(totViews * 1200) / 2}</div>
                      </div>
                      {profileInfo[0]?.data?.data?.planId?.name !== "Free" ? (
                        <div className="d-flex justify-content-between">
                          <div style={{ fontSize: "13px", fontWeight: "700" }}>
                            Discount{" "}
                            <span
                              style={{
                                color: "#888888",
                                fontWeight: "400",
                                fontSize: "12px",
                              }}
                            >
                              (premium user)
                            </span>
                          </div>
                          <div style={{ fontSize: "13px", fontWeight: "400", color: "#176091" }}>- ₹ {discount}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "30px", marginBottom: "16px" }}>
                        <div style={{ fontSize: "13px", fontWeight: "700" }}>GST- 18%</div>
                        <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {gst}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div style={{ fontSize: "15px", fontWeight: "700" }}>Total Price</div>
                        <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(userData.paidAmount)}</div>
                      </div>
                    </div>
                    <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                      <div className="d-flex align-items-center" style={{ paddingBottom: "10px" }}>
                        <div>
                          <div
                            className="customCheckbox"
                            style={{ marginRight: "12px", userSelect: "none" }}
                            role="button"
                            onClick={() => {
                              setGstOffcan(true);
                            }}
                          >
                            {profileInfo[0]?.data?.data?.gstNumber ? <img src={check} /> : null}
                          </div>
                        </div>
                        <div style={{ fontSize: "15px" }}>
                          I have a GST number <span>(Optional)</span>
                        </div>
                      </div>
                      <div>
                        {gstData?.gstNum?.length > 0 && (
                          <div className="gstInfoShowingBox" style={{ padding: "8px 16px 8px 12px" }}>
                            <div className="d-flex align-items-center">
                              <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                <img src={gstDoc} />
                              </div>
                              <div>
                                <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.companyName}</div>
                                <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.gstNum}</div>
                              </div>
                            </div>
                            <div>
                              <img
                                src={editIcon}
                                role="button"
                                style={{ height: "20px", width: "18px" }}
                                onClick={() => {
                                  setGstOffcan(true);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="sections" style={{ border: "none", marginBottom: "2rem" }}>
                      <button className="proceedToBuyButton" style={{ marginBottom: "1rem" }} onClick={proceedToBuy}>
                        Proceed To Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CartPayHalfMob />
    </>
  );
};

export default CartPayHalf;
