import React, {useState, useEffect} from "react";
import styles from "./ProjectManagementContainer.module.css"
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const ProjectManagementContainer=()=>{
  const isMobile = useMediaQuery('(max-width:767px)');

  const navigate=useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);

  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
return(
    <>
   
  <div className={styles.heading}>Intuitive Features that understand this Industry</div>
  <div className={styles.mainContainer}>
  <div className={styles.flexContainer}>
  <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/home1.png" alt=" " className={styles.homeSectionImgs} />

 {/* {isDesktop
 ? 
 <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/home1.png" alt=" " className={styles.homeSectionImgs} />
     :
     <div className={styles.outerContainer}>
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/project.png"
       alt="project" className={styles.project} />
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/TopProject.png"
       alt="project top" className={styles.projectTop}/>
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/proStats.png"
       alt="projectStats" className={styles.projectStatsImg}  />
    </div> 
    } */}
    <div className={styles.textMainContainer}>
      <div className={styles.projectManagementBtn}><div className={styles.projectManagementBtnText}>Project Management</div></div>
      <div className={styles.projectManagementHeading}>Managing your Project has never been this easy</div>
      <div className={styles.projectManagementText}>Centralize project files, streamline timelines, manage tasks, track materials, resolve issues, receive daily site reports, and facilitate design collaboration.</div>
    <div className={styles.learnMoreBtn}>
      <div className={styles.learnMoreBtnText}  >
        <a href="/features#jumpto1" style={{color:"inherit", textDecoration:"none"}}>Read More</a>
        </div>
      {isMobile 
      ?
     ""
       :
       <img alt="" 
       src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg"
        className={styles.arrowRight}  />
       }
    </div>
    </div>
  </div>
  <div className={`${styles.flexContainer} ${styles.flexSecondContainer}`}>
    <div  className={styles.textMainContainer}>
      <div className={styles.projectManagementBtn} style={{background:"#F3EAFA"}}><div className={styles.projectManagementBtnText} style={{color:"#7B2CBF"}}>Cost Management</div></div>
      <div className={styles.projectManagementHeading}>Efficiently manage your finances, save cost & earn profit</div>
      <div className={styles.projectManagementText}>Generate, share, approve, monitor item masters and quotations. Convert quotations into purchase orders, monitor additional items and margins. Instantly create project ledgers with payment triggers.</div>
    <div className={styles.learnMoreBtn}>
    <div className={styles.learnMoreBtnText}     >
        <a href="/features#jumpto2" style={{color:"inherit", textDecoration:"none"}}>Read More</a>
        </div>
        {isMobile 
      ?
     ""
       :
       <img alt="" 
       src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg"
        className={styles.arrowRight}  />
       }
    </div>
    </div>
    {isDesktop ?
    <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/home2.png" alt="" className={styles.homeSectionImgs} />
      :
    <div className={styles.outerContainer} style={{background:"#F6EFFB"}}>
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/cost.png" alt="project" className={styles.project} />
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CostBottom.png"
      alt="cost top" className={styles.costBtm}  />
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/costTop.png"
       alt="cost bottom" className={styles.costTop}  />
    </div>}
  </div>
  <div className={styles.flexContainer}>
   { isDesktop ?
    <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/home3.png" alt="" className={styles.homeSectionImgs} />
    :
    <div className={styles.outerContainer} style={{background:"#E9FBF6"}}>
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/client.png"
      alt="project" className={styles.project} />
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/clientTop.png" alt="client top" className={styles.clientTop} />
      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/clientBtm.png" alt="client Btm" className={styles.clientBtm} />
    </div>
    }
    <div  className={styles.textMainContainer}>
      <div className={styles.projectManagementBtn} style={{background:"#DCF9F0"}}><div className={styles.projectManagementBtnText} style={{color:"#1A936F"}}>Client Management</div></div>
      <div className={styles.projectManagementHeading}>Stay ahead of the competition, nurture your clients</div>
      <div className={styles.projectManagementText}>Stay on track with your projects from start to finish via profile listing. Better project updates with login for your clients. Get approvals from your clients in minutes.</div>
    <div className={styles.learnMoreBtn}>
    <div className={styles.learnMoreBtnText} >
        <a href="/features#jumpto3" style={{color:"inherit", textDecoration:"none"}}>Read More</a>
        </div>
        {isMobile 
      ?
     ""
       :
       <img alt="" 
       src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg"
        className={styles.arrowRight}  />
       }
    </div>
    </div>
  </div>
  </div>
    </>
)
}

export default ProjectManagementContainer;