import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ScheduleADemo from "./ScheduleADemo";
import styles from "./ProductsCss/productsSec7.module.css";
import icon1 from "./ProductsImg/task/all_match.svg";
import icon2 from "./ProductsImg/task/auto_delete.svg";
import icon3 from "./ProductsImg/task/vital_signs.svg";
// import img7 from "./ProductsImg/task/task.svg";

const ProductSection7 = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className={styles.container} id="task">
        <div className={styles.files_contain}>
          <div className={styles.img_div}>
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/taskNew.svg" alt="" className={styles.mainImg} />
          </div>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>TASK MANAGEMENT</div>
            <div className={styles.file_para}>
              Optimize resource usage and ensures that <br /> projects are
              completed efficiently and on time.
            </div>
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Manage pending <br />& Delayed Tasks
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Prioritize Important <br /> Tasks
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Track <br />
                  Dependencies
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection7;
