import React, { useEffect } from "react";
import editImage from "../Images/editIcon.png";
import img from "../Images/addMoreImage.png";
import bluetick from "../Images/blueTick.png";
import { useState } from "react";
import { useNavigate } from "react-router";
import SaveOrEditPicsDesignerWeb from "./OnboardingWebScreens/SaveOrEditPicsDesignerWeb";
import { useSelector } from "react-redux";
import { addMoreProjectImages, addProjectToArray, deleteProjectImages, saveEditedProjectImages, setProjectDetailsRedux } from "../Lms/Actions";
import { useDispatch } from "react-redux";

export default function SaveOrEditProject() {
  const dispatch = useDispatch();
  const [currentIds, setCurrentIds] = useState([]);
  const filesArr = useSelector((state) => state.addToCartReducer.projectImages);
  const tempProjArr = useSelector((state) => state.addToCartReducer.tempProjectList);
  const projName = useSelector((state) => state.addToCartReducer.projectDetails);
  const [title, setTitle] = useState(projName.name);
  const [isEditMode, setIsEditMode] = useState(false);

  const navigateTo = useNavigate();

  function handleClick(element) {
    if (!currentIds.includes(element)) {
      setCurrentIds((prev) => {
        return [...prev, element]
      })
    } else {
      setCurrentIds(
        currentIds.filter((curElem) => {
          return curElem !== element;
        })
      )
    }
  }

  function handleDelete(e) {
    dispatch(deleteProjectImages(currentIds));
    setCurrentIds(currentIds.filter((curElem) => {
      return !currentIds.includes(curElem);
    }))
  }

  function handleSaveProject() {
    let temp = 0;
    console.log(tempProjArr);
    tempProjArr?.forEach((curElem) => {
      if (curElem.name === projName.name) {
        temp++;
      }
    })
    if (temp > 0) {
      dispatch(saveEditedProjectImages(projName.name, filesArr));
      navigateTo("/projectsummary");
    }
    else if (filesArr.length > 0 && temp == 0) {
      const x = {
        name: projName?.name,
        city: projName?.city,
        images: filesArr
      }
      dispatch(addProjectToArray(x))
      navigateTo("/projectsummary");
    }
  }
  function handleTitle(e) {
    dispatch(setProjectDetailsRedux(e.target.value, projName?.city))
  }

  function handleEdit() {
    setIsEditMode((prev) => !prev);
  }

  function handlePics(e) {
    let obj = e.target.files;
    for (let i = 0; i < obj.length; i++) {
      dispatch(addMoreProjectImages({ file: obj[i], path: URL.createObjectURL(obj[i]) }));
    }
  }

  const files = useSelector((state) => state.addToCartReducer.projectImages);

  useEffect(() => {
    setTitle(projName?.name)
  }, [projName])


  return (
    <>
      {window.screen.width > "768" ?
        <SaveOrEditPicsDesignerWeb />
        :
        <div className="saveOrEdit-container">
          <div className="main-saveoredit">
            <div>
              <div className="header">
                {/* {!isEditMode && <div style={{ marginLeft: "5%", width: "100%", fontSize: "16px", fontWeight: "400" }}>{title}</div>} */}
                <div style={{ marginLeft: "5%", width: "100%", fontSize: "16px", fontWeight: "400" }}>{title}</div>
                {/* {isEditMode && <input className="editField" onChange={handleTitle} type="text" value={title} />}
                <button onClick={handleEdit} style={{ backgroundColor: "white" }}>
                  <img src={editImage} alt="" />
                </button> */}
              </div>
              <div className="body">
                {/* <div>asdf</div>
          <div>dfgh</div>
          <div>fghjk</div> */}
                <div style={{ backgroundColor: "#F2F2F2", borderRadius: "10px", paddingLeft: "3rem", paddingRight: "3rem", margin: "1rem 0 1rem 1rem", height: "5rem", width: "104px", height: "98px" }} className="addmoreImage">
                  <input onChange={handlePics} type="file" multiple />
                  <button>
                    {" "}
                    <img src={img} alt="" />{" "}
                  </button>
                </div>
                {files?.map((obj, i) => {
                  return (
                    <div onClick={() => handleClick(obj.path)} key={obj.path} id={obj.path} className="addmoreImages">
                      {currentIds.includes(obj.path) && (
                        <img
                          id={obj.path}
                          className="bluetick"
                          src={bluetick}
                          style={{
                            position: "absolute",
                            top: "15px",
                            right: "10px",
                            backgroundColor: "blue",
                          }}
                        />
                      )}
                      <img className="uploadedImage" id={obj.path} src={obj.path} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex justify-content-center m-3">
              {currentIds.length == 0 && (
                <button onClick={handleSaveProject} className="nextbutton">
                  Save & Next
                </button>
              )}
              {currentIds.length > 0 && (
                <button onClick={handleDelete} className="nextbutton">
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}
