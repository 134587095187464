import React, { useEffect, useState } from 'react'
import goBack from "../Images/gobackicon.svg";
import img3d from './3dImages/3dimg.svg'
import cadicon from './3dImages/cadicon.svg'
import pdficon from './3dImages/pdficon.svg'
import check from "../Images/tickIcon.svg";
import gstDoc from "../Images/gstShowingDocIcon.svg";
import editIcon from "../Images/editingPencil.svg";
import info from "./3dImages/info.svg";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, payMethod } from '../Lms/Actions/threedActions';
import axios from 'axios';
import { fetchProfileData, setGstDetails } from '../Lms/Actions';
import { Form, Modal, Offcanvas } from 'react-bootstrap';
import closeCanvasBar from "./3dImages/closeCanvasBar.svg"
import { getToken } from "../Lms/getToken";
import SideCartMob from './SideCartMob';
import { createOrder } from '../Apis';
import { RAZOR_PAY_KEY } from '../Config';
import { toast } from "react-hot-toast";
const Cart3dMob = () => {
    const [pay, setPay] = useState("full")
    const handleRadio = (event) => {
        setPay(event.target.value)
    }
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo)
    // console.log(profileInfo[0]?.data?.data?.planId?._id)

    const planId = profileInfo[0]?.data?.data?.planId?._id
    useEffect(async () => {
        const res3 = await axios.get(`https://3d-api.idesign.market/api/room/req-designs?designerId=${localStorage.getItem("userId")}`)
        // console.log(res3)
        setUserData(res3.data)

    }, [])

    useEffect(async () => {
        const res = await axios.get(`https://3d-api.idesign.market/api/room/cart-data?designerId=${localStorage.getItem("userId")}&planId=${profileInfo[0]?.data?.data?.planId?._id}`)
        setData(res.data)
    }, [profileInfo[0]?.data?.data?.planId?._id])

    // console.log(userData)
    const placeOrderOnRazorpay = async () => {
        var amount;
        if (pay === "half") {
            amount = Math.round( data.totalAmountToBePaid / 2);
        }
        else {
            amount = Math.round(data.totalAmountToBePaid);
        }

        const res = await createOrder({ amount: parseFloat(amount.toFixed(2)) });

        if (res?.statusCode !== 200) {
            console.log(res?.message);
            return;
        }

        const option = {
            description: "iDesign payment",
            currency: "INR",
            key: RAZOR_PAY_KEY,
            amount: amount,
            name: "iDesign.market pvt. ltd.",
            order_id: res?.data?.id,
            handler: paymentHandler.bind(this, amount),
            prefill: {
                name: profileInfo[0].data.data.companyName,
                email: profileInfo[0].data.data.email,
                contact: profileInfo[0].data.data.phoneNumber,
            },
            notes: {
                address: `Payment for cart`,
            },
            theme: {
                color: "#61dafb",
            },
        };

        try {
            const paymentObject = new window.Razorpay(option);
            paymentObject.open();
        } catch (err) {
            toast.error(err?.message)
        }
    };

    const paymentHandler = async (amount, response) => {
        const data = {
            pricePaid: amount,
            razorPaymentId: response?.razorpay_payment_id,
            razorOrderId: response?.razorpay_order_id,
            razorSignature: response?.razorpay_signature,
        };
        if (response) {
            userData.map(async (item) => {
                const resM = await axios.put(`https://3d-api.idesign.market/api/room/save-amount-details?id=${item._id}&planId=${profileInfo[0]?.data?.data?.planId?._id}`).then((res) => {
                    navigate("/3dFinal")
                })
            })
        }
    };

    const proceedToBuy = () => {
        dispatch(payMethod(pay))
        placeOrderOnRazorpay()
        // userData.map(async (item) => {
        //     const resM = await axios.put(`https://3d-api.idesign.market/api/room/save-amount-details?id=${item._id}&planId=${profileInfo[0]?.data?.data?.planId?._id}`).then((res) => {
        //         navigate("/3dFinal")
        //     })
        // })
    }

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    // console.log(userData)
    var totViews = 0

    for (let i = 0; i < userData?.length; i++) {
        totViews += userData[i]?.views
    }
    const initialGstData = {
        companyName: profileInfo[0]?.data?.data?.companyName,
        gstNum: profileInfo[0]?.data?.data?.gstNumber,
    };
    const authTok = getToken() ? getToken() : "";
    const [gstOffcan, setGstOffcan] = useState(false);
    const [gstData, setGstData] = useState(initialGstData);
    const [showError, setShowError] = useState({
        companyNameError: "",
        gstNumError: "",
    });
    const gstOffcanHandler = (event) => {
        setGstOffcan(event.target.checked);
    };
    const [customCheckbox, setCustomCheckbox] = useState(profileInfo[0]?.data?.data?.planId?.name === "Free" ? false : false);
    const gstDataInputHandler = (event) => {
        const { name, value } = event.target;
        setGstData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleGstSubmit = () => {
        if (gstData.companyName.length < 1) {
            setShowError((prev) => {
                return {
                    ...prev,
                    companyNameError: "You cannot leave company name empty",
                };
            });
        } else if (gstData.gstNum.length < 1) {
            setShowError((prev) => {
                return {
                    ...prev,
                    gstNumError: "You cannot leave GST number empty",
                };
            });
        } else {
            setGstOffcan(false);
            dispatch(setGstDetails(authTok, gstData.gstNum, gstData.companyName));
        }
    };

    var totViews = 0
    for (let i = 0; i < userData.length; i++) {
        for (let j = 0; j < userData[i].room.length; j++) {
            totViews += userData[i].room[j].views
        }
    }

    return (
        <>
    
            <div className='d-block d-md-none' style={{ background: "white" }}>
                <Offcanvas
                    show={gstOffcan}
                    onHide={() => {
                        setGstOffcan(false);
                    }}
                    placement="bottom"
                    style={{ height: "fit-content", borderRadius: "1rem 1rem 0 0" }}
                >
                    <Offcanvas.Header className="mb-0">
                        <div
                            className="w-100 d-flex justify-content-center"
                            onClick={() => {
                                setGstOffcan(false);
                            }}
                        >
                            <img src={closeCanvasBar} />
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-3">
                        <div className="w-100 fs-4 fw-bold mb-3">GST Information</div>
                        <div className="mb-3">
                            <Form.Control type="text" value={gstData.companyName} name="companyName" placeholder="Company Name" onChange={gstDataInputHandler} />
                            {gstData?.companyName?.length < 1 && <span style={{ color: "red", fontSize: "10px", fontWeight: "400" }}>{showError.companyNameError}</span>}
                        </div>
                        <div className="mb-3">
                            <Form.Control type="text" maxLength={15} value={gstData.gstNum} name="gstNum" placeholder="GST Number" onChange={gstDataInputHandler} />
                            {gstData?.gstNum?.length < 1 && <span style={{ color: "red", fontSize: "10px", fontWeight: "400" }}>{showError.gstNumError}</span>}
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <button style={{ width: "100%", padding: "0.5rem", border: "none", backgroundColor: "#176091", color: "white", borderRadius: "0.3rem" }} onClick={handleGstSubmit}>
                                CONFIRM
                            </button>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <div style={{ background: "white" }}>
                    <div className="webMyCart-header" style={{ padding: "12px 12px", borderBottom: "1px solid #DFDFDF", boxShadow: "none" }}>
                        <div className="d-flex align-items-center" role="button">
                            <img src={goBack} />
                        </div>
                        <div style={{ fontSize: "20px", fontWeight: "500" }} role="button">
                            My Cart
                        </div>
                    </div>
                    <div style={{ width: "100%", padding: "2rem", background: "white" }} className="myCart-wholeContent">

                        <div className="webCart-pricingPart" style={{ width: "100%" }}>
                            {pay === "full" ? <div className="sections" style={{ padding: "16px 24px 16px 20px", border: "1px solid #DFDFDF" }}>
                                <div style={{ fontSize: "15px", fontWeight: "400", marginBottom: "16px", paddingBottom: "10px", borderBottom: "1px solid #DFDFDF" }}>Price Details</div>

                                <div className="d-flex justify-content-between">
                                    <div style={{ fontSize: "13px", fontWeight: "700" }}>Total View({totViews})</div>
                                    <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {data.totalAmount}</div>
                                </div>
                                {profileInfo[0]?.data?.data?.planId?.name !== "Free" ? <div className="d-flex justify-content-between">
                                    <div style={{ fontSize: "13px", fontWeight: "700" }}>Discount <span style={{
                                        color: "#888888", fontWeight: "400", fontSize: "12px"
                                    }}>(premium user)</span></div>
                                    <div style={{ fontSize: "13px", fontWeight: "400", color: "#176091" }}>- ₹ {Math.round(data.discount)}</div>
                                </div> : ""}
                                <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "30px", marginBottom: "16px" }}>
                                    <div style={{ fontSize: "13px", fontWeight: "700" }}>GST- 18%</div>
                                    <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.gstApplied)}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div style={{ fontSize: "15px", fontWeight: "700" }}>Total Price</div>
                                    <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.totalAmountToBePaid)}</div>
                                </div>
                            </div> : <div className="sections" style={{ padding: "16px 24px 16px 20px", border: "1px solid #DFDFDF" }}>
                                <div style={{ fontSize: "15px", fontWeight: "400", marginBottom: "16px", paddingBottom: "10px", borderBottom: "1px solid #DFDFDF" }}>Price Details</div>

                                    <div className="d-flex justify-content-between">
                                        <div style={{ fontSize: "13px", fontWeight: "700" }}>Total View({totViews})</div>
                                        <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {data.totalAmount/2}</div>
                                    </div>
                                {profileInfo[0]?.data?.data?.planId?.name !== "Free" ? <div className="d-flex justify-content-between">
                                    <div style={{ fontSize: "13px", fontWeight: "700" }}>Discount <span style={{
                                        color: "#888888", fontWeight: "400", fontSize: "12px"
                                    }}>(premium user)</span></div>
                                    <div style={{ fontSize: "13px", fontWeight: "400", color: "#176091" }}>- ₹ {Math.round(data.discount / 2)}</div>
                                </div> : ""}
                                <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "30px", marginBottom: "16px" }}>
                                    <div style={{ fontSize: "13px", fontWeight: "700" }}>GST- 18%</div>
                                        <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.gstApplied / 2)}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div style={{ fontSize: "15px", fontWeight: "700" }}>Total Price</div>
                                        <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(data.totalAmountToBePaid / 2)}</div>
                                </div>
                            </div>}
                            <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                                <div className="d-flex align-items-center" style={{ paddingBottom: "10px" }} >
                                    <div>
                                        <div
                                            className="customCheckbox"
                                            style={{ marginRight: "12px", userSelect: "none" }}
                                            role="button"
                                            onClick={() => {
                                                setGstOffcan(true);
                                            }}
                                        >
                                            {profileInfo[0]?.data?.data?.gstNumber ? <img src={check} /> : null}
                                        </div>
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                        I have a GST number <span>(Optional)</span>
                                    </div>
                                </div>
                                <div>
                                    {gstData?.gstNum?.length > 0 && (<div className="gstInfoShowingBox" style={{ padding: "8px 16px 8px 12px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                                <img src={gstDoc} />
                                            </div>
                                            <div>
                                                <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.companyName}</div>
                                                <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.gstNum}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                src={editIcon}
                                                role="button"
                                                style={{ height: "20px", width: "18px" }}
                                                onClick={() => {
                                                    setGstOffcan(true);
                                                }}
                                            />
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                                <div className="d-flex flex-column align-items-center" >
                                    <div className='d-flex w-100' style={{ borderBottom: "1px solid #DFDFDF" }}>
                                        <input type="radio" value="full" checked={pay === "full"} onChange={handleRadio} /><p className='my-2 px-2 d-flex justify-content-between  w-100' style={{ fontWeight: "400", fontSize: "14px", color: "black" }}> Pay in full<span className='float-end'>₹ {Math.round(data.totalAmountToBePaid)} /-</span></p>
                                    </div>
                                    <div className='d-flex w-100'>
                                        <input type="radio" value="half" checked={pay === "half"} onChange={handleRadio} /><p className='my-2 px-2 d-flex justify-content-between  w-100' style={{ fontWeight: "400", fontSize: "14px", color: "black" }}>Pay 50% later<span className='float-end'>₹ {Math.round(data.totalAmountToBePaid / 2)}/-</span></p>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="item_detailsMob mt-5 pb-5">
                            <h3 className='img_lan_header mb-3'>Item Details</h3>
                            <SideCartMob payMethod={pay} />
                        </div>

                    </div>

                </div>
                <div className="cart-btns">
                    <div className="car_btns_det">
                        {pay === "full" ? <p className='det_price'>{Math.round(data.totalAmountToBePaid)}</p> : <p className='det_price'>{Math.round(data.totalAmountToBePaid / 2)}</p>}
                        <p className='p_details'>View price details</p>
                    </div>
                    <button className="car_btns_place" onClick={proceedToBuy}>Place Order</button>
                </div>
            </div>
        </>
    )
}

export default Cart3dMob