import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./loadingComponent.module.css";

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <div className={styles.loadingContainer}>
        <Spinner animation="border" size="lg" />
      </div>
    </React.Fragment>
  );
};

export default LoadingComponent;
