import React, {useEffect, useState} from "react";
import styles from "./Common.module.css";
import { useNavigate } from "react-router-dom";

const QueriesContainer=()=>{
    const navigate = useNavigate();
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };
  const scrollToTop=()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    });
  }
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

    return(
        <div className={styles.QueriesContainer}>
        <div className={styles.flexContainer}>
        <div className={styles.leftContainerFlexItem}>
          <img
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Headphones.svg"
            className={styles.questionMarkImg}
          />
         {isDesktop
         ?
          <div className={styles.text}>Have any queries? Feel free to get in touch, we’re here to assist you!</div>
         :
          <div className={styles.text}>Have any queries? We are here to assist you!</div>
          }
        </div>
        <div className={styles.rightContainerFlexItem}>
          <div
            className={styles.scheduleDemoContainer}
            style={{ marginTop: "0rem",  width : "unset" }}
            onClick={() => navigate("/contact")}
          >
            <div className={styles.scheduleDemoText} style={{margin:"0 2rem", userSelect:"none" }}
              onClick={()=>scrollToTop()}>Talk to us</div>
          </div>
        </div>
      </div>
      </div>
    )
}

export default QueriesContainer;