import React from "react";
import { useState } from "react";
import img from "../../Images/addImageIcon.png";
import editImage from "../../Images/editIcon.png";
import { useContext } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { OnboardingContext } from "../../Context/Context";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addMoreProjectImages, addProjectImages, setProjectDetailsRedux } from "../../Lms/Actions";
import { useEffect } from "react";

export default function OemAddKitchenPhotosMob() {
  const dispatch = useDispatch();
  const addImageRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const context = useContext(OnboardingContext);
  const projName = useSelector((state) => state.addToCartReducer.projectDetails);
  const [title, setTitle] = useState(projName?.name);
  //   const [projectImages, setProjectImages] = useState([]);
  const navigateTo = useNavigate();

  function handlePics(e) {
    let obj = e.target.files;
    let filesArr = [];

    for (let i = 0; i < obj.length; i++) {
      filesArr.push({ file: obj[i], path: URL.createObjectURL(obj[i]) });
    }

    dispatch(addProjectImages(filesArr))

    navigateTo("/oemsavekitchenphotos");
  }

  function handleClick() {
    setIsEditMode((prev) => !prev);
  }

  function handleTitle(e) {
    dispatch(setProjectDetailsRedux(e.target.value, projName?.city))
  }

  const clickToAddImages = () => {
    addImageRef.current.click();
  }


  return (
    <>
      <div className="addProjectPics-container">
        <div className="main-addprojectpicsContainer">
          <div className="header w-100">
          <p style={{color: "#282828", fontWeigth: "400", fontSize: "22px", marginLeft: "66px", marginTop: "23px"}}>Upload Images of Kitchen</p>
          </div>
          <div className="body" onClick={clickToAddImages}>
            <button className="clicktoaddpics">
              <input className="d-none" onChange={handlePics} ref={addImageRef} type="file" multiple /> <img src={img} />
            </button>
            <div style={{fontSize: "14px", fontWeight: "400", margin: "40px 0 4px 0"}}>Please upload your project photos</div>
            <div style={{fontSize: "14px", fontWeight: "400"}}> Accepted format jpeg,png </div>
          </div>
          <div></div>
          {/* {isEditMode && <AddProjectDetails />} */}
        </div>
      </div>
    </>
  );
}
