import React, { useState } from 'react'
import congo from '../../Images/CongoWebImage.svg'
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const CongoWeb = () => {
  const navigateTo = useNavigate();

  const [startPosition, setStartPosition] = useState();

  const profileData = useSelector((state) => state.addToCartReducer.profileData);

  useEffect(()=>{
    localStorage.removeItem("path")
  },[])

  function navigate() {
    if (startPosition !== "profilepage") {
      navigateTo("/plans")
    }
  }

  useEffect(() => {
    const startPosition = localStorage.getItem('from');
    setStartPosition(startPosition);
  }, [])
  useEffect(() => {
    if (profileData.length !== 0) {
      localStorage.setItem("listinglink", `https://www.idesign.market/designer/interior-designers-in-${profileData[0]?.data?.data?.city}/${profileData[0]?.data?.data?.companyName.trim().replaceAll(" ", "-")}`)
    }
  }, [])

  return (
    <div className='congo_main d-flex justify-content-center align-items-center'>
      <div className="congo_main2 vh-100">
        <div className="congo_center2">
          <div className="congo_start">
            <img src={congo} alt="congo image" className="congo_img" />
            <h2 className="congo_header">Congratulations!</h2>
            <p className="congo_line">Your Free listing has been created. Want to see your listing ? <a href={localStorage.getItem("listinglink")} className="congo_click">Click Here</a></p>
            <button className="congo_submit" onClick={navigate}>Continue</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CongoWeb