import React, {useState, useEffect} from "react";
import styles from "./Common.module.css";
import { Modal } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const CommonSecondContainer = ({ secMainHeading, dataArray, subHeading }) => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
  }
  const [showButton, setShowButton] = useState(false);
  const [showButton1, setShowButton1] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }

      if (window.scrollY > 300) {
        setShowButton1(true);
      } else {
        setShowButton1(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
      return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [showModal, setShowModal] = useState(false);

  const handleTopImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {/* {isDesktop?
      <div className={`${styles.icon} ${styles.stickyImage}`}
      style={{ display: showButton ? "block" : "none" }}>
        <img
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
              alt="" onClick={() => scrollToTop()} /></div>
              :
              <div className={styles.icon} style={{display:"none"}}><img
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
              alt="" onClick={() => scrollToTop()} /></div>
      } */}
          {isDesktop?
    <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton ? "block" : "none", height:"5.5rem" }}>
    <img data-tooltip-id="my-tooltip" data-tooltip-content="Go to top"
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
              alt="" onClick={() => scrollToTop()}
              className={styles.topIconImg}
              /></div>
            :
            <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton1 ? "block" : "none", height:"5.5rem" }}>
  <img
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
            alt="" onClick={() => scrollToTop()}
            className={styles.topIconImg}
            /></div>
}  <Tooltip id="my-tooltip" />
      <div className={styles.mainContainerSecond}>
        {isDesktop? <div className={styles.mainContSecondHeading}>
          {secMainHeading}
        </div>:""
        }
{isDesktop ? <div className={styles.subHeadingStyles}>
  {subHeading}
</div> : ""}
      {isDesktop ?

    <div className={styles.flexContainerSecond}>
    {dataArray.map((item, index) => (<>
      <div className={styles.flexContainerSecondInner} style={{
        // marginBottom:item.marginBottom, 
        // marginTop:item.marginTop
        }}>
        <div className={styles.leftContainerSecond}>
         {
         item?.image.length==0
         ?
          <div className={styles.coloredBox} style={{background: item.background}}>  </div>
            :
            <img src={item.image1} className={styles.mainImages}  style={{transformOrigin:item.transformOrigin, transform:item.transform}}alt="" />            
            }
        </div>
        <div className={styles.rightContainerSecond} style={{marginLeft:item.marginLeft, marginRight:item.marginRight}}>
          <div key={index}>
            <div className={styles.itemHeading}>{item.heading}</div>
            <div className={styles.itemSubHeading}>{item.subHeading}</div>
            <ul>
              {item.list.map((listItem, listIndex) => (
                <li key={listIndex}>{listItem}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
     ))}
     </div>
        :
               ( <div className={styles.flexContainerSecond}>
          {dataArray.map((item, index) => (<>
            <div className={styles.flexContainerSecondInner}>
              {/* <div className={styles.leftContainerSecond}>
              <div className={styles.coloredBox} style={{background: item.background, position:"relative"}}>
                    <img src={item.image} className={styles.mainImages} alt="" />
                     </div>
              </div> */}
              <div className={styles.leftContainerSecond}>
                <div 
                style={{height:"2rem", width:"2rem", backgroundColor:"white", position:"absolute", right:"1.5rem", zIndex:1, 
                borderRadius:"50%", display:"flex", justifyContent:"center", alignItems:"center", top:"2rem"}}
                >
        <img 
        src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/magnifier.jpg"
         alt="" className={styles.overlayImageWrapper}
        onClick={() => handleTopImageClick(item.mobImg)}
        style={{borderRadius:"50%"}}
         />
         </div>
              <img src={item.mobImg} alt="" className={styles.mobImg} style={{marginBottom:item.marginBottomImgMob}} />
              </div>
              <Modal show={showModal} onHide={handleCloseModal} centered>
       <Modal.Body>
          <img
            // src={item.mobImg}
            src={selectedImage}
            alt=""
            className="mobImg"
            style={{height:"18.375rem", maxWidth: "100%"}}
          />
        </Modal.Body>
      </Modal>
              <div className={styles.rightContainerSecond} style={{marginTop:item.marginTopMob}}>
                <div key={index}>
                  <div className={styles.itemHeading}>{item.heading}</div>
                  <div className={styles.itemSubHeading}>{item.subHeading}</div>
                  <ul>
                    {item.list.map((listItem, listIndex) => (
                      <li key={listIndex}>{listItem}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
          ))}
        </div>)
        }

      </div>
    </>
  )
}

export default CommonSecondContainer;