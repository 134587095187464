import React, { useEffect } from "react";
import styles from "./AboveFooter.module.css";
import { useNavigate } from "react-router-dom";

const AboveFooter = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const handleShow = () => {
    scrollToTop()
    navigate("/schedule")
  };

  // useEffect(()=>{
  //   setTimeout(() => {
  //     scrollToTop()
  //   }, 1000);
  // })

  return (
    <>
      <div className={styles.background}>
        <div className={styles.contain}>
          <div className={styles.heading}>
            Experience Seamless Interior & Project Managements
          </div>
          <div className={styles.para}>
            Streamline your design and executions process and take your <br />
            business to the next level with our easy-to-use platform.
          </div>

          <div className={styles.btnContainer}>
            <div style={{userSelect:"none"}}
            className={styles.btn2} onClick={() => {
              scrollToTop()
              navigate("/schedule")
            }}>
              <div> Schedule a Demo</div>
            </div>
            <div style={{userSelect:"none"}}
            className={styles.btnn1} onClick={() => {
              scrollToTop()
              navigate("/signup")
            }}>
              <div>Get Started</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboveFooter;
