import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateUserProfile } from "../../../../Apis";
import { toast } from "react-hot-toast";
import { useState } from "react";

const CARDS = [
  {
    title: "Minimum Project Value",
    fields: [
      {
        label: "What is the minimum project Value you are willing to do?",
        key: "minimumProjectValue",
        priceType: "/Sqft",
      },
    ],
  },
  {
    title: "How much fees would you charge for these services?",
    fields: [
      {
        label: "POP False Ceiling",
        key: "popFee",
        priceType: "/Sqft",
      },
      {
        label: "Fixing of 2 × 2 Tiles with Cement and Sand",
        key: "tilesFixFee",
        priceType: "/Sqft",
      },
      {
        label: "Premium Plastic Emulsion with Putty",
        key: "paintFee",
        priceType: "/Sqft",
      },
    ],
  },
  {
    title: "Wardrobes/Almirahs",
    fields: [
      {
        label: "Normal (SF) Laminated",
        key: "wardrobeAlmiraFee",
        priceType: "/Hour",
      },
    ],
  },
  {
    title: "Kitchen",
    fields: [
      {
        label: "High Gloss Laminated",
        key: "kitchenFee",
        priceType: "/Sqft",
      },
    ],
  },
  {
    title: "Discount",
    fields: [
      {
        label:
          "How much discount would you provide to Architects and Designers on these rates ?",
        key: "discountForDesigner",
        priceType: "%",
      },
    ],
  },
];

const ContractorFees = ({ fees, discountForDesigner }) => {
  const [edit, setEdit] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (fees) reset({ ...fees, discountForDesigner });
  }, [fees, discountForDesigner, reset]);

  const onUpdate = ({ discountForDesigner, ...data }) => {
    const payload = new FormData();
    payload.append("fees", JSON.stringify(data));
    payload.append("discountForDesigner", +discountForDesigner);
    updateUserProfile(payload).then((res) => {
      setEdit(false);
      toast.success("Fee prices updated!");
    });
  };

  return (
    <div className="col-md-12 mt-5">
      <div className={styles.titleContainer}>
        <h2 className={styles.cardTitle}>My Designing Fees</h2>
        <span className={styles.edit} onClick={() => setEdit((prev) => !prev)}>
          {edit ? "Cancel" : "Edit"}
        </span>
      </div>
      <form onSubmit={handleSubmit(onUpdate)}>
        {CARDS.map((card, index) => (
          <div className="checkout-content interFont mt-2" key={index}>
            <h5>{card.title}</h5>
            {card.fields.map((field, indx) => (
              <div className={styles.fieldsContainer} key={indx}>
                <h6>{field.label}</h6>
                <div className="zoom-input" key={index}>
                  <span>INR</span>
                  <FormControl
                    type="number"
                    min={1}
                    key={field.key}
                    className="disableErrorIcon interFont"
                    isInvalid={!!errors[field.key]}
                    disabled={!edit}
                    {...register(field.key, { required: true })}
                  />
                  <span>{field.priceType}</span>
                </div>
              </div>
            ))}
          </div>
        ))}

        {!!edit && (
          <div className="d-flex justify-content-end mt-2">
            <button type="submit" className="btn btn-edit">
              Update
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContractorFees;
