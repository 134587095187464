import React, { useEffect, useRef, useState } from "react";
import styles from "./Checkoutt.module.css";
import LoginHeader from "../LoginHeader";
// import pro from "../PricingPage/footerImg/prooo.png";
import visa from "./footerImg/Visa._logo.svg";
import circle from "./footerImg/Group (1).png";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "../../../Actions";
import { paymentStatus } from "../../../../Redux/Actions/actionTypes";
import { Country } from "country-state-city";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { createPlan, createSubscription, createOrder } from "../../../../Apis";
import { RAZOR_PAY_KEY } from "../../../../Config";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import pro from "../Pricing/Images/pro.svg";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { constants } from "../../../../../src/constants";
import { postReq } from "../../../../../src/Components/SecureChat/piservices/apis";
import { useLocation } from "react-router-dom";
import { sendEmailSuccess } from "../SendEmail";

const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;


const checkIfHasOnlyNumbers = (value) => {
  // return /^[0-9]*$/.test(value);
  return /^\d*$/.test(value);
};

const Paymentss = () => {
  const location = useLocation();
  const [expired, setExpired] = useState(false);
  const [planId, setPlanId] = useState("");
  const [subId, setSubId] = useState("");
  const Location = useLocation();

  const encryptedUrl = window.location.href;

  const [isQueryParameterStorage, setIsQueryParameterStorage] =
    useState("direct");
  const [decryptedParams, setDecryptedParams] = useState();
  const currentUrl = window.location.href;
  const [payButtonClicked, setPayButtonClicked] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(true);
  const navigateTo = useNavigate();
  const [paymentLink, setPaymentLink] = useState("");
  // let priceValue = localStorage.getItem("price");
  const [priceValue, setPriceValue] = useState("");
  const isMobile = useMediaQuery("(max-width:767px)");
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  const [input, setinput] = useState({
    fname: "",
    mob: "",
    email: "",
    gstNo: "",
    vatNo: "",
    pincode: "",
    billingAddress: "",
    country: "",
    city: "",
    lname: "",
    companyName: "",
  });

  const [error, setError] = useState({
    lname: false,
    fname: false,
    mob: false,
    pincode: false,
    companyName: false,
    billingAddress: false,
    gstNo: false,
    vatNo: false,
    country: false,
    city: false,
  });

  const [errorMsgs, setErrorMsgs] = useState({
    lnameErr: "",
    fnameErr: "",
    mobErr: "",
  });

  // currency value and country value

  const indexvalue = window.location.href.lastIndexOf("&");
  const urlvalue = window.location.href.slice(indexvalue).split("=");
  console.log(urlvalue[1], "Newurlvaluedatafind");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const profileData = useSelector(
    (state) => state.addToCartReducer.profileData
  );

  const [tab, setTab] = useState(0);
  const handleSignUp = () => {
    setTab(0);
  };
  const handleSignIn = () => {
    setTab(1);
  };



  useEffect(() => {


    if (urlvalue[1] === "AUD") {
      setinput((prev) => ({
        ...prev,
        country: "Australia",
      }));
    } else if (urlvalue[1] === "AED") {
      console.log(urlvalue[1], input?.country, "countryflag")
      setinput((prev) => ({
        ...prev,
        country: "United Arab Emirates",
      }));


    } else if (urlvalue[1] === "INR") {
      setinput((prev) => ({
        ...prev,
        country: "India",
      }));
    }
  }, [urlvalue[1]]);



  console.log(input, "countryflag")


  useEffect(() => {
    const decryptUrlParams = (encryptedUrlParams) => {
      const decryptedParams = {};
      const params = encryptedUrlParams?.split("&");

      params?.forEach((param) => {
        const [key, value] = param.split("==");
        try {
          const decryptedKey = CryptoJS.AES.decrypt(
            key,
            "18B69E843399F1A2AFC5853129299"
          ).toString(CryptoJS.enc.Utf8);
          const decryptedValue = CryptoJS.AES.decrypt(
            value,
            "18B69E843399F1A2AFC5853129299"
          ).toString(CryptoJS.enc.Utf8);
          decryptedParams[decryptedKey] = decryptedValue;
        } catch (error) {
          console.error("Error decrypting parameter:", error);
        }
      });
      console.log("decryptedParams", decryptedParams);

      return decryptedParams;
    };

    const encryptedUrlParams = encryptedUrl?.split("?")[1];
    const decryptedParams1 = decryptUrlParams(encryptedUrlParams);
    console.log(decryptedParams1, "Newurlvaluedatafind");

    const urlObj = new URL(encryptedUrl);

    const searchParams = urlObj.searchParams;

    const hasQueryParams = searchParams && searchParams.toString().length > 0;

    if (hasQueryParams) {
      localStorage.setItem("userId", decryptedParams1?.userIddecryptedParams1);
      localStorage.setItem("token", decryptedParams1?.token);
      localStorage.setItem("paymentPlan", decryptedParams1?.yearly);
      if ("startDate" in decryptedParams1 && "users" in decryptedParams1) {
        setIsQueryParameterStorage("renew");
      } else if ("firstName" in decryptedParams1) {
        setIsQueryParameterStorage("adminPanel");
        localStorage.setItem(
          "fullName",
          decryptedParams1.firstName + " " + decryptedParams1.lastName
        );
        localStorage.setItem(
          "initialName",
          decryptedParams1.firstName + " " + decryptedParams1.lastName
        );
        localStorage.setItem("userId", decryptedParams1?.userId);
        localStorage.setItem("token", decryptedParams1?.userToken);
        localStorage.setItem("cId", decryptedParams1?.cId);
        localStorage.setItem("email", decryptedParams1?.email);
        localStorage.setItem("paymentPlan", decryptedParams1?.yearly);
        localStorage.setItem("price", decryptedParams1?.price)
      } else if ("fullName" in decryptedParams1) {
        setIsQueryParameterStorage("direct");
        localStorage.setItem("userId", decryptedParams1?.userId);
        localStorage.setItem("paymentPlan", decryptedParams1?.paymentPlan);
        localStorage.setItem("fullName", decryptedParams1?.fullName);
        localStorage.setItem("cId", decryptedParams1?.cId);
        localStorage.setItem("price", decryptedParams1?.price);
        localStorage.setItem("token", decryptedParams1?.token);
        // localStorage.setItem("password", decryptedParams1?.password)
        setPriceValue(decryptedParams1?.price);
      } else if ("gb" in decryptedParams1 && "startDate" in decryptedParams1) {
        setIsQueryParameterStorage("storage");
      }
      // else {
      //   setIsQueryParameterStorage("storage");
      //   }
    }
    setDecryptedParams(decryptedParams1);
    if (decryptedParams1) {
      if (decryptedParams1?.timestamp) {
        const originalDate = new Date(decryptedParams1?.timestamp);
        const expirationTime = new Date(
          originalDate.getTime() + 72 * 60 * 60 * 1000
        );
        const currentTime = new Date();

        if (currentTime > expirationTime) {
          setExpired(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = await Country.getAllCountries();
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  const handleCountryChange = async (countryCode) => {
    setSelectedCountry(countryCode);
  };

  const getParticularCountryData = async (countryCode) => {
    const data = await Country.getCountryByCode(countryCode);
    setinput((prev) => {
      return {
        ...prev,
        country: data?.name,
      };
    });
    setError((prev) => {
      return {
        ...prev,
        country: false,
      };
    });
  };
  function validatePIN(pin) {
    if (pin.length === 4 || pin.length === 6) {
      if (/^\d+$/.test(pin)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const getData = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "emailaddress")

    const alphaValue = value?.replace(/[^a-zA-Z]/g, "");
    const numValue = value?.replace(/[^0-9]/g, "");
    // const alphaValue = value
    // const numValue = value
    if (name === "pincode") {
      if (input.country == "India") {
        if (value.length < 7) {
          setinput((prev) => ({
            ...prev,
            [name]: numValue,
          }));
          setError((prev) => {
            return {
              ...prev,
              pincode: false,
            };
          });
        }
      } else {
        if (value.length < 11) {
          setinput((prev) => ({
            ...prev,
            [name]: numValue,
          }));
          setError((prev) => {
            return {
              ...prev,
              pincode: false,
            };
          });
        }
      }
    }

    if (name === "companyName") {
      if (value.length < 51) {
        setinput((prev) => {
          return {
            ...prev,
            companyName:
              alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            companyName: false,
          };
        });
      }
    }

    if (name === "gstNo") {
      const inputValue = value.toUpperCase();
      const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
      if (inputValue.length < 17 && alphaNumericRegex.test(inputValue)) {
        setinput((prev) => {
          return {
            ...prev,
            gstNo: inputValue,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            gstNo: false,
          };
        });
      }
    }

    if (name === "vatNo") {
      const inputValue = value.toUpperCase();
      const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
      if (inputValue.length < 17 && alphaNumericRegex.test(inputValue)) {
        setinput((prev) => {
          return {
            ...prev,
            vatNo: inputValue,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            vatNo: false,
          };
        });
      }
    }

    if (name === "billingAddress") {
      const value = event.target.value;
      const words = value.trim().split(/\s+/);
      if (words.length <= 20) {
        setinput((prev) => {
          return {
            ...prev,
            billingAddress: value.charAt(0).toUpperCase() + value.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            billingAddress: false,
          };
        });
      }
    }

    if (name === "city") {
      // let check = numberArray.some((elem) => {
      //   return value.includes(elem);
      // });
      if (value?.length < 25) {
        setinput((prev) => {
          return {
            ...prev,
            city: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            city: false,
          };
        });
      }
    }

    if (name === "email") {
      // let x = emailRegex.test(value);
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
      // if (value) {
      //   setinput((prev) => {
      //     return {
      //       ...prev,
      //       email: value,
      //     };
      //   });
      // }
    }
    if (name === "country") {
      setinput((prev) => {
        return {
          ...prev,
          country: value.charAt(0).toUpperCase() + value.slice(1),
        };
      });
      setError((prev) => {
        return {
          ...prev,
          country: false,
        };
      });
    }
    if (name === "mob") {
      if (value.length < 11) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            mob: false,
          };
        });
      }
      if (value.length >= 11) {
        setErrorMsgs((prev) => {
          return { ...prev, mobErr: "Maximum digits has been reached." };
        });
      } else if (value.length < 10) {
        setErrorMsgs((prev) => {
          return { ...prev, mobErr: "" };
        });
      }
    }
    if (name === "fname") {
      if (value?.length < 25) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            fname: false,
          };
        });
      }
      if (value.length >= 24) {
        setErrorMsgs((prev) => {
          return { ...prev, fnameErr: "" };
        });
      } else if (value.length <= 24) {
        setErrorMsgs((prev) => {
          return { ...prev, fnameErr: "" };
        });
      }
    }
    if (name === "lname") {
      if (value?.length < 25) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            lname: false,
          };
        });
      }
      if (value.length >= 24) {
        setErrorMsgs((prev) => {
          return { ...prev, lnameErr: "" };
        });
      } else if (value.length <= 24) {
        setErrorMsgs((prev) => {
          return { ...prev, lnameErr: "" };
        });
      }
    }
  };

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  useEffect(() => {
    function preventBack() {
      window.history.forward();
    }

    setTimeout(preventBack(), 0);

    window.onunload = function () {
      return null;
    };
  }, []);

  const saveClientDetails = async () => {
    const data = {
      clientName: input.fname + " " + input.lname,
      email: input.email,
      phoneNumber: input.mob,
      // users: "Unlimited",
      users:
        isQueryParameterStorage == "adminPanel"
          ? decryptedParams?.users
          : "Unlimited",
      // addOn: "1 TB",
      addOn:
        isQueryParameterStorage == "adminPanel"
          ? decryptedParams?.gb
          : isQueryParameterStorage == "storage"
            ? decryptedParams?.gb + "GB"
            : "Unlimited",
      plan: localStorage.getItem("paymentPlan"),
      // discount: "NA",
      discount:
        isQueryParameterStorage == "adminPanel"
          ? decryptedParams?.discount
          : "NA",
      // payment:
      //   parseFloat(priceValue?.replace(/,/g, "")) +
      //   parseFloat(priceValue?.replace(/,/g, "")) * 0.18,
      payment:
        isQueryParameterStorage == "direct"
          ? (
            parseFloat(priceValue?.replace(/,/g, "")) +
            parseFloat(priceValue?.replace(/,/g, "")) * 0.18
          ).toFixed(0)
          : (
            parseFloat(decryptedParams?.price) +
            parseFloat(decryptedParams?.price) * 0.18
          ).toFixed(0),
      billingAddress: input.billingAddress,
      country: input.country,
      city: input.city,
      pinCode: input.pincode,
      GST: input.gstNo,
      userId: localStorage.getItem("userId"),
      cId: localStorage.getItem("cId"),
      planType: "Professional",
      companyName: input.companyName,
    };
    await axios
      .post(`${constants.B2B_BASE}/b2b/rm/save-client-details`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
        console.log("api req successful");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isQueryParameterStorage == "adminPanel") {
      if (profileData[0]?.data?.data?.isSubscribed) {
        setIsPaymentDone(true);
      }
    }
  }, [profileData, isQueryParameterStorage]);

  function getCurrentDatePlusOneDay() {
    var today = new Date();
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    var year = tomorrow.getFullYear();
    var month = tomorrow.getMonth() + 1;
    var day = tomorrow.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    var dateString = year + "-" + month + "-" + day;
    return dateString;
  }

  function addMonthsToUnixTimestamp(unixTimestamp, monthsToAdd) {
    // console.log(unixTimestamp)
    var milliseconds = unixTimestamp * 1000;
    var date = new Date(milliseconds);
    date.setMonth(date.getMonth() + monthsToAdd);
    var updatedUnixTimestamp = Math.floor(date.getTime() / 1000);
    return updatedUnixTimestamp;
  }

  function dateTimeFromTimeStamp(dateString, addDay) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + addDay);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();
    return {
      date: year + "-" + month + "-" + day,
      time: hours + ":" + minutes + ":" + seconds + "." + milliseconds,
    };
  }

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  console.log(
    isQueryParameterStorage === "storage" ||
    isQueryParameterStorage === "renew" ||
    isQueryParameterStorage === "adminPanel"
      ? (parseFloat(decryptedParams?.price) +
         parseFloat(decryptedParams?.price) * 0.18
        ).toFixed(0)
      : (parseFloat(priceValue?.replace(/,/g, '')) +
         parseFloat(priceValue?.replace(/,/g, '')) * 0.18
        ).toFixed(0),
    "ytfugyugy"
  );
  
  const createNewSubscription = async (payload) => {
   
    let res = await axios.post(`${constants.B2B_BASE}/b2b/user/create-new-plan`, {
      description:
        isQueryParameterStorage == "storage"
          ? "storage plan"
          : isQueryParameterStorage == "renew"
            ? "subscription plan"
            : "subscription plan",
      interval:
        localStorage.getItem("paymentPlan") === "Quarterly" ||
          localStorage.getItem("paymentPlan") === "Quaterly"
          ? 3
          : 12,
      // localStorage.getItem("paymentPlan") == "Quarterly" ? 3 : 12,
      // interval:1,
      period: "monthly",
      currency: urlvalue[1],
      planAmt: isQueryParameterStorage == "storage"
      ? Math.round(
          parseFloat(decryptedParams?.price) +
          parseFloat(decryptedParams?.price) * 0.18
        )
      : isQueryParameterStorage == "renew"
      ? Math.round(
          parseFloat(decryptedParams?.price) +
          parseFloat(decryptedParams?.price) * 0.18
        )
      : isQueryParameterStorage == "adminPanel"
      ? Math.round(
          parseFloat(decryptedParams?.price) +
          parseFloat(decryptedParams?.price) * 0.18
        )
      : Math.round(
          parseFloat(priceValue?.replace(/,/g, '')) +
          parseFloat(priceValue?.replace(/,/g, '')) * 0.18
        ),
    
     
      planName: localStorage.getItem("fullName"),

      // localStorage.getItem("isGoogleLogin") == "true"
      //   ? localStorage.getItem("initialName")
      //   : localStorage.getItem("fullName"),
    });
    if (res && !res.error) {
      setPlanId(res?.data?.data?.id);
      localStorage.setItem("planId", res?.data?.data?.id);

      const payload = {
        id: res?.data?.data?.id,
        // cId: localStorage.getItem("cId"),
        cId: 489,
        userId: localStorage.getItem("userId"),
        paymentCycles:
          localStorage.getItem("paymentPlan") === "Quarterly" ||
            localStorage.getItem("paymentPlan") === "Quaterly"
            ? 40
            : 10,
            planAmt: isQueryParameterStorage == "storage"
            ? Math.round(
                parseFloat(decryptedParams?.price) +
                parseFloat(decryptedParams?.price) * 0.18
              )
            : isQueryParameterStorage == "renew"
            ? Math.round(
                parseFloat(decryptedParams?.price) +
                parseFloat(decryptedParams?.price) * 0.18
              )
            : isQueryParameterStorage == "adminPanel"
            ? Math.round(
                parseFloat(decryptedParams?.price) +
                parseFloat(decryptedParams?.price) * 0.18
              )
            : Math.round(
                parseFloat(priceValue?.replace(/,/g, '')) +
                parseFloat(priceValue?.replace(/,/g, '')) * 0.18
              ),
          
          

        start_at:
          isQueryParameterStorage == "storage"
            ? Math.floor(
              new Date(formatDate(decryptedParams?.startDate)).getTime() /
              1000
            )
            : isQueryParameterStorage == "renew"
              ? Math.floor(
                new Date(formatDate(decryptedParams?.startDate)).getTime() /
                1000
              )
              : isQueryParameterStorage == "adminPanel"
                ? Math.floor(
                  new Date(
                    dateTimeFromTimeStamp(decryptedParams?.timestamp, 1).date
                  ).getTime() / 1000
                )
                : Math.floor(new Date(getCurrentDatePlusOneDay()).getTime() / 1000),
        // start_at: 1714196109,
        expire_by:
          isQueryParameterStorage == "storage"
            ? addMonthsToUnixTimestamp(
              Math.floor(
                new Date(formatDate(decryptedParams?.startDate)).getTime() /
                1000
              ),
              12
            )
            : isQueryParameterStorage == "renew"
              ? addMonthsToUnixTimestamp(
                Math.floor(
                  new Date(formatDate(decryptedParams?.startDate)).getTime() /
                  1000
                ),
                decryptedParams?.yearly == "Quarterly" ? 3 : 12
              )
              : isQueryParameterStorage == "adminPanel"
                ? addMonthsToUnixTimestamp(
                  Math.floor(
                    new Date(
                      dateTimeFromTimeStamp(decryptedParams?.timestamp, 0).date
                    ).getTime() / 1000
                  ),
                  12
                )
                : addMonthsToUnixTimestamp(
                  Math.floor(
                    new Date(getCurrentDatePlusOneDay()).getTime() / 1000
                  ),
                  localStorage.getItem("paymentPlan") == "Quarterly" ? 3 : 12
                ),
      };

      if (
        isQueryParameterStorage == "adminPanel" ||
        isQueryParameterStorage == "direct"
      ) {
        delete payload.start_at;
        delete payload.expire_by;
      }

      let secRes = await axios.post(
        `${constants.B2B_BASE}/b2b/user/create-new-subscription`,
        payload
      );

      if (secRes && !secRes.error) {
        setIsLoading(false);
        setVisible(false);
        localStorage.setItem("subId", secRes?.data?.data?.id);
        setSubId(secRes?.data?.data?.id);
        // setIsLoading(false);
        const option = {
          description: "iDesign payment",
          currency: urlvalue[1],
          key: RAZOR_PAY_KEY,
          // amount: 1,
          amount:
            isQueryParameterStorage == "storage"
              ? (
                parseFloat(decryptedParams?.price) +
                parseFloat(decryptedParams?.price) * 0.18
              ).toFixed(0)
              : isQueryParameterStorage == "renew"
                ? (
                  parseFloat(decryptedParams?.price) +
                  parseFloat(decryptedParams?.price) * 0.18
                ).toFixed(0)
                : isQueryParameterStorage == "adminPanel"
                  ? (
                    parseFloat(decryptedParams?.price) +
                    parseFloat(decryptedParams?.price) * 0.18
                  ).toFixed(0)
                  : (
                    parseFloat(priceValue?.replace(/,/g, "")) +
                    parseFloat(priceValue?.replace(/,/g, "")) * 0.18
                  ).toFixed(0),

          name: "Tech Designworks Pvt Ltd",
          // order_id: res?.data?.id,
          subscription_id: secRes?.data?.data?.id,
          handler: paymentHandler,
          prefill: {
            name: profileData[0]?.data?.data?.fullName,
            email: profileData[0]?.data?.data?.email,
            contact: input.mob,
          },
          notes: {
            address: `Payment for cart`,
          },
          theme: {
            color: "#3b5998",
          },
          recurring: true,
          checkout: {
            method: {
              upi: 1,
              netbanking: 1,
              card: 1,
              wallet: 1,
            },
          },
        };
        try {
          const paymentObject = new window.Razorpay(option);
          await paymentObject.open();

          // await postUserData();
          //  setIsPaymentSuccessful(true)
        } catch (err) {
          console.log(err?.message);
        }
      }
    } else {
      console.log(res.error);
    }
  };


  console.log(typeof (decryptedParams?.price), "Newurlvaluedatafind")
  const postUserData = async () => {
    const payload = new FormData();
    payload.append("isSubscribed", true);
    // const data = {
    //   isSubscribed: true,
    // };
    await axios
      .post(`${constants.B2B_BASE}/b2b/user/updateProfile`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
        // console.log("api req successfull");
        dispatch(paymentStatus(true));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getFormattedDate = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const paddedDay = day < 10 ? "0" + day : day;
    return `${paddedDay}-${months[monthIndex]}-${year}`;
  };

  const addMonths = (date, months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  };

  const editClientToAddLink = async (payload) => {
    try {
      let res = await postReq(
        `${constants.B2B_BASE}/b2b/rm/edit-client-details?id=${localStorage.getItem(
          "userId"
        )}`,
        payload
      );
      if (res && !res.error) {
        console.log(res.data);
      } else {
        console.log(res.error);
      }
    } catch {
      // console.log(res.error)
    }
  };
  const savePaymentDetails = async () => {
    try {
      let res = await postReq(`${constants.B2B_BASE}/b2b/user/save-payment-logs`, {
        cId: localStorage.getItem("cId"),
        userId: localStorage.getItem("userId"),
        // amount: 1,
        amount:
          isQueryParameterStorage == "storage"
            ? (
              parseFloat(decryptedParams?.price) +
              parseFloat(decryptedParams?.price) * 0.18
            ).toFixed(0)
            : isQueryParameterStorage == "renew"
              ? (
                parseFloat(decryptedParams?.price) +
                parseFloat(decryptedParams?.price) * 0.18
              ).toFixed(0)
              : isQueryParameterStorage == "adminPanel"
                ? (
                  parseFloat(decryptedParams?.price) +
                  parseFloat(decryptedParams?.price) * 0.18
                ).toFixed(0)
                : (
                  parseFloat(priceValue?.replace(/,/g, "")) +
                  parseFloat(priceValue?.replace(/,/g, "")) * 0.18
                ).toFixed(0),
        subscriptionId: localStorage.getItem("subId"),
        planId: localStorage.getItem("planId"),
      });
      if (res && !res.error) {
        console.log(res.data);
      } else {
        console.log(res.error);
      }
    } catch {
      // console.log(res.error)
    }
  };
  const saveAddOnPaymentLogs = async () => {
    try {
      let res = await axios.post(
        `${constants.B2B_BASE}/b2b/user/save-addOn-payment-logs`,
        {
          cId: localStorage.getItem("cId"),
          userId: localStorage.getItem("_id"),
          // addOnAmount:(parseFloat(decryptedParams?.price) +
          //   parseFloat(decryptedParams?.price) * 0.18).toFixed(0),
          // addOnAmount: 1,
          addOnAmount:
            isQueryParameterStorage == "adminPanel"
              ? parseFloat(
                decryptedParams?.addOnRate ? decryptedParams?.addOnRate : "0"
              ).toFixed(0)
              : isQueryParameterStorage == "storage"
                ? parseFloat(
                  decryptedParams?.price ? decryptedParams?.price : "0"
                ).toFixed(0)
                : 0,
          addOnSubscriptionId: localStorage.getItem("subId"),
          addOnPlanId: localStorage.getItem("planId"),
          // addOnStorage: decryptedParams?.gb,
          parseInt,
          addOnStorage:
            isQueryParameterStorage == "adminPanel"
              ? parseInt(decryptedParams?.gb)
              : decryptedParams?.gb,
          addOnPlanType: decryptedParams?.yearly,
        }
      );
      if (res && !res.error) {
        console.log(res.data);
      } else {
        console.log(res.error);
      }
    } catch (err) {
      // console.log(res.error)
    }
  };



  const createInvoice = async () => {
    try {
      let res = await postReq(
        `${constants.B2B_BASE
        }/b2b/user/create-invoice?userId=${localStorage.getItem("userId")}`,
        {
          companyName: input.companyName,
          companyAddress: input.billingAddress,
          city: input.city,
          country: input.country,
          pinCode: input.pincode,
          countryCode: "+91",
          phoneNumber: input.mob,
          GST: input.gstNo,
          invoiceDate: getFormattedDate(new Date()),
          // invoiceDate: "15-Aug-2023",
          dueDate: getFormattedDate(
            addMonths(
              new Date(),
              localStorage.getItem("paymentPlan") === "Quarterly" ||
                localStorage.getItem("paymentPlan") === "Quaterly"
                ? 3
                : 12
              // localStorage.getItem("paymentPlan") == "Quarterly" ? 3 : 12
            )
          ),
          plan: "Professional Plan",
          planType:
            // localStorage.getItem("paymentPlan") == "Quarterly"
            localStorage.getItem("paymentPlan") === "Quarterly" ||
              localStorage.getItem("paymentPlan") === "Quaterly"
              ? "Quarterly"
              : "Yearly",
          // users: "Unlimited",
          users:
            isQueryParameterStorage == "adminPanel"
              ? decryptedParams?.users
              : "Unlimited",
          planQuantity: 1,
          planRate:
            isQueryParameterStorage == "storage"
              ? 0
              : isQueryParameterStorage == "renew"
                ? 0
                : isQueryParameterStorage == "adminPanel"
                  ? decryptedParams?.planRate
                  : priceValue,
          // planRate: priceValue,
          planAmount:
            isQueryParameterStorage == "storage"
              ? 0
              : isQueryParameterStorage == "renew"
                ? 0
                : isQueryParameterStorage == "adminPanel"
                  ? decryptedParams?.planRate
                  : priceValue,
          // planAmount: priceValue,
          addOnQuantity: 1,
          addOnRate:
            isQueryParameterStorage == "storage"
              ? decryptedParams?.price
              : isQueryParameterStorage == "renew"
                ? decryptedParams?.price
                : isQueryParameterStorage == "adminPanel"
                  ? decryptedParams?.addOnRate
                  : 0,
          addOnAmount:
            isQueryParameterStorage == "storage"
              ? decryptedParams?.price
              : isQueryParameterStorage == "renew"
                ? decryptedParams?.price
                : isQueryParameterStorage == "adminPanel"
                  ? decryptedParams?.addOnRate
                  : 0,
          currency:
            urlvalue[1] === "AUD" ? "$" : urlvalue[1] === "AED" ? "د.إ" : "₹",
          // currency: localStorage.getItem("fromCountry")=="Dubai" ? "د.إ" : localStorage.getItem("fromCountry")=="Sydney" ? "$" : "₹" ,
          // currencyName: "INR",
          currencyName: urlvalue[1],

          // currencyName: localStorage.getItem("fromCountry")=="Dubai" ? "AED" : localStorage.getItem("fromCountry")=="Sydney" ? "AUD" : "INR" ,
          subTotal: priceValue,
          taxPercent: 18,
          taxAmount:
            isQueryParameterStorage == "direct"
              ? (parseFloat(priceValue?.replace(/,/g, "")) * 0.18).toFixed(0)
              : (parseFloat(decryptedParams?.price) * 0.18).toFixed(0),

          // isQueryParameterStorage == "storage"
          //   ? parseFloat(decryptedParams?.price) * 0.18
          //   : isQueryParameterStorage == "renew"
          //   ? parseFloat(decryptedParams?.price) * 0.18
          //   : parseFloat(priceValue.replace(/,/g, "")) * 0.18,

          discountPercent:
            isQueryParameterStorage == "adminPanel"
              ? decryptedParams?.discount
              : 0,
          discountAmount:
            isQueryParameterStorage == "adminPanel"
              ? decryptedParams?.planRate -
              (decryptedParams?.planRate * decryptedParams?.discount) / 100
              : 0,
          total:
            isQueryParameterStorage == "direct"
              ? (
                parseFloat(priceValue?.replace(/,/g, "")) +
                parseFloat(priceValue?.replace(/,/g, "")) * 0.18
              ).toFixed(0)
              : (
                parseFloat(decryptedParams?.price) +
                parseFloat(decryptedParams?.price) * 0.18
              ).toFixed(0),
          // isQueryParameterStorage == "storage"
          //   ? (parseFloat(decryptedParams?.price) +
          //     parseFloat(decryptedParams?.price) * 0.18).toFixed(0)
          //   : isQueryParameterStorage == "renew"
          //   ? (parseFloat(decryptedParams?.price) +
          //     parseFloat(decryptedParams?.price) * 0.18).toFixed(0)
          //   : (parseFloat(priceValue?.replace(/,/g, "")) +
          //     parseFloat(priceValue?.replace(/,/g, "")) * 0.18).toFixed(0),

          // total:
          //   (parseFloat(priceValue?.replace(/,/g, "")) +
          //   parseFloat(priceValue?.replace(/,/g, "")) * 0.18).toFixed(0),
          paymentMethod: "UPI",
        }
      );
      if (res && !res.error) {
        console.log(res.data);

        let invoiceAtttachedMail = `
        <p>Dear ${localStorage.getItem("fullName")},</p>
        <p>Welcome to iDesign Market's ERP Platform! We are thrilled to have you join our community of forward-thinking professionals in the fields of construction, interior design, retail, architecture, and real estate development.</p>
        <p>Your payment has been successfully processed, invoice for your payment is attached herein. You are now all set to unlock the full potential of our comprehensive suite of tools designed to streamline your business.</p>
        <p>Here's a glimpse of what you can expect from our platform:</p>
        <p style="margin-left:25px;">Project Management: Easily organise, assign tasks, and track the progress of your projects in real-time, ensuring smooth workflows and timely deliveries.
        </p>
        <p style="margin-left:25px;">Client Management: Centralise your client information, communication history, and interactions to provide personalised experiences and foster stronger relationships.
        </p>
        <p style="margin-left:25px;">Lead Management: Capture, nurture, and convert leads efficiently with our intuitive lead management system tailored to your industry's unique requirements.</p>
        <p style="margin-left:25px;">
        <a style="text-decoration:underline; color:blue" href="https://www.idesign.market/timelineTimesheet">Timeline and Timesheet</a>: Keep projects on schedule and monitor resource allocation with detailed timeline and timesheet features, enabling you to optimise productivity and profitability.</p>
        <p style="margin-left:25px;">
        <a style="text-decoration:underline; color:blue" href="https://www.idesign.market/attendance">Attendance Marking</a>: Streamline attendance tracking for your teams with our user-friendly interface, facilitating accurate reporting and compliance management.</p>
        <p style="margin-left:25px;">
        <a style="text-decoration:underline; color:blue" href="https://www.idesign.market/chat">Chat</a>: Foster collaboration and seamless communication among team members and clients through our integrated chat functionality, ensuring everyone stays connected and informed.</p>
        <p>Additionally, our ERP platform offers a myriad of features including a <a style="text-decoration:underline; color:blue"
         href="https://www.idesign.market/fileManager">file manager</a>, <a href="https://www.idesign.market/taskManager">task manager</a>, <a style="text-decoration:underline; color:blue"
          href="https://www.idesign.market/mom">MOM (Minutes of Meeting)</a>,<a  style="text-decoration:underline; color:blue"
          href="https://www.idesign.market/rfi"> RFI (Request for Information)</a>,<a style="text-decoration:underline; color:blue"
           href="https://www.idesign.market/quotation"> quotation management</a>, <a style="text-decoration:underline; color:blue"
            href="https://www.idesign.market/account">accounts management</a>, <a style="text-decoration:underline; color:blue"
             href="https://www.idesign.market/vendorPO">vendor and purchase order management</a>, <a style="text-decoration:underline; color:blue"
              href="https://www.idesign.market/itemLibraryInventory">item library</a>, and team management functionalities. These comprehensive features are designed to streamline and enhance various aspects of your business operations.</p>
        <p>At iDesign Market, we are committed to empowering professionals like you with the tools they need to succeed. Whether you're a seasoned industry veteran or just starting out, our platform is designed to adapt to your unique workflow and scale alongside your business.</p>
        <p>Once again, welcome to iDesign Market's ERP Platform! We can't wait to see the incredible projects you'll accomplish with our tools at your fingertips.</p>
       <p> <a style="text-decoration:none;"
         href="https://pro.idesign.market/" style="cursor:pointer;">
         <button type="button">Login</button>
         </a> </p>
        <div style="margin-bottom: 0;">Best Regards,</div>
         <div style="margin-bottom: 0;">iDesign.Market</div>
          <a href=${res?.data?.data?.invoiceLink
          } style="text-decoration:underline; color:blue">
         Invoice
         </a>
        `;
        await sendEmailSuccess(
          " Welcome to iDesign Market's ERP Platform!",
          invoiceAtttachedMail,
          profileData[0]?.data?.data?.email
        );
      } else {
        console.log(res.error);
      }
    } catch {
      // console.log(res.error)
    }
  };

  const paymentHandler = async (response) => {
    if (response.razorpay_payment_id) {
      const payload = {
        // isAdded:true,
        isSubscribed: true,
        paymentStatus: "success",
        planEndDate: new Date(
          Date.parse(
            getFormattedDate(
              addMonths(
                new Date(),
                // localStorage.getItem("paymentPlan") == "Quarterly" ? 3 : 12
                localStorage.getItem("paymentPlan") === "Quarterly" ||
                  localStorage.getItem("paymentPlan") === "Quaterly"
                  ? 3
                  : 12
              )
            )
          )
        )
          .toISOString()
          .replace("Z", "+00:00"),
      };
      // alert("Payment Successful!");
      await postUserData();
      // await saveClientDetails();
      // {
      //   isQueryParameterStorage == "storage"
      //     ? await saveAddOnPaymentLogs()
      //     : isQueryParameterStorage == "renew"
      //     ? await savePaymentDetails()
      //     : await savePaymentDetails();
      // }

      if (isQueryParameterStorage === "storage") {
        // await saveClientDetails();
        await saveAddOnPaymentLogs();
      } else if (isQueryParameterStorage === "adminPanel") {
        let credentialsMail = `
        <p>Hi ${localStorage.getItem("fullName")}</p>
        <p>Greetings for the day! I'm excited to announce that you've been added to the ERP platform, which will empower us with operational expertise, seamless collaboration, and amplified efficiency.
        </p>
        <p>Below are your Login Details- </p>
        <div style="margin-bottom: 0;">
        <span style=" font-weight:bold;">UserId:</span>
        <span>${profileData[0]?.data?.data?.email}</span>
        </div>
        <div style="margin-bottom: 0;">
        <span style=" font-weight:bold;">Password:</span>
        <span>${profileData[0]?.data?.data?.tempPassword}</span>
        </div>
        <p>To access the platform -</p>
        <ol>
        <li> Open the <a style="text-decoration:underline; color:blue"
         href="https://pro.idesign.market/">website</a> or download the app 
        (<a style="text-decoration:underline; color:blue" href="https://play.google.com/store/apps/details?id=com.iDesignErp&pli=1">Android</a> or 
        <a style="text-decoration:underline; color:blue" href="https://apps.apple.com/in/app/idesign-market/id6450389218">iOS</a>).</li>
        <li>Enter login details.</li>
        <li>Change your password once you login.</li>
        </ol>
        <p>You're good to go! </p>
        <div style="margin-bottom: 0;">Best Regards,</div>
         <div style="margin-bottom: 0;">iDesign.Market</div>
        `;
        await sendEmailSuccess(
          " Welcome to iDesign Market's ERP Platform!",
          credentialsMail,
          profileData[0]?.data?.data?.email
        );
        await saveAddOnPaymentLogs();
        await savePaymentDetails();
      } else if (isQueryParameterStorage === "direct") {
        let credentialsMail = `
      <p>Hi ${localStorage.getItem("fullName")}</p>
      <p>Greetings for the day! I'm excited to announce that you've been added to the ERP platform, which will empower us with operational expertise, seamless collaboration, and amplified efficiency.
      </p>
      <p>Below are your Login Details- </p>
      <div style="margin-bottom: 0;">
      <span style=" font-weight:bold;">UserId:</span>
      <span>${profileData[0]?.data?.data?.email}</span>
      </div>
      <div style="margin-bottom: 0;">
      <span style=" font-weight:bold;">Password:</span>
      <span>${profileData[0]?.data?.data?.tempPassword}</span>
      </div>
      <p>To access the platform -</p>
      <ol>
      <li> Open the <a style="text-decoration:underline; color:blue"
       href="https://pro.idesign.market/">website</a> or download the app 
      (<a style="text-decoration:underline; color:blue" href="https://play.google.com/store/apps/details?id=com.iDesignErp&pli=1">Android</a> or 
      <a style="text-decoration:underline; color:blue" href="https://apps.apple.com/in/app/idesign-market/id6450389218">iOS</a>).</li>
      <li>Enter login details.</li>
      <li>Change your password once you login.</li>
      </ol>
      <p>You're good to go! </p>
      <div style="margin-bottom: 0;">Best Regards,</div>
       <div style="margin-bottom: 0;">iDesign.Market</div>
      `;
        await sendEmailSuccess(
          " Welcome to iDesign Market's ERP Platform!",
          credentialsMail,
          profileData[0]?.data?.data?.email
        );
        await saveClientDetails();
        await savePaymentDetails();
      } else {
        await saveClientDetails();
        await savePaymentDetails();
      }

      await editClientToAddLink(payload);
      await createInvoice();

      {
        isQueryParameterStorage == "storage"
          ? window.location.assign(
            `${constants.PRO_URL}my-profile/myprofile/CompanySetting/BillingAndPayment?payment=success`
          )
          : isQueryParameterStorage == "renew"
            ? window.location.assign(
              `${constants.PRO_URL}my-profile/myprofile/CompanySetting/BillingAndPayment?payment=success`
            )
            : navigateTo("/profilebuild");
      }
      // navigateTo("/profilebuild");
      localStorage.removeItem("isGoogleLogin");
      localStorage.removeItem("googleEmail");
      localStorage.removeItem("fromCountry");
    } else {
      const payload = {
        isSubscribed: false,
        paymentStatus: "failed",
        // isAdded:false,
      };
      setIsLoading(false);
      editClientToAddLink(payload);
      setVisible(false);
    }
  };



  useEffect(() => {
    if (urlvalue[1]?.length > 0) {
      if (urlvalue[1] === "AED") {
        localStorage.setItem("fromCountry", "Dubai")

      } else if (urlvalue[1] === "AUD") {
        localStorage.setItem("fromCountry", "Australia")

      } else if (urlvalue[1] === "INR") {
        localStorage.setItem("fromCountry", "India")

      }
    }
    if (profileData.length > 0) {
      const spaceIndex = profileData[0]?.data?.data?.fullName?.indexOf(" ");
      let x = {};
      if (decryptedParams && "firstName" in decryptedParams) {
        x["fname"] = decryptedParams.firstName;
        x["lname"] = decryptedParams.lastName;
        x["email"] = decryptedParams.email;
        // x["mob"] = "";
      } else {
        x["fname"] = profileData[0]?.data?.data?.fullName?.substring(
          0,
          spaceIndex
        );
        x["lname"] = profileData[0]?.data?.data?.fullName?.substring(
          spaceIndex + 1
        );
        // x["mob"] = profileData[0]?.data?.data?.phoneNumber;
        x["email"] = profileData[0]?.data?.data?.email;
        localStorage.setItem("fullName", profileData[0]?.data?.data?.fullName);
        localStorage.setItem("cId", profileData[0]?.data?.data?.cId);
      }

      // x["fname"] = profileData[0]?.data?.data?.fullName?.substring(
      //   0,
      //   spaceIndex
      // );
      // x["lname"] = profileData[0]?.data?.data?.fullName?.substring(
      //   spaceIndex + 1
      // );
      // x["mob"] = profileData[0]?.data?.data?.phoneNumber;
      // x["email"] = profileData[0]?.data?.data?.email
      x["mob"] = "";
      x["companyName"] = "";
      x["billingAddress"] = "";
      x["city"] = "";
      x["country"] = "";
      x["pincode"] = "";
      x["gstNo"] = "";


      //  if(profileData[0]?.data?.data?.CompanySettingDetails){
      //   x["companyName"] = profileData[0]?.data?.data?.CompanySettingDetails?.companyName;
      //   x["billingAddress"] =  profileData[0]?.data?.data?.CompanySettingDetails?.address;
      //   x["city"] =  profileData[0]?.data?.data?.CompanySettingDetails?.city;
      //   x["country"] =  profileData[0]?.data?.data?.CompanySettingDetails?.country;
      //   x["pincode"] =  profileData[0]?.data?.data?.CompanySettingDetails?.pincode;
      //   x["gstNo"] =  profileData[0]?.data?.data?.CompanySettingDetails?.gstNo;
      //   x["mob"] = profileData[0]?.data?.data?.CompanySettingDetails?.contact;
      //  }

      setinput({ ...x });
    }
  }, [profileData]);



  useEffect(() => {
    dispatch(fetchProfileData(`Bearer ${localStorage.getItem("token")}`));
  }, []);

  const handlePayButtonClick = () => {
    setVisible(true);
    setIsLoading(true);
    createNewSubscription();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };
  return (
    <>
      {expired ? (
        <div>Your payment link has been expired</div>
      ) : isPaymentDone ? (
        <div>Your payment link has been expired</div>
      ) : (
        <>
          <LoginHeader
            active={tab}
            setActive={setTab}
            changeTab={handleSignUp}
            changeTab2={handleSignIn}
          />

          <div className={styles.container} style={{ paddingBottom: "4rem" }}>
            <div className={styles.firstSection}>
              <div className={styles.Check_heading}>Checkout</div>
              <div className={styles.first_div}>
                <div className={styles.div_head}>Personal Details</div>
                <div className={styles.topBox}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      error={error.fname}
                      id="outlined-basic"
                      label="First Name*"
                      variant="outlined"
                      className="saas-form"
                      type="text"
                      name="fname"
                      value={input.fname}
                      onChange={getData}
                      fullWidth
                      InputProps={{ style: { borderRadius: "12px" } }}
                    />
                    {errorMsgs.fnameErr !== "" ? (
                      <div style={{ color: "red", fontSize: "10px" }}>
                        {errorMsgs.fnameErr}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      error={error.lname}
                      id="outlined-basic"
                      label="Last Name*"
                      variant="outlined"
                      className="saas-form"
                      type="text"
                      name="lname"
                      value={input.lname}
                      onChange={getData}
                      fullWidth
                      InputProps={{ style: { borderRadius: "12px" } }}
                    />
                    {errorMsgs.lnameErr !== "" ? (
                      <div style={{ color: "red", fontSize: "10px" }}>
                        {errorMsgs.lnameErr}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      error={error.mob}
                      id="outlined-basic"
                      label="Mobile Number*"
                      variant="outlined"
                      className="saas-form"
                      type="number"
                      name="mob"
                      value={input.mob}
                      onChange={getData}
                      fullWidth
                      onKeyPress={handleKeyPress}
                      InputProps={{ style: { borderRadius: "12px" } }}
                    />
                    {/* {errorMsgs.mobErr !== "" ? (
                  <div style={{ color: "red", fontSize: "10px" }}>
                    {errorMsgs.mobErr}
                  </div>
                ) : (
                  ""
                )} */}
                  </div>
                  <TextField
                    error={error.email}
                    id="outlined-basic"
                    label="Email Address*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="email"
                    value={input.email}
                    onChange={getData}
                    fullWidth
                    InputProps={{ style: { borderRadius: "12px" } }}

                  />
                </div>
              </div>

              <div className={styles.sec_div}>
                <div className={styles.div_head}>Billing Details</div>
                <div className={styles.billingDetailsContainer}>
                  <TextField
                    error={error.companyName}
                    id="outlined-basic"
                    label="Company Name*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="companyName"
                    value={input.companyName}
                    onChange={getData}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "12px", marginBottom: "1.2rem" },
                    }}
                  />
                  <TextField
                    error={error.billingAddress}
                    id="outlined-basic"
                    label="Billing Address*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="billingAddress"
                    value={input.billingAddress}
                    onChange={getData}
                    fullWidth
                    InputProps={{ style: { borderRadius: "12px" } }}
                  />
                  <div className={styles.cityContainer} >
                    {/* <FormControl
                      error={error.country}
                      sx={{
                        borderRadius: "12px",
                 
                        width: isMobile ? "100%" : "33%",
                      }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Country*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedCountry}
                        label="Country"
                        onChange={(e) => {
                          handleCountryChange(e.target.value);
                          getParticularCountryData(e.target.value);
                        }}
                        sx={{ borderRadius: "12px", width: "100%" }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 500, 
                              borderRadius: "12px",
                            },
                          },
                        }}
                      >
                    
                        {countries.map((country) => (
                          <MenuItem
                            key={country.isoCode}
                            value={country.isoCode}
                          >
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

                    <TextField
                      error={error.country}
                      id="outlined-basic"
                      label="Country*"
                      variant="outlined"
                      className="saas-form"
                      type="text"
                      name="country"
                      value={input.country}
                      onChange={getData}
                      sx={{
                        borderRadius: "12px",
                        width: isMobile ? "100%" : "33%",
                        marginTop: isMobile ? "-0px" : "0px",
                      }}
                      InputProps={{ style: { borderRadius: "12px" } }}

                    // disabled
                    />
                    <TextField
                      error={error.city}
                      id="outlined-basic"
                      label="City*"
                      variant="outlined"
                      className="saas-form"
                      type="text"
                      name="city"
                      value={input.city}
                      onChange={getData}
                      sx={{
                        borderRadius: "12px",
                        width: isMobile ? "100%" : "33%",
                        marginTop: isMobile ? "0px" : "0px",
                      }}
                      InputProps={{ style: { borderRadius: "12px" } }}
                    />
                    <TextField
                      error={error.pincode}
                      id="outlined-basic"
                      label={
                        input.country == "India" ? "Pin Code*" : "Zip Code*"
                      }
                      variant="outlined"
                      className="saas-form"
                      type="text"
                      name="pincode"
                      value={input.pincode}
                      onChange={getData}
                      InputProps={{ style: { borderRadius: "12px" } }}
                      sx={{
                        borderRadius: "12px",
                        width: isMobile ? "100%" : "33%",
                      }}
                    />
                  </div>

                  {urlvalue[1] === "AED" ? <TextField
                    error={error.vatNo}
                    id="outlined-basic"
                    // label="GST Number*"
                    label={`VAT Number* `}

                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="vatNO"
                    // name={`${urlvalue[1]==="AED"?"vatNo":"gstNo"} `}
                    value={input.vatNo}
                    onChange={getData}
                    fullWidth
                    sx={{ marginTop: isMobile ? "0px" : "-18px" }}
                    InputProps={{ style: { borderRadius: "12px" } }}
                  /> :

                    <TextField
                      error={error.gstNo}
                      id="outlined-basic"
                      // label="GST Number*"
                      label={`GST Number* `}

                      variant="outlined"
                      className="saas-form"
                      type="text"
                      name="gstNo"
                      // name={`${urlvalue[1]==="AED"?"vatNo":"gstNo"} `}
                      value={input.gstNo}
                      onChange={getData}
                      fullWidth
                      sx={{ marginTop: isMobile ? "0px" : "-18px" }}
                      InputProps={{ style: { borderRadius: "12px" } }}
                    />
                  }

                </div>
              </div>
            </div>
{console.log(isQueryParameterStorage,"isQueryParameterStorage")}
            <div className={styles.secondSection}>
              <div className={styles.summary}>
                <div className={styles.sum_head}>Summary</div>
                <div className={styles.pro_btn}>
                  {/* <div>Pro</div> */}
                  {isQueryParameterStorage == "storage" ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>{decryptedParams?.gb} GB </div>
                      <div>{decryptedParams?.yearly}</div>
                      <img src={pro} alt="" className={styles.proImg} />
                    </div>
                  ) : isQueryParameterStorage == "renew" ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>{decryptedParams?.yearly}</div>
                      <img src={pro} alt="" className={styles.proImg} />
                    </div>
                  ) : isQueryParameterStorage == "adminPanel" ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>{decryptedParams?.gb}</div>
                      <div>{decryptedParams?.yearly}</div>
                      <img src={pro} alt="" className={styles.proImg} />
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "10px" }}>
                      {localStorage.getItem("paymentPlan") == "Quarterly"
                        ? "Quarterly"
                        : "Yearly"}
                      <img src={pro} alt="" className={styles.proImg} />
                    </div>
                  )}

                  {/* <img src={pro} alt="" className={styles.proImg} />
              <div onClick={()=>{navigateTo('/pricing')}} style={{cursor:"pointer"}}>CHANGE PLAN</div> */}

                  {isQueryParameterStorage == "storage" ? (
                    <div
                      onClick={() => {
                        window.location.assign(
                          `${constants.PRO_URL}my-profile/myprofile/CompanySetting/BillingAndPayment`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      CHANGE PLAN
                    </div>
                  ) : isQueryParameterStorage == "renew" ? (
                    <div style={{ visibility: "hidden" }}>CHANGE PLAN</div>
                  ) : isQueryParameterStorage == "adminPanel" ? (
                    <div style={{ visibility: "hidden" }}>CHANGE PLAN</div>
                  ) : (
                    <div
                      onClick={() => {
                        if (urlvalue[1] === "AUD") {
                          navigateTo("/auz");
                        } else if (urlvalue[1] === "AED") {
                          navigateTo("/uae");
                        } else if (urlvalue[1] === "INR") {
                          navigateTo("/pricing");
                        } else if (urlvalue[1] === "AUD") {
                          navigateTo("/aud");
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      CHANGE PLAN
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                    marginTop: "0.7rem",
                  }}
                >
                  <div className={styles.sub_head}>Sub total</div>
                  <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {isQueryParameterStorage == "direct" ? (
                      // <div>₹ {parseInt(priceValue?.replace(/,/g, '')).toLocaleString("en-IN")}</div>
                      <div>
                        {" "}
                        {localStorage.getItem("fromCountry") == "Dubai"
                          ? "د.إ"
                          : localStorage.getItem("fromCountry") == "Sydney"
                            ? "$"
                            : localStorage.getItem("fromCountry") === "Australia"
                              ? "₹"
                              : "₹"}{" "}
                        {priceValue.toLocaleString("en-IN")}
                      </div>
                    ) : (
                      <div>
                        {/* <div>{localStorage.getItem("fromCountry")=="Dubai" ? "د.إ" : localStorage.getItem("fromCountry")=="Sydney" ? "$" : "₹"} {decryptedParams?.price}</div> */}
                        <div>
                          {localStorage.getItem("fromCountry") == "Dubai"
                            ? "د.إ"
                            : localStorage.getItem("fromCountry") == "Sydney"
                              ? "$"
                              : localStorage.getItem("fromCountry") === "Australia"
                                ? "$"
                                : "₹"}{" "}
                          {(decryptedParams?.price * 1).toLocaleString("en-IN")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <div className={styles.sub_head}>{urlvalue[1] === "AED" ? "VAT(5%)" : "GST(18%)"}</div>
                  <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {isQueryParameterStorage === "direct" ? (
                      <div>
                        {localStorage.getItem("fromCountry") === "Dubai"
                          ? "د.إ"
                          : ["Sydney", "Australia"].includes(localStorage.getItem("fromCountry"))
                            ? "$"
                            : "₹"}{" "}
                        {urlvalue[1] === "AED"
                          ? isNaN(parseFloat(priceValue?.replace(/,/g, "")) * 0.05)
                            ? 0
                            : (parseFloat(priceValue?.replace(/,/g, "")) * 0.05).toLocaleString("en-IN")
                          : isNaN(parseFloat(priceValue?.replace(/,/g, "")) * 0.18)
                            ? 0
                            : (parseFloat(priceValue?.replace(/,/g, "")) * 0.18).toLocaleString("en-IN")}
                      </div>
                    ) : (
                      <div>
                        {localStorage.getItem("fromCountry") === "Dubai"
                          ? "د.إ"
                          : ["Sydney", "Australia"].includes(localStorage.getItem("fromCountry"))
                            ? "$"
                            : "₹"}{" "}
                        {urlvalue[1] === "AED"
                          ? isNaN(decryptedParams?.price * 0.05)
                            ? 0
                            : Number((decryptedParams?.price * 0.05).toFixed(0)).toLocaleString("en-IN")
                          : isNaN(decryptedParams?.price * 0.18)
                            ? 0
                            : Number((decryptedParams?.price * 0.18).toFixed(0)).toLocaleString("en-IN")}
                      </div>
                    )}

                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <div className={styles.sub_head}>GRand total</div>
                  <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {isQueryParameterStorage === "direct" ? (
                      <div>
                        {localStorage.getItem("fromCountry") === "Dubai"
                          ? "د.إ"
                          : ["Sydney", "Australia"].includes(localStorage.getItem("fromCountry"))
                            ? "$"
                            : "₹"}{" "}
                        {urlvalue[1] === "AED"
                          ? isNaN(
                            parseFloat(priceValue?.replace(/,/g, "")) * 1.05
                          )
                            ? 0
                            : (
                              parseFloat(priceValue?.replace(/,/g, "")) +
                              parseFloat(priceValue?.replace(/,/g, "")) * 0.05
                            ).toLocaleString("en-IN")
                          : isNaN(
                            parseFloat(priceValue?.replace(/,/g, "")) * 1.18
                          )
                            ? 0
                            : (
                              parseFloat(priceValue?.replace(/,/g, "")) +
                              parseFloat(priceValue?.replace(/,/g, "")) * 0.18
                            ).toLocaleString("en-IN")}
                      </div>
                    ) : (
                      <div>
                        {localStorage.getItem("fromCountry") === "Dubai"
                          ? "د.إ"
                          : ["Sydney", "Australia"].includes(localStorage.getItem("fromCountry"))
                            ? "$"
                            : "₹"}{" "}
                        {urlvalue[1] === "AED"
                          ? isNaN(
                            parseFloat(decryptedParams?.price) * 1.05
                          )
                            ? 0
                            : Number(
                              (
                                parseFloat(decryptedParams?.price) +
                                parseFloat(decryptedParams?.price) * 0.05
                              ).toFixed(0)
                            ).toLocaleString("en-IN")
                          : isNaN(
                            parseFloat(decryptedParams?.price) * 1.18
                          )
                            ? 0
                            : Number(
                              (
                                parseFloat(decryptedParams?.price) +
                                parseFloat(decryptedParams?.price) * 0.18
                              ).toFixed(0)
                            ).toLocaleString("en-IN")}
                      </div>
                    )}

                  </div>
                </div>

                {/* <button className={styles.btn} onClick={() => window.location.href = "/profilebuild"}> */}
                <button
                  className={styles.btn}
                  // onClick={handlePayButtonClick}
                  onClick={() => {
                    Object.keys(input).forEach((curElem) => {
                      if (input[curElem]) {
                        setError((prev) => {
                          return {
                            ...prev,
                            [curElem]: false,
                          };
                        });
                      } else {
                        setError((prev) => {
                          return {
                            ...prev,
                            [curElem]: true,
                          };
                        });
                      }
                    });
                    if (
                      input.fname !== "" &&
                        input.lname !== "" &&
                        input.companyName !== "" &&
                        input.billingAddress !== "" &&
                        input.city !== "" &&
                        input.pincode !== "" &&
                        urlvalue[1] === "AED" ? true : input.gstNo !== "" &&
                        input.country.length > 0 &&
                      input.mob.length > 0
                    ) {
                      if (input.mob.length !== 10) {
                        setError((prev) => {
                          return {
                            ...prev,
                            mob: true,
                          };
                        });
                      } else {
                        setError((prev) => {
                          return {
                            ...prev,
                            mob: false,
                          };
                        });

                        console.log("mohit sharma ")
                        handlePayButtonClick();
                      }
                    }
                  }}
                  disabled={isLoading}
                >
                  {visible == true ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ThreeDots
                        height="20"
                        width="24"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      PAY{" "}
                      {/* {isQueryParameterStorage == "direct" ? (
                        <div>
                          {localStorage.getItem("fromCountry") == "Dubai"
                            ? "د.إ"
                            : localStorage.getItem("fromCountry") == "Sydney"
                            ? "$"
                            : localStorage.getItem("fromCountry") ===
                              "Australia"
                            ? "$"
                            : "₹"}{" "}
                        
                           {urlvalue[1]==="AED"?(
                          parseFloat(priceValue?.replace(/,/g, "")) +
                          parseFloat(priceValue?.replace(/,/g, "")) * 0.05
                        ).toLocaleString("en-IN"):(
                          parseFloat(priceValue?.replace(/,/g, "")) +
                          parseFloat(priceValue?.replace(/,/g, "")) * 0.18
                        ).toLocaleString("en-IN")}
                        </div>
                      ) : (
                        <div>
                        {localStorage.getItem("fromCountry") == "Dubai"
                            ? "د.إ"
                            : localStorage.getItem("fromCountry") == "Sydney"
                            ? "$"
                            : localStorage.getItem("fromCountry") ===
                              "Australia"
                            ? "$"
                            : "₹"}{" "}
                          {urlvalue[1]==="AED"?Number(
                            (
                              parseFloat(decryptedParams?.price) +
                              parseFloat(decryptedParams?.price) * 0.05
                            ).toFixed(0)
                          ).toLocaleString("en-IN"):Number(
                            (
                              parseFloat(decryptedParams?.price) +
                              parseFloat(decryptedParams?.price) * 0.18
                            ).toFixed(0)
                          ).toLocaleString("en-IN")}
                        </div>
                      )} */}
                      {isQueryParameterStorage === "direct" ? (
                        <div>
                          {localStorage.getItem("fromCountry") === "Dubai"
                            ? "د.إ"
                            : ["Sydney", "Australia"].includes(localStorage.getItem("fromCountry"))
                              ? "$"
                              : "₹"}{" "}
                          {urlvalue[1] === "AED"
                            ? isNaN(parseFloat(priceValue?.replace(/,/g, "")) * 1.05)
                              ? 0
                              : (
                                parseFloat(priceValue?.replace(/,/g, "")) +
                                parseFloat(priceValue?.replace(/,/g, "")) * 0.05
                              ).toLocaleString("en-IN")
                            : isNaN(parseFloat(priceValue?.replace(/,/g, "")) * 1.18)
                              ? 0
                              : (
                                parseFloat(priceValue?.replace(/,/g, "")) +
                                parseFloat(priceValue?.replace(/,/g, "")) * 0.18
                              ).toLocaleString("en-IN")}
                        </div>
                      ) : (
                        <div>
                          {localStorage.getItem("fromCountry") === "Dubai"
                            ? "د.إ"
                            : ["Sydney", "Australia"].includes(localStorage.getItem("fromCountry"))
                              ? "$"
                              : "₹"}{" "}
                          {urlvalue[1] === "AED"
                            ? isNaN(parseFloat(decryptedParams?.price) * 1.05)
                              ? 0
                              : Number(
                                (
                                  parseFloat(decryptedParams?.price) +
                                  parseFloat(decryptedParams?.price) * 0.05
                                ).toFixed(0)
                              ).toLocaleString("en-IN")
                            : isNaN(parseFloat(decryptedParams?.price) * 1.18)
                              ? 0
                              : Number(
                                (
                                  parseFloat(decryptedParams?.price) +
                                  parseFloat(decryptedParams?.price) * 0.18
                                ).toFixed(0)
                              ).toLocaleString("en-IN")}
                        </div>
                      )}

                    </div>
                  )}
                </button>
                <div className={styles.foot_head}>Securely pay using</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <img src={visa} alt="" />
                  <img src={circle} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Paymentss;
