import React, { useRef } from "react";
import editImage from "../../Images/editIcon.png";
import { Link } from "react-router-dom";
import img from "../../Images/addMoreImage.png";
import bluetick from "../../Images/blueTick.png";

import { useState } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Resizer from "react-image-file-resizer";
import { addMoreProjectImages, addProjectToArray, cleanTempProjectList, deleteProjectImages, saveEditedProjectImages, setProjectDetailsRedux } from "../../Lms/Actions";
import axios from "axios";
import { BASE_URL } from "../../Config";
import { getToken } from "../../Lms/getToken";
import { updateUserProfile } from "../../Apis";
import OemSaveKitchenPhotosMob from "./oemSaveKitchenPhotosMob";

export default function OemSaveKitchenPhotos() {
  const dispatch = useDispatch();
  const addImagesRef = useRef(null);
  const [currentIds, setCurrentIds] = useState([]);
  const [files, setFiles] = useState();
  // const [tempProjArr, setTempProjArr] = useState();
  const context = useContext(OnboardingContext);
  const projName = useSelector((state) => state.addToCartReducer.projectDetails);
  const filesArr = useSelector((state) => state.addToCartReducer.projectImages);
  const tempProjArr = useSelector((state) => state.addToCartReducer.tempProjectList);
  const tempProjectList = useSelector((state) => state.addToCartReducer.tempProjectList);

  console.log(filesArr)
  const [title, setTitle] = useState(projName.name);
  // const [title, setTitle] = useState()
  const [isEditMode, setIsEditMode] = useState(false);
  const userId = localStorage.getItem("userId");
  const authTok = localStorage.getItem("token") ? getToken() : "";



  const navigateTo = useNavigate();

  function handleClick(element) {
    if (!currentIds.includes(element)) {
      setCurrentIds((prev) => {
        return [...prev, element]
      })
    } else {
      setCurrentIds(
        currentIds.filter((curElem) => {
          return curElem !== element;
        })
      )
    }
  }

  function handleDelete(e) {
    dispatch(deleteProjectImages(currentIds));
    setCurrentIds(currentIds.filter((curElem) => {
      return !currentIds.includes(curElem);
    }))
  }

//   function handleSaveProject() {
//     let temp = 0;
//     console.log(tempProjArr);
//     tempProjArr?.forEach((curElem) => {
//       if (curElem.name === projName.name) {
//         temp++;
//       }
//     })
//     if (temp > 0) {
//       dispatch(saveEditedProjectImages(projName.name, filesArr));
//       navigateTo("/projectsummary");
//     }
//     else if (filesArr.length > 0 && temp == 0) {
//       const x = {
//         name: projName?.name,
//         city: projName?.city,
//         images: filesArr
//       }
//       dispatch(addProjectToArray(x))ap
//       navigateTo("/projectsummary");
//     }
//   }

async function saveFee(){
    const payload = new FormData();
    const fees = {
        wardrobsNormalSFLaminatedPrice: localStorage.getItem("oemWardrobeNormalSF"),
        wardrobsHighGrossLaminatedPrice: localStorage.getItem("oemWardrobeHighGlose"),
        kitchenNormalSFLaminatedPrice: localStorage.getItem("oemKitchenNormalSF"),
        kitcheHighGrossLaminatedPrice: localStorage.getItem("oemKitchenHighGlose")
    }
    payload.append("fees", JSON.stringify(fees));
    updateUserProfile(payload).then((res) => {
        localStorage.removeItem("oemWardrobeNormalSF");
        localStorage.removeItem("oemWardrobeHighGlose");
        localStorage.removeItem("oemKitchenNormalSF");
        localStorage.removeItem("oemKitchenNormalSF");
        // navigateTo("/addproject");
      }).res((res)=>{
        console.log(res)
      }).catch((err)=>{
        console.log(err)
      });
}

  function handleSaveProject() {
    console.log(1)

    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1920,
          1080,
          "JPEG",
          80,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      });

      console.log(tempProjectList)
      console.log(filesArr)

    //   filesArr.map(async (data) => {
      axios
        .post(
          `${BASE_URL}/api/addKitchen`,
          {
            userId: userId,
          },
          {
            headers: {
              authorization: authTok,
            },
          }
        )
        .then(async (response) => {
          const image = new FormData();
          for (const files of filesArr) {
            var images = await resizeFile(files.file);
            image.append("image", images);
          }
          const id = response.data.data._id;
          await axios
            .put(`${BASE_URL}/api/uploadImages?id=${id}`, image, {
              headers: {
                authorization: authTok,
              },
            })
            .then((response) => {
            //   toast.success("Project uploaded Successfully");
              const featured = new FormData();
              featured.append("featured", images);
              axios
                .put(`${BASE_URL}/api/uploadFeatured?id=${id}`, featured, {
                  headers: {
                    authorization: authTok,
                  },
                })
                .then((res) => {
                  console.log("feature image uploaded successfully");
                  saveFee()
                })
                .catch((err) => {
                  console.log("error from feature");
                });
            //   toast.success("Projects uploaded successfully");
              dispatch(cleanTempProjectList());
             
              navigateTo("/congratulations");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    // });
  }

  function handleTitle(e) {
    dispatch(setProjectDetailsRedux(e.target.value, projName.city));
  }

  function handleEdit() {
    setIsEditMode(!isEditMode);
  }

  function handlePics(e) {
    let obj = e.target.files;
    // let filesArr = [];
    for (let i = 0; i < obj.length; i++) {
      dispatch(addMoreProjectImages({ file: obj[i], path: URL.createObjectURL(obj[i]) }));
    }
    // dispatch(addMoreProjectImages(filesArr));
  }

  useEffect(() => {
    setTitle(projName?.name)
  }, [projName])

  useEffect(() => {
    setFiles(filesArr)
  }, [filesArr]);

//   useEffect(() => {
//     if (filesArr.length == 0) {
//       navigateTo("/addproject")
//     }
//   }, [filesArr])

  // useEffect(() => {
  //   setTempProjArr(tempProjArr);
  // }, [tempProj])

  return (
    <>
    {window.screen.width < "768" ?
    <OemSaveKitchenPhotosMob />
    :
      <div className="main-professionContainer-web d-flex justify-content-center align-items-center">
        <div style={{ background: "white" }} className="main-div row">
          <div className="main-saveoredit-web">
            <div className="h-100">
              <div className="header" style={{ boxShadow: 'none', justifyContent: 'start' }}>
              <p style={{color: "#282828", fontWeigth: "400", fontSize: "17px"}}>Upload Images of Kitchen</p>
                {/* {!isEditMode && <p className='fs-5 mx-3' style={{ marginLeft: "1%", border: "none", marginTop: "3%" }}>{title}</p>}
                {isEditMode && <input style={{ marginTop: "3%", border: "none" }} className="editField" onChange={handleTitle} type="text" value={title} />}
                <button onClick={handleEdit} style={{ backgroundColor: "white" }}>
                  <img src={editImage} alt="" />
                </button> */}
              </div>
              <div className="d-flex justify-content-between flex-column" style={{ height: "88%" }}>
                <div className="body">
                  <div role="button" onClick={() => { addImagesRef.current.click() }} style={{ backgroundColor: "#F2F2F2", borderRadius: "10px", marginLeft: "0.5rem", marginTop: "0.5rem" }} className="addmoreImage-web">
                    <input className="d-none" ref={addImagesRef} onChange={handlePics} type="file" multiple />
                    <button>
                      <img src={img} alt="" />
                    </button>
                  </div>

                  {files?.map((obj, i) => {
                    return (
                      <div onClick={() => handleClick(obj.path)} key={obj.path} id={obj.path} className="addmoreImages-web">
                        {currentIds.includes(obj.path) && (
                          <img
                            id={obj.path}
                            className="bluetick"
                            src={bluetick}
                            style={{
                              position: "absolute",
                              top: "15px",
                              right: "10px",
                              backgroundColor: "blue",
                            }}
                          />
                        )}
                        <img className="uploadedImage" id={obj.path} src={obj.path} alt="" />
                      </div>
                    );
                  })}
                </div>
                  <div className="btn-container-web1 my-3 d-flex justify-content-end" style={{ width: "98%" }}>
                  {currentIds.length == 0 && (
                    <button onClick={handleSaveProject} className="nextbutton-web">
                      Save & Next
                    </button>
                  )}
                  {currentIds.length > 0 && (
                    <button onClick={handleDelete} className="nextbutton-web">
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    }
    </>
  )
}
