import React, { useEffect, useRef } from 'react';
// import off from "../NewLandingPage/imagesAnj/pricing/OFF.png";
// import right from "../../NewLandingPage/imagesAnj/pricing/right.png";
import { BsTelephone } from "react-icons/bs";
import wrong from "../../NewLandingPage/imagesAnj/pricing/close.png";
// import styles from "../PricingPageNewMob/PricingNewMob.module.css";
import styles from "../../Pricing/BoxOne.module.css";
import disable from "../../NewLandingPage/imagesAnj/pricing/disable.png"
import cloud from "../../NewLandingPage/imagesAnj/pricing/cloud.png";
import Icon from "../../Pricing/Images/Icon.svg";
import right from "../../Pricing/Images/right.svg";
import web from "../../Pricing/Images/web.svg";
import app from "../../Pricing/Images/app.svg";
import pro from "../../Pricing/Images/pro.svg";
import caretRight from "../../Pricing/Images/CaretRight.svg";
// import checkCircle from "./Images/CheckCircle.svg";
import {mockDataBoxOne} from "../../Pricing/mockData";
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";

const BasicMob = ({change, forScroll}) => {
  const navigateTo = useNavigate();
  
  useEffect(()=>{
    localStorage.setItem("price", 25000)
    localStorage.setItem("fromCountry", "India")
  },[])
  // const scrollRef=useRef();
  function generateHashedLink(token, userId, paymentPlan, fullName, cId, price, password) {
    const encryptedData = {};
    const encryptionKey = "18B69E843399F1A2AFC5853129299";
  
    const encryptField = (field) => {
      const fieldValue = field?.toString() || ''; // Convert value to string, handle undefined
      return CryptoJS.AES.encrypt(fieldValue, encryptionKey).toString();
    };
  
    encryptedData.token = encryptField(token);
    encryptedData.userId = encryptField(userId);
    encryptedData.paymentPlan = encryptField(paymentPlan);
    encryptedData.cId = encryptField(cId);
    encryptedData.fullName = encryptField(fullName);
    encryptedData.price = encryptField(price);
    encryptedData.password = encryptField(password);
  
    const now = new Date();
    const timestamp = now.toISOString();
    const encryptedTimestamp = CryptoJS.AES.encrypt(timestamp, encryptionKey).toString();
    encryptedData.timestamp = encryptedTimestamp;
  
    const encryptKey = (key) => {
      return CryptoJS.AES.encrypt(key, encryptionKey).toString();
    };
    const url = `https://www.idesign.market/payment?${encryptKey("userId")}=${encryptedData.userId}&${encryptKey("cId")}=${encryptedData.cId}&${encryptKey("token")}=${encryptedData.token}&${encryptKey("fullName")}=${encryptedData.fullName}&${encryptKey("price")}=${encryptedData.price}&${encryptKey("paymentPlan")}=${encryptedData.paymentPlan}&${encryptKey("timestamp")}=${encryptedData.timestamp}&${encryptKey("password")}=${encryptedData.password}`;
    return url;
  }

  return (
   <>   
      <div className={styles.box2}>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              {/* <div className={styles.buttons}>
                <span className={styles.buttonsText}>SOLO DESIGNERS</span>
              </div> */}
              <div className={styles.buttons}>
                <span className={styles.buttonsText}>Small-mid Teams</span>
              </div>
            </div>
            <div style={{marginTop:"1rem"}}>
                  <span className={styles.amount}>{change === "quarter" ? "₹ 25,000" : "₹ 20,000"}</span>
                  <span className={styles.afterAmount} style={{marginLeft:"0.5rem"}}>billed monthly</span>
            </div>
            <div style={{marginTop:"1rem", display:"flex", gap:"1rem", justifyContent:"space-between"}}>
            <div className={styles.blueButtonContainer}>
                <div className={styles.blueButton}>
                  <div className={styles.blueButtonText} 
                  style={{cursor:"pointer"}}
                  onClick={()=>
                    {
                      if (localStorage.getItem("pricing") === "true") {
                      navigateTo("/signup");
                    } else {
                      // navigateTo("/payment");
                      window.location.assign(generateHashedLink(localStorage.getItem("token"),
                      localStorage.getItem("userId"),
                      localStorage.getItem("paymentPlan"),
                      localStorage.getItem("fullName"),
                      localStorage.getItem("cId"),
                      localStorage.getItem("price"),
                      localStorage.getItem("password")
                      )) 
                    }
                    }
                   }
                  >Get Started</div>
                  <img src={caretRight} className={styles.checkCircle} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "0.56rem" }}>
              <div className={styles.inBoxHeading}>
              Professional
              <img src={pro} alt="pro" className={styles.proImage} />
              <span className={styles.pro}>Pro</span>
              <div className={styles.mostPopularBtn}><span className={styles.mostPopularText}>Most Popular</span></div>
              </div>
            </div>
            <div 
            // style={{ marginTop: "1rem" }}
            >
              <div className={styles.inBoxSummary}>
                Unlimited Users | 1 TB Storage limit{" "}
              </div>
            </div>
            <div style={{marginTop:"0.75rem"}}>
            <div style={{marginBottom:"0.25rem"}} className={styles.arrayHeadings}>
             <img src={app} alt="app" style={{verticalAlign:"middle",  marginRight:"0.25rem", height:"1rem", width:"1rem"}}/>
             App</div>
              {mockDataBoxOne.map((x, index) => (
                <div key={index} className={styles.arrayData}> <img src={right} alt="right" style={{marginRight:"0.75rem", height:"1rem", width:"1rem"}} /> {x}</div>
              ))}
            </div>
            <div style={{marginTop:"1rem"}}>
            <div style={{marginBottom:"0.25rem"}} className={styles.arrayHeadings}>
             <img src={web} alt="web" style={{verticalAlign:"middle", marginRight:"0.25rem", height:"1rem", width:"1rem"}} />Web for any browser</div>
             
                  <div className={styles.arrayData}></div>
              <div>
              <div className={styles.arrayData}>
              <img src={right} alt="right" style={{marginRight:"0.75rem", height:"1rem", width:"1rem"}} />Unlimited free Users
              </div>
              <img src={Icon} alt="" style={{marginTop:"-38px", marginLeft:"25px", width:"6rem"}} />
              </div>
               <div style={{marginTop:"-24px", lineHeight:"1rem", display:"flex"}} className={styles.arrayData}> <img src={right} alt="right" style={{marginRight:"0.75rem",  height:"1rem", width:"1rem"}} />
              Inspect designs, Create Timelines and Execute handoffs to Stakeholders
              </div>
              <div className={styles.arrayData}> <img src={right} alt="right" style={{marginRight:"0.75rem",  height:"1rem", width:"1rem"}} />
                Proper Analytics Visuals 
               </div>
             
            </div>
            {/* <div style={{marginTop:"1.25rem"}}>
                  <span className={styles.amount}>{change === "quarter" ? "₹ 25,000" : "₹ 20,000"}</span>
                  <span className={styles.afterAmount} style={{marginLeft:"0.5rem"}}>billed monthly</span>
            </div>
            <div style={{marginTop:"1rem", display:"flex", gap:"1rem", justifyContent:"space-between"}}>
            <div className={styles.blueButtonContainer}>
                <div className={styles.blueButton}>
                  <div className={styles.blueButtonText} 
                  style={{cursor:"pointer"}}
                  onClick={()=>
                    {
                      if (localStorage.getItem("pricing") === "true") {
                      navigateTo("/signup");
                    } else {
                      navigateTo("/payment");
                    }
                    }
                   }
                  >Get Started</div>
                  <img src={caretRight} className={styles.checkCircle} />
                </div>
              </div>
              <div
                className={styles.blueButtonContainer}
                style={{ marginBottom: "0.75rem" }}
              >
                <div className={styles.blueBorderButton}>
                  <div className={styles.blueBorderButtonText} style={{cursor:"pointer"}}
                   onClick={()=>navigateTo("/contact")}>Let's Talk!</div>
                </div>
              </div>
            </div> */}
            <div className={styles.showFeaturesBtn}
            style={{cursor:"pointer", marginTop:"0.75rem"}}
            //  onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}
            >
            <a href={forScroll} style={{color:"inherit", textDecoration:"none"}}>Show features</a>
            </div>
          </div>

   </>
  )
}

export default BasicMob;