import React, { useState } from "react";
import idesignLogo from "./3dImages/idesignLogo.svg";
import banner from "./3dImages/banner.svg";
import SidebarNav from "../Lms/SidebarNav";
import { handleLogout } from "../Redux/Actions/auth";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";

const LandingMobHeader = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const closeSidebar = () => {
    setShowSidebar(false);
  };
  const dispatch = useDispatch();
  const logoutHandler = () => {
    setShowSidebar(false);
    confirmAlert({
      message: `Are you sure you want to logout?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(handleLogout()),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSideShow = () => {
    setShowSidebar(true);
  };

  return (
    <>
      <div
        className="landingMobNav d-flex p-3"
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 4px 10px rgb(0 0 0 / 5%)",
          borderBottom: "1px solid rgb(0 0 0 / 5%)",
        }}
      >
        <div className="d-flex justify-content-around" style={{ width: "80%" }}>
          <img src={banner} alt="" onClick={handleSideShow} />
          <img src={idesignLogo} alt="" style={{ width: "66%", height: "40px" }} />
        </div>
      </div>
      <SidebarNav sidebarShow={showSidebar} sidebarClose={closeSidebar} sidebarLogout={logoutHandler} />
    </>
  );
};

export default LandingMobHeader;
