import React, { useEffect, useState } from 'react'
import './Onboarding2.css'
import con_img5 from './On_images2/con_img5.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import OemKitchenMob from './oemKitchenMob'

const OEMkitchen = () => {
    const [showError1, setShowError1] = useState(false);
    const [showError2, setShowError2] = useState(false);

    const [state, setState] = useState({
        oem_kitchen1: "",
        oem_kitchen2: ""
    })
    const location = useLocation();

    useEffect(()=>{
        localStorage.setItem("path", location.pathname)
    },[])
    

    const handleChange = (evt) => {
        // const value = evt.target.value.toString();
        let name = evt.target.name 

        if (name === "oem_kitchen2") {
            if (evt.target.value.length <= 4 && evt.target.value >= 0) {
                setState((prev) => {
                    return { ...prev, ["oem_kitchen2"]: evt.target.value };
                });
            }
        }
       else if (name === "oem_kitchen1") {
            if (evt.target.value.length <= 4 && evt.target.value >= 0) {
                setState((prev) => {
                    return { ...prev, ["oem_kitchen1"]: evt.target.value };
                });
            }
        }
        else{
            setState({
                ...state,
                [evt.target.name]: evt.target.value
            });
        }
    }

    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        if (state.oem_kitchen1.length < 1) {
            setShowError1(true);
        }
        else if (state.oem_kitchen2.length < 1) {
            setShowError2(true);
        }
        else if (state.oem_kitchen1.length < 1 && state.oem_kitchen2.length < 1) {
            setShowError1(true);
            setShowError2(true);
        } else {
            setShowError1(false);
            setShowError2(false);
            localStorage.setItem("oemKitchenNormalSF", state.oem_kitchen1)
            localStorage.setItem("oemKitchenHighGlose", state.oem_kitchen2)
            navigate("/oemaddkitchenphotos")
        }
    }

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    return (
        <>
            {window.screen.width < "768" ?
                <OemKitchenMob />
                :
                <div className="oem_main">
                    <div className="oem_main2 vh-100">
                        <div className="oem_center2">
                            <div className="oem_left">
                                <div className="oem_left1">
                                    <img src={con_img5} alt="" className="oem_img2" />
                                    <h2 className="oem_header">How much fees would you charge for Kitchen</h2>
                                </div>
                            </div>
                            <div className="oem_right">
                                <form className="oem_form" >
                                    <input type="number" className="oem_amount" name="oem_kitchen1" value={state.oem_kitchen1} style={showError1 ? { borderColor: "red", textAlign: "center" } : { textAlign: "center" }} onChange={handleChange} placeholder='For Normal SF Laminated price in INR' onKeyDown={(e) =>
                                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    } required />
                                    <span style={{
                                        color: "red", textAlign: "center", fontSize: "12px", alignSelf: "center", marginTop: "4px"
                                    }} className={`${showError1 ? "" : "d-none"}`}>You cannot leave this field empty</span>
                                    <input type="number" className="oem_amount2" value={state.oem_kitchen2} name="oem_kitchen2" style={showError2 ? { borderColor: "red", textAlign: "center" } : { textAlign: "center" }} onChange={handleChange} placeholder='For High Gloss Laminated price in INR' onKeyDown={(e) =>
                                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    } required />
                                    <span style={{ color: "red", textAlign: "center", fontSize: "12px", alignSelf: "center", marginTop: "4px" }} className={`${showError2 ? "" : "d-none"}`}>You cannot leave this field empty</span>
                                    <div className="btn_err">
                                        <button className="oem_submit" onClick={handleSubmit}>Next</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default OEMkitchen