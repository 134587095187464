import React, { useEffect, useRef } from "react";
import "./SaasOnboarding.css";
import closeEye from "./SaasAssets/closeEye.svg";
import eye from "./SaasAssets/eye.svg";
import { FcGoogle } from "react-icons/fc";
import { useState } from "react";
import info from "./SaasAssets/info.svg";

import { signInWithGoogle } from "../../../Config/firebase";
import { getDeviceId } from "../../../Helpers/utils";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword, socialLogin } from "../../../Apis";
import { completeProfile, handleLogin } from "../../../Redux/Actions/auth";
import { Form, Modal, Overlay, Tooltip } from "react-bootstrap";
import FormInput from "../../../Components/Forms/FormInput";
import bgmain from "./SaasAssets/bg-main.svg";
import { Popover, TextField } from "@mui/material";
import LoginHeader from "./LoginHeader";
import SignInMob from "../SaasOnboardingMobile/SignInMob";
import BeforeOtpHeader from "../SaasOnboardingMobile/BeforeOtpHeader";

const SaasLogin = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [link, setLink] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [show, setShow] = useState(0);
  const [show3, setShow3] = useState(0);
  const target = useRef(null);
  const target3 = useRef(null);

  const [errors, setErrors] = useState({
    emailErr: "",
    passwordErr: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    setFormData((prev) => {
      return { ...prev, [name]: e.target.value };
    });
    if (formData?.email?.length > 0) {
      setErrors((prev) => {
        return { ...prev, emailErr: "" };
      });
    }
    if (formData?.password?.length > 0) {
      setErrors((prev) => {
        return { ...prev, passwordErr: "" };
      });
    }
  };
  const handleLoginMain = () => {
    if (formData.email.length < 1) {
      setErrors((prev) => {
        return { ...prev, emailErr: "You cannot leave email empty" };
      });
      setShow(1);
    } else if (formData.password.length < 1) {
      setErrors((prev) => {
        return { ...prev, passwordErr: "You cannot leave password empty" };
      });
      setShow3(1);
    } else {
      const payload = {
        email: formData.email,
        password: formData.password,
        deviceId: `${getDeviceId()}`,
      };
      dispatch(handleLogin(payload));
    }
  };
  const googleLogin = () => {
    signInWithGoogle()
      .then((response) => {
        socialLogin({
          email: response.user.email,
          googleId: response.user.uid,
          deviceId: `${getDeviceId()}`,
        }).then((res) => {
          window.localStorage.setItem("userId", res.data._id);
          localStorage.setItem("token", res.data.accessToken);
          window.localStorage.removeItem("sessionExpire");
          dispatch(completeProfile(res?.data));
          if (res.data.isProfileComplete) {
            // return navigateTo("/lead");
          }
          // navigateTo("/onboard-designer");
        });
      })
      .catch((err) => {
        toast.error("Cannot authenticate! try again");
      });
  };

  const forgotPassword = (e) => {
    e?.preventDefault();
    const email = e?.target[0]?.value;
    resetPassword({ email }).then(() => setLink(e?.target[0]?.value));
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else if (passwordType === "text") {
      setPasswordType("password");
    }
  };

  // console.log(modal)

  const handleNew = () => {
    props.changeTab();
  };

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      {isDesktop ? (
        <div>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
              setLink(null);
            }}
            centered
            className="interFont"
          >
            <Modal.Header closeButton>
              <Modal.Title>{link ? "Email Sent" : "Forgot Password?"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {link ? (
                <p>
                  {`We have sent an email to ${link} with a link to reset
                your password.`}
                </p>
              ) : (
                <Form onSubmit={forgotPassword}>
                  <FormInput name="email" label="Enter Email Address" type="email" placeholder="Enter Email Address" required />
                  <button className="btn-login m-0 p-2" style={{ width: "100%" }} type="submit">
                    Request a reset link
                  </button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
          <BeforeOtpHeader />
          <div className="w-100" style={{ height: "calc(100vh - 57px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "25rem", height: "25rem", border: "2px solid #f0e9e9", borderRadius: "8px" }}>
              <div className="welcome">Welcome Back!</div>
              <div className="mt-4 input_div">
                <input className="input_tag" type="email" placeholder="email" ref={target} name="email" value={formData.email} onChange={handleChange} />
                {errors.emailErr.length > 0 && (
                  // <div className=" d-flex align-items-center" style={{ position: "absolute", left: "23rem" }}>
                  //     <div style={{
                  //         height: "0",
                  //         borderLeft: "15px solid transparent",
                  //         borderBottom: "15px solid white",
                  //         // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                  //         marginTop: "auto"
                  //     }}></div>
                  //     <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px" }} />{errors.emailErr}</div>
                  // </div>
                  // <Overlay target={target.current} show={show} placement="bottom">
                  //     <Tooltip id="overlay-example" >
                  //         <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px", marginBottom: "auto" }} /> {errors.emailErr}
                  //     </Tooltip>
                  // </Overlay>
                  <Overlay show={show} target={target.current} placement="bottom">
                    <Popover id="popover-contained">
                      <Popover.Body>
                        <img
                          src={info}
                          alt=""
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "5px",
                            marginBottom: "auto",
                          }}
                        />{" "}
                        {errors.emailErr}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                )}
              </div>
              <div className="mt-3 input_div">
                <div className="d-flex" style={{ position: "relative" }}>
                  <input className="input_tag" type={passwordType} placeholder="password" ref={target3} name="password" value={formData.password} onChange={handleChange} />
                  {passwordType === "password" ? (
                    <img src={closeEye} style={{ position: "absolute", right: "1rem", top: "0.7rem" }} onClick={togglePassword} />
                  ) : (
                    <img src={eye} alt="" style={{ position: "absolute", right: "1rem", top: "0.7rem" }} onClick={togglePassword} />
                  )}
                  {/* {errors.passwordErr.length > 0 && <div className=" d-flex align-items-center">
              <div style={{
                height: "0",
                borderLeft: "15px solid transparent",
                borderBottom: "15px solid white",
                // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                marginTop: "auto"
              }}></div>
              <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "7px" }} />{errors.passwordErr}</div>
            </div>} */}
                  {errors.passwordErr.length > 0 && (
                    //  <div className=" d-flex align-items-center" style={{ position: "absolute", left: "23rem" }}>
                    //     <div style={{
                    //         height: "0",
                    //         borderLeft: "15px solid transparent",
                    //         borderBottom: "15px solid white",
                    //         // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                    //         marginTop: "auto"
                    //     }}></div>
                    //     <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px" }} />{errors.passwordErr}</div>
                    // </div>
                    <Overlay show={show3} target={target3.current} placement="bottom">
                      <Popover id="popover-contained">
                        <Popover.Body>
                          <img
                            src={info}
                            alt=""
                            style={{
                              height: "15px",
                              width: "15px",
                              marginRight: "5px",
                              marginBottom: "auto",
                            }}
                          />{" "}
                          {errors.passwordErr}
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  )}
                </div>
              </div>
              <div style={{ width: "94%" }}>
                <div className="forgot mt-3 d-flex justify-content-end" onClick={() => setModal(true)} style={{cursor:"pointer"}}>
                  Forgot Password
                </div>
              </div>
              <div className="btn_back mt-2">
                <button className="btn-login" style={{ background: "#3B5998", color: "#ffffff", padding: "10px" }} onClick={handleLoginMain}>
                  Sign In
                </button>
              </div>
              {/* <div className="mt-2 d-flex w-100">
               

                <div style={{ marginLeft: "0.4rem" }}>
                  <div className="empty_div"></div>
                </div>
              </div> */}
              <div>
                <div className="d-flex mt-1" style={{ position: "relative" }}>
                  {/* <img
              role="button"
              src={google}
              style={{ position: "absolute", left: "2rem", top: "2.1rem" }}
            /> */}
                  {/* <button
              className="btn-login"
              style={{
                background: " #F6F6F6",
                color: "#888888",
                padding: "10px",
                border: "1px solid #DFDFDF",
                boxShadow: "none",
              }}
              onClick={googleLogin}
            >
              Sign in with Google
            </button> */}
                </div>
              </div>
              {/* <div className='account mt-3'>Don’t have an account yet?<span className='signup' onClick={() => navigateTo('/signup')}> Sign Up</span></div> */}
            </div>
          </div>
        </div>
      ) : (
        <SignInMob />
      )}
    </>
  );
};

export default SaasLogin;
