import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAlignLeft,
  faAlignRight,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faArrowUp,
  faAt,
  faBan,
  faBell,
  faBook,
  faCalendarAlt,
  faCalendarCheck,
  faCamera,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClock,
  faCodeBranch,
  faCog,
  faComments,
  faCopy,
  faCrown,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFilter,
  faFolderPlus,
  faGift,
  faGlobeAmericas,
  faHeart,
  faHome,
  faIcons,
  faImage,
  faLink,
  faList,
  faListAlt,
  faListUl,
  faLocationArrow,
  faLock,
  faMinus,
  faMapMarkerAlt,
  faPalette,
  faPaperclip,
  faPencilAlt,
  faPercentage,
  faPlay,
  faPlug,
  faPlus,
  faPlusSquare,
  faPoll,
  faPrint,
  faPuzzlePiece,
  faQuestionCircle,
  faRedo,
  faReply,
  faSearch,
  faShare,
  faShareAlt,
  faShoppingCart,
  faSignInAlt,
  faSlidersH,
  faSmileBeam,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp,
  faStar,
  faStarHalfAlt,
  faStream,
  faTag,
  faTh,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
  faUsers,
  faCross,
  faStoreAlt,
  faReceipt,
  faCalendarWeek,
  faConciergeBell,
  faPeopleArrows,
  faBox,
  faDoorOpen,
  faPen,
  faSignOutAlt,
  faSortDown,
  faFile,
  faCalendar,
  faCalendarDay,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  // Solid
  faEyeSlash,
  faCalendarDay,
  faCalendar,
  faFile,
  faSortDown,
  faSignOutAlt,
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faAngleRight,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArchive,
  faEnvelope,
  faClock,
  faStar,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashAlt,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faEye,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faPlusSquare,
  faCaretDown,
  faUserPlus,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faComments,
  faPalette,
  faEdit,
  faTrashAlt,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,
  faCalendarAlt,
  faCalendarCheck,
  faMapMarkerAlt,
  faCross,
  faBox,
  faStoreAlt,
  faReceipt,
  faCalendarWeek,
  faConciergeBell,
  faPeopleArrows,
  faDoorOpen,
  faPen
);
