import React, {useEffect, useState, useRef} from "react";
import styles from "./WhoWeServe.module.css";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Interiorfirm from "./Images/Interiorfirm.jpg"

const WhoWeServe=()=>{
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const navigate = useNavigate();

    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };

    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const [showButton, setShowButton] = useState(false);
    const [showButton1, setShowButton1] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 150) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }

        if (window.scrollY > 300) {
          setShowButton1(true);
        } else {
          setShowButton1(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

return(
    <>
    <div className={styles.mainContainer}>
    {isDesktop?
    <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton ? "block" : "none", height:"5.5rem" }}>
    <img  data-tooltip-id="my-tooltip" data-tooltip-content="Go to top"
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png" 
              alt="" onClick={() => scrollToTop()} 
              className={styles.topIconImg}
              /></div>
            :
            <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton1 ? "block" : "none", height:"5.5rem" }}>
  <img 
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png" 
            alt="" onClick={() => scrollToTop()} 
            className={styles.topIconImg}
            /></div>
}
<Tooltip id="my-tooltip" />
     
    <div className={styles.heading}>Software Solutions Designed Specifically for the AEC Industry</div>
   <div className={styles.imagesContainer}>
   <div className={`${styles.whoWeServeImageContainer} ${styles.whoWeServeImageConFirst}`} 
   onClick={()=>{
    scrollToTop();
    navigate("/new/design")
    }}>
   {isDesktop?
    <img src={Interiorfirm}
     alt="third" className={`${styles.whoWeServeImages} ${styles.whoWeServeImagesFirst}`} />
   :
   <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/architectMob.png"
   alt="third" className={`${styles.whoWeServeImages} ${styles.whoWeServeImagesFirst}`} />
   }
        {/* <img src={third} alt="third" className={`${styles.whoWeServeImages} ${styles.whoWeServeImagesFirst}`} /> */}
        <div className={styles.whoWeServeImageText}>INTERIOR FIRMS</div>
    </div>
    <div className={styles.innerFlexContainer}>
    <div className={styles.whoWeServeImageContainer} 
     onClick={()=>{
        scrollToTop();
        navigate("/new/architect")}}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/architects.png" 
        alt="one" className={styles.whoWeServeImages} />
        <div className={styles.whoWeServeImageText}>Architects</div>
    </div>
    <div className={styles.whoWeServeImageContainer}  onClick={()=>{
          scrollToTop();
        navigate("/new/contractor")}}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/constructin.png"
         alt="two" className={styles.whoWeServeImages} />
        <div className={styles.whoWeServeImageText}>Construction Companies</div>
    </div>
    </div>
    <div  className={styles.innerFlexContainer}>
    <div className={styles.whoWeServeImageContainer}  onClick={()=>{
          scrollToTop();
        navigate("/new/estate")}}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/real.png"
         alt="four" className={styles.whoWeServeImages} />
        <div className={styles.whoWeServeImageText}>Real Estate Developers</div>
    </div>
    <div className={styles.whoWeServeImageContainer}  onClick={()=>{
          scrollToTop();
        navigate("/new/retail")}}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/retail.png"
         alt="five" className={styles.whoWeServeImages} />
        <div className={styles.whoWeServeImageText}>Retail Chains</div>
    </div>
    </div>
   </div>
   </div>
    </>
)
}

export default WhoWeServe;