import React, { useState } from "react";
import facebook from "../../../Assets/Logos/Social/Facebook.svg";
import instagram from "../../../Assets/Logos/Social/Instagram.svg";
import whatsapp from "../../../Assets/Logos/Social/WhatsApp.svg";
import youtube from "../../../Assets/Logos/Social/Youtube.svg";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../Router/routes";
import { scrollToTop } from "../../../Helpers/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {newsLetterSchema} from "../../../Constants/formSchema";
import FormInput from "../../Forms/FormInput";
import { submitQuery } from "../../../Apis";
import { toast } from "react-hot-toast";

const companyLinks = [

  { label: "About", to: ROUTES.ABOUT_US },
  {
    label: "Careers",
    to: ROUTES.CAREER,
    external: true,
  },
  { label: "Privacy Policy", to: ROUTES.PRIVACY_POLICY },
  { label: "Terms & Conditions", to: ROUTES.TERMS_AND_CONDITIONS },
  { label: "Refund & Cancellation", to: ROUTES.REFUNDS_RETURN },
  { label: "Cookie policy", to: ROUTES.COOKIE_POLICY },
  { label: "Contact", to: ROUTES.CONTACT },
];

const homeownersLinks = [
  // { label: "Find Professionals", to: ROUTES.PROFESSIONAL_B2B },
  // { label: "Explore Projects", to: ROUTES.EXPLORE_B2B },
  // { label: "Magazine", to: ROUTES.MAGAZINES },
  // { label: "Sign In", to: ROUTES.LOGIN },
];

const designLinks = [
  // { label: "Join iDesign Pro", to: ROUTES.ID },
  // { label: "Pricing", to: ROUTES.PRICING },
  // { label: "Sign In", to: ROUTES.LOGIN },
];

const Footertwo = () => {

  const [joinProShow, setJoinProShow] = useState(false);
  const buttonScrollVisibilityHandler = () => {
  let y = window.scrollY;
    if(y>=550){
      setJoinProShow(true);
    }else{
      setJoinProShow(false);
    }
  }
  window.addEventListener("scroll", buttonScrollVisibilityHandler);

  const { pathname } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(newsLetterSchema) });

  const newsLetterSubmit = ({ email }) => {
    submitQuery({ type: 1, data: email }).then((res) =>
      toast.success("Newsletter subscribed")
    );
  };

  return (
    <div className={styles.footer}>
      <Container>
        <Row>
          <Col md="3" className={`text-start fotoer-one ${styles.fullWidth}`}>
            <div className={styles.footerLogo}>
              <h2>iDesign Pro</h2>
              <p style={{textAlign:"justify"}}>
                iDesign Pro is an online marketplace providing SaaS, Design
                Tools and workflow features, dedicated to Interior Community.
              </p>

              <div className={`d-flex mt-4 mb-4 ${styles.socialIcon}`}>
                <a
                  href="https://fb.com/iDesign.market"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="fb" className="pointer" />
                </a>
                <a
                  href="https://www.instagram.com/iDesign.market/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={instagram} alt="ig" className="pointer" />
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=%2B919289347893&text=Hi%2C+pls+resolve+my+below+query&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={whatsapp} alt="wa" className="pointer" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCZYsSoot4r9eZSPJk6F7-xw"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={youtube} alt="yt" className="pointer" />
                </a>
              </div>
              {/*<p>Corporate Social Responsibility</p>*/}
            </div>
          </Col>
          <Col md="2" className={`text-start ${styles.fullWidth}`}>
            <ul className={styles.menuFooter}>
              <h4>Company</h4>
              {companyLinks.map((link, i) =>
                link?.external ? (
                  <a href={link.to} key={i}>
                    <li>{link.label}</li>
                  </a>
                ) : (
                  <Link to={link.to} key={i} onClick={scrollToTop}>
                    <li>{link.label}</li>
                  </Link>
                )
              )}
            </ul>
          </Col>

          <Col md="2" className={`text-start ${styles.fullWidth}`}>
            <ul className={styles.menuFooter}>
              {/* <h4>Homeowners</h4> */}
              {homeownersLinks.map((link, i) => (
                <a href={link.to} rel="noreferrer" key={i}>
                  <li>{link.label}</li>
                </a>
              ))}
            </ul>
          </Col>

          <Col md="2" className={`text-start ${styles.fullWidth}`}>
            <ul className={styles.menuFooter}>
              {/* <h4>Design Professionals</h4> */}

              {designLinks.map((link, i) => (
                <Link to={link.to} key={i} onClick={scrollToTop}>
                  <li>{link.label}</li>
                </Link>
              ))}
            </ul>
          </Col>
          <Col md="3" className={`text-start ${styles.fullWidth}`}>
            <ul className={styles.menuFooter}>
              <h4>Sign up to our newsletter</h4>

              <div className={styles.emailBtn}>
                <form onSubmit={handleSubmit(newsLetterSubmit)}>
                  <FormInput
                    type="text"
                    placeholder="Email"
                    register={register("email")}
                    isInvalid={!!errors?.email}
                    error={errors?.email?.message}
                  />
                  <button className="know-more" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </ul>
          </Col>

          <Col md="12" className="pt-4 pb-4 text-center interFont">
            <div className={styles.footerBottom}>
              <h4>
                {new Date().getFullYear().toString()} iDesign Pro is a copyright product of Tech Designworks Pvt. Ltd. All Rights Reserved
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      {pathname !== ROUTES.PROFILE_BUILD && (
        <Link to={ROUTES.LOGIN} onClick={scrollToTop}>
          <button className={`know-more join-as-pro ${joinProShow ? "show-join-pro-button" : "hide-join-pro-button"}`} style={{transition: "all", transitionDuration: "300ms"}} >{joinProShow ? "Join as a Pro" : ""}</button>
        </Link>
      )}
    </div>
  );
};

export default Footertwo;
