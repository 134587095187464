import React from "react";
import styles from "./HeaderMob.module.css";

const HeaderMob = ({openHamburger,setOpenHamburger}) => {
  return (
    <>
      <div className={`${styles.mainContainer} ${styles.sticky} `}>
        <div className={styles.innerContainer}>
          <img
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/logo.png"
            alt="logo"
            className={styles.logo}
          />
          <img
          onClick={()=>setOpenHamburger(!openHamburger)}
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/octicon_three-bars-16.svg"
            alt="hamburger"
            className={styles.ham}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderMob;
