import React, { useEffect, useState } from "react";
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
// import goBack from "../../Images/gobackicon.svg";
import { useSelector } from "react-redux";
import check from "./VendorImgs/tickIcon.svg";
import gstDoc from "./VendorImgs/gstShowingDocIcon.svg";
import editIcon from "./VendorImgs/editingPencil.svg";
import { Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { addPurchasedLeads, fetchProfileData, sendInvoice, setGstDetails } from "../Lms/Actions";
import { getToken } from "../Lms/getToken";
import { useNavigate } from "react-router-dom";
import VendorCartSide from "./VendorCartSide";
import axios from "axios";
import congo from './VendorImgs/congo.svg'
import { changePlan, createOrder, createPlan, createSubscription } from "../Apis";
import { RAZOR_PAY_KEY } from "../Config";
import { ROUTES } from "../Router/routes";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";

const VendorCart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authTok = localStorage.getItem("token") ? getToken() : "";
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    const [isLoading, setIsLoading] = useState();

    // console.log(profileInfo)

    const initialGstData = {
        companyName: profileInfo[0]?.data?.data?.companyName,
        gstNum: profileInfo[0]?.data?.data?.gstNumber,
    };
    const [gstOffcan, setGstOffcan] = useState(false);
    const [gstData, setGstData] = useState(initialGstData);
    const [showError, setShowError] = useState({
        companyNameError: "",
        gstNumError: "",
    });
    const gstDataInputHandler = (event) => {
        const { name, value } = event.target;
        setGstData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleGstSubmit = () => {
        if (gstData.companyName.length < 1) {
            setShowError((prev) => {
                return {
                    ...prev,
                    companyNameError: "You cannot leave company name empty",
                };
            });
        } else if (gstData.gstNum.length < 1) {
            setShowError((prev) => {
                return {
                    ...prev,
                    gstNumError: "You cannot leave GST number empty",
                };
            });
        } else {
            setGstOffcan(false);
            dispatch(setGstDetails(authTok, gstData.gstNum, gstData.companyName));
        }
    };

    const [data, setData] = useState([])
    useEffect(async () => {
        const res = await axios.get("https://pro-api.idesign.market/user/listVendorPlanInCart", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authTok
            }
        }).then((res) => {
            console.log(res)
            setData(res?.data?.data)
        }).catch(err => console.warn(err))
    }, [])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    console.log(data)

    const [venId, setVenId] = useState([])

    useEffect(async () => {
        await axios.get("https://pro-api.idesign.market/user/listVendorPlans").then((venRes) => {
            console.warn(venRes)
            setVenId(venRes?.data?.data[0]?._id)
        }).catch(err => console.warn(err))
    }, [])

    // console.log(venId)


    const [planid, setPlanid] = useState("")
    const [subsid, setSubsid] = useState("")

    // let placeOrderOnRazorpay = async () => {
    //     let amount = data?.totalPrice;
    //     let res = await createOrder({ amount: parseInt(amount.toFixed(2)) });

    //     console.log(res)

    //     if (res) {
    //         const planRes = await createPlan({
    //             period: "monthly",
    //             interval: data?.plan.duration,
    //             planName: data?.plan.name,
    //             planAmt: parseFloat(amount.toFixed(2)),
    //             description: "subscription plan",
    //         });
    //         // console.log(planRes);
    //         setPlanid(planRes?.data?.id)
    //         localStorage.setItem("vendorPlanId", planRes?.data?.id)
    //         if (planRes) {
    //             const date = Date.now();
    //             const x = {
    //                 planId: planRes?.data?.id,
    //                 paymentCycles: data?.plan.duration === 1 ? 30 : null,
    //                 startAt: Math.floor(new Date().getTime() / 1000) + 2592000
    //             };

    //             const subRes = await createSubscription(x);
    //             // console.log(subRes);
    //             setSubsid(subRes?.data?.id)
    //             localStorage.setItem("vendorSubsId", subRes?.data?.id)
    //             if (subRes) {
    //                 // console.log(subRes);
    //                 setIsLoading(false);
    //                 const option = {
    //                     description: "iDesign payment",
    //                     currency: "INR",
    //                     key: RAZOR_PAY_KEY,
    //                     amount: amount,
    //                     name: "iDesign.market pvt. ltd.",
    //                     subscription_id: subRes?.data?.id,
    //                     handler: paymentHandler.bind(this, amount),
    //                     prefill: {
    //                         name: profileInfo[0]?.data?.data?.companyName,
    //                         email: profileInfo[0]?.data?.data?.email,
    //                         contact: profileInfo[0]?.data?.data?.phoneNumber,
    //                     },
    //                     notes: {
    //                         address: `Payment for cart`,
    //                     },
    //                     theme: {
    //                         color: "#3b5998",
    //                     },
    //                     recurring: true,
    //                     checkout: {
    //                         method: {
    //                             upi: 1,
    //                             netbanking: 1,
    //                             card: 1,
    //                             wallet: 1,
    //                         },
    //                     },
    //                 };
    //                 try {
    //                     console.log(option)
    //                     const paymentObject = new window.Razorpay(option);
    //                     paymentObject.open();
    //                 } catch (err) {
    //                     console.log(err?.message);
    //                 }
    //             }
    //             return;
    //         }
    //     }
    // };

    // const paymentHandler = async (amount, response) => {
    //     console.log(response);
    //     const data0 = {
    //         planId: data?.plan._id,
    //         pricePaid: amount,
    //         razorPaymentId: response?.razorpay_payment_id,
    //         razorOrderId: response?.razorpay_order_id,
    //         razorSignature: response?.razorpay_signature,
    //     };

    //     if (response) {
    //         const payload = {
    //             vendorSubPlanId: data0?.planId,
    //             pricePaid: data?.totalPrice,
    //             razorOrderId: data0?.razorOrderId,
    //             razorPaymentId: data0?.razorPaymentId,
    //             razorSignature: data0?.razorSignature,
    //             razorpay_plan_id: localStorage.getItem("vendorPlanId"),
    //             razorpay_subscription_id: localStorage.getItem("vendorSubsId")
    //         }
    //         const res = await axios.post("https://pro-api.idesign.market/user/changeVendorPlan", payload, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': authTok
    //             }
    //         }).then((res) => {
    //             console.log(res)
    //             setShow(true);
    //         }).catch(err => console.warn(err))
    //     }
    // };

    const placeOrderOnRazorpay = async () => {
        const amount = data?.totalPrice;
        // const amount = 1
        const res = await createOrder({ amount: parseFloat(amount.toFixed(2)) });

        if (res) {
            const planRes = await createPlan({
                period: "monthly",
                interval: data?.plan.duration,
                planName: data?.plan.name,
                planAmt: parseFloat(amount.toFixed(2)),
                description: "subscription plan",
            });

            localStorage.setItem("vendorPlanId", planRes?.data?.id)
            if (planRes) {
                const x = {
                    planId: planRes?.data?.id,
                    paymentCycles: data?.plan.duration === 1 ? 30 : null,
                    // startAt: (Math.floor(new Date().getTime() / 1000) + 2592000).toString()
                    startAt: (Math.floor(new Date().getTime() / 1000) + 2592000).toString()
                };
                const subRes = await createSubscription(x);
                // console.log(subRes);
                localStorage.setItem("vendorSubsId", subRes?.data?.id)
                if (subRes) {
                    // console.log(subRes);
                    setIsLoading(false);
                    localStorage.setItem("vendorSubsId", subRes?.data?.id)
                    const option = {
                        description: "iDesign payment",
                        currency: "INR",
                        key: RAZOR_PAY_KEY,
                        amount: data?.totalPrice,
                        name: "iDesign.market pvt. ltd.",
                        // order_id: res?.data?.id,
                        subscription_id: subRes?.data?.id,
                        handler: paymentHandler.bind(this, amount),
                        prefill: {
                            name: profileInfo[0]?.data?.data?.companyName,
                            email: profileInfo[0]?.data?.data?.email,
                            contact: profileInfo[0]?.data?.data?.phoneNumber,
                        },
                        notes: {
                            address: `Payment for cart`,
                        },
                        theme: {
                            color: "#3b5998",
                        },
                        recurring: true,
                        checkout: {
                            method: {
                                upi: 1,
                                netbanking: 1,
                                card: 1,
                                wallet: 1,
                            },
                        },
                    };
                    try {
                        const paymentObject = new window.Razorpay(option);
                        paymentObject.open();
                    } catch (err) {
                        console.log(err?.message);
                    }
                }
                return;
            }
        }
    };

    const paymentHandler = async (amount, response) => {
        console.log(response);
        const data0 = {
            planId: data?.plan._id,
            pricePaid: amount,
            razorPaymentId: response?.razorpay_payment_id,
            razorOrderId: response?.razorpay_order_id,
            razorSignature: response?.razorpay_signature,
        };

        if (response) {
            const payload = {
                vendorSubPlanId: data0?.planId,
                pricePaid: data?.totalPrice,
                razorOrderId: data0?.razorOrderId,
                razorPaymentId: data0?.razorPaymentId,
                razorSignature: data0?.razorSignature,
                razorpay_plan_id: localStorage.getItem("vendorPlanId"),
                razorpay_subscription_id: localStorage.getItem("vendorSubsId")
            }
            const res = await axios.post("https://pro-api.idesign.market/user/changeVendorPlan", payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authTok
                }
            }).then((res) => {
                console.log(res)
                setShow(true);
            }).catch(err => console.warn(err))
        }

    };

    const handleShow = async () => {
        setIsLoading(true)
        placeOrderOnRazorpay()
        // const payload = {
        //     vendorSubPlanId: data?.plan._id,
        //     pricePaid: data?.totalPrice,
        //     razorOrderId: "666666",
        //     razorPaymentId: "666666fgfgfhfh",
        //     razorSignature: "ghghgvhgggh",
        //     razorpay_plan_id: "fyfyfytfyy",
        //     razorpay_subscription_id: "dtdtdtdrtrd"
        // }
        // const res = await axios.post("https://pro-api.idesign.market/user/changeVendorPlan", payload, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': authTok
        //     }
        // }).then((res) => {
        //     console.log(res)
        //     setShow(true);
        // }).catch(err => console.warn(err))
    }

    const handleBuy = () => {
        // navigate("/lead")
    }

    // console.log(planid)
    // console.log(subsid)

    // const date = Date.now();
    // console.log(new Date().valueOf())
    // console.log(date + 2592000000)
    // console.log(new Date())
    console.log((Math.floor(new Date().getTime() / 1000) + 2592000).toString())
    // 5184000000

    // console.log((Math.floor(new Date().getTime() / 1000) + 2592000))

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size="md">
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center p-3">
                        <img src={congo} alt="" className="modal-blah" />
                        <h2 className="ven-blah-blah mt-5">Congratulations,</h2>
                        <p className="lan-ven-start mb-3">Your vendor details has been submitted </p>
                        <button className='rate_btn4 mt-5' onClick={handleBuy}>Okay, got it</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={gstOffcan}
                onHide={() => {
                    setGstOffcan(false);
                }}
                centered
            >
                <Modal.Header className="p-0" closeButton style={{ margin: "16px 16px 0 16px" }}>
                    <Modal.Title>GST Information</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0" style={{ margin: "16px" }}>
                    <div style={{ marginBottom: "24px" }}>
                        <div>Company Name</div>
                        <div>
                            <input className="w-100" placeholder="Type company name" name="companyName" value={gstData.companyName} onChange={gstDataInputHandler} />
                            {gstData?.companyName?.length < 1 && <span style={{ fontSize: "12px", color: "red" }}>{showError.companyNameError}</span>}
                        </div>
                    </div>
                    <div style={{ marginBottom: "32px" }}>
                        <div>Registration No.</div>
                        <div>
                            <input className="w-100" maxLength={15} placeholder="Enter Registration No." name="gstNum" value={gstData.gstNum} onChange={gstDataInputHandler} />
                            {gstData?.gstNum?.length < 1 && <span style={{ fontSize: "12px", color: "red" }}>{showError.gstNumError}</span>}
                        </div>
                    </div>
                    <div>
                        <button className="gstModalWebButton" onClick={handleGstSubmit}>
                            Confirm
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <div style={{ minHeight: "100vh", minWidth: "100vw" }}>
                <HeaderNav />
                <div style={{ height: "90vh", display: "flex" }}>
                    <SidebarWebNew />
                    <div style={{ width: "78vw", padding: "1rem", height: "90vh", overflowY: "scroll", background: "white" }} className="myCart-wholeContent">
                        <div className="webMyCart-header">
                            <div style={{ fontSize: "20px", marginBottom: "0.5rem" }} role="button">
                                My Cart
                            </div>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <div style={{ width: "65%", overflow: "auto", marginBottom: "1rem" }} className="myCartMiddle">
                                {/* <div>
                                    {leadsAddedToCart[0]?.data?.data?.leads.map((curElem) => {
                                        const firstLetter = curElem.name.split(" ")[0].split("")[0].toUpperCase();
                                        const secondLetter = curElem?.name.trim().split(" ").length > 1 ? curElem?.name.trim().split(" ")[curElem.name.trim().split(" ").length - 1].split("")[0].toUpperCase() : "";
                                        const initials = firstLetter + secondLetter;
                                        return <WebCartAddedLeadCard data={curElem} initials={initials} />;
                                    })}
                                </div> */}
                                <VendorCartSide />
                            </div>
                            <div className="webCart-pricingPart" >
                                <div className="sections" >
                                    <button className="proceedToBuyButton" onClick={handleShow}>{isLoading ? <Spinner animation="border" /> : " Proceed To Buy"}
                                    </button>
                                </div>
                                <div className="sections" style={{ padding: "32px 24px 16px 20px", border: "1px solid #DFDFDF" }} >
                                    <div style={{ fontSize: "16px", fontWeight: "400", marginBottom: "16px" }}>Price Details</div>

                                    <div className="d-flex justify-content-between">
                                        <div style={{ fontSize: "14px", fontWeight: "700" }}>Price (1)</div>
                                        <div style={{ fontSize: "14px", fontWeight: "400" }}>₹ {data?.planPrice}</div>
                                    </div>

                                    {/* <div className="d-flex justify-content-between">
                                        <div style={{ fontSize: "14px", fontWeight: "700" }}>Discount</div>
                                        <div style={{ fontSize: "14px", fontWeight: "400", color: "#176091" }}>- ₹ 1111</div>
                                    </div> */}

                                    <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "16px", marginBottom: "16px" }}>
                                        <div style={{ fontSize: "14px", fontWeight: "700" }}>GST- 18%</div>
                                        <div style={{ fontSize: "14px", fontWeight: "400" }}>₹ {data?.gst}</div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div style={{ fontSize: "16px", fontWeight: "700" }}>Total Price</div>
                                        <div style={{ fontSize: "14px", fontWeight: "400" }}>₹ {data?.totalPrice}</div>
                                    </div>
                                </div>

                                <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <div
                                                className="customCheckbox"
                                                style={{ marginRight: "12px", userSelect: "none" }}
                                                onClick={() => {
                                                    setGstOffcan(true);
                                                }}
                                                role="button"
                                            >
                                                {profileInfo[0]?.data?.data?.gstNumber ? <img src={check} /> : null}
                                            </div>
                                        </div>
                                        <div>
                                            I have a GST number <span>(Optional)</span>
                                        </div>
                                    </div>
                                    {profileInfo[0]?.data?.data?.gstCompanyName && profileInfo[0]?.data?.data?.gstNumber ? <div>
                                        <div className="gstInfoShowingBox" style={{ padding: "12px 16px 12px 12px" }}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                                    <img src={gstDoc} />
                                                </div>
                                                <div>
                                                    <div style={{ fontSize: "12px", fontWeight: "700" }}>{profileInfo[0]?.data?.data?.gstCompanyName}</div>
                                                    <div style={{ fontSize: "12px", fontWeight: "700" }}>{profileInfo[0]?.data?.data?.gstNumber}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <img
                                                    src={editIcon}
                                                    role="button"
                                                    onClick={() => {
                                                        setGstOffcan(true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorCart;
