import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ScheduleADemo from "./ScheduleADemo";
import styles from "./ProductsCss/productSec6.module.css";
import icon1 from "./ProductsImg/accounts/credit_score.svg";
import icon2 from "./ProductsImg/accounts/currency_exchange.svg";
import icon3 from "./ProductsImg/accounts/newspaper.svg";
// import img6 from "./ProductsImg/accounts/ace.svg";

const ProductSection6 = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className={styles.container} id="aco">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>ACCOUNTS</div>
            <div className={styles.file_para}>
            Monitor expenses, identify areas for cost savings, and ensure  <br /> that accounting is linked with critical project milestones.
            </div>
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Create payment <br />
                  Requests
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Track Expenses <br />& Receipts
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>Create <br /> Invoices</div>
              </div>
            </div>
          </div>
          <div className={styles.img_div}>
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/AccNew.svg" alt="" className={styles.mainImg} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection6;
