import React, { useEffect, useState } from 'react'
import plus from './3dImages/plus2.svg'
import downArrow from './3dImages/downArrow.svg'
import cross from './3dImages/cross.svg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-hot-toast";
import { constants } from '../constants';

const SelectRoom = () => {
    const [inputState, setInputState] = useState({ living: "", bedroom: "", bathroom: "", kitchen: "", room1: "", room2: "", room3: "", room4: "" })
    const [counter, setCounter] = useState({ lcount: 0, bcount: 0, bacount: 0, kcount: 0, room1: 0, room2: 0, room3: 0, room4: 0 })
    const [addMoreCount, setAddMoreCount] = useState(0)
    const [bool1, setBool1] = useState(false)
    const [bool2, setBool2] = useState(false)
    const [bool3, setBool3] = useState(false)
    const [bool4, setBool4] = useState(false)
    const [count, setCount] = useState(0)
    const [initial, setInitial] = useState(false)
    const room_id = useSelector((state) => state.threedReducer.roomID)
    const [error, setError] = useState("")

    const handleChange = (e) => {

        if (inputState.living.length > 0 && counter.lcount === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    lcount: counter.lcount + 1
                }
            })
        }
        if (inputState.bedroom.length > 0 && counter.bcount === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    bcount: counter.bcount + 1
                }
            })
        }
        if (inputState.bathroom.length > 0 && counter.bacount === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    bacount: counter.bacount + 1
                }
            })
        }
        if (inputState.kitchen.length > 0 && counter.kcount === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    kcount: counter.kcount + 1
                }
            })
        }
        if (inputState.room1.length > 0 && counter.room1 === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    room1: counter.room1 + 1
                }
            })
        }
        if (inputState.room2.length > 0 && counter.room2 === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    room2: counter.room2 + 1
                }
            })
        }
        if (inputState.room3.length > 0 && counter.room3 === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    room3: counter.room3 + 1
                }
            })
        }
        if (inputState.room4.length > 0 && counter.room4 === 0) {
            setCounter((prev) => {
                return {
                    ...prev,
                    room4: counter.room4 + 1
                }
            })
        }
        else {
            setInputState({
                ...inputState,
                [e.target.name]: e.target.value
            });
            setError("")
        }
    }

    const handleIncrease = (type) => {
        if (type === "lcount") {
            if (counter.lcount < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        lcount: counter.lcount + 1
                    }
                })

            }

        }
        if (type === "bcount") {
            if (counter.bcount < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        bcount: counter.bcount + 1
                    }
                })
            }

        }
        if (type === "bacount") {
            if (counter.bacount < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        bacount: counter.bacount + 1
                    }
                })
            }
        }
        if (type === "kcount") {
            if (counter.kcount < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        kcount: counter.kcount + 1
                    }
                })

            }
        }
        if (type === "room1") {
            if (counter.room1 < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room1: counter.room1 + 1
                    }
                })
            }
        }
        if (type === "room2") {
            if (counter.room2 < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room2: counter.room2 + 1
                    }
                })
            }
        }
        if (type === "room3") {
            if (counter.room3 < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room3: counter.room3 + 1
                    }
                })
            }
        }
        if (type === "room4") {
            if (counter.room4 < 5) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room4: counter.room4 + 1
                    }
                })
            }
        }

    }

    const handleDecrease = (type) => {
        if (type === "lcount") {
            if (counter.lcount > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        lcount: counter.lcount - 1
                    }
                })
            }
        }
        if (type === "bcount") {
            if (counter.bcount > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        bcount: counter.bcount - 1
                    }
                })
            }
        }
        if (type === "bacount") {
            if (counter.bacount > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        bacount: counter.bacount - 1
                    }
                })
            }
        }
        if (type === "kcount") {
            if (counter.kcount > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        kcount: counter.kcount - 1
                    }
                })
            }
        }
        if (type === "room1") {
            if (counter.room1 > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room1: counter.room1 - 1
                    }
                })
            }
        }
        if (type === "room2") {
            if (counter.room2 > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room2: counter.room2 - 1
                    }
                })
            }
        }
        if (type === "room3") {
            if (counter.room3 > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room3: counter.room3 - 1
                    }
                })
            }
        }
        if (type === "room4") {
            if (counter.room4 > 0) {
                setCounter((prev) => {
                    return {
                        ...prev,
                        room4: counter.room4 - 1
                    }
                })
            }
        }

    }

    const handleStateChange = () => {
        if (counter.lcount === 1 && inputState.living.length === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    living: "Living/Dining"
                }
            })
            setError("")
        }
        if (counter.bcount === 1 && inputState.bedroom.length === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    bedroom: "Bedroom"
                }
            })
            setError("")
        }
        if (counter.bacount === 1 && inputState.bathroom.length === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    bathroom: "Bathroom"
                }
            })
            setError("")
        }
        if (counter.kcount === 1 && inputState.kitchen.length === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    kitchen: "Kitchen"
                }
            })
            setError("")
        }
        if (counter.lcount === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    living: ""
                }
            })
        }
        if (counter.bcount === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    bedroom: ""
                }
            })
        }
        if (counter.bacount === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    bathroom: ""
                }
            })
        }
        if (counter.kcount === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    kitchen: ""
                }
            })
        }
        if (counter.room1 === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    room1: ""
                }
            })
        }
        if (counter.room2 === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    room2: ""
                }
            })
        }
        if (counter.room3 === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    room3: ""
                }
            })
        }
        if (counter.room4 === 0) {
            setInputState((prev) => {
                return {
                    ...prev,
                    room4: ""
                }
            })
        }
    }

    const addMoreInputs = () => {
        setAddMoreCount(addMoreCount + 1)
        setBool1(true)
        if (addMoreCount === 1) {
            setBool2(true)
        }
        if (addMoreCount === 2) {
            setBool3(true)
        }
        if (addMoreCount === 3) {
            setBool4(true)
        }
    }
    const removeInputField = (field) => {
        if (field === "del1") {
            setBool1(false)
            setAddMoreCount(addMoreCount - 1)

            setCounter((prev) => {
                return {
                    ...prev,
                    room1: 0
                }
            })
            setInputState((prev) => {
                return {
                    ...prev,
                    room1: ""
                }
            })
        }
        if (field === "del2") {
            setBool2(false)
            setAddMoreCount(addMoreCount - 1)
            setCounter((prev) => {
                return {
                    ...prev,
                    room2: 0
                }
            })

            setInputState((prev) => {
                return {
                    ...prev,
                    room2: ""
                }
            })
        }
        if (field === "del3") {
            setBool3(false)
            setAddMoreCount(addMoreCount - 1)
            setCounter((prev) => {
                return {
                    ...prev,
                    room3: 0
                }
            })

            setInputState((prev) => {
                return {
                    ...prev,
                    room3: ""
                }
            })
        }
        if (field === "del4") {
            setBool4(false)
            setAddMoreCount(addMoreCount - 1)
            setCounter((prev) => {
                return {
                    ...prev,
                    room4: 0
                }
            })
            setInputState((prev) => {
                return {
                    ...prev,
                    room4: ""
                }
            })
        }
    }

    useEffect(() => {
        handleStateChange()
    }, [counter])

    useEffect(() => {
        localStorage.removeItem("livingInput1")
        localStorage.removeItem("bedroomInput1")
        localStorage.removeItem("bathroomInput1")
        localStorage.removeItem("kitchenInput1")
        localStorage.removeItem("dInput1")
        localStorage.removeItem("dInput2")
        localStorage.removeItem("dInput3")
        localStorage.removeItem("dInput4")
        localStorage.removeItem("room_ids")
    }, [])

    localStorage.setItem("livingInput1", inputState.living)
    localStorage.setItem("bedroomInput1", inputState.bedroom)
    localStorage.setItem("bathroomInput1", inputState.bathroom)
    localStorage.setItem("kitchenInput1", inputState.kitchen)
    localStorage.setItem("dInput1", inputState.room1)
    localStorage.setItem("dInput2", inputState.room2)
    localStorage.setItem("dInput3", inputState.room3)
    localStorage.setItem("dInput4", inputState.room4)

    const totalPrice = (counter.lcount + counter.bcount + counter.bacount + counter.kcount + counter.room1 + counter.room2 + counter.room3 + counter.room4) * 1000

    const navigate = useNavigate()

    const arr = [
        { name: localStorage.getItem("livingInput1"), views: counter.lcount, id: 1, stateName: "state1" },
        { name: localStorage.getItem("bedroomInput1"), views: counter.bcount, id: 2, stateName: "state2" },
        { name: localStorage.getItem("bathroomInput1"), views: counter.bacount, id: 3, stateName: "state3" },
        { name: localStorage.getItem("kitchenInput1"), views: counter.kcount, id: 4, stateName: "state4" },
        { name: localStorage.getItem("dInput1"), views: counter.room1, id: 5, stateName: "state5" },
        { name: localStorage.getItem("dInput2"), views: counter.room2, id: 6, stateName: "state6" },
        { name: localStorage.getItem("dInput3"), views: counter.room3, id: 7, stateName: "state7" },
        { name: localStorage.getItem("dInput4"), views: counter.room4, id: 8, stateName: "state8" }
    ]

    const dispatch = useDispatch()

    const handleNext = async () => {
        if (localStorage.getItem("livingInput1").length > 0 || localStorage.getItem("bedroomInput1").length > 0 || localStorage.getItem("bathroomInput1").length > 0 || localStorage.getItem("kitchenInput1").length > 0 || localStorage.getItem("dInput1").length > 0 || localStorage.getItem("dInput2").length > 0 || localStorage.getItem("dInput3").length > 0 || localStorage.getItem("dInput4").length > 0) {
            localStorage.setItem("tick2", true)
            const newArr = arr.filter((currElem) => {
                return currElem.name !== "" && currElem.views !== 0
            })
            // console.log(newArr)

            // navigate("/3dMakePPT")
            const nArr = newArr.map((data) => {
                const d = data.name;
                return d;
            });
            // const idArr = newArr.map((data) => {
            //     const _id = data.id;
            //     return _id;
            // });
            //  console.log(idArr)
            // localStorage.setItem("filteredRoom", JSON.stringify(idArr))


            const vArr = newArr.map((data) => {
                const d = data.views;
                return d;
            });
            const sArr = newArr.map((data) => {
                const d = data.stateName;
                return d;
            });

            // console.log(sArr)
            // console.log(nArr)

            const mainArr = { nArr, vArr, sArr }
            const name = mainArr.nArr
            const views = mainArr.vArr
            const states = mainArr.sArr

            await axios.put(`${constants.threeDApi}/api/room/add-rooms?id=${localStorage.getItem("apiId")}`, { name, views, states }
            ).then((res) => {
                const RoomID = res.data.room.map((item) => {
                    const a = item._id;
                    const nO = {
                        id: a
                    }
                    return nO
                    //  dispatch(roomID(a))
                })
                // console.log(RoomID)
                localStorage.setItem("room_ids", JSON.stringify(RoomID))
                localStorage.setItem('key', false)
                // console.log(JSON.parse(localStorage.getItem("room_ids")))
                console.warn(res)
                navigate("/3dMakePPT")
                setError("")
            }).catch((err) => setError(err))
        }
        else {
            // alert("mo")
            // toast.error("Select atleast 1 view")
            setError("Select a room first")
        }
    }

    const handleBack = () => {
        navigate("/3dUploadCad")
        localStorage.removeItem("tick")
    }
    // console.log(JSON.parse(localStorage.getItem("filteredRoom"))[0] === "Bathroom")

    return (
        <>
            <div className="d-flex flex-column" style={{height: "100%"}}>
            <div className="selectRoom">
                <div className="selectRoom1">
                    <div className="selectRoom2">
                        <div className="select_left">
                            <h4 className="select_name">Room Name</h4>
                            <input type="text" value={inputState.living} name="living" className={error === "" ? "select_text" : "select_text-err"} placeholder='Living/Dining' onChange={handleChange} />
                            <input type="text" value={inputState.bedroom} name="bedroom" className={error === "" ? "select_text" : "select_text-err"} placeholder='Bedroom' onChange={handleChange} />
                            <input type="text" value={inputState.bathroom} name="bathroom" className={error === "" ? "select_text" : "select_text-err"} placeholder='Bathroom' onChange={handleChange} />
                            <input type="text" value={inputState.kitchen} name="kitchen" className={error === "" ? "select_text" : "select_text-err"} placeholder='Kitchen' onChange={handleChange} />
                            {bool1 ? <> <input type="text" name="room1" value={inputState.room1} className={error === "" ? "select_text" : "select_text-err"} placeholder='Enter room name' onChange={handleChange} /><img src={cross} alt="" className="cross_img2 float-end" style={{
                                width: "16px", height: "20px", marginLeft: "auto", position: "relative", top: "-2.2rem", left: "-1.4rem"
                            }} onClick={() => removeInputField("del1")} /></> : ""}
                            {bool2 ? <><input type="text" name="room2" value={inputState.room2} className={error === "" ? "select_text" : "select_text-err"} style={{ marginTop: "-19px" }} placeholder='Enter room name' onChange={handleChange} /><img src={cross} alt="" className="cross_img2 float-end" style={{
                                width: "16px", height: "20px", marginLeft: "auto", position: "relative", top: "-2.2rem", left: "-1.4rem"
                            }} onClick={() => removeInputField("del2")} /></> : ""}
                            {bool3 ? <><input type="text" name="room3" value={inputState.room3} className={error === "" ? "select_text" : "select_text-err"} style={{ marginTop: "-19px" }} placeholder='Enter room name' onChange={handleChange} /><img src={cross} alt="" className="cross_img2 float-end" style={{
                                width: "16px", height: "20px", marginLeft: "auto", position: "relative", top: "-2.2rem", left: "-1.4rem"
                            }} onClick={() => removeInputField("del3")} /></> : ""}
                            {bool4 ? <><input type="text" name="room4" value={inputState.room4} className={error === "" ? "select_text" : "select_text-err"} style={{ marginTop: "-19px" }} placeholder='Enter room name' onChange={handleChange} /><img src={cross} alt="" className="cross_img2 float-end" style={{
                                width: "16px", height: "20px", marginLeft: "auto", position: "relative", top: "-2.2rem", left: "-1.4rem"
                            }} onClick={() => removeInputField("del4")} /></> : ""}

                        </div>
                        <div className="select_right">
                            <h4 className="select_name">No. of Views</h4>
                            <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("lcount")}>-</button>
                                <p className="increment_0">{counter.lcount}</p>
                                <button className="inc_1" onClick={() => handleIncrease("lcount")}>+</button>
                            </div>
                            <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("bcount")}>-</button>
                                <p className="increment_0">{counter.bcount}</p>
                                <button className="inc_1" onClick={() => handleIncrease("bcount")}>+</button>
                            </div>
                            <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("bacount")}>-</button>
                                <p className="increment_0">{counter.bacount}</p>
                                <button className="inc_1" onClick={() => handleIncrease("bacount")}>+</button>
                            </div>
                            <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("kcount")}>-</button>
                                <p className="increment_0">{counter.kcount}</p>
                                <button className="inc_1" onClick={() => handleIncrease("kcount")}>+</button>
                            </div>
                            {bool1 ? <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("room1")}>-</button>
                                <p className="increment_0">{counter.room1}</p>
                                <button className="inc_1" onClick={() => handleIncrease("room1")}>+</button>
                            </div> : ""}
                            {bool2 ? <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("room2")}>-</button>
                                <p className="increment_0">{counter.room2}</p>
                                <button className="inc_1" onClick={() => handleIncrease("room2")}>+</button>
                            </div> : ""}
                            {bool3 ? <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("room3")}>-</button>
                                <p className="increment_0">{counter.room3}</p>
                                <button className="inc_1" onClick={() => handleIncrease("room3")}>+</button>
                            </div> : ""}
                            {bool4 ? <div className="inc_btns">
                                <button className="dec_1" onClick={() => handleDecrease("room4")}>-</button>
                                <p className="increment_0">{counter.room4}</p>
                                <button className="inc_1" onClick={() => handleIncrease("room4")}>+</button>
                            </div> : ""}
                        </div>

                    </div>

                    <button className="add_moreRoom" onClick={addMoreInputs}><img src={plus} alt="" style={{ height: "14px", width: "14px", paddingRight: "5px" }} /> Add Room</button>
                    <div className='d-flex flex-column my-4 add_main_tab' style={{
                        width: "81%", background: "#F0F0F0",
                        border: " 1px solid #DFDFDF",
                        borderRadius: " 4px",
                        padding: "1rem"
                    }}>
                        <div className={(count == 0) ? 'dummy_main' : (count % 2 == 0) ? 'dummy_tab' : 'dummy_tab_anim'}>
                            <div className={(count % 2 == 0) ? 'addRoom_total2 pb-2' : 'addRoom_total2_anim pb-2'}>
                                <p className="add-view-rooms d-flex flex-column justify-content-between mb-2">Rooms
                                    {arr.map((item, index) => (item.views > 0 ? <p className="add-view-rooms mb-0">{item.name}</p> : ""))}
                                </p>
                                <p className="add-view-rooms d-flex flex-column justify-content-around mb-2">Views
                                    {arr.map((item, index) => (item.views > 0 ? <p className="add-view-rooms mb-0 text-align-center">0{item.views}</p> : ""))}
                                </p>
                                <p className="add-view-rooms d-flex flex-column justify-content-between mb-2">Price
                                    {arr.map((item, index) => (item.views > 0 ? <p className="add-view-rooms2 mb-0">₹{(item.views) * 1000}/-</p> : ""))}</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between" >
                            <p className="addRoom_tot1 mb-0">Total Amount :</p>
                            <p className="addRoom_tot2 mb-0">₹ {totalPrice}/-</p>
                        </div>
                    </div>
                    <img src={downArrow} alt="" className={(count % 2 == 0) ? 'down_select-btn' : 'down_select-btn_anim'} onClick={() => {
                        setCount(count + 1)
                        setInitial(true)
                    }} />

                </div>
                {error !== "" ? <span className='err-line2'>{error}</span> : ""}
            </div>
            <div className="tab_button">
                <button className="tab_next2" onClick={handleNext}>Next</button>
                {/* <button className="tab_back" onClick={handleBack}>Back</button> */}
            </div>
        </div>
        </>
    )
}

export default SelectRoom