import React, { useContext, useState } from "react";
import image from "../../Images/Group.svg";
import f1 from "../../Images/f1.svg";
import f2 from "../../Images/f2.svg";
import f3 from "../../Images/f3.svg";
import addProjectIcon1 from "../../Images/addProjectIcon1.svg";
import addProjectIcon2 from "../../Images/addProjectIcon2.svg";
import addProjectIcon3 from "../../Images/addProjectIcon3.svg";
import "../../App.css";
import AddProjectDetailsWeb from "./AddProjectDetailsWeb";
import { Link, useNavigate } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { OnboardingContext } from "../../Context/Context";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setProjectDetailsRedux } from "../../Lms/Actions";
import client from "../../Images/client.svg";
import buildTrust from "../../Images/buildTrust.svg";
import portray from "../../Images/portray.svg";

export default function AddProjectWeb() {
  const [addProject, setAddProject] = useState(false);
  // const [projectDetails, setProjectDetails] = useState({ name: "", city: "" });
  // const context = useContext(OnboardingContext);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ name: "", city: "" });

  function handleNext(data) {
    localStorage.removeItem("path");
    dispatch(setProjectDetailsRedux(data.name, data.city));
    navigateTo("/addprojectpics");
  }

  return (
    <>
      <Modal className="addProjectModalPopup" centered show={addProject} onHide={() => { setAddProject(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleNext)}>
            <div style={{ margin: "16px 0" }}>
              <div style={{ marginBottom: "8px" }}>Project Name</div>
              <div>
                <Form.Control
                  className="w-100"
                  style={{ fontSize: "15px", fontWeight: "400" }}
                  {...register("name", {
                    required: true,
                  })}
                  name="name"
                  type="text"
                  placeholder="Enter Project Name" />
              </div>
            </div>
            <div style={{ margin: "0 0 16px 0" }}>
              <div style={{ marginBottom: "8px" }}>City</div>
              <div>
                <Form.Control
                  className="w-100"
                  style={{ fontSize: "15px", fontWeight: "400" }}
                  {...register("city", {
                    required: true,
                  })}
                  name="city"
                  type="text"
                  placeholder="Enter City"
                />
              </div>
            </div>
            <div style={{ margin: "0 0 16px 0" }}>
              <button type="submit" style={{ width: "100%", border: "none", backgroundColor: "#176091", color: "#FFFFFF", padding: "8px 16px", borderRadius: "8px" }}>Next</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="main-professionContainer-web d-flex justify-content-center align-items-center">
        <div style={{ background: "white", borderRadius: "10px" }} className="main-div row">
          <div className="main-addProject-web">
            <div>
              <div style={{fontSize: "20px", marginBottom: "16px", fontWeight: "600", display: "flex", justifyContent: "center"}}>You are almost there!</div>
              <div className="d-flex justify-content-center"><img style={{height: "120px"}} src={image} /></div>
            </div>
            <div>
              <h6 style={{ textAlign: "center", color: "#00000", opacity: "0.4" }}>
                Add Projects & Unlock Free Access To Client Queries
              </h6>
              <div className="features-web">
                <div className="feature-web">
                  <div className="imgContainer-web">
                    <img src={client} style={{height: "56px"}} />
                  </div>
                  <p style={{ color: "#00000", opacity: "0.5", fontWeight: "600" }}>
                    Get 3X more Clients
                  </p>
                </div>
                <div className="feature-web">
                  <div className="imgContainer-web">
                    <img src={portray} style={{height: "56px"}} />
                  </div>
                  <p style={{ color: "#00000", opacity: "0.5", fontWeight: "600" }}>
                    Portray your Experience
                  </p>
                </div>
                <div className="feature-web">
                  <div className="imgContainer-web" >
                    <img src={buildTrust} style={{height: "56px"}} />
                  </div>
                  <p style={{ color: "#00000", opacity: "0.5", fontWeight: "600" }}>
                    Build trust with Clients.
                  </p>
                </div>
              </div>
            </div>
            {/* <button onClick={()=>{setAddProject(true)}} className="addProjectbtn">Add Project</button> */}
            <div className="btn-container-web">
              <Link to="/plans">
                <button onClick={localStorage.removeItem("path")} className="skipbtn">Skip</button>
              </Link>

              <button
                onClick={() => {
                  setAddProject(true);
                }}
                className="nextbutton1-web"
              >
                Add Project
              </button>
            </div>
            {/* {addProject && <AddProjectDetailsWeb setAddProject={setAddProject} />} */}
          </div>
        </div>
      </div>
    </>
  )
}