import React from "react";
import { useState } from "react";
import img from "../Images/addImageIcon.png";
import editImage from "../Images/editIcon.png";
import saveImage from "../Images/bx_save.svg";
import AddProjectDetails from "./AddProjectDetails";
import { useContext } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { OnboardingContext } from "../Context/Context";
import { useRef } from "react";
import AddProjectPicsDesignerWeb from "./OnboardingWebScreens/AddProjectPicsDesignerWeb";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addMoreProjectImages, addProjectImages, setProjectDetailsRedux } from "../Lms/Actions";
import { useEffect } from "react";

export default function AddProjectPics() {
  const dispatch = useDispatch();
  const addImageRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const projName = useSelector((state) => state.addToCartReducer.projectDetails);
  const [title, setTitle] = useState(projName?.name);
  const navigateTo = useNavigate();

  function handlePics(e) {
    let obj = e.target.files;
    let filesArr = [];
    for (let i = 0; i < obj.length; i++) {
      filesArr.push({ file: obj[i], path: URL.createObjectURL(obj[i]) });
    }
    dispatch(addProjectImages(filesArr))
    navigateTo("/saveoredit");
  }

  function handleClick() {
    setIsEditMode((prev) => !prev);
  }

  function handleTitle(e) {
    setTitle(e.target.value);
  }

  const saveProjectTitle = () => {
    dispatch(setProjectDetailsRedux(title, projName?.city))
  }

  const clickToAddImages = () => {
    addImageRef.current.click();
  }

  // useEffect(() => {
  //   setTitle(projName?.name);
  // }, [projName.name])

  // useEffect(() => {
  //   if(!title){
  //     navigateTo("/addproject")
  //   }
  // }, [])

  return (
    <>
      {window.screen.width > "768" ?
        <AddProjectPicsDesignerWeb />
        :
        <div className="addProjectPics-container">
          <div className="main-addprojectpicsContainer">
            <div className="header w-100">
              {!isEditMode && <div style={{ marginLeft: "5%", fontSize: "16px", fontWeight: "400" }}>{projName?.name}</div>}
              {isEditMode && <input onChange={handleTitle} type="text" value={projName?.name} />}
              <button onClick={handleClick}>
                {isEditMode ? <img src={saveImage} onClick={saveProjectTitle} /> : <img src={editImage} alt="" />}
              </button>
            </div>
            <div className="body" onClick={clickToAddImages}>
              <button className="clicktoaddpics">
                <input className="d-none" onChange={handlePics} ref={addImageRef} type="file" multiple /> <img src={img} />
              </button>
              <div style={{ fontSize: "14px", fontWeight: "400", margin: "40px 0 4px 0" }}>Please upload your project photos</div>
              <div style={{ fontSize: "14px", fontWeight: "400" }}> Accepted format jpeg,png </div>
            </div>
            <div></div>
            {/* {isEditMode && <AddProjectDetails />} */}
          </div>
        </div>
      }
    </>
  );
}
