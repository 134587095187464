import React, { useState } from 'react'
import makeone from './3dImages/MakeOne.svg'
import uploadAPdf from './3dImages/uploadAPdf.svg'
import skip from './3dImages/skip.svg'
import UploadReference0 from './UploadReference0'
import UploadReference1 from './UploadReference1'
import { useNavigate } from 'react-router-dom'
import { clearAll } from '../Lms/Actions/threedActions'
import { useDispatch } from 'react-redux'

const UploadRefMain = () => {
    const [pdf, setPdf] = useState(false)
    const [makeOne, setMakeOne] = useState(false)
    const [current, setCurrent] = useState(true)
    const [later, setLater] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleUpload = () => {
        localStorage.removeItem("saveLater")
        navigate("/3dMakePPT/MakeOne")
    }
    const handleUpload2 = () => {
        localStorage.removeItem("saveLater")
        navigate("/3dMakePPT/UploadPdf")
    }

    const handleLater = () => {
        localStorage.setItem("saveLater", true)
        localStorage.removeItem('key')
        localStorage.removeItem("livingInput1")
        localStorage.removeItem("bedroomInput1")
        localStorage.removeItem("bathroomInput1")
        localStorage.removeItem("kitchenInput1")
        localStorage.removeItem("dInput1")
        localStorage.removeItem("dInput2")
        localStorage.removeItem("dInput3")
        localStorage.removeItem("dInput4")
        // localStorage.removeItem("room_ids")
        dispatch(clearAll())
        navigate("/3dCart")
        // localStorage.removeItem("saveLater")
    }


    return (
        <>
            <div className="mainRender">
                <p className="mainRender_header mb-4">Upload/Add a reference images for each element of 3D</p>
                <p className="mainRender_byline">(This will help in getting work done faster)</p>
                <div className="mainRender2">
                    <div className="mainRen_comp1 p-3" onClick={handleUpload}>
                        <img src={makeone} className="upload_icons" alt="" />
                        <div className="makeOne_lines px-2">
                            <p className='makOne_line1' >Make one right now</p>
                            <p className="makOne_line2">Help in getting 3D done in 2 days</p>
                        </div>
                    </div>
                    <p className="makeOne_or">OR</p>
                    <div className="mainRen_comp2 p-3" onClick={handleUpload2}>
                        <img src={uploadAPdf} className="upload_icons" alt="" />
                        <div className="makeOne_lines px-3">
                            <p className="makOne_line1_2">Upload a PDF</p>
                            <p className="makOne_line2_2">to be delivered in 3 days</p>
                        </div>
                    </div>
                </div>
                <a className="my-5 py-5" onClick={handleLater} style={{
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#176091", textDecoration: "none", cursor: "pointer"
                }}>I will do it later</a>
            </div>

        </>
    )
}

export default UploadRefMain;