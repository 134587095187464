import React, { useRef, useState, useEffect } from "react";
import styles from "./PricingMain.module.css";
import Testimonials from "./Testimonials";

import Icon from "./Images/Icon.svg";
import right from "./Images/right.svg";
import web from "./Images/web.svg";
import app from "./Images/app.svg";
import pro from "./Images/pro.svg";
import caretRight from "./Images/CaretRight.svg";
import checkCircle from "./Images/CheckCircle.svg";
import styless from "./BoxOne.module.css";
import {mockDataBoxOne} from "./mockData";
import {additionalFeatures} from "./mockData";


import QAndA from "./QAndA";
import info from "./Images/Info.svg";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import OtherCompanies from "./OtherCompanies";
import BoxOne from "./BoxOne";
import FooterPricing from "./FooterPricing";
import LoginHeader from "../LoginHeader";
import {mockArray} from "./mockData";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Dubai from "./Dubai";
import Sydney from "./Sydney";
import LocationComponent from "./LocationComponent";
import NewTestimonials from "../../../../NewHome/NewTestimonials";
import NewTestimonialsMobile from "../../../../NewHome/NewTestimonialsMobile";
import NewFooter from "../../../../NewHome/NewFooter";
import Header from "../../../../NewHome/Header";
import CryptoJS from "crypto-js";
import HamburgerPage from "../../../../NewHome/hamburgerMenus/HamburgerPage";
import HeaderMob from "../../../../NewHome/HeaderMob";
import SecondComponent from "../PricingPage/PricingPageNewMob/SecondComponent";



const SingleDubaipage = () => {
  const [openHamburger, setOpenHamburger] = useState(false);
localStorage.setItem("fromCountry","Dubai")


  const [isTalkBtnClicked,setIsTalkBtnClicked] = useState(false)
  const scrollRef=useRef();
 const navigateTo=useNavigate();
 const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
 const updateMedia = () => {
   setIsDesktop(window.screen.width > 767);
 };

 useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
});
 const [price, setPrice] = useState("6999");
 const [selectedBillingType, setSelectedBillingType] = useState("Quarterly");
 const [changePrice, setchangePrice] = useState("quarter");
 const [plan, setPlan] = useState("3 months");
 const handleBillingTypeChange = (event) => {
   const newBillingType = event.target.value;
   setSelectedBillingType(newBillingType);
   if (newBillingType === 'Quarterly') {
       setPrice(6999);
localStorage.setItem("price", 6999)
     } else if (newBillingType === 'Yearly') {
       setPrice(21999);
      localStorage.setItem("price", 21999)
     }
 };


 const handlemobbiltypechange=()=>{



 }
 useEffect(() => {
  localStorage.setItem("paymentPlan",selectedBillingType )
  localStorage.setItem("price", price);
}, [price]);
 const scrollToTop=()=>{
   window.scrollTo({
     top:0,
   });
 }
 
 useEffect(()=>{
  scrollToTop()
 })
 
 let lastScrollTop = 0;
 
 // window.addEventListener('scroll', function() {
 //   var hiddenDiv = document.getElementById('hiddenDiv');
 //   var position = hiddenDiv.getBoundingClientRect();
 //   var distanceFromTop = position.top + window.scrollY;
 //   var st = window.pageYOffset || document.documentElement.scrollTop;
 
 //   if (st < lastScrollTop) {
 //     // User is scrolling up, hide the hiddenDiv
 //     hiddenDiv.style.display = 'none';
 //   } else {
 //     if (distanceFromTop <= 85) {
 //       hiddenDiv.style.display = 'block';
 //     }else if (position.top <= 85) {
 //       hiddenDiv.style.display = 'block';
 //     }  
 //     else {
 //       hiddenDiv.style.display = 'none'; 
 //     }
 //   }
 //   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
 // });
 
 console.log(openHamburger,"menuvalue")
 function generateHashedLink(token, userId, paymentPlan, fullName, cId, price, password) {
   const encryptedData = {};
   const encryptionKey = "18B69E843399F1A2AFC5853129299";
 
   const encryptField = (field) => {
     const fieldValue = field?.toString() || ''; // Convert value to string, handle undefined
     return CryptoJS.AES.encrypt(fieldValue, encryptionKey).toString();
   };
 
   encryptedData.token = encryptField(token);
   encryptedData.userId = encryptField(userId);
   encryptedData.paymentPlan = encryptField(paymentPlan);
   encryptedData.cId = encryptField(cId);
   encryptedData.fullName = encryptField(fullName);
   encryptedData.price = encryptField(price);
   encryptedData.password = encryptField(password);
 
   const now = new Date();
   const timestamp = now.toISOString();
   const encryptedTimestamp = CryptoJS.AES.encrypt(timestamp, encryptionKey).toString();
   encryptedData.timestamp = encryptedTimestamp;
 
   const encryptKey = (key) => {
     return CryptoJS.AES.encrypt(key, encryptionKey).toString();
   };
   const url = `https://www.idesign.market/payment?${encryptKey("userId")}=${encryptedData.userId}&${encryptKey("cId")}=${encryptedData.cId}&${encryptKey("token")}=${encryptedData.token}&${encryptKey("fullName")}=${encryptedData.fullName}&${encryptKey("price")}=${encryptedData.price}&${encryptKey("paymentPlan")}=${encryptedData.paymentPlan}&${encryptKey("timestamp")}=${encryptedData.timestamp}&${encryptKey("password")}=${encryptedData.password}&currency=AED`;
   return url;
 }
 
      return (
        <>

{isDesktop?<Header/>:
<>
{openHamburger && <HamburgerPage openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />}
{!openHamburger &&(
      <HeaderMob openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />)
      }

</>
}


      
        
        <div>

{/* <Header/> */}


<div className={styles.blueContainer} style={{ paddingBottom: "2.5rem" }}>
        <div className={styles.headingText}>
          Seamless Solutions, Straightforward Pricing
        </div>
       
<div>
        <div className={styless.Mainbox_div} >
          {/* <Box1 /> */}
          <div className={styless.box1}>

            {!isDesktop?
            <div style={{textAlign:"end"}}>

      

          <div
              className="p-1  "
              style={{
                // background: "#FFFFFF",
                border: "1px solid #D9D9D9",
                // borderRadius: "50p",
                marginTop: "0.5rem",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                borderRadius: "3.125rem",
                background: "#538CCE",
                boxShadow: "0px 4px 12px 0px rgba(33, 33, 33, 0.20)",
                // width: "10%",
                // marginBottom:"1rem"
              }}
            >
              <div
                className={
                  plan === "3 months"
                    ? styles.toggleactive
                    : styles.toggleunactive
                }

                onClick={() => {
                  setPlan("3 months");
                  setchangePrice("quarter");
                  setPrice(6999);
                  localStorage.setItem("price", 6999)

                }}
                
           
              >
                Quarterly billing
              </div>

              <div
                className={
                  plan === "Yearly"
                    ? styles.toggleactive
                    : styles.toggleunactive
                }
                onClick={() => {
                  setPlan("Yearly");
                  setchangePrice("yearly");
                  setPrice(21999);
                  localStorage.setItem("price", 21999)
             
                }}
         
              >
                Yearly billing
              </div>


            </div>
            {changePrice === "yearly" ? (
                  <div className={styles.save25btn}>
                    <span className={styles.save25btnText}>Save 20%</span>
                  </div>
              ) : (
                ""
              )}
            </div>:

<div className={styless.contentBox1}>
              <div className={styless.innerHeadingBox1}>Pick your plan</div>
              <div className={styless.billingBox}>
                <label className={styless.billingOption} style={{cursor:"pointer"}}>
                  <input className={styless.radioBtn}
                    type="radio"
                    value="Quarterly"
                    checked={selectedBillingType === "Quarterly"}
                    onChange={handleBillingTypeChange}
                  />
                  <div className={styless.billingTypeText} style={{userSelect:"none"}}>Quaterly Billing</div>
                </label>
  
                <label className={styless.billingOption} style={{cursor:"pointer"}}>
                  <input className={styless.radioBtn}
                    type="radio"
                    value="Yearly"
                    checked={selectedBillingType === "Yearly"}
                    onChange={handleBillingTypeChange}
                  />
                  <div className={styless.billingTypeText} style={{gap:"0.5rem"}}>
                  <span style={{userSelect:"none"}}>Yearly Billing</span>
                  <div className={styless.save25btn}>
                    <span className={styless.save25btnText}>Save 20%</span>
                  </div>
                  </div>
                </label>
              </div>
            </div> 
}
          </div>
          {/* <Box2 />
            <Box3 /> */}
          <div className={styless.box2}>
            <div style={{ display: "flex", gap: "0.5rem" }}>
           
              <div className={styless.buttons}>
                <span className={styless.buttonsText}>Small-mid Teams</span>
              </div>
            </div>
            <div style={{ marginTop: "1.12rem" }}>
              <div className={styless.inBoxHeading}>
              Professional
              <img src={pro} alt="pro" className={styless.proImage} />
              <span className={styless.pro}>Pro</span>
              <div className={styless.mostPopularBtn}><span className={styless.mostPopularText}>Most Popular</span></div>
              </div>
            </div>
            <div style={{marginTop:"2.13rem"}}>
                  <span className={styless.amount}>AED {price.toLocaleString('en-IN')}</span>
                  <span className={styless.afterAmount} style={{marginLeft:"0.5rem"}}>billed monthly</span>
            </div>
            <div style={{marginTop:"2rem"}}>
            <div className={styless.blueButtonContainer}>
                <div className={styless.blueButton} 
                 style={{cursor:"pointer", userSelect:"none"}}
                 onClick={()=>
                 {
                   if (localStorage.getItem("pricing") === "true") {
                   navigateTo("/signup");
                 } else {
               
                  window.location.assign(generateHashedLink(localStorage.getItem("token"),
                  localStorage.getItem("userId"),
                  localStorage.getItem("paymentPlan"),
                  localStorage.getItem("fullName"),
                  localStorage.getItem("cId"),
                  localStorage.getItem("price"),
                  localStorage.getItem("password")
                  )) 
                 }
                 }
                }
                >
                  <div className={styless.blueButtonText}
                   >Get Started</div>
                  <img src={caretRight} className={styless.checkCircle} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div className={styless.inBoxSummary}>
                Unlimited Users | 1 TB Storage limit{" "}
              </div>
            </div>
            <div style={{marginTop:"1.12rem"}}>
            <div style={{marginBottom:"0.5rem"}} className={styless.arrayHeadings}>
             <img src={app} alt="app"/>
             App</div>
              {mockDataBoxOne.map((x, index) => (
                <div key={index} className={styless.arrayData} style={{display:"flex", marginBottom:"0.5rem"}}> <img src={right} alt="right" /> <div>{x}</div></div>
              ))}
            </div>
            <div style={{marginTop:"2.25rem"}}>
            <div style={{marginBottom:"0.5rem"}} className={styless.arrayHeadings}>
             <img src={web} alt="web" style={{verticalAlign:"middle", marginRight:"0.5rem"}} />Web for any browser</div>
             
                  <div className={styless.arrayData}></div>
              <div>
              <div className={styless.arrayData} style={{display:"flex"}}>
              <img src={right} alt="right"  />
              <div>Unlimited free Users</div>
              </div>
              <img src={Icon} alt="" style={{ marginLeft:"28px" ,marginTop:"-20px"}} />
              </div>
               <div className={styless.arrayData}> 
               <img src={right} alt="right"  />
              <div >Inspect designs, Create Timelines and Execute handoffs to Stakeholders</div>
              </div>
              {/* <div className={styless.arrayData} style={{display:"flex"}}>
               <img src={right} alt="right" style={{marginRight:"0.75rem"}} />
                <div>Proper Analytics Visuals </div>
               </div>
              */}
            </div>
            <div className={styless.showFeaturesBtn}
            style={{cursor:"pointer", marginTop:"3rem"}}
             onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}>Show features</div>
          </div>

          
          
          <div className={styless.box2} >
            <div style={{ display: "flex" }}>
              <div className={styless.buttons}>
                <span className={styless.buttonsText}>Large firms</span>
              </div>
            </div>
            <div style={{ marginTop: "1.12rem" }}>
              <div className={styless.inBoxHeading}>Enterprise</div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div className={styless.inBoxSummary}>
                Bespoke system connected with existing platform and databases for
                seamless project management{" "}
              </div>
            </div>
            
            <div style={{marginTop:"1.4rem"}}>
            <div
                className={styles.blueButtonContainer}
                style={{ marginBottom: "2rem" }}
              >
                <div className={styles.blueBorderButton}
                onClick={()=>navigateTo("/contact")}
                style={{cursor:"pointer", userSelect:"none"}}
                >
                  <div className={styles.blueBorderButtonText}>Let's Talk!</div>
                </div>
              </div>
            <div className={styless.additionalFeatures}>Additional features</div>
            <div   >
                {additionalFeatures.map((x,index)=>{
                   return(
                    <>
                    
                    <div className={styless.arrayData} style={{display:"flex",marginBottom:"8px"}}>
                  <img src={right} alt="right"  />
                  <div>{x}</div>
                  </div>
                    
                    </>
                   )
                  //  <div key={index} style={{marginBottom:"0.5rem", display:"flex"}}>
                  // //  <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
                  // // <span style={{marginLeft:"0.75rem", fontSize:"0.875rem", fontWeight:"500"}}>{x}</span>
                  // // </div>

            
                })}
            </div>
            <div style={{marginTop:"1.5rem"}}>
            <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
            <span className={styless.yoursForeverText} style={{marginLeft:"0.75rem"}}>Yours to keep, forever.</span>
          </div>
          <img src={Icon} alt="" style={{marginTop:"-25px", marginLeft:"30px"}} />
          <div classname={styless.allBasicProText} style={{ display:"flex", gap:"0.5rem"}}>
          <img src={checkCircle} alt="right" style={{ verticalAlign:"middle"}} />
          <span >All basic Pro features</span>
          </div>
        
              <div className={styless.showFeaturesBtn}
               style={{cursor:"pointer",  marginTop:"1.7rem"}}
             onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}
              >Show features</div>
          </div>
          </div>
        </div>
      </div>
      </div>
</div>


{isDesktop?<>

<div
      ref={scrollRef}
   
       className={styles.outSecondContainer}
    
  
      >
        <div className={styles.secondContainerFirst} style={{ position: "relative"}}>
          <div style={{ marginTop: "6.5rem", marginBottom:"2.5rem" }}>
            <div className={styles.compareOurPlansTxt}>Compare our plans</div>
          </div>
          <div id="hiddenDiv" className={styles.hiddenDiv} ></div>
          {mockArray.map((category) => (
            <div key={category.heading} className={styles.arrayHeadingContainer}>
              <div className={styles.overlayWhite}></div>
              <div
                className={`${styles.arrayHeading} ${styles.overlay}`}
                style={{padding:"0.44rem 1.25rem" }}> {category.heading} </div>
              {category.subHeadings.map((subHeading) => (
                <div className={styles.arraySubHeadingsContainer}>
                <div
                  key={subHeading.subHead}
                  className={styles.arraySubHeadings} 
                  style={{marginLeft:"1.25rem"}}
                >
                  {subHeading.subHead}
                 <img alt="" src={info} data-tooltip-id="my-tooltip"  data-tooltip-place="right"
                 data-tooltip-content={subHeading.toolTip}
                  style={{cursor:"pointer", marginLeft:"0.56rem"}} />
                </div>
                </div>
              ))}
            </div>
          ))}
           <Tooltip id="my-tooltip" />
        </div>
        <div className={styles.secondContainer}>
          <div
            className={styles.secondContainerBoxes}
            style={{ boxShadow: " 0px 4px 12px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <div
              className={styles.secondContainerBoxHeading}
              // style={{ background: " #0081E8" }}
            >
              <span className={styles.secondContainerBoxHeadingText}>
                Professional
              </span>
            </div>
            <div className={styles.blueButtonContainer} style={{marginTop:"2rem"}}>
              <div className={styles.blueButton}
               style={{cursor:"pointer", userSelect:"none"}}
              onClick={()=>
                {
                  if (localStorage.getItem("pricing") === "true") {
                  navigateTo("/signup");
                } else {
                  // navigateTo(`/payment`);
                  window.location.assign(generateHashedLink(localStorage.getItem("token"),
                  localStorage.getItem("userId"),
                  localStorage.getItem("paymentPlan"),
                  localStorage.getItem("fullName"),
                  localStorage.getItem("cId"),
                  localStorage.getItem("price"),
                  localStorage.getItem("password")
                  )) 
                }
                }
               }>
                <div className={styles.blueButtonText}>Get Started</div>
                <img alt="" src={caretRight} className={styles.checkCircle} />
              </div>
            </div>
            <div>
              {mockArray.map((category) => (
                <div key={category.heading} className={`${styles.arrayHeadingContainer} ${styles.arrayHeadingContainerExtra}`}>
                  <div className={`${styles.arrayHeading} ${styles.arrayHeadingExtra}  ${styles.arrayHeadingExtraShow}`} >
                    {category.heading}
                  </div>
                  {category.subHeadings.map((subHeading) => (
                    <div
                      key={subHeading.subHead}
                      className={styles.arraySubHeadings}
                      style={{ textAlign: "center", marginTop: "27px" }}
                    >
                      {subHeading.pro == "yes" ? (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Check11.svg" className={styles.checkCircle} />
                      ) : (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/XCircle1.svg" className={styles.checkCircle} />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          
          </div>
          <div
            className={styles.secondContainerBoxes}
            style={{ boxShadow: " 0px 4px 12px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <div
              className={styles.secondContainerBoxHeading}
              // style={{ background: "#D261D2" }}
            >
              <span className={styles.secondContainerBoxHeadingText}>
                Enterprise
              </span>
            </div>
            <div
              className={styles.blueButtonContainer}
              style={{ marginTop: "2rem", userSelect:"none"}}
            >
              <div className={` ${isTalkBtnClicked ? styles.blueBorderButtonClicked : styles.blueBorderButton}`} 
               style={{cursor:"pointer"}}
               onClick={()=>{
                setIsTalkBtnClicked(true)
                navigateTo("/contact")
              }
              }
               >
                <div className={styles.blueBorderButtonText}
                >Let's Talk</div>
              </div>{" "}
            </div>
            <div>
              {mockArray.map((category) => (
                <div key={category.heading} className={`${styles.arrayHeadingContainer} ${styles.arrayHeadingContainerExtra}`}>
                <div className={`${styles.arrayHeading} ${styles.arrayHeadingExtra}  ${styles.arrayHeadingExtraShow}`} >
                    {category.heading}
                  </div>
                  {category.subHeadings.map((subHeading) => (
                    <div
                      key={subHeading.subHead}
                      className={styles.arraySubHeadings}
                      style={{ textAlign: "center", marginTop: "27px" }}
                    >
                      {subHeading.ent == "yes" ? (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Check11.svg"  className={styles.checkCircle} />
                      ) : (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/XCircle1.svg"  className={styles.checkCircle} />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
</>:<>
<SecondComponent scrollRef={scrollRef} forScroll="jumpto"/>
</>}

{!openHamburger && <NewFooter />}


        
        </>
      );
}

export default SingleDubaipage