import React, { useEffect } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import LeadsHistory from "./LeadsHistory";
import './style.css'
import { useNavigate } from 'react-router-dom'
import { useState } from "react";
import ThreeDHistory from "./ThreeDHistory";
import MeasurementHistory from "./MeasurementHistory";
import OrderLandingMobile from "./OrderLandingMobile";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";

export default function OrderLanding() {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
        setIsDesktop(window.screen.width > 767);
    };

    const navigate = useNavigate()
    const [lead, setLead] = useState(true);
    const [threeD, setThreeD] = useState(false);
    const [measure, setMeasure] = useState(false);
    const [boq, setBoq] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const handleButton = (buttontype) => {
        if (buttontype == "lead") {
            setLead(true)
            setThreeD(false)
            setMeasure(false)
        }
        if (buttontype == "threeD") {
            setThreeD(true)
            setLead(false)
            setMeasure(false)
        }
        if (buttontype == "measure") {
            setMeasure(true)
            setThreeD(false)
            setLead(false)
        }
        if (buttontype == "boq") {
            setThreeD(false)
            setLead(false)
            setBoq(true)
        }
    }
    return (
        <>
            {isDesktop ? <div className="d-none d-md-block">
                <div>
                    <HeaderNav />
                </div>
                <div className="d-flex w-100" style={{ backgroundColor: '#ffffff' }}>
                    <div>
                        <SidebarWebNew />
                    </div>

                    <div style={{ width: '55%', borderRight: '1px solid #DFDFDF' }}>
                        <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '10px' }}>
                            <div className="heading">My Order</div>
                        </div>

                        <div className="sub-heads" style={{ borderBottom: '1px solid #DFDFDF' }}>
                            <div className="mx-3" style={{ backgroundColor: '#ffffff', display: 'flex', flexDirectionL: 'row', width: '50%' }}>
                                <div role="button" style={{ marginRight: '20px',marginLeft:'10px' }} className={lead ? 'active' : "unactive"} onClick={() => handleButton("lead")}> Leads</div>
                                <div role="button" style={{ marginRight: '20px' }} className={threeD ? 'active' : "unactive"} onClick={() => handleButton("threeD")}>3Ds</div>
                                {/* <div role="button" style={{ marginRight: '10px' }} className={measure ? 'active' : "unactive"} onClick={() => handleButton("measure")} >Measurements</div> */}
                                {/* <div role="button" onClick={() => handleButton("boq")}>BOQ Assistance</div> */}
                            </div>

                        </div>
                        <div>
                            {lead ? <LeadsHistory /> : threeD ? <ThreeDHistory /> : measure ? <MeasurementHistory /> : ''}
                        </div>
                    </div>
                </div>

            </div> : <OrderLandingMobile />}


        </>

    );
}