import contact from "../Assets/Dashboard/contact.svg";
// import bq from "../Assets/Dashboard/bq.svg";
import market from "../Assets/Dashboard/market.svg";
import price from "../Assets/Dashboard/price.svg";
import money from "../Assets/Dashboard/moneyIcon.svg";
import products from "../Assets/Dashboard/productsIcon.svg";
// import message from "../Assets/Dashboard/message.svg";
import premiumIcon from "../Assets/Dashboard/premiumIcon.svg";
import { ROUTES } from "../Router/routes";

export const SIDE_MENU = [
  { label: "Manage Leads", icon: contact, to: '/leads'},
  { label: "Go Premium", icon: premiumIcon, to: '/plans' },
  // { label: "BOQ assistance", icon: bq, to: ROUTES.APP },
  { label: "Magazines", icon: market, to: ROUTES.MAGAZINES, external: true },
  // { label: "Community", to: ROUTES.COMMUNITY, external: true },
  // {
  //   label: "Brand Offers",
  //   icon: price,
  //   to: ROUTES.BRAND_OFFERS,
  //   external: true,
  // },
];

export const TOP_NAV_MENU = [
  { label: "Products", to: ROUTES.PRODUCTS, icon: products, external: false },
  // { label: "Pricing", to: ROUTES.PRICING, icon: money, external: false },
  // {
  //   label: "Brand Offers",
  //   to: ROUTES.BRAND_OFFERS,
  //   icon: price,
  //   external: true,
  // },
  { label: "Magazine", to: ROUTES.MAGAZINES, icon: market, external: true },
  { label: "Community", to: ROUTES.COMMUNITY, external: true },
];
