import { AxiosInstance, NotificationsAPIs } from "./client";

export const getNotifications = (params) => {
  return AxiosInstance()
    .get(NotificationsAPIs.GET_NOTIFICATIONS, { params })
    .then((response) => response.data);
};

export const notificationActions = (payload) => {
  return AxiosInstance()
    .post(NotificationsAPIs.NOTIFICATION_ACTIONS, payload)
    .then((response) => response.data);
};
