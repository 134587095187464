import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
// import bluetick from "../../Images/blueTick.png";
import img1 from "../../Images/DesignerProfessionPic.svg";
import img2 from "../../Images/ContractorProfessionPic.svg";
import img3 from "../../Images/ModularFactoryPic.svg";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import bluetick from "../../Images/boxtick.svg";
import { useLocation } from 'react-router-dom'

const picArr = [
  [img1, "Interior Designer/Architect"],
  [img2, "Contractor"],
  [img3, "Modular Factory", true],
];

export default function ChooseProfessionMob() {
  const [currentIds, setCurrentIds] = useState([]);
  const context = useContext(OnboardingContext)
  const navigateTo = useNavigate()
  const location = useLocation()

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])


  function handleClick(name) {
    setCurrentIds(name)
    // console.log(currentIds);
    context.setData((pre) => ({ ...pre, userData: { ...pre.userData, prof: name } }));
  }

  function handleSubmit() {
    console.log("inside handleSubmit", context.data);
    if (context.data.userData.prof === "Interior Designer/Architect") {
      window.localStorage.setItem("type", 1)
      navigateTo('/profilebuild')
    } else if (context.data.userData.prof === "Contractor") {
      window.localStorage.setItem("type", 2)
      navigateTo('/contractorprofilebuild')
    } else {
      window.localStorage.setItem("type", 3)
      navigateTo('/oemprofilebuild')
    }
  }


  return (
    <div className="d-flex flex-column align-items-center  main-chooseprofession1">
      <div className="oem-saveprojectspics-header">
        <div className="oem-header-main" style={{ fontWeight: "600", fontSize: "26px", marginBottom: "12px" }}>
          {" "}
          Choose your Profession
        </div>
      </div>
      <div className="professions1">
        {picArr.map((pic, i) => {
          return (
            <div
              key={pic[1]}
              onClick={() => handleClick(pic[1])}
              className={`profession1 ${currentIds == pic[1] ? "block-active1" : ""}`}
              style={{ position: "relative" }}
            >
              {currentIds == pic[1] && (
                <img
                  className="bluetick"
                  src={bluetick}
                  style={{
                    position: "absolute",
                    top: "0.2rem",
                    right: "0.2rem",
                    width: "23px",
                    height: "23px"
                  }}
                />
              )}
              <img className="main-pic" id={pic[1]} src={pic[0]} />
              {/* {pic[2] == true ? <p className='coming-soon' style={{position:"absolute",top:"0.5rem",right:"0.7rem",fontSize:"8px"}}>Coming Soon</p>: ''} */}
              <p style={{ fontWeight: "600", color: "#3B5998", fontSize: "16px", textAlign: "center" }}>{pic[1]}</p>
            </div>
          );
        })}
      </div>
      <div className="profession-btn-container">
        {currentIds.length > 0 &&

          <button onClick={handleSubmit} className="profession-nextbutton1">Next</button>
        }
      </div>
    </div>
  );
}
