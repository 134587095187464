import React, { useRef } from "react";
import styles from "../onBoardingCss/createproject.module.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import cloud from "../footerImg/profileList/cloud.svg";
import icon1 from "../footerImg/profileList/Frame 2611596.png";
import icon2 from "../footerImg/profileList/Frame 2611597.svg";
import icon3 from "../footerImg/profileList/Frame 2611598.svg";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateProject = () => {
  const [changecomp, setChangecomp] = useState("change");
  const [img, setImg] = useState([]);
  const uploadRef = useRef(null);

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const uploadImage = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      let url = URL.createObjectURL(event.target.files[i]);
      setImg((prev) => {
        return [...prev, url];
      });
    }
  };
  // console.log(img);
  const navigate = useNavigate();

  // remove image
  const removeImage = (index) => {
    const oldArr = [...img];
    oldArr.splice(index, 1);
    setImg(oldArr);
  };

  // handlechange
  const [input, setinput] = useState({
    projName: "",
  });
  console.log(input);
  const handleinput = (event) => {
    const { name, value } = event.target;
    setinput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  // upload images
  const uploadImgs = async () => {
    await axios
      .post(
        "https://pro-api.idesign.market/api/addProject",
        {
          userId: localStorage.getItem("userId"),
          name: input.projName,
          city: `${localStorage.getItem("city")}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: authTok,
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const uploadImgs = async () => {
  //   let images = new FormData();
  //   for(const image in img){
  //     images.append("image", image);
  //   }
  //   await axios
  //     .put(
  //       `https://pro-api.idesign.market/api/uploadImages?id=${localStorage.getItem(
  //         "_id"
  //       )}`,
  //       {
  //         image: images,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          <div className={styles.contentDiv}>
            <div className={styles.top_head}>
              Upload Project photos & Gain <br /> New clients
            </div>
            <div className={styles.iconsDiv}>
              <img src={icon1} alt="" className={styles.iconImg} />
              <img src={icon2} alt="" className={styles.iconImg} />
              <img src={icon3} alt="" className={styles.iconImg} />
            </div>
          </div>
        </div>
        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <div className={styles.personal_head}>Create Project</div>
              <button
                className={styles.backBtn}
                onClick={() => navigate("/profile")}
              >
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
            </div>

            <input
              type="text"
              placeholder="Project Name"
              className={styles.inputs}
              value={input.projName}
              name="projName"
              onChange={handleinput}
            />
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
              }}
            >
              <div className={styles.uploadImg_head}>Upload Project Images</div>
              {changecomp === "yes" ? (
                <img
                  src="https://idesign-office.s3.amazonaws.com/Frame%202611617.svg"
                  alt=""
                  style={{
                    marginTop: "1rem",
                    width: "7rem",
                    cursor: "pointer",
                    // border:"2px solid red",
                  }}
                  onClick={() => uploadRef.current.click()}
                />
              ) : (
                ""
              )}
            </div>

            {img && img.length > 0 ? (
              <>
                <div
                  style={{
                    // border: "2px solid black",
                    overflowX: "scroll",
                    height: "13rem",
                    width: "80%",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <div style={{ width: "fit-content",display:"flex",gap:"1rem" }}>
                    {img &&
                      img.map((curelem, index) => {
                        return (
                          <>
                            <div
                              style={{
                                height: "8rem",
                                width: "8rem",
                                borderRadius: "12px",
                                border: "1px solid grey",
                                position: "relative",
                                // border:"2px solid red"
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.amazonaws.com/Group%202610961.svg"
                                alt=""
                                style={{
                                  height: "20px",
                                  position: "absolute",
                                  right: "2%",
                                  top: "2%",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeImage(index)}
                              />
                              <img
                                src={curelem}
                                alt=""
                                style={{
                                  borderRadius: "12px",
                                  height: "100%",
                                  overflowX: "hidden",
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              <div
                className={styles.uploadImg_div}
                onClick={() => {
                  uploadRef.current.click();
                  setChangecomp("yes");
                }}
              >
                <img src={cloud} alt="" className={styles.cloudImg} />
              </div>
            )}

            <input
              type="file"
              className="d-none"
              ref={uploadRef}
              onChange={uploadImage}
              multiple
            />

            <button
              className={styles.btn}
              onClick={() => {
                navigate("/few");
                uploadImgs();
              }}
            >
              NEXT
            </button>
            <a
              href="https://www.idesign.market/"
              className={styles.skip}
              style={{ textDecoration: "none" }}
            >
              Skip Project Listing
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProject;
