import React, { useEffect } from "react";
import editImage from "../../Images/editIcon.png";
import { Link } from "react-router-dom";
import img from "../../Images/addMoreImage.png";
import bluetick from "../../Images/blueTick.png";
import { useState } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { addMoreProjectImages, addProjectToArray, cleanTempProjectList, deleteProjectImages, saveEditedProjectImages, setProjectDetailsRedux } from "../../Lms/Actions";
import { useDispatch } from "react-redux";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { BASE_URL } from "../../Config";
import { getToken } from "../../Lms/getToken";


export default function OemSavePhotosMob() {
  const dispatch = useDispatch();
  const [currentIds, setCurrentIds] = useState([]);
  const filesArr = useSelector((state) => state.addToCartReducer.projectImages);
  const tempProjArr = useSelector((state) => state.addToCartReducer.tempProjectList);
  const projName = useSelector((state) => state.addToCartReducer.projectDetails);
  const [title, setTitle] = useState(projName.name);
  const [isEditMode, setIsEditMode] = useState(false);
  const tempProjectList = useSelector((state) => state.addToCartReducer.tempProjectList);
  const userId = localStorage.getItem("userId");
  const authTok = localStorage.getItem("token") ? getToken() : "";



  const navigateTo = useNavigate();

  function handleClick(element) {
    if (!currentIds.includes(element)) {
      setCurrentIds((prev) => {
        return [...prev, element]
      })
    } else {
      setCurrentIds(
        currentIds.filter((curElem) => {
          return curElem !== element;
        })
      )
    }
  }

  function handleDelete(e) {
    dispatch(deleteProjectImages(currentIds));
    setCurrentIds(currentIds.filter((curElem) => {
      return !currentIds.includes(curElem);
    }))
  }

//   function handleSaveProject() {
//     let temp = 0;
//     console.log(tempProjArr);
//     tempProjArr?.forEach((curElem) => {
//       if (curElem.name === projName.name) {
//         temp++;
//       }
//     })
//     if (temp > 0) {
//       dispatch(saveEditedProjectImages(projName.name, filesArr));
//       navigateTo("/projectsummary");
//     }
//     else if (filesArr.length > 0 && temp == 0) {
//       const x = {
//         name: projName?.name,
//         city: projName?.city,
//         images: filesArr
//       }
//       dispatch(addProjectToArray(x))
//       navigateTo("/projectsummary");
//     }
//   }
function handleSaveProject() {
    console.log(1)

    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1920,
          1080,
          "JPEG",
          80,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      });

      console.log(tempProjectList)
      console.log(filesArr)

    //   filesArr.map(async (data) => {
      axios
        .post(
          `${BASE_URL}/api/addwardrob`,
          {
            userId: userId,
          },
          {
            headers: {
              authorization: authTok,
            },
          }
        )
        .then(async (response) => {
          const image = new FormData();
          for (const files of filesArr) {
            var images = await resizeFile(files.file);
            image.append("image", images);
          }
          const id = response.data.data._id;
          await axios
            .put(`${BASE_URL}/api/uploadImages?id=${id}`, image, {
              headers: {
                authorization: authTok,
              },
            })
            .then((response) => {
            //   toast.success("Project uploaded Successfully");
              const featured = new FormData();
              featured.append("featured", images);
              axios
                .put(`${BASE_URL}/api/uploadFeatured?id=${id}`, featured, {
                  headers: {
                    authorization: authTok,
                  },
                })
                .then((res) => {
                  console.log("feature image uploaded successfully");
                })
                .catch((err) => {
                  console.log("error from feature");
                });
            //   toast.success("Projects uploaded successfully");
              dispatch(cleanTempProjectList());
              navigateTo("/oemkitchen");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    // });
  }
  function handleTitle(e) {
    dispatch(setProjectDetailsRedux(e.target.value, projName?.city))
  }

  function handleEdit() {
    setIsEditMode((prev) => !prev);
  }

  function handlePics(e) {
    let obj = e.target.files;
    for (let i = 0; i < obj.length; i++) {
      dispatch(addMoreProjectImages({ file: obj[i], path: URL.createObjectURL(obj[i]) }));
    }
  }

  const files = useSelector((state) => state.addToCartReducer.projectImages);
  // console.log(files[0])

  useEffect(() => {
    setTitle(projName?.name)
  }, [projName])

  return (
    <>
        <div className="saveOrEdit-container">
          <div className="main-saveoredit">
            <div>
              <div className="header">
              <p style={{color: "#282828", fontWeigth: "400", fontSize: "22px", marginLeft: "66px", marginTop: "23px"}}>Upload Images of Wardrobe</p>
              </div>
              <div className="body">
                {/* <div>asdf</div>
          <div>dfgh</div>
          <div>fghjk</div> */}
                <div style={{ backgroundColor: "#F2F2F2", borderRadius: "10px", paddingLeft: "3rem", paddingRight: "3rem", margin: "1rem 0 1rem 1rem", height: "5rem", width: "104px", height: "98px" }} className="addmoreImage">
                  <input onChange={handlePics} type="file" multiple />
                  <button>
                    {" "}
                    <img src={img} alt="" />{" "}
                  </button>
                </div>
                {files?.map((obj, i) => {
                  return (
                    <div onClick={() => handleClick(obj.path)} key={obj.path} id={obj.path} className="addmoreImages">
                      {currentIds.includes(obj.path) && (
                        <img
                          id={obj.path}
                          className="bluetick"
                          src={bluetick}
                          style={{
                            position: "absolute",
                            top: "15px",
                            right: "10px",
                            backgroundColor: "blue",
                          }}
                        />
                      )}
                      <img className="uploadedImage" id={obj.path} src={obj.path} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          <div className="btn-container my-3 position-fixed bottom-0" style={{ boxShadow: "none", marginLeft: "0" }}>
              {currentIds.length == 0 && (
                <button onClick={handleSaveProject} className="nextbutton">
                  Save & Next
                </button>
              )}
              {currentIds.length > 0 && (
                <button onClick={handleDelete} className="nextbutton">
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
    </>
  );
}
