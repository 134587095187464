import React from 'react';
import styles from "./OtherCompanies.module.css";

const OtherCompanies = () => {
  return (
    <div>
    <div className={styles.companyHeading}>Trusted by other companies like you</div>
    <div className={styles.mainContainer}>
    <div className={styles.companyLogoContainer}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/arquite.svg" alt="arquite" className={styles.arquite} />
        <img alt="essentia" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/essentia_logo.svg" className={styles.essentia} />
        <img alt="design" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/design.png" className={styles.design} />
    </div>
    <div className={styles.companyLogoContainer} >
        <img alt="SWB" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/SWB.png" className={styles.swb} />
        <img alt="akdesigno" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/akdesigno.png" className={styles.akdesigno} />
        <img alt="alsorg" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/alsorg%20logo.svg" className={styles.alsorg} />
    </div>
    </div>
    </div>
  )
}

export default OtherCompanies