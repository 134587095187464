import React from "react";
import { Table } from "react-bootstrap";
import styles from "../../onBoardingCss/DashboardCssNew/task.module.css";
import { useState, useEffect } from "react";
import axios from "axios";

const Task = ({ task }) => {
  const [sortDate, setsortDate] = useState("ascending");
  const [sortendDate, setsortendDate] = useState("asc");
  const [filterState, setfilterState] = useState([...task?.myTasks]);

  // console.log(task);
  const sortStartDate = () => {
    let x = filterState;
    if (sortDate === "ascending") {
      x.sort((a, b) => {
        return (
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
      });
      setfilterState([...x]);
    } else {
      x.sort((a, b) => {
        return (
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        );
      });
      setfilterState([...x]);
    }
  };

  useEffect(() => {
    sortStartDate();
  }, [sortDate]);

  // end date sort
  const sortEndDate = () => {
    let x = filterState;
    if (sortendDate === "asc") {
      x.sort((a, b) => {
        return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
      });
      setfilterState([...x]);
    } else {
      x.sort((a, b) => {
        return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
      });
      setfilterState([...x]);
    }
  };
  useEffect(() => {
    sortEndDate();
  }, [sortendDate]);

  // id to name get for assigned to
  const getName = async (id, index) => {
    await axios
      .get(
        `https://pro-api.idesign.market/api/listDesigners?apitoken=hWFfEkzkYE1X691J4qmcuZHAoet7Ds7ADhL&designerId=${id}&designerId=${id}`
      )
      .then(function (response) {
        let x = filterState;
        x[index]["assignedToData"] = response.data.data.data[0];
        setfilterState([...x]);
        // console.log(response)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // console.log(filterState);
  useEffect(() => {
    filterState.forEach((curelem, index) => {
      getName(curelem.assignedTo, index);
    });
  }, []);

  // id to name get for assigned by
  const getNameAssignedBY = async (id, index) => {
    await axios
      .get(
        `https://pro-api.idesign.market/api/listDesigners?apitoken=hWFfEkzkYE1X691J4qmcuZHAoet7Ds7ADhL&designerId=${id}&designerId=${id}`
      )
      .then(function (response) {
        let x = filterState;
        x[index]["AssignedByData"] = response?.data?.data?.data[0];
        setfilterState([...x]);
      });
  };
  useEffect(() => {
    filterState?.forEach((curelem, index) => {
      getNameAssignedBY(curelem.assignedBy, index);
    });
  }, []);

  // console.log(filterState)
  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        <Table
          className=" overflow-y-scroll"
          style={{ borderRight: "none", borderLeft: "none" }}
        >
          <thead style={{ border: "none" }}>
            <tr  style={{ border: "none" }}>
              <th
                style={{
                  border: "none",
                  paddingLeft: "2rem",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: " 14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                  border: "none",
                  // border:"2px solid red",
                  width:'16%',
                }}
              >
                Task Name
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: " 14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Assigned To
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Assigned By
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                  borderTop:"none",
                }}
              >
                Start date
                <img
                  src="https://idesign-office.s3.amazonaws.com/Sort.svg"
                  alt=""
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() => {
                    if (sortDate === "ascending") {
                      setsortDate("descending");
                    } else {
                      setsortDate("ascending");
                    }
                  }}
                />
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                End date
                <img
                  src="https://idesign-office.s3.amazonaws.com/Sort.svg"
                  alt=""
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() => {
                    if (sortendDate === "asc") {
                      setsortendDate("des");
                    } else {
                      setsortendDate("asc");
                    }
                  }}
                />
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  cursor: "pointer",
                  lineHeight: "19px",
                  paddingLeft: "2rem",
                }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody style={{ borderRight: "none", borderLeft: "none" }}>
            {filterState?.length > 0 ? (
              filterState?.slice(0, 5)?.map((elem) => {
                return (
                  <>
                    <tr style={{ borderBottom: "1px solid #E6E6E6" }}>
                      <td
                        className={styles.eachCol}
                        style={{ paddingLeft: "2rem", overflow: "hidden" }}
                      >
                        {elem?.taskName && elem?.taskName?.length > 0
                          ? elem?.taskName?.slice(0, 15)
                          : "-"}
                      </td>
                      <td className={styles.eachCol}>
                        {elem?.assignedToData?.fullName?.length > 0
                          ? elem?.assignedToData?.fullName
                          : ""}
                      </td>
                      <td className={styles.eachCol}>
                        {elem?.AssignedByData?.fullName?.length > 0
                          ? elem?.AssignedByData?.fullName
                          : ""}
                      </td>
                      <td className={styles.eachCol}>
                        {new Date(elem?.startDate).toLocaleDateString("en-En", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </td> 
                      <td className={styles.eachCol}>
                        {new Date(elem?.endDate).toLocaleDateString("en-En", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </td>
                      <td className={styles.eachCol}>
                        <button className={styles.statusBtn}>
                          {elem?.status === 0
                            ? "not started"
                            : elem?.status === 1
                            ? "started"
                            : elem?.status === 2
                            ? "completed"
                            : elem?.status === 4
                            ? "Delay"
                            : elem?.status === 3
                            ? "Rejected"
                            : null}
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <div
                style={{ display: "flex", padding: "1rem 2rem", width: "100%",fontWeight:'600',fontSize:"18px" }}
              >
                No data
              </div>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Task;
