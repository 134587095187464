import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PhotoUpload from './PhotoUpload'
import SelectRoom from './SelectRoom'
import side from './3dImages/side.svg'
import UploadRefMain from './UploadRefMain'
import completed from './3dImages/completed.svg'
import { useSelector } from 'react-redux'

const TabButtonsMob3 = () => {
return (
        <>
        <div className="d-block d-md-none tabDisplay">
                <div className="tab_main">
                    <div className="tab_mobile">
                    <a className="d-flex align-items-center"  style={{ textDecoration: "none", color: "black" }}>
                            <span className="d-flex align-items-center">
                                <div className="me-3 d-flex align-items-center">
                                    <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                                </div>
                                <div className="page-Heading">3D Feature</div>
                            </span>
                        </a>
                    </div>
                    <div className="tabs_mob_main">
                        <div className="tab_mob1-unactive" >
                            {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : <button className="no2">1</button>}
                            <p className="no1_line d-none">Upload AutoCAD file</p>
                        </div>
                        <div className="tab_mob1-unactive" >
                            {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : <button className="no2">2</button>}
                            <p className="no1_line d-none">Select room & views</p>
                        </div>
                        <div className="tab_mob1-active" >
                            <button className="no1">3</button>
                            <p className="no1_line">Upload reference PPT</p>
                        </div>
                    </div>
                    { <UploadRefMain />}
                   
                </div>
            </div>
        </>
    )
}

export default TabButtonsMob3