import React, { useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import ScheduleADemo from './ScheduleADemo';
import styles from "./ProductsCss/productSec2.module.css";
// import img2 from "../Products/ProductsImg/quotationImg/quotation.png";
// import back from "../Products/ProductsImg/quotationImg/Rectangle 7482.png"
import icon1 from "./ProductsImg/quotationImg/grid_guides.svg";
import icon2 from "./ProductsImg/quotationImg/circle_notifications.svg";
import icon3 from "./ProductsImg/quotationImg/inventory.svg";
// import img2 from "./ProductsImg/quotationImg/que.svg";
const ProductSection2 = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
    <div className={styles.container} id="quo">
      <div className={styles.files_contain}>
            
            <div className={styles.file_content_div}>
              <div className={styles.file_head}>QUOTATION</div>
              <div className={styles.file_para}>Save time and improve team credibility with iterative and error free quotes with multiple template options. Keep track of all version, changes, smart comparison tools and sign offs.</div>


              <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Real-time updates on <br /> the  status of quotes
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Quick Customized <br /> templates
                </div>
              </div>
              <div className={styles.eachBox}> 
                <div>
                  <img
                    src={icon3}
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>Accurate & <br /> Professional quotes </div>
              </div>
            </div>
            </div>
            <div className={styles.img_div}>
           
              <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/quoNew.svg" alt="" className={styles.mainImg}/>
            </div>
          </div>
          </div>
    </>
  )
}

export default ProductSection2