import React from "react";
import "../../App.css";
import image from "../../Images/OEM_wardrobe.png";
import dropImage from "../../Images/removeWorking.png";
import vector from "../../Images/Vector.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function OemWardrobeMob() {
  const [showWorking, setShowWorking] = useState(false);
  const [normalPrice, setNormalPrice] = useState("");
  const [HGPrice, setHGPrice] = useState("");
  const [showError1, setShowError1] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();

  // useEffect(() => {
  //   context.setData((pre) => ({ ...pre, perRoomDesignCharge: price }));
  //   // console.log("hi",context.data.userData)
  // }, [price]);

  const location = useLocation();

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  function handleChange1(e) {
    if (e.target.value.length <= 4 && e.target.value >= 0) {
      setNormalPrice(e.target.value);
    }
  }
  function handleChange2(e) {
    if (e.target.value.length <= 4 && e.target.value >= 0) {
      setHGPrice(e.target.value);
    }
  }

  function handleSubmit() {
     if (normalPrice.length < 1) {
      setShowError1(true);
    }
   else if (HGPrice.length < 1) {
      setShowError2(true);
    }
    else if (HGPrice.length < 1 && normalPrice.length < 1) {
      setShowError2(true);
      setShowError1(true);
    } else {
      setShowError1(false);
      setShowError2(false);
       context.setData((pre)=>({...pre,oemSFLaminatedPrice_wardrobe:normalPrice}))
       context.setData((pre)=>({...pre,oemHighGlossLaminatedPrice_wardrobe:HGPrice}))

      console.log(context.data);
      console.warn(normalPrice);
      console.warn(HGPrice)
      let d = [];
      const url = "";
      // axios.post(url, d).then((response) => {
      //   console.log(response.data);
      // });
      localStorage.setItem("oemWardrobeNormalSF", normalPrice)
      localStorage.setItem("oemWardrobeHighGlose", HGPrice)
      navigateTo("/oemaddphotos");
    }
    console.log(showError1, showError2)
  }

  return (
    <div className="main-falseceiling">
      <div style={{ marginTop: "3rem" }} className="falseceiling-upper d-flex flex-column align-items-center">
      <img style={{backgroundColor:"#BFD4FF"}} src={image} />
      <p style={{fontSize:"23px",textAlign:"center",fontWeight:"500", marginTop: "25px", marginBottom: "40px"}}>
      How much fees would you <br /> charge for <strong>Wardrobes</strong>  in <br /> <strong>per sq.ft basis ?</strong> 
      </p>
        <input value={normalPrice} className="oem_wardrobe_input" style={showError1 ? { borderColor: "red", textAlign: "center" } : { textAlign: "center" }} onChange={handleChange1} type="number" placeholder="For Normal SF Laminated price in INR  " /> 
        <span style={{ color: "red", fontSize: "10px",  alignSelf: "center", marginTop: "4px", alignItems: "center" }} className={`${showError1 ? "" : "d-none"}`}>You cannot leave this field empty</span>
        <input value={HGPrice} className="oem_wardrobe_input" style={showError2 ? {borderColor: "red", textAlign: "center"} : {textAlign: "center"}} onChange={handleChange2} type="number" placeholder="For High Gloss Laminated price in INR  " />
        <span style={{ color: "red", fontSize: "10px", alignSelf: "center", marginTop: "4px", alignItems: "center" }} className={`${showError2 ? "" : "d-none"}`}>You cannot leave this field empty</span>
      </div>
      <div  className="d-flex flex-column align-items-center">
     
      <div className="btn-container1" style={{boxShadow: "none"}}>
        {/* <Link to="/addproject"> */}
        <button onClick={handleSubmit} className="nextbutton">
          Next
        </button>
        {/* </Link> */}
      </div>
      </div>
      
    </div>
  );
}

