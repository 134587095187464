import React from "react";
import styles from "../AboutMobCss/whatweMob.module.css";

const WhatWeDoMob = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>What we do</div>
        <div style={{ width: "90%" }}>
          <img
            src="https://idesign-office.s3.amazonaws.com/Rectangle%207482.svg"
            alt=""
            className={styles.img}
          />
        </div>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className={styles.para}>
            Welcome to iDesign.Market, your trusted partner in the digital
            transformation of Interior and Construction Companies. With our
            comprehensive range of features and products, we are here to support
            you on your journey towards digitization. Our goal is to streamline
            your project and business management processes, bringing your entire
            team together on a user-friendly and efficient platform. Through our
            innovative suite of products, we have consistently delivered
            tangible results. Our solutions have proven to enhance efficiency,
            minimize delays, foster seamless collaboration between on-site and
            design teams, and ultimately drive increased profitability and
            customer satisfaction for companies like yours. Experience the power
            of iDesign.Market and unlock the true potential of your business.
            Join us today and embrace the future of Interior and Construction
            Company management.
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDoMob;
