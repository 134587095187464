import React, {useState, useEffect} from "react";
import styles from "../cssFiles/files.module.css";
import Donut from "./Donut";
import axios from 'axios'

const Files = () => {
  const [files, setFiles] = useState([])
  const getFiles = async () => {
    await axios.get(`https://pmt-api.essentiaenvironments.com/api/file-manager/get-file-feedback?id=${localStorage.getItem('userId')}&fileId=${localStorage.getItem('userId')}`).then((res) => {
      // setFiles(res.data)
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() =>{
    // getFiles()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div style={{ width: "52%", paddingRight: "2rem",borderRight:"1px solid #E6E6E6",marginTop:'2rem'}}>
        <span className={styles.fileSum}>File Summary</span>
        <div
          style={{
            // width: "55%",
            display: "flex",
            alignItems: "center",
            paddingTop: "1rem",
            // border:"2px solid red" 
          }}
        >
          <div>
            <Donut />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // padding: "1rem",
              gap: "1rem",
              width: "50%",
              marginLeft: "2rem",
            }}
          >
            <div className={styles.threeBtnDiv1}>
              <div>Approved</div>
              <div>04</div>
            </div>

            <div className={styles.threeBtnDiv2}>
              <div>In-Discussion</div>
              <div>04</div>
            </div>

            <div className={styles.threeBtnDiv3}>
              <div>In-Execution</div>
              <div>04</div>
            </div>
          </div>
        </div>
        </div>
       
       
        <div style={{ width: "45%", marginTop: "2rem",paddingLeft:"1rem" }}>
          <div className={styles.pending}>
            <div>Pending Appovals</div>
            <div>View all</div>
          </div>
          <div className={styles.fileDiv}>
            <div className={styles.fileText}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector%20%287%29.svg"
                alt=""
                style={{ height: "1.5rem" }}
              />
              <div style={{ marginLeft: "0.7rem" }}>
                <div>Bedroom Drawing</div>
                <div className={styles.date}>12 Aug 23</div>
              </div>
            </div>
            <div className={styles.fileText1}>
              <div>Approval Pending</div>
            </div>
          </div>

          <div className={styles.fileDiv}>
            <div className={styles.fileText}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector%20%287%29.svg"
                alt=""
                style={{ height: "1.5rem" }}
              />
              <div style={{ marginLeft: "0.7rem" }}>
                <div>Bedroom Drawing</div>
                <div className={styles.date}>12 Aug 23</div>
              </div>
            </div>
            <div className={styles.fileText1}>
              <div>Approval Pending</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Files;
