import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Logos/marketlogo1.svg";
import { scrollToTop } from "../../../Helpers/utils";

const Logo = ({ to = "/"}) => {
  return (
    <a href="https://www.idesign.market/" className="navbar-brand">
      <img src={logo} alt="idesign pro" />
    </a>
  );
};

export default Logo;
