import React, {useEffect, useState} from "react";
// import { Link } from "react-router-dom";
import { ROUTES } from "../../Router/routes";
import { useNavigate } from "react-router-dom";

// import { scrollToTop } from "../../Helpers/utils";
import {getPlans} from "../../Apis";

const PlanCard = ({
  name = "",
  price = "",
  duration = "",
  features = [],
  variant = 0,
}) => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        getPlans().then((response) => setPlans(response?.data));
    }, []);
    const buyPlan = (index) => {
        navigate(`${ROUTES.CHECKOUT}/${plans[index]?._id}`);
    };
  return (
    <div className="col-md-4 mb-4">
      <div className={`dash-plan ${
          variant === 1
            ? "color-plan"
            : variant === 2
            ? "color-plan-change"
            : ""
        }`}
      >
        <h4>{name}</h4>
        <h5 className="">
          {`₹ ${price}/`}
          <span>month</span>
        </h5>
        <ul className="plan-ul">
          {features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
        {/*<Link to={ROUTES.PLANS} state={{ prevPath: ROUTES.GO_PREMIUM }} onClick={scrollToTop}>*/}
          <button className="btn know-more" onClick={() => buyPlan(variant)}>Buy Now</button>
        {/*</Link>*/}
      </div>
    </div>
  );
};

export default PlanCard;
