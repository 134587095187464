import React from "react";
import styles from "../Products/ProductsCss/productSec12.module.css";
import icon1 from "./ProductsImg/meeting/assignment_add.svg";
import icon2 from "./ProductsImg/meeting/inventory.svg";
import icon3 from "./ProductsImg/meeting/quick_reference_all.svg";

const ProductSection12 = () => {
  return (
    <>
      <div className={styles.container} id="quality">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>Quality Check</div>
            <div className={styles.file_para}>
              Save time, reduces rework, and showcase your professionalism and
              attention to detail in every Project.
            </div>
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/rebase.svg" alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                Comprehensive Quality Assessment
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/note_alt.svg" alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Documentation and Reporting
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/add_road.svg" alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                Share with stakeholders <br /> & track
                </div>
              </div>
            </div>
          </div>
          <div className={styles.img_div}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610957.svg"
              alt=""
              className={styles.mainImg}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection12;
