import React from "react";
import editImage from "../../Images/editIcon.png";
import { Link } from "react-router-dom";
import img from "../../Images/addMoreImage.png";
import bluetick from "../../Images/blueTick.png";
// import img1 from '../Images/projectImage1.png'
// import img2 from '../Images/projectImage2.png'
// import img3 from '../Images/projectImage3.png'
// import img4 from '../Images/projectImage4.png'
// import img5 from '../Images/projectImage5.png'
import { useState } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import SaveOrEditPicsDesignerWeb from "../OnboardingWebScreens/SaveOrEditPicsDesignerWeb";

// const images = [img1,img2,img3,img4,img5]

export default function OemSelectedImg() {
  const [currentIds, setCurrentIds] = useState([]);
  const context = useContext(OnboardingContext);
//   const [title, setTitle] = useState(context.data.projects[context.data.projects.length - 1].details.name);
  const [isEditMode, setIsEditMode] = useState(false);
  const title = "Upload images of Wardrobe"

  const navigateTo = useNavigate();

  function handleClick(e) {
    let flag = false;
    for (let i = 0; i < currentIds.length; i++) {
      if (currentIds[i] == e.target.id) {
        flag = true;
      }
    }
    let ids = currentIds.filter((id, i) => {
      return id != e.target.id;
    });

    if (flag == false) {
      setCurrentIds([...currentIds, e.target.id]);
    } else {
      setCurrentIds([...ids]);
    }
    //  console.log(currentIds)
  }

  function handleDelete(e) {
    let Arr = [];
    Arr = context.data.projects[context.data.projects.length - 1].images.filter((curElem, index) => {
      return !currentIds.includes(curElem.path);
    });

    // let tempArr = context.data.projects || [];
    //   let arrObj = tempArr[tempArr.length-1] || {};
    //   arrObj.images = filesArr;
    //   tempArr[tempArr.length-1] = arrObj
    //   context.setData((pre)=>({...pre,projects:tempArr}))

    let allProjects = context.data.projects;
    let lastProject = allProjects[allProjects.length - 1];
    lastProject.images = Arr;
    allProjects[allProjects.length - 1] = lastProject;
    context.setData((pre) => ({ ...pre, projects: allProjects }));

    // let allprojects = [...context.data.projects,context.data.projects[context.data.projects.length-1].images:Arr]
    // console.log(currentIds)
    // console.log(Arr)

    setCurrentIds([]);
    // context.setData({...context.data,projectImages:allprojects})
    // const finalArr = [];
    //  for(let i  = 0 ; i <  context.data.projectImages.length; i++){
    //    for(let j = 0  ; j < currentIds.length; j++){
    //            if(currentIds[j]==context.data.projectImages[i].path){

    //            }else{
    //             Arr.push(context.data.projectImages[i])
    //            }
    //    }
    //  }

    // let set = new Set(Arr);
    // for(let i = 0 ; i < Arr.length; i++){
    //   if(Arr.contains(currentIds[i])){
    //     finalArr.push()
    //   }
    //    console.log(currentIds)
    //    console.log(Arr)
    //    console.log(set)

    //    setCurrentIds([])
    //    context.setData({...context.data,projectImages:Arr})
  }

  function handleSaveProject() {
    // let project = [context.data.projectImages[0],context.data.projectDetails]
    // if(context.data.projects){
    //    let allProjects = [...context.data.projects,project]
    // context.setData((pre)=>({...pre,allProjects}))
    // }else{
    //   context.setData((pre)=>({...pre,project}))
    // }
    // console.log(context.data)
    navigateTo("/projectsummary");
  }

  //   const data = {
  //     userData: {},
  //     typeOfService: {},
  //     designStyles : [],
  //     consultationCharge: 100;
  //       designFeePerSq: 100,
  //       perRoomDesign: 1000,
  //
  //     projects:[
  //           {
  //               details: {},
  //               images:[{}]
  //           },
  //           {
  //               details: {},
  //               images:[{}]
  //           }
  //     ]
  // };
  function handleTitle(e) {
    let d = context.data.projects;
    d[d.length - 1].details.name = e.target.value;
    context.setData((pre) => ({ ...pre, projects: d }));
  }

  function handleEdit() {
    // console.log(isEditMode);
    setIsEditMode(true);
  }

  function handlePics(e) {
    //  console.log(e.target.files)
    // let tmppath = URL.createObjectURL(e.target.files[0]);
    // console.log(tmppath)
    let obj = e.target.files;
    let filesArr = [...context.data.projects[context.data.projects.length - 1].images];
    //console.log("hi")
    for (let i = 0; i < obj.length; i++) {
      filesArr.push({ file: obj[i], path: URL.createObjectURL(obj[i]) });
    }

    let tempArr = context.data.projects || [];
    let arrObj = tempArr[tempArr.length - 1] || {};
    arrObj.images = filesArr;
    tempArr[tempArr.length - 1] = arrObj;
    context.setData((pre) => ({ ...pre, projects: tempArr }));

    // console.log(filesArr);
    // setProjectImages([...filesArr]);
    //   let previous = [];
    //   if(context.data.projectImages){
    //     previous = [...context.data.projectImages,...filesArr]
    //     context.setData((pre)=>({...pre,projectImages:previous}))
    //   }else{
    //     context.setData((pre)=>({...pre,projectImages:filesArr}))
    //   }
    // console.log(context.data.projectImages)
    //   //context.setData((pre)=>({...pre,projectImages:filesArr}))
    //  // navigateTo("/saveoredit");
  }

  const files = context.data.projects[context.data.projects.length - 1].images || [];

  return (
    <>
    {window.screen.width > "768" ?
    <SaveOrEditPicsDesignerWeb />
    :
    <div className="saveOrEdit-container">
      <div className="main-saveoredit">
        <div>
          <div className="header">
            {!isEditMode && <div style={{ marginLeft: "5%", width: "100%", fontSize: "16px", fontWeight: "400" }}>{title}</div>}
            {isEditMode && <input className="editField" onChange={handleTitle} type="text" value={title} />}
            {/* <button onClick={handleEdit} style={{ backgroundColor: "white" }}>
              <img src={editImage} alt="" />
            </button> */}
          </div>
          <div className="body">
            {/* <div>asdf</div>
          <div>dfgh</div>
          <div>fghjk</div> */}
            <div style={{ backgroundColor: "#F2F2F2", borderRadius: "10px", paddingLeft: "3rem", paddingRight: "3rem", margin: "1rem 0 1rem 1rem", height: "5rem", width: "104px", height: "98px" }} className="addmoreImage">
              <input onChange={handlePics} type="file" multiple />
              <button>
                {" "}
                <img src={img} alt="" />{" "}
              </button>
            </div>
            {files.map((obj, i) => {
              return (
                <div onClick={handleClick} key={obj.path} id={obj.path} className="addmoreImages">
                  {currentIds.includes(obj.path) && (
                    <img
                      id={obj.path}
                      className="bluetick"
                      src={bluetick}
                      style={{
                        position: "absolute",
                        top: "15px",
                        right: "10px",
                        backgroundColor: "blue",
                      }}
                    />
                  )}
                  <img className="uploadedImage" id={obj.path} src={obj.path} alt="" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="btn-container my-3 position-fixed bottom-0">
          {currentIds.length == 0 && (
            <button onClick={handleSaveProject} className="nextbutton">
              Save & Next
            </button>
          )}
          {currentIds.length > 0 && (
            <button onClick={handleDelete} className="nextbutton">
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
    }
    </>
  );
}
