import React, { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCadFile, uploadAutocad } from '../Lms/Actions/threedActions'
import upload from './3dImages/upload.svg'
import uploadFinal from './3dImages/uploadFinal.svg'
import cross from './3dImages/cross.svg'
const PhotoUpload = (props) => {
    const dispatch = useDispatch();
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const addImageRef = useRef()
    const [uploaded, setUploaded] = useState(false)
    const [imageName, setImageName] = useState("");


    const handleUpload = (e) => {
        // console.log(e.target.files)
        if (e.target.files[0]?.name?.includes(".dwg")) {
            const x = {
                name: e.target.files[0].name,
                path: URL.createObjectURL(e.target.files[0]),
                fullFile: e.target.files[0]

            }
            dispatch(uploadAutocad(x));
            props.function2()
            
        }
        else {
            props.function1()
        }

    }

    useEffect(() => {
        setImageName(autocadFile1?.name)
    }, [autocadFile1]);

    const handleFileDelete = (element) => {
        dispatch(deleteCadFile(element))
    }

    // console.log(error)

    return (
        <>
            <div className="d-flex flex-column align-items-center">
                <div className="file_upload">
                    {autocadFile1?.name ? <img src={cross} alt="" className="cross_img2 float-end" onClick={() => handleFileDelete(autocadFile1?.name)} /> : ""}
                    <div className="main_file" style={props?.err !== "" ? { border: "1px solid #A30D0D" } : autocadFile1?.name ? { border: "1px solid #176091" } : { border: "1px dashed #A7A7A7" }} onClick={() => { addImageRef.current.click() }}>
                        <input ref={addImageRef} type="file" accept=".dwg" className="fileType d-none" onChange={handleUpload} id='main_input' onClick={(event) => {
                            event.target.value = null
                        }} />
                        <img src={autocadFile1?.name ? uploadFinal : upload} alt="image upload" className="upload_img" />
                        {autocadFile1?.name ? <p className="upload_line2">{autocadFile1?.name}</p>
                            : <><p className="upload_line">Select or drag & drop your file to add</p>
                            </>
                        }
                    </div>
                </div>
                {props?.err !== "" ? <span className='err-line'>{props.err}</span> : ""}
            </div>
        </>
    )
}

export default PhotoUpload