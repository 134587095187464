import React, { useRef } from "react";
import { Accordion, Carousel, Modal } from "react-bootstrap";
import bedroom2 from "./3dImages/bedroom2.svg";
import uncoloredStar from "./3dImages/uncoloredStar.svg";
import coloredStar from "./3dImages/coloredStar.svg";
import cadDrawing from "./3dImages/cadDrawing.svg";
import referenceImg from "./3dImages/referenceImg.svg";
import vmeeting from "./3dImages/vmeeting.svg";
import fproduct from "./3dImages/fproduct.svg";
import arrow1 from "./3dImages/arrow1.svg";
import arrow2Rot from "./3dImages/arrow2Rot.svg";
import { useNavigate } from "react-router-dom";
import LandingMobHeader from "./LandingMobHeader";
import reviews from "./3dImages/reviews.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import img1 from "./3dImages/images3d/1.jpeg";
import img2 from "./3dImages/images3d/2.jpeg";
import img3 from "./3dImages/images3d/3.jpeg";
import img4 from "./3dImages/images3d/4.jpeg";
import img5 from "./3dImages/images3d/5.jpeg";
import img6 from "./3dImages/images3d/6.jpeg";
import img7 from "./3dImages/images3d/7.jpeg";
import img8 from "./3dImages/images3d/8.jpeg";
import img9 from "./3dImages/images3d/9.jpeg";
import img10 from "./3dImages/images3d/10.jpeg";
import next from "./3dImages/images3d/next.png";
import close from "./3dImages/close.svg";
import sidebar from "./3dImages/sidebar.svg";
import axios from "axios";
import MobHeader from "../Lms/LmsMobileComponents/MobHeader";

const LandingMob = () => {
  const [scroll_, setScroll_] = useState(0);
  const navigate = useNavigate();
  const handleStart = () => {
    navigate("/3dUploadCad");
  };

  const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  var scroll = window.scrollY;

  window.onscroll = () => {
    scroll = window.scrollY;
    setScroll_(scroll);
  };

  const faqs = [
    {
      no: 1,
      faq: "For which kind of Spaces do you provide 3Ds ?",
      faqa: "We provide 3Ds for interior spaces of Home Interiors. We are currently not providing 3Ds for commercial spaces or facades",
    },
    {
      no: 2,
      faq: "Do you make 3Ds of furniture as per drawing or use library ?",
      faqa: "We use 3DMax or Sketch up library to provide the closest furniture to the reference image shared by you. Our team does some customized visualization also if its limited in nature.",
    },
    {
      no: 3,
      faq: "Is my ACAD Drawings safe with iDesign?",
      faqa: "We place highest amount of emphasis on data security and your ACAD drawings and 3Ds will be your property and never shared with any third party and will never be used by iDesign for any purpose other than providing you the service",
    },
    {
      no: 4,
      faq: "What if I am not satisfied with the Final 3D ?",
      faqa: "We will be showing you the 3D block and low renders before the final render and hence the final render is done only on your approval. If incase you are not happy with the final render, we will be happy to give you a fresh render. Also, we take only 50% payment as advance,so you pay the balance 50% only when you are totally comfortable with the process.",
    },
  ];
  const benefits = [
    {
      no: 1,
      data: "Professionally done 3Ds delivered in a timebound way",
    },
    {
      no: 2,
      data: "Low cost of views starting at Rs 850 per view",
    },
    {
      no: 3,
      data: "Large 3D backoffice with specialists to cater to multiple and large 3D demand",
    },
    {
      no: 4,
      data: "Multiple changes allowed at block and low render",
    },
  ];

  const images = [
    {
      no: 1,
      imgName: img1,
    },
    {
      no: 2,
      imgName: img2,
    },
    {
      no: 3,
      imgName: img3,
    },
    {
      no: 4,
      imgName: img4,
    },
    {
      no: 5,
      imgName: img5,
    },
    {
      no: 6,
      imgName: img6,
    },
    {
      no: 7,
      imgName: img7,
    },
    {
      no: 8,
      imgName: img8,
    },
    {
      no: 9,
      imgName: img9,
    },
    {
      no: 10,
      imgName: img10,
    },
  ];

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [showId, setShowId] = useState();
  const [ofArr, setOfArr] = useState([]);
  let [left, setLeft] = useState(0);

  const handleShow2 = (id) => {
    setShowId(id);
    setShow2(true);
  };
  useEffect(() => {
    const b = images.filter((currElem) => {
      return currElem.no == showId;
    });
    setOfArr(b);
  }, [showId]);

  // console.log(showId)

  const [userData, setUserData] = useState([]);

  useEffect(async () => {
    await axios.get(`https://3d-api.idesign.market/api/room?designerId=${localStorage.getItem("userId")}`).then((res) => {
      // console.log(res)
      setUserData(res?.data);
    });
  }, []);

  useEffect(() => {
    if (userData?.length > 0) {
      navigate("/3dFinal");
    }
  }, [userData]);

  const [filteredArr, setFilteredArr] = useState([]);

  useEffect(() => {
    const a = images.filter((currElem) => {
      return currElem.no !== showId;
    });
    setFilteredArr(a);
  }, [showId]);

  return (
    <>
      {/* <LandingMobHeader /> */}
      <MobHeader />
      <Modal show={show2} onHide={handleClose2} background={{ background: "none" }} centered>
        <img
          src={close}
          alt=""
          onClick={handleClose2}
          className="float-end"
          style={{
            filter: "brightness(100)",
            cursor: "pointer",
            position: " absolute",
            right: "0.5rem",
            top: "-10rem",
            width: "18px",
            height: "18px",
          }}
        />
        <Modal.Body>
          {/* <img src={ofArr[0]?.imgName} alt="" style={{ width: '100%' }} /> */}
          <Carousel variant="dark">
            <Carousel.Item>
              <div style={{ display: "flex", width: "fit-content" }}>
                <div
                  style={{
                    width: "22rem",
                    height: "18rem",
                    marginRight: "10px",
                  }}
                >
                  <img src={ofArr[0]?.imgName} alt="" style={{ width: "100%", height: "100%" }} />
                </div>
              </div>
            </Carousel.Item>
            {filteredArr.map((item, index) => {
              return (
                <Carousel.Item>
                  <div style={{ display: "flex", width: "fit-content" }}>
                    <div
                      style={{
                        width: "22rem",
                        height: "18rem",
                        marginRight: "10px",
                      }}
                    >
                      <img src={item?.imgName} alt="" style={{ width: "100%", height: "100%" }} />
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
      <div style={{ background: "White", display: "flex" }}>
        <div className="landing_main">
          <div className="landing_main2">
            <div className="landing_top">
              <h3 className="landing_header">
                Get quick and high quality 3D views just at{" "}
                {profileInfo[0]?.data?.data?.planId?.name === "Free" ? <b style={{ color: "#3B5998" }}> ₹1200/- </b> : <b style={{ color: "#3B5998" }}> ₹1000/- </b>} per view.{" "}
              </h3>
              <p className="landing_p">iDesign provides on time and value for money 3Ds to over 300 Designer and Architects across India</p>
              <button className="landing_started" onClick={handleStart} id="btn">
                Get Started
              </button>
              {/* <span className='mt-2' style={{ fontSize: "12px", color: "#888888", fontWeight: "400" }}>only for Residential Interiors</span> */}
            </div>
          </div>
          {profileInfo[0]?.data?.data?.planId?.name === "Free" ? (
            <div className="disc-offer mt-5">
              <button className="dis_prem" onClick={() => navigate("/plans")}>
                Upgrade to premium
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="landing_images_secs mt-5 mb-3 d-flex flex-column">
            <h3 className="img_lan_header mb-4 mt-5">Want to see some of our designs?</h3>
            {/* <div className="d-flex">
                            <div className="landing_img_main d-flex justify-content-start" style={{ overflowX: "scroll" }}>
                                <Carousel variant="dark" style={{
                                    border: "none"
                                }}>
                                    {images.map((item, index) => (<Carousel.Item style={{ border: "none", padding: "0" }}>
                                        <img src={item.imgName} alt="" className="img1" style={{
                                            objectFit: "cover", width: "19rem", height: "11rem", cursor: "pointer", borderRadius: "8px"
                                        }} onClick={() => handleShow2(item.no)} />
                                    </Carousel.Item>))}
                                </Carousel>
                            </div> 
                        </div> */}
            <div className="d-flex">
              <img
                src={sidebar}
                alt=""
                className="dimag"
                style={{ transform: "rotate(180deg)", cursor: "pointer", position: "relative", left: "0.7rem" }}
                onClick={() => {
                  let element_ = document.querySelector(".landing_img_main");

                  if (element_.scrollLeft > 0) {
                    element_.scrollLeft -= 298;
                  }
                }}
              />

              <div className="landing_img_main d-flex justify-content-start" style={{ overflowX: "scroll" }}>
                <div style={{ display: "flex", width: "fit-content" }}>
                  {images.map((item, index) => (
                    <div
                      style={{
                        width: "18rem",
                        height: "10rem",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={item.imgName}
                        alt=""
                        className="img1"
                        style={{ height: "100%", width: "100%", marginRight: "10px", cursor: "pointer", borderRadius: "8px" }}
                        onClick={() => {
                          handleShow2(item.no);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <img
                src={sidebar}
                alt=""
                className="dimag"
                style={{ marginLeft: "auto", cursor: "pointer", position: "relative", right: "0.7rem" }}
                onClick={() => {
                  let element_ = document.querySelector(".landing_img_main");

                  if (element_.scrollLeft != 781.5999755859375) {
                    element_.scrollLeft += 298;
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="side_carousel d-flex flex-column mt-5">
                    <h3 className="img_lan_header mt-5">Reviews</h3>
                    <div className="carouselComp_start mt-2">
                        <Carousel variant="dark">
                            <Carousel.Item>
                                <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                <div className="caption_reviwes d-flex">
                                    <div className="caption_name d-flex flex-column">
                                        <p className="cap_byline">Rohit Rathod</p>
                                        <span className="cap_span">Interior Designer</span>
                                    </div>
                                    <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                                        <img src={coloredStar} alt="" className="starColor" />
                                        <img src={coloredStar} alt="" className="starColor" />
                                        <img src={coloredStar} alt="" className="starColor" />
                                        <img src={uncoloredStar} alt="" className="starColor" />
                                        <img src={uncoloredStar} alt="" className="starColor" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <p className='caption_main'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                <div className="caption_reviwes d-flex">
                                    <div className="caption_name d-flex flex-column">
                                        <p className="cap_byline">Rohit Rathod</p>
                                        <span className="cap_span">Interior Designer</span>
                                    </div>
                                    <div className="cap_start d-flex justify-content-evenly w-40 float-end" style={{ marginLeft: "auto" }}>
                                        <img src={coloredStar} alt="" className="starColor" />
                                        <img src={coloredStar} alt="" className="starColor" />
                                        <img src={coloredStar} alt="" className="starColor" />
                                        <img src={uncoloredStar} alt="" className="starColor" />
                                        <img src={uncoloredStar} alt="" className="starColor" />
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div> */}
          {/* <div className="side_carousel mt-4">
                        <h3 className="img_lan_header mb-3 mt-5">Reviews</h3>
                        <div className="d-flex flex-column w-100 justify-content-center" style={{
                            height: "26vh", border: "1px solid #DFDFDF",
                            borderRadius: "8px"
                        }}>
                            <img src={reviews} style={{ height: "38px" }} alt="" />
                            <p style={{
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "black",
                                textAlign: "center"
                            }}>No reviews yet</p>
                        </div>
                    </div> */}
          <div className="lan-mob-info  d-flex flex-column">
            <h3 className="img_lan_header mb-3 mt-5">How it works?</h3>
            <div className="info_top d-flex mt-5">
              <div className="info1 d-flex flex-column">
                <img src={cadDrawing} alt="" className="sec3_img1" />
                <h3 className="sec3_head">Upload ACAD Drawings</h3>
                <p className="sec3_byline">Upload 3D details such as AutoCAD Drawings, Number of Views Required</p>
              </div>
              <img src={arrow1} alt="" className="arrow_connector1" />
              <div className="info2 d-flex flex-column" style={{ marginLeft: "-5vh" }}>
                <img src={referenceImg} alt="" className="sec3_img1" />
                <h3 className="sec3_head">Provide Reference Images</h3>
                <p className="sec3_byline">Provide images for the elements of the 3D such as furniture, wall panelling etc</p>
              </div>
            </div>
            <img src={arrow2Rot} alt="" className="arrow_connector2" />
            <div className="info_top d-flex">
              <div className="info2 d-flex flex-column">
                <img src={fproduct} alt="" className="sec3_img1" />
                <h3 className="sec3_head">Final Product</h3>
                <p className="sec3_byline">Receive 3D blocks and low renders for approval. On approval final 3D is rendered</p>
              </div>
              <img src={arrow1} alt="" className="arrow_connector1" style={{ transform: "rotate(180deg)" }} />
              <div className="info1 d-flex flex-column" style={{ marginLeft: "-5vh" }}>
                <img src={vmeeting} alt="" className="sec3_img1" />
                <h3 className="sec3_head">Virtual Meetings </h3>
                <p className="sec3_byline">iDesign receives details and books a virtual meeting with you to understand angles and elements</p>
              </div>
            </div>
          </div>
          <div className="side_benefitsd-flex flex-column">
            <h3 className="img_lan_header mb-3">Benefits</h3>
            {benefits.map((item, i) => (
              <div className="benefit1 d-flex">
                <p className="bno1">{item.no}</p>
                <p className="bnoContent">{item.data}</p>
              </div>
            ))}
          </div>
          <div className="landing_faqs mb-5">
            <p className="faqs_header">FAQs</p>
            <Accordion style={{ width: "88%" }}>
              {faqs.map((item, i) => (
                <Accordion.Item eventKey={item.no}>
                  <Accordion.Header>{item.faq}</Accordion.Header>
                  <Accordion.Body>{item.faqa}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          {/* <p>{scroll}</p> */}
          {scroll_ > 400 ? (
            <button className="landing_started2" onClick={handleStart}>
              Get Started
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default LandingMob;
