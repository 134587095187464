import React, { useContext, useEffect, useState } from "react";
import ChatItem from "./ChatElements/ChatItem";
import dashContext from "../picontext/DashBoard/dashContext";
import { getAllCon, getReq, postReq } from "../piservices/apis";
import { getLoginId, getToken } from "../piservices/authService";
import NoChatYet from "./NoChatYet";
import { useNavigate, useParams } from "react-router-dom";
import FullLoader from "../FullLoader/FullLoader";
import axios from "axios";

const AllChatItems = () => {
  const navigateTo = useNavigate();
  const { chatId } = useParams();
  const [desRes, setDesRes] = useState();
  const context = useContext(dashContext);
  const authTok = `Bearer ${getToken()}`;
  const { allAvChatRes, showChat, setShowChat, setRoomActiveChat, socket, msg, setActiveChatMsgs } = context;

  const startNewConv = async (e, hc = true) => {
    console.log(e);
    try {
      const call = await axios({
        method: "get",
        url: "https://home-api.idesign.market/api/user/users/" + e.homeOwnerId,
      });
      console.log(call?.data?.userData);
      if (call && !call.error) {
        hc && (await hitConv({ ...call?.data?.userData, homeOwnerId: e.homeOwnerId }));
        if (chatId === e.homeOwnerId) {
          setRoomActiveChat({ ...call?.data?.userData, ...e });
        }
        return { ...call?.data?.userData, homeOwnerId: e.homeOwnerId };
      } else {
        console.log(call.error.response.data || call.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hitConv = async (e) => {
    setTimeout(async () => {
      const call2 = await postReq(`https://chat.idesign.market/conversation/`, {
        senderId: getLoginId(),
        recieverId: e.homeOwnerId,
      });
      if (call2 && !call2.error) {
        // setDesRes({...desRes , conversationId  : call2?.data?._id })
        // navigate("/dashboard/chat/");
      } else {
        console.log(call2.error);
      }
    }, 1000);
  };

  socket.on("connect", () => {
    // setIsConnected(true);
    console.log("connected");
  });

  let countHit = 0;
  const init = async (skiphitconv = []) => {
    if (getToken() && getLoginId()) {
      setRoomActiveChat();
      setActiveChatMsgs();
      document.querySelector(".secureChat .allChatItems").classList.remove("mv-expander");
      const call = await getAllCon();
      if (call && !call.error) {
        var memArr = call?.data?.map(function (el) {
          return el.members[1] !== getLoginId() ? el.members[1] : el.members[0];
        });
        if (chatId && !memArr.includes(chatId) && !skiphitconv.includes(chatId)) {
          await hitConv({ homeOwnerId: chatId });
          await setTimeout(async () => {
            await init([...skiphitconv, chatId]);
          }, 100);
          return;
        }

        setShowChat(false);
        let allChats = [];
        for (let i = 0; i < call?.data?.length; i++) {
          // const alCon = call?.data[i];
          console.log(call?.data[i]?.homeOwnerId);
          let currDes = call?.data[i]?.members[1] === getLoginId() ? call?.data[i]?.members[0] : call?.data[i]?.members[1];
          console.log(await startNewConv({ homeOwnerId: currDes, conversationId: call?.data[i]?._id }, false));
          allChats.push({ ...(await startNewConv({ homeOwnerId: currDes, conversationId: call?.data[i]?._id }, false)), conversationId: call?.data[i]?._id });
        }
        console.log(allChats);

        if (!chatId) {
          setRoomActiveChat(allChats[0]);
        }
        await setDesRes(allChats);
      } else {
        console.log(call.error);
      }
    } else {
      // document.getElementById("loginBtn").click();
      navigateTo("/");
    }

    if (chatId) {
      document.querySelector(".secureChat .allChatItems").classList.add("mv-expander");
    } else {
      document.querySelector(".secureChat .allChatItems").classList.remove("mv-expander");
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  return (
    <div className="allChatItems bg-white border pt-3" style={{ width: "25%" }}>
      <div id="chatItemsID" className="position-fixed allChatItemsInner">
        {<NoChatYet desRes={desRes} />}
        <div className=" overflow-y-auto h-100 scb-none" style={{ maxHeight: "100vh" }}>
          {!showChat &&
            desRes &&
            desRes.length > 0 &&
            desRes?.map((e, i) => {
              console.log(e);
              return (
                e.email && (
                  <ChatItem
                    avatar={"https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"}
                    alt={"Avatar"}
                    title={e.name}
                    subtitle={e.email}
                    date={new Date()}
                    unread={0}
                    key={i}
                    data={e}
                    id={`currChat${e.homeOwnerId}`}
                  />
                )
              );
            })}
          {showChat && (
            <div>
              {/* {showChat && <div> */}
              <p className="mx-2">
                <i
                  className="fa-solid fa-angle-left mx-2 cursor-pointer"
                  onClick={() => {
                    setShowChat(false);
                  }}
                ></i>
                Shortlisted Homeowners
              </p>
              {allAvChatRes && allAvChatRes.length > 0 ? (
                allAvChatRes?.map((e, i) => {
                  return (
                    e.homeOwnerId && (
                      <ChatItem
                        avatar={"https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"}
                        alt={"Avatar"}
                        title={e.name}
                        subtitle={e.email}
                        date={new Date()}
                        unread={0}
                        key={i}
                        onClick={() => {
                          startNewConv(e);
                        }}
                        data={e}
                      />
                    )
                  );
                })
              ) : (
                <div className="d-flex align-items-center w-100 " style={{ minHeight: "60vh" }}>
                  <FullLoader msg={msg} />
                </div>
              )}
            </div>
          )}

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default AllChatItems;
