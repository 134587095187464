import React from 'react'
import parse from "html-react-parser";
const MessageBox = (props) => {

    return (
        <>
            {props.type === "text" && <div>
                <div className={`msg small p-2 rounded-3 my-2 type-${props.position}`} >
                    {parse(props.text)}
                    <p className={`small rounded-3 mb-0 time mt-2`}>{props?.time || ""}</p>
                </div>

            </div>}
        </>
    )
}

export default MessageBox