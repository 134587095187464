import React, { useContext, useEffect, useState } from "react";
import dashContext from "../../picontext/DashBoard/dashContext";
import { postReq } from "../../piservices/apis";
import { getLoginId, uploadImages } from "../../piservices/authService";
import MessageBox from "../MessageBox";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import imgx from "../Images/nochatyet.png";
import chatBubblesImg from "../Images/chatBubblesImg.svg";
import { useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";

const AllMessages = (props) => {
  const context = useContext(dashContext);
  const profileData = useSelector((state) => state.addToCartReducer.profileData);
  const { activeChat, activeChatMsgs, setActiveChatMsgs, socket } = context;
  const sendMessage = async () => {
    let chatTxt = document.getElementById("chatInp").innerHTML;
    if (!chatTxt || chatTxt.trim() === "") {
      toast.error("Enter A Text");
      return;
    }
    const call = await postReq(`https://chat.idesign.market/message`, {
      senderId: getLoginId(),
      conversationId: activeChat.conversationId,
      text: chatTxt,
    });
    if (call && !call.error) {
      socket.emit("new message", call?.data);
      if (!activeChatMsgs || activeChatMsgs.length === 0) {
        const call2 = await postReq("https://pro-api.idesign.market" + `/api/sendMail`, {
          data: [
            {
              email: activeChat.email,
              subject: profileData[0]?.data?.data?.companyName + " wants to connect with you",
              body: profileData[0]?.data?.data?.companyName + " have messaged you regarding their query . ",
            },
          ],
        });
        if (call2 && call2.error) {
          console.log(call2);
        } else {
          console.log(call2);
        }
      }
      // await setActiveChatMsgs([...activeChatMsgs, call?.data]);
      // await window.scrollTo(0, document.body.scrollHeight);
    } else {
      console.log(call.error);
    }
  };

  useEffect(() => {
    socket.on("message recieved", async (data) => {
      await setActiveChatMsgs((x) => {
        if (x) {
          if (x.filter((item) => item?._id === data?._id).length == 0) {
            return [...x, data];
          } else {
            return x;
          }
        } else {
          return [data];
        }
      });
      window.scrollTo(0, 100000);
    });
  }, [socket]);

  return (
    <div className="allMessages position-relative mv-w-100" style={{ background: "#F2EDFD", width: "50%" }}>
      {activeChat?.name && (
        <div className="d-flex justify-content-between bg-white px-2 ripple border position-fixed box-fixlen">
          {activeChat && (
            <div className="d-flex flex-row align-items-center">
              <Link to="/secure-chat" className="mb-0 mx-2 ml-0 ">
                <i className="fa-solid fa-angle-left"></i>
              </Link>
              <div>
                <img src={props.avatar || "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"} alt={props.alt || "avatar"} className="d-flex align-self-center me-3" width={50} />
              </div>
              <div className="pt-1">
                <p className="fw-bold mb-0 cl-black  make-text-ellipsis">{activeChat?.name || "Not Found"}</p>
                <p className="small text-muted w-80 make-text-ellipsis">{activeChat?.email || "Not Found"}</p>
              </div>
            </div>
          )}
        </div>
      )}
      {!activeChat && (
        <div className="d-flex justify-content-center px-5 position-fixed box-fixlen" style={{ paddingTop: "160px", backgroundColor: "#F4F4F4" }}>
          <div className="d-flex flex-column align-items-center vh-100">
            <img src={chatBubblesImg} alt="" width={130} height={105} />
            <p className="cl-sec-blue text-align-center" style={{ fontSize: "26px" }}>
              Use{" "}
              <span>
                <AiOutlinePlusCircle />
              </span>{" "}
              button to chat with existing leads
            </p>
            <p className="cl-grey1">OR</p>
            <p className="cl-sec-blue" style={{ fontSize: "18px" }}>
              Wait for a homeowner to reach out to you
            </p>
          </div>
        </div>
      )}
      <div className="p-3 currmsgs overflow-auto scb-none">
        <br />
        <br />
        <br />
        {activeChatMsgs ? (
          activeChatMsgs?.map((e, i) => {
            return <MessageBox position={e?.sender === getLoginId() ? "end" : "start"} type={"text"} text={e?.text || ""} time={new Date(e?.createdAt).toGMTString().slice(0, -3)} key={i} />;
          })
        ) : (
          <div
            style={{ color: "#3b5998", width: "100%", height: "60vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "22px", fontWeight: "500" }}
          >
            <img src={chatBubblesImg} alt="" width={130} height={105} />
            <div>Click on a homeowner to start chat</div>
          </div>
        )}
      </div>
      <br />
      <br />
      <br />

      {activeChat?.email && (
        <div className="bg-white text-muted d-flex justify-content-start align-items-center py-3 px-2  mt-2  msgInput box-fixlen" style={{ width: "70%" }}>
          {/* <input type="text" className="form-control form-control-lg rounded-pill bg-grey1" id="exampleFormControlInput2" placeholder="Type message" value={chatTxt} onChange={(e) => {
                    setChatTxt(e.target.value);
                }} /> */}
          <div className="position-relative ">
            <button
              className="btn btn-link me-3 text-muted ripple rounded-circle px-2 pclip"
              style={{ height: "2rem", width: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                document.getElementById("pclipop").classList.toggle("show-pclip");
              }}
            >
              <i className="fas fa-paperclip" />
            </button>

            <div className="position-absolute" id="pclipop">
              <div className="position-relative">
                <input
                  type="file"
                  className="opacity-0 position-absolute"
                  accept=".jpeg , .png"
                  multiple="multiple"
                  style={{ zIndex: 1 }}
                  onChange={async (e) => {
                    let x = await uploadImages(e.target.files);
                    console.log(x);
                    document.getElementById("chatInp").innerHTML += x;
                  }}
                />
                <button className="btn bg-white btn-floating">
                  <i className="fas fa-camera" />
                </button>
              </div>
            </div>
          </div>
          <div className="position-absolute top-0" style={{ marginLeft: "50%", marginTop: "-4px" }}>
            <i
              className="fa-solid fa-angle-up"
              onClick={(e) => {
                document.getElementById("chatInp").classList.toggle("expandChatInp");
                e.target.classList.toggle("fa-angle-down");
                e.target.classList.toggle("fa-angle-up");
              }}
            ></i>
          </div>
          <div
            className="form-control br-5 rounded-pill bg-grey1 overflow-auto scb-none py-2"
            id="chatInp"
            contentEditable={true}
            suppressContentEditableWarning={true}
            style={{ maxHeight: "100px", lineHeight: "20px" }}
          >
            {" "}
          </div>

          <a
            className="ms-3"
            href="#!"
            onClick={() => {
              sendMessage();
            }}
          >
            <i className="fas fa-paper-plane" />
          </a>
        </div>
      )}
    </div>
  );
};

export default AllMessages;
