import React from 'react';
import styles from "./ProductsCss/productSec8.module.css";
import icon1 from "./ProductsImg/leads/database.svg";
import icon2 from "./ProductsImg/leads/manage_accounts.svg";
import icon3 from "./ProductsImg/leads/motion_photos_auto.svg";
// import img8 from "./ProductsImg/leads/newlead.svg";


const ProductSection8 = () => {
  return (
    <>
        <div className={styles.container} id="lead">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>LEAD MANAGEMENT</div>
            <div className={styles.file_para}>
            Manage leads systematically and on the go with quick tools like ‘create quotation’ or ‘follow ups’ and multiple email templates and status updates
            </div>
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt=""  className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Centralized lead <br /> Database
                </div>
              </div>

              <div className={styles.eachBox}> 
                <div>
                  <img src={icon3} alt=""  className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Automated emails <br /> and notifications
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img
                    src={icon2}
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>Sales pipeline <br /> management </div>
              </div>
            </div>
          </div>
          <div className={styles.img_div}>
            
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/leadNew.svg" alt="" className={styles.mainImg}/>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default ProductSection8;