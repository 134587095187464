import React from "react";
import styles from "../../Pricing/BoxOne.module.css";
import Icon from "../../Pricing/Images/Icon.svg";
import right from "../../Pricing/Images/right.svg";
import app from "../../Pricing/Images/app.svg";
import { additionalFeatures } from "../../Pricing/mockData";
import checkCircle from "../../Pricing/Images/CheckCircle.svg";
import { useNavigate } from 'react-router-dom';
import pro from "../../Pricing/Images/pro.svg";

const mockData=[
    "Custom Business App",
    "Product Alignments with organization flow ",
    "Work privately on your white label Product",
    "ZOHO/META/Domain server Integrations",
    "Personalized Customizations ",
    "On-site Training",
    "Dedicated implementation and support teams",
    "High End Data Security with streamlined analytics"
]

const EnterpriseMob = ({forScroll}) => {
  const navigateTo= useNavigate()
  return (
    <>
      <div className={styles.box2}>
        <div style={{ display: "flex" }}>
          <div className={styles.buttons} style={{background: "#E5F4FF"}}>
            <span className={styles.buttonsText} style={{color: "#0081E8"}}>Large firms</span>
          </div>
        </div>
        <div style={{ marginTop: "0.56rem" }}>
          <div className={styles.inBoxHeading}>Enterprise</div>
        </div>
        <div style={{ marginTop: "0.69rem" }}>
          <div className={styles.inBoxSummary}>
            Bespoke system connected with existing platform and databases for
            seamless project management{" "}
          </div>
        </div>
        <div style={{ marginTop: "0.75rem" }}>
          <div className={styles.additionalFeatures}>
          <img src={pro} alt="pro" className={styles.proImage} style={{marginLeft:"0rem", marginRight:"0.5rem"}} /> 
          {/* <img src={app} alt="app" style={{verticalAlign:"middle",  marginRight:"0.5rem", height:"1rem", width:"1rem"}}/> */}
          Additional features</div>
          <div>
            {mockData.map((x, index) => {
              return (
                <div key={index}>
                  <img
                    src={right}
                    alt="right"
                    style={{ verticalAlign: "middle",width:"1rem",height:"1rem" }}
                  />
                  <span
                    style={{
                      marginLeft: "0.75rem",
                      fontSize: "0.625rem",
                      fontWeight: "400",
                    }}
                  >
                    {x}
                  </span>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "1.32rem" }}>
            <img src={right} alt="right" style={{ verticalAlign: "middle", width:"1rem",height:"1rem" }} />
            <span
              className={styles.yoursForeverText}
              style={{ marginLeft: "0.75rem" }}
            >
              Yours to keep, forever.
            </span>
          </div>
          <img
            src={Icon}
            alt=""
            style={{ marginTop: "-38px", marginLeft: "25px", width:"7rem" }}
          />
          <div
            classname={styles.allBasicProText}
            style={{ marginTop: "-0.8rem", display: "flex", gap: "0.5rem" }}
          >
            <img
              src={checkCircle}
              alt="right"
              style={{ verticalAlign: "middle", width:"0.9375rem", height:"0.9375rem" }}
            />
            <span style={{fontSize:"0.75rem", fontWeight:"700"}}>All basic Professional features</span>
          </div>
          <div
            className={styles.blueButtonContainer}
            style={{ marginTop: "1.16rem", marginBottom: "0.84rem", marginLeft:"auto", marginRight:"auto" }}
          >
            <div className={styles.blueBorderButton} style={{marginLeft:"auto", marginRight:"auto"}}>
              <div className={styles.blueBorderButtonText}
               style={{cursor:"pointer"}}
               onClick={()=>navigateTo("/contact")}>Let's Talk!</div>
            </div>
          </div>
          <div
            className={styles.showFeaturesBtn}
            style={{ cursor: "pointer" }}
          >
           <a href={forScroll} style={{color:"inherit", textDecoration:"none"}}>Show features</a> 
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterpriseMob;
