import React from "react";
import styles from "./buyLeadsContentMob.module.css";
import { BsChevronDown } from "react-icons/bs";
import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
import peekingKid from "../../../../../Assets/BuyLeads/kidPeekinHi.svg";
import coolKid from "../../../../../Assets/BuyLeads/coolKid.svg";
import oneMonthKid from "../../../../../Assets/BuyLeads/lessThanMonthKid.svg";
import sixWeekKid from "../../../../../Assets/BuyLeads/sixWeeksKid.svg";
import dialogBox from "../../../../../Assets/BuyLeads/dialogBoxPeekinHi.svg";
import BuyLeadsCardMob from "../BuyLeadsCardMob/BuyLeadsCardMob";
import { useSelector } from "react-redux";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import { useDispatch } from "react-redux";
import { lmsBuyLeadsBudgetFilter, lmsBuyLeadsWorkFilter } from "../../../../../Lms/Actions/lmsActions";
import FootNavigation from "../../../../LeadManagementPage/Components/LeadManagementComponentsMob/FootNavigation/FootNavigation";

const BuyLeadsContentMob = () => {
  const dispatch = useDispatch();
  const buyLeads = useSelector((state) => state.lmsReducer.buyLeads);
  const isLoading = useSelector((state) => state.noPersist.isLoading);
  const buyLeadsFilterBudget = useSelector((state) => state.noPersist.buyLeadsFilterBudget);
  const buyLeadsFilterWork = useSelector((state) => state.noPersist.buyLeadsFilterWork);
  const [showBudgetFilter, setShowBudgetFilter] = useState(false);
  const [showWorkFilter, setShowWorkFilter] = useState(false);
  return (
    <React.Fragment>
      <Offcanvas show={showBudgetFilter} onHide={() => setShowBudgetFilter(false)} placement="bottom" className={styles.budgetFilterOffcanvas}>
        <Offcanvas.Header onClick={() => setShowBudgetFilter(false)} className={styles.filterHeader}>
          <div className={styles.closeBar}></div>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.budgetFilterBody}>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsBudgetFilter("all"))}>
            All
          </div>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsBudgetFilter("₹ 1,00,000 - ₹ 3,00,000"))}>
            1 - 3 Lacs
          </div>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsBudgetFilter("₹ 3,00,000 - ₹ 7,00,000"))}>
            3 - 7 Lacs
          </div>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsBudgetFilter("Over ₹ 7,00,000"))}>
            Over 7 Lakh
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showWorkFilter} onHide={() => setShowWorkFilter(false)} placement="bottom" className={styles.budgetFilterOffcanvas}>
        <Offcanvas.Header onClick={() => setShowWorkFilter(false)} className={styles.filterHeader}>
          <div className={styles.closeBar}></div>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.budgetFilterBody}>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsWorkFilter("all"))}>
            All
          </div>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsWorkFilter("Full home interior"))}>
            Full home interior
          </div>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsWorkFilter("Modular Renovation"))}>
            Renovation
          </div>
          <div className={styles.eachFilterButton} onClick={() => dispatch(lmsBuyLeadsWorkFilter("Only Design"))}>
            Only Design
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className={styles.fullContainer}>
        <div className={styles.pageHeading}>
          <div className={styles.mainHead}>Buy Leads</div>
          <div className={styles.filterButtonContainer}>
            <div className={`me-2 ${styles.filterButtons}`} onClick={() => setShowBudgetFilter(true)}>
              {buyLeadsFilterBudget === "all"
                ? "Budget"
                : buyLeadsFilterBudget === "₹ 1,00,000 - ₹ 3,00,000"
                ? "1 - 3 Lakhs"
                : buyLeadsFilterBudget === "₹ 3,00,000 - ₹ 7,00,000"
                ? "3 - 7 Lakhs"
                : buyLeadsFilterBudget === "Over ₹ 7,00,000"
                ? "Over 7 Lakh"
                : "N/A"}{" "}
              <BsChevronDown />
            </div>
            <div className={styles.filterButtons} onClick={() => setShowWorkFilter(true)}>
              {buyLeadsFilterWork === "all"
                ? "Work Type"
                : buyLeadsFilterWork === "Full home interior"
                ? "Full Home Interior"
                : buyLeadsFilterWork === "Modular Renovation"
                ? "Modular Renovation"
                : buyLeadsFilterWork === "Only Design"
                ? "Only Design"
                : "N/A"}{" "}
              <BsChevronDown />
            </div>
          </div>
        </div>
        {!isLoading ? (
          <div className={styles.allSectionsContainer}>
            <div className={styles.justLandedContainer}>
              <div className={styles.justLandedContainerHeading}>
                <div className={styles.sectionTextHead}>Just Landed!</div>
                <div className={styles.sectionHeading}>
                  <img src={peekingKid} alt="" />
                  <div style={{ backgroundImage: `url(${dialogBox})` }} className={styles.dialogBox}>
                    These leads are brand-new, grab them with a call!
                  </div>
                </div>
              </div>
              {buyLeads?.data?.data[0] && buyLeads?.data?.data[0]?.justLanded.length > 0 ? (
                <div className={styles.cardsContainer}>
                  {buyLeads?.data &&
                    buyLeads?.data?.data[0]?.justLanded.map((curElem) => {
                      return <BuyLeadsCardMob key={curElem._id} cardData={curElem} />;
                    })}
                </div>
              ) : (
                <div className={styles.nothingToShow}>Nothing To Show Here...</div>
              )}
            </div>

            <div className={styles.justLandedContainer}>
              <div className={styles.justLandedContainerHeading}>
                <div className={styles.sectionTextHead}>Less than 2 Weeks Old</div>
                <div className={styles.sectionHeading}>
                  <img src={coolKid} alt="" />
                  <div style={{ backgroundImage: `url(${dialogBox})` }} className={styles.dialogBox}>
                    You wouldn't want to miss on a great lead, would you? So check out these leads right away!
                  </div>
                </div>
              </div>
              {buyLeads?.data?.data[1] && buyLeads?.data?.data[1]?.twoWeeks.length > 0 ? (
                <div className={styles.cardsContainer}>
                  {buyLeads?.data &&
                    buyLeads?.data?.data[1]?.twoWeeks.map((curElem) => {
                      return <BuyLeadsCardMob key={curElem._id} cardData={curElem} />;
                    })}
                </div>
              ) : (
                <div className={styles.nothingToShow}>Nothing To Show Here...</div>
              )}
            </div>

            <div className={styles.justLandedContainer}>
              <div className={styles.justLandedContainerHeading}>
                <div className={styles.sectionTextHead}>Less than a Month old</div>
                <div className={styles.sectionHeading}>
                  <img src={oneMonthKid} alt="" />
                  <div style={{ backgroundImage: `url(${dialogBox})` }} className={styles.dialogBox}>
                    Hey, take care not to keep these leads waiting for too long. Seize the deal today!
                  </div>
                </div>
              </div>
              {buyLeads?.data?.data[2] && buyLeads?.data?.data[2]?.oneMonth.length > 0 ? (
                <div className={styles.cardsContainer}>
                  {buyLeads?.data &&
                    buyLeads?.data?.data[2]?.oneMonth.map((curElem) => {
                      return <BuyLeadsCardMob key={curElem._id} cardData={curElem} />;
                    })}
                </div>
              ) : (
                <div className={styles.nothingToShow}>Nothing To Show Here...</div>
              )}
            </div>

            <div className={styles.justLandedContainer}>
              <div className={styles.justLandedContainerHeading}>
                <div className={styles.sectionTextHead}> 4 to 6 Week Old</div>
                <div className={styles.sectionHeading}>
                  <img src={sixWeekKid} alt="" />
                  <div style={{ backgroundImage: `url(${dialogBox})` }} className={styles.dialogBox}>
                    Good day! Hurry, you still have time to grab these fantastic leads!
                  </div>
                </div>
              </div>
              {buyLeads?.data?.data[3] && buyLeads?.data?.data[3]?.sixWeeks.length > 0 ? (
                <div className={styles.cardsContainer}>
                  {buyLeads?.data &&
                    buyLeads?.data?.data[3]?.sixWeeks.map((curElem) => {
                      return <BuyLeadsCardMob key={curElem._id} cardData={curElem} />;
                    })}
                </div>
              ) : (
                <div className={styles.nothingToShow}>Nothing To Show Here...</div>
              )}
            </div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </div>
      <div className="w-100" style={{ position: "fixed", bottom: "0" }}>
        <FootNavigation />
      </div>
    </React.Fragment>
  );
};

export default BuyLeadsContentMob;
