import React, { useEffect } from 'react'
import { useState } from "react";
import img from "../../Images/addImageIcon.png";
import editImage from "../../Images/editIcon.png";
import saveImage from "../../Images/bx_save.svg";
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addMoreProjectImages, addProjectImages, setProjectDetailsRedux, setProjectPicLoadingTrue } from '../../Lms/Actions';
import { useRef } from 'react';


export default function AddProjectPicsDesignerWeb() {
  const addImagesRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const projName = useSelector((state) => state.addToCartReducer.projectDetails);
  const [title, setTitle] = useState(projName?.name);
  const navigateTo = useNavigate();

  function handlePics(e) {
    let obj = e.target.files;
    let filesArr = [];
    for (let i = 0; i < obj.length; i++) {
      filesArr.push({ file: obj[i], path: URL.createObjectURL(obj[i]) });
    }
    dispatch(addProjectImages(filesArr));
    dispatch(setProjectPicLoadingTrue());
    navigateTo("/saveoredit");
  }

  function handleClick() {
    setIsEditMode(!isEditMode);
  }

  function handleTitle(e) {
    setTitle(e.target.value);
  }
  const saveProjectTitle = () => {
    dispatch(setProjectDetailsRedux(title, projName?.city));
  }

  // useEffect(() => {
  //   setTitle(projName?.name);
  // }, [projName.name])

  // useEffect(() => {
  //   if(!title){
  //     navigateTo("/addproject")
  //   }
  // }, [])
  return (
    <>
      <div className="main-professionContainer-web d-flex justify-content-center align-items-center">
        <div style={{ background: "white", borderRadius: "10px", overflow: "hidden" }} className="main-div row">
          <div className="main-addprojectpicsContainer-web">
            <div className="header" style={{ boxShadow: 'none', justifyContent: 'start', alignItems: "center" }}>
              {!isEditMode && <div className='fs-5'>{projName?.name}</div>}
              {isEditMode && <input style={{ fontSize: "16px", borderRadius: "4px", border: "1px solid black", width: "8rem" }} onChange={handleTitle} type="text" value={title} />}
              <div style={{ marginLeft: "1rem" }} onClick={handleClick}>
                {isEditMode ? <img role="button" style={{ height: "14px" }} src={saveImage} onClick={saveProjectTitle} alt="" /> : <img role="button" style={{ height: "12px" }} src={editImage} alt="" />}
              </div>
            </div>
            <div className="body" role="button" onClick={() => { addImagesRef.current.click() }}>
              <button className="clicktoaddpics">
                <input className="d-none" onChange={handlePics} ref={addImagesRef} type="file" multiple /> <img src={img} />
              </button>
              <h2 style={{ textAlign: "center", color: "#3D3D3D" }}>Please upload your project photos</h2>
              <p style={{ textAlign: "center" }}> Accepted format jpeg,png </p>
            </div>
            <div></div>
            {/* {isEditMode && <AddProjectDetails />} */}
          </div>
        </div>
      </div>
    </>
  )
}
