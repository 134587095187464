import React, { useEffect, useState } from 'react'
import goBack from "../Images/gobackicon.svg";
import img3d from './3dImages/3dimg.svg'
import cadicon from './3dImages/cadicon.svg'
import pdficon from './3dImages/pdficon.svg'
import blank3d from './3dImages/blank3d.svg'
import check from "../Images/tickIcon.svg";
import gstDoc from "../Images/gstShowingDocIcon.svg";
import editIcon from "../Images/editingPencil.svg";
import info from "./3dImages/info.svg";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import { fetchProfileData, setGstDetails } from '../Lms/Actions';
import { Form, Modal, Offcanvas } from 'react-bootstrap';
import closeCanvasBar from "./3dImages/closeCanvasBar.svg"
import { getToken } from "../Lms/getToken";
import { createOrder } from '../Apis';
import { RAZOR_PAY_KEY } from '../Config';
import { toast } from "react-hot-toast";
const CartPayHalfMob = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pay, setPay] = useState("full")
    const handleRadio = (event) => {
        setPay(event.target.value)
    }
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])

    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo)
    // console.log(profileInfo[0]?.data?.data?.planId?._id)

    useEffect(async () => {
        const res2 = await axios.get(`https://3d-api.idesign.market/api/room/get-full-data?id=${localStorage.getItem("halfPayId")}`)
        // console.log(res2.data)
        setUserData(res2.data)
    }, [])

    useEffect(async () => {
        const res = await axios.get(`https://3d-api.idesign.market/api/room/cart-data?designerId=${localStorage.getItem("userId")}&planId=${profileInfo[0]?.data?.data?.planId?._id}`)
        setData(res.data)
    }, [profileInfo[0]?.data?.data?.planId?._id])

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    // console.log(userData)
    var totViews = 0
    for (let j = 0; j < userData?.room?.length; j++) {
        totViews += userData?.room[j]?.views
    }

    const initialGstData = {
        companyName: profileInfo[0]?.data?.data?.companyName,
        gstNum: profileInfo[0]?.data?.data?.gstNumber,
    };
    const authTok = getToken() ? getToken() : "";
    const [gstOffcan, setGstOffcan] = useState(false);
    const [gstData, setGstData] = useState(initialGstData);
    const [showError, setShowError] = useState({
        companyNameError: "",
        gstNumError: "",
    });
    const gstOffcanHandler = (event) => {
        setGstOffcan(event.target.checked);
    };
    const [customCheckbox, setCustomCheckbox] = useState(profileInfo[0]?.data?.data?.planId?.name === "Free" ? false : false);
    const gstDataInputHandler = (event) => {
        const { name, value } = event.target;
        setGstData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleGstSubmit = () => {
        if (gstData.companyName.length < 1) {
            setShowError((prev) => {
                return {
                    ...prev,
                    companyNameError: "You cannot leave company name empty",
                };
            });
        } else if (gstData.gstNum.length < 1) {
            setShowError((prev) => {
                return {
                    ...prev,
                    gstNumError: "You cannot leave GST number empty",
                };
            });
        } else {
            setGstOffcan(false);
            dispatch(setGstDetails(authTok, gstData.gstNum, gstData.companyName));
        }
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var discount = 0
    if (profileInfo[0]?.data?.data?.planId?.name !== "Free") {
        discount = Math.round((0.166667) * ((totViews * 1200) / 2))
    }
    var gst = Math.round((18 / 100) * (((totViews * 1200) / 2) - discount))
    
    const placeOrderOnRazorpay = async () => {
        var amount = Math.round(userData.paidAmount);
        const res = await createOrder({ amount: parseFloat(amount.toFixed(2)) });

        if (res?.statusCode !== 200) {
            console.log(res?.message);
            return;
        }

        const option = {
            description: "iDesign payment",
            currency: "INR",
            key: RAZOR_PAY_KEY,
            amount: amount,
            name: "iDesign.market pvt. ltd.",
            order_id: res?.data?.id,
            handler: paymentHandler.bind(this, amount),
            prefill: {
                name: profileInfo[0].data.data.companyName,
                email: profileInfo[0].data.data.email,
                contact: profileInfo[0].data.data.phoneNumber,
            },
            notes: {
                address: `Payment for cart`,
            },
            theme: {
                color: "#61dafb",
            },
        };

        try {
            const paymentObject = new window.Razorpay(option);
            paymentObject.open();
        } catch (err) {
            toast.error(err?.message)
        }
    };

    const paymentHandler = async (amount, response) => {
        const data = {
            pricePaid: amount,
            razorPaymentId: response?.razorpay_payment_id,
            razorOrderId: response?.razorpay_order_id,
            razorSignature: response?.razorpay_signature,
        };
        if (response) {
            const res = await axios.put(`https://3d-api.idesign.market/api/room/update-amount?id=${localStorage.getItem("halfPayId")}`, { paidAmount: userData.amountToBePaid }).then((res) => {
                navigate("/3dFinal")
                localStorage.removeItem("halfPayId")
            }).catch(err => console.warn(err))
        }
    };

    const proceedToBuy = async() => {
        placeOrderOnRazorpay()
        // const res = await axios.put(`https://3d-api.idesign.market/api/room/update-amount?id=${localStorage.getItem("halfPayId")}`, { paidAmount: userData.amountToBePaid }).then((res) => {
        //     navigate("/3dFinal")
        //     localStorage.removeItem("halfPayId")
        // }).catch(err => console.warn(err))
    }
    return (
        <>
            
            <div className='d-block d-md-none'>
            <Offcanvas
                show={gstOffcan}
                onHide={() => {
                    setGstOffcan(false);
                }}
                placement="bottom"
                style={{ height: "fit-content", borderRadius: "1rem 1rem 0 0" }}
            >
                <Offcanvas.Header className="mb-0">
                    <div
                        className="w-100 d-flex justify-content-center"
                        onClick={() => {
                            setGstOffcan(false);
                        }}
                    >
                        <img src={closeCanvasBar} />
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-3">
                    <div className="w-100 fs-4 fw-bold mb-3">GST Information</div>
                    <div className="mb-3">
                        <Form.Control type="text" value={gstData.companyName} name="companyName" placeholder="Company Name" onChange={gstDataInputHandler} />
                        {gstData?.companyName?.length < 1 && <span style={{ color: "red", fontSize: "10px", fontWeight: "400" }}>{showError.companyNameError}</span>}
                    </div>
                    <div className="mb-3">
                        <Form.Control type="text" maxLength={15} value={gstData.gstNum} name="gstNum" placeholder="GST Number" onChange={gstDataInputHandler} />
                        {gstData?.gstNum?.length < 1 && <span style={{ color: "red", fontSize: "10px", fontWeight: "400" }}>{showError.gstNumError}</span>}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <button style={{ width: "100%", padding: "0.5rem", border: "none", backgroundColor: "#176091", color: "white", borderRadius: "0.3rem" }} onClick={handleGstSubmit}>
                            CONFIRM
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
                <Modal show={show} onHide={handleClose} centered size="md">
                    <Modal.Body className='d-flex flex-column p-4'>
                        <p style={{
                            fontWeight: "400",
                            fontSize: "22px",
                            color: "black"
                        }}>Are you sure you want to remove this?</p>
                        <div>
                            <button style={{
                                background: "#176091",
                                borderRadius: "4px", border: "none", color: "white", width: "90px", height: "32px", cursor: "pointer"
                            }}>Yes</button>
                            <button className='mx-3' style={{
                                background: "#FFFFFF",
                                borderRadius: "4px", border: "1px solid gainsboro", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)", color: "black", width: "90px", height: "32px", cursor: "pointer"
                            }} onClick={handleClose}>No</button>
                        </div>
                    </Modal.Body>
                </Modal>
            <div style={{ background: "white" }}>
                <div className="webMyCart-header" style={{ padding: "12px 12px", borderBottom: "1px solid #DFDFDF", boxShadow: "none" }}>
                    <div className="d-flex align-items-center" role="button">
                        <img src={goBack} />
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: "500" }} role="button">
                        My Cart
                    </div>
                </div>
                <div style={{ width: "100%", padding: "2rem", height: "90vh", background: "white" }} className="myCart-wholeContent">

                    <div className="webCart-pricingPart" style={{ width: "100%" }}>
                        <div className="sections" style={{ padding: "16px 24px 16px 20px", border: "1px solid #DFDFDF" }}>
                            <div style={{ fontSize: "15px", fontWeight: "400", marginBottom: "16px", paddingBottom: "10px", borderBottom: "1px solid #DFDFDF" }}>Price Details</div>

                            <div className="d-flex justify-content-between">
                                <div style={{ fontSize: "13px", fontWeight: "700" }}>Total View({totViews})</div>
                                    <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {(totViews * 1200) / 2}</div>
                            </div>
                            {profileInfo[0]?.data?.data?.planId?.name !== "Free" ? <div className="d-flex justify-content-between">
                                <div style={{ fontSize: "13px", fontWeight: "700" }}>Discount <span style={{
                                    color: "#888888", fontWeight: "400", fontSize: "12px"
                                }}>(premium user)</span></div>
                                    <div style={{ fontSize: "13px", fontWeight: "400", color: "#176091" }}>- ₹ {discount}</div>
                            </div> : ""}
                            <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: "30px", marginBottom: "16px" }}>
                                <div style={{ fontSize: "13px", fontWeight: "700" }}>GST- 18%</div>
                                    <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {gst}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div style={{ fontSize: "15px", fontWeight: "700" }}>Total Price</div>
                                    <div style={{ fontSize: "13px", fontWeight: "400" }}>₹ {Math.round(userData.paidAmount)}</div>
                            </div>
                        </div>
                        <div className="sections" style={{ padding: "12px 18px 16px 18px", border: "1px solid #DFDFDF" }}>
                            <div className="d-flex align-items-center" style={{ paddingBottom: "10px" }} >
                                <div>
                                    <div
                                        className="customCheckbox"
                                        style={{ marginRight: "12px", userSelect: "none" }}
                                        role="button"
                                        onClick={() => {
                                            setGstOffcan(true);
                                        }}
                                    >
                                        {profileInfo[0]?.data?.data?.gstNumber ? <img src={check} /> : null}
                                    </div>
                                </div>
                                <div style={{ fontSize: "12px" }}>
                                    I have a GST number <span>(Optional)</span>
                                </div>
                            </div>
                            <div>
                                {gstData?.gstNum?.length > 0 && (<div className="gstInfoShowingBox" style={{ padding: "8px 16px 8px 12px" }}>
                                    <div className="d-flex align-items-center">
                                        <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                            <img src={gstDoc} />
                                        </div>
                                        <div>
                                            <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.companyName}</div>
                                            <div style={{ fontSize: "11px", fontWeight: "700" }}>{gstData.gstNum}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={editIcon}
                                            role="button"
                                            style={{ height: "20px", width: "18px" }}
                                            onClick={() => {
                                                setGstOffcan(true);
                                            }}
                                        />
                                    </div>
                                </div>)}
                            </div>
                        </div>
                        {/* <div className="sections">
                              <button className="proceedToBuyButton" >
                                  Proceed To Buy
                              </button>
                          </div> */}
                    </div>
                    <div className="item_detailsMob mt-5 pb-5">
                        <h3 className='img_lan_header mb-3'>Item Details</h3>
                        <div className="box_start d-flex flex-column ">
                            <div className="box_top d-flex mt-3">
                                <img src={blank3d} alt="" className="box_blank" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                                <div className="box_lines d-flex flex-column mx-2">
                                    <h4 className="box_head">3D feature</h4>
                                        {userData && userData?.cadFile?.fileName && <p className="box-file1" ><img src={cadicon} style={{ height: "15px", width: "10px", marginRight: "8px" }} alt="" className="fileimg" />{userData?.cadFile?.fileName?.slice(0, 9)}{"..."}</p>}
                                        {userData.pdf && userData?.pdf?.fileName ? <p className="box-file1"><img src={pdficon} style={{ height: "15px", width: "10px", marginRight: "8px" }} alt="" className="fileimg" />{userData?.pdf?.fileName?.slice(0, 9)}{"..."}</p> : <p className="box-file1"><img src={pdficon} style={{ height: "15px", width: "10px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                                </div>
                                    {/* <button className='pay-btn float-end' onClick={handleShow}>Remove</button> */}
                            </div>
                            <div className="view_btns-mob d-flex p-3 flex-wrap">
                                {userData?.room?.map((item, index) => (
                                    <button className="mapped mb-2">{item.name}({item.views})</button>
                                ))}
                            </div>
                            <div className="end-cart-mob d-flex w-100 px-3">
                                    <p className="cart_tot2">₹ {(totViews * 1200) / 2}</p>
                                <span className='cart_payment2'>(Half Payment)</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="cart-btns">
                <div className="car_btns_det">
                        <p className='det_price'>{Math.round(userData.paidAmount)}</p>
                    <p className='p_details'>View price details</p>
                </div>
                <button className="car_btns_place" onClick = {proceedToBuy}>Place Order</button>
            </div>
            </div>
        </>
    )
}

export default CartPayHalfMob