import React, { useState, useEffect } from "react";
import styles from "./OtherCompanies.module.css";
import cicle from "./Images/circle.jpg"
import Interia from "./Images/Interia.jpg"
import Chalkstudio from "./Images/chalkstudio.jpg"
import Aroquite from "./Images/Aroquite.jpg"
import Alience from "./Images/Alience.jpg"
import Ambienece from "./Images/Ambienece.jpg"
import Alsorg from "./Images/Alsorg.jpg"
import Magppie from "./Images/Magppie.jpg"
import woodofa from "./Images/woodofa.jpg"
import Luxlen from "./Images/Luxlen.jpg"
import Trones from "./Images/Trones.jpg"
import RRR from "./Images/RRR.jpg"
import BNK from "./Images/BNK.jpg"
import Sign from "./Images/Sign.jpg"
import Archihives from "./Images/Archihives.jpg"
import Tejomaya from "./Images/Tejomaya.jpg"
import Unified from "./Images/Unified.jpg"
import DESIGN from "./Images/DESIGN.jpg"
import Feybrush from "./Images/Feybrush.jpg"
import UrbanZen from "./Images/UrbanZen.jpg"
import Ochrone from "./Images/Ochrone.jpg"
import Awd  from "./Images/Awd.jpg"


const OtherCompanies = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);

  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);

    
  });

  return (
    <>
      <div className={styles.otherCompaniesContainer}>
        <div className={styles.mainContainer}>
          {isDesktop ? (
            <div className={styles.heading}>Preferred by industry leaders</div>
          ) : (
            <div className={styles.heading}>
              Preferred by industry
              <br /> leaders
            </div>
          )}
          <div className={styles.imagesContainer}>
            {isDesktop ? (
              <>
              <div style={{width:"100%",display:"flex",flexDirection:"column",gap:"20px"}}>

              <marquee  behavior="" scrollamount="10" direction="right">
                <div style={{display:"flex",gap:"20px" ,alignItems:"center",width:"100%"}}>

   
                <img
                  className={styles.ambience}
                  src={Ambienece}
                  alt="ambience"
                />
                <img
                  className={styles.swb}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/swb.png"
                  alt="swb"
                />
                <img
                  className={styles.essentia}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/essentia.svg"
                  alt="essentia"
                />
                <img
                  className={styles.akdesigno}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/akDesigno.png"
                  alt="akDesigno"
                />
                <img
                  className={styles.alsorg}
                  src={Alsorg}
                  alt="alsorg"
                />
                <img
                  className={styles.design}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/design.png"
                  alt="design"
                />
                   <img
                  className={styles.design}
                  src={Magppie}
                  alt="Magppie"
                />
                     <img
                  className={styles.design}
                  src={woodofa}
                  alt="woodofa"
                />

<img
                  className={styles.design}
                  src={Luxlen}
                  alt="Luxlen"
                />
                        <img
                  className={styles.design}
                  src={Trones}
                  alt="Trones"
                />
                           <img
                  className={styles.design}
                  src={RRR}
                  alt="rrr"
                />
                            <img
                  className={styles.design}
                  src={BNK}
                  alt="BNK"
                />
                             </div>
                </marquee>
                <marquee behavior="" scrollamount="10" direction="left">

                  <div style={{display:"flex",gap:"10px",alignItems:"center"}}>


                <img
                  className={styles.Tejomaya}
                src={Interia}
                  alt="Interia"
                />
                <img
                  className={styles.Interia}
                 src={Sign}
                  alt="Sign"
              
       
                />
                <img
                  className={styles.essentia}
                  src={Chalkstudio}
                  alt="Chalkstudio"
                />
                <img
                  className={styles.akdesigno}
                  src={Aroquite}
                  alt="Aroquite"
                />
                <img
                  className={styles.alsorg}
                  src={Archihives}
                  alt="Archihives"
                />
                  <img
                  className={styles.alsorg}
                  src={Tejomaya}
                  alt="Tejomaya"
                />
                  <img
                  className={styles.alsorg}
                  src={Unified}
                  alt="Unified"
                />
                  <img
                  className={styles.alsorg}
                  src={DESIGN}
                  alt="DESIGN"
                />
                  <img
                  className={styles.alsorg}
                  src={Feybrush}
                  alt="Feybrush"
                />
                    <img
                  className={styles.alsorg}
                  src={UrbanZen}
                  alt="UrbanZen"
                />
                    <img
                  className={styles.alsorg}
                  src={Ochrone}
                  alt="Ochrone"
                />

                    <img
                  className={styles.alsorg}
                  src={Awd}
                  alt="Awd"
                />
             
             </div>
                </marquee>

              </div>
           
              </>
            ) : (
              <>
                <div className={styles.imagesContainerInner}>
                  <img
                    className={styles.ambience}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ambience.png"
                    alt="ambience"
                  />
                  <img
                    className={styles.essentia}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/essentia.svg"
                    alt="essentia"
                  />
                  <img
                    className={styles.design}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/design.png"
                    alt="design"
                  />
                </div>
                <div className={styles.imagesContainerInner}>
                  <img
                    className={styles.swb}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/swb.png"
                    alt="swb"
                  />
                  <img
                    className={styles.akdesigno}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/akDesigno.png"
                    alt="akDesigno"
                  />
                  <img
                    className={styles.alsorg}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/alsorg.png"
                    alt="alsorg"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherCompanies;
