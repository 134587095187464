import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import FormInput from "../../../../Components/Forms/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "../../../../Constants/formSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import authStyles from "../../../Auth/ResetPassword/styles.module.scss";
import { setNewPassword } from "../../../../Apis";
import { toast } from "react-hot-toast";

const ChangePassword = ({ show, onHide }) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(changePasswordSchema) });
  const [showOldPass, setOldShowPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const onSubmit = ({ oldPassword, password }) => {
    setNewPassword({ oldPassword, newPassword: password }).then(() => {
      toast.success("Password Changed Successfully!");
      reset();
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
        onHide();
      }}
      centered
      className="interFont"
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={authStyles.input}>
            <FormInput
              label="Old Password"
              type={showOldPass ? "text" : "password"}
              placeholder="Old Password"
              isInvalid={!!errors?.oldPassword}
              error={errors?.oldPassword?.message}
              register={register("oldPassword")}
              formControlClass="disableErrorIcon"
            />
            <span
              onClick={() => setOldShowPass((show) => !show)}
              className={authStyles.eye}
            >
              <FontAwesomeIcon
                icon={showOldPass ? "eye-slash" : "eye"}
                className="me-1"
              />
            </span>
          </div>
          <div className={authStyles.input}>
            <FormInput
              label="New Password"
              type={showPass ? "text" : "password"}
              placeholder="New Password"
              isInvalid={!!errors?.password}
              error={errors?.password?.message}
              register={register("password")}
              formControlClass="disableErrorIcon"
            />
            <span
              onClick={() => setShowPass((show) => !show)}
              className={authStyles.eye}
            >
              <FontAwesomeIcon
                icon={showPass ? "eye-slash" : "eye"}
                className="me-1"
              />
            </span>
          </div>
          <div className={authStyles.input}>
            <FormInput
              label="Confirm New Password"
              type={showConfirmPass ? "text" : "password"}
              placeholder="Confirm New Password"
              isInvalid={!!errors?.confirmPassword}
              error={errors?.confirmPassword?.message}
              register={register("confirmPassword")}
              formControlClass="disableErrorIcon"
            />
            <span
              onClick={() => setShowConfirmPass((show) => !show)}
              className={authStyles.eye}
            >
              <FontAwesomeIcon
                icon={showConfirmPass ? "eye-slash" : "eye"}
                className="me-1"
              />
            </span>
          </div>
          <button type="submit" className="btn btn-create create-account">
            Change Password
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
