import React, { useEffect, useState } from "react";
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
// import goBack from "../../Images/gobackicon.svg";
import { useSelector } from "react-redux";
import check from "./VendorImgs/tickIcon.svg";
import gstDoc from "./VendorImgs/gstShowingDocIcon.svg";
import editIcon from "./VendorImgs/editingPencil.svg";
import { Form, Modal, Offcanvas, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { addPurchasedLeads, fetchProfileData, sendInvoice, setGstDetails } from "../Lms/Actions";
import { getToken } from "../Lms/getToken";
import { useLocation, useNavigate } from "react-router-dom";
import VendorCart from "./VendorCart";
import { Link } from "react-router-dom";
import closeCanvasBar from "./VendorImgs/closeCanvasBar.svg";
import left from "./VendorImgs/leftarrow.png";
import VendorCartSide from "./VendorCartSide";
import { faDotCircle, faFile, faInfoCircle, faPencilAlt, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomNav from "../Lms/BottomNav";
import axios from "axios";
import congo from './VendorImgs/congo.svg'
import { changePlan, createOrder, createPlan, createSubscription } from "../Apis";
import { RAZOR_PAY_KEY } from "../Config";
import { ROUTES } from "../Router/routes";


export default function VendorCartMob() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const path = useLocation();
  const authTok = localStorage.getItem("token") ? getToken() : "";
  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const initialGstData = {
    companyName: profileInfo[0]?.data?.data?.companyName,
    gstNum: profileInfo[0]?.data?.data?.gstNumber,
  };
  const [gstOffcan, setGstOffcan] = useState(false);
  const [gstData, setGstData] = useState(initialGstData);
  const [showError, setShowError] = useState({
    companyNameError: "",
    gstNumError: "",
  });
  const gstDataInputHandler = (event) => {
    const { name, value } = event.target;
    setGstData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleGstSubmit = () => {
    if (gstData.companyName.length < 1) {
      setShowError((prev) => {
        return {
          ...prev,
          companyNameError: "You cannot leave company name empty",
        };
      });
    } else if (gstData.gstNum.length < 1) {
      setShowError((prev) => {
        return {
          ...prev,
          gstNumError: "You cannot leave GST number empty",
        };
      });
    } else {
      setGstOffcan(false);
      dispatch(setGstDetails(authTok, gstData.gstNum, gstData.companyName));
    }
  };

  const [data, setData] = useState([])
  useEffect(async () => {
    const res = await axios.get("https://pro-api.idesign.market/user/listVendorPlanInCart", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authTok
      }
    }).then((res) => {
      // console.log(res)
      setData(res?.data?.data)
    }).catch(err => console.warn(err))
  }, [])
  // console.log(data)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [venId, setVenId] = useState([])

  useEffect(async () => {
    await axios.get("https://pro-api.idesign.market/user/listVendorPlans").then((venRes) => {
      console.warn(venRes)
      setVenId(venRes?.data?.data[0]?._id)
    }).catch(err => console.warn(err))
  }, [])

  // console.log(venId)


  const [planid, setPlanid] = useState()
  const [subsid, setSubsid] = useState()

  const placeOrderOnRazorpay = async () => {
    const amount = data?.totalPrice;

    // const amount = 1;
    const res = await createOrder({ amount: parseFloat(amount.toFixed(2)) });

    if (res) {
      const planRes = await createPlan({
        period: "monthly",
        interval: data?.plan.duration,
        planName: data?.plan.name,
        planAmt: parseFloat(amount.toFixed(2)),
        description: "subscription plan",
      });
      // console.log(planRes);
      setPlanid(planRes?.data?.id)
      localStorage.setItem("vendorPlanId", planRes?.data?.id)
      if (planRes) {
        const date = Date.now();
        const x = {
          planId: planRes?.data?.id,
          paymentCycles: data?.plan.duration === 1 ? 30 : null,
          startAt: Math.floor(new Date().getTime() / 1000) + 2592000
        };
        const subRes = await createSubscription(x);
        // console.log(subRes);
        setSubsid(subRes?.data?.id)
        localStorage.setItem("vendorSubsId", subRes?.data?.id)
        if (subRes) {
          console.log(subRes);
          setIsLoading(false);
          const option = {
            description: "iDesign payment",
            currency: "INR",
            key: RAZOR_PAY_KEY,
            amount: data?.totalPrice,
            name: "iDesign.market pvt. ltd.",
            subscription_id: subRes?.data?.id,
            handler: paymentHandler.bind(this, amount),
            prefill: {
              name: profileInfo[0]?.data?.data?.companyName,
              email: profileInfo[0]?.data?.data?.email,
              contact: profileInfo[0]?.data?.data?.phoneNumber,
            },
            notes: {
              address: `Payment for cart`,
            },
            theme: {
              color: "#3b5998",
            },
            recurring: true,
            checkout: {
              method: {
                upi: 1,
                netbanking: 1,
                card: 1,
                wallet: 1,
              },
            },
          };
          try {
            const paymentObject = new window.Razorpay(option);
            paymentObject.open();
          } catch (err) {
            console.log(err?.message);
          }
        }
        return;
      }
    }
  };

  const paymentHandler = async (amount, response) => {
    console.log(response);
    const data0 = {
      planId: data?.plan._id,
      pricePaid: amount,
      razorPaymentId: response?.razorpay_payment_id,
      razorOrderId: response?.razorpay_order_id,
      razorSignature: response?.razorpay_signature,
    };

    if (response) {
      const payload = {
        vendorSubPlanId: data0?.planId,
        pricePaid: data?.totalPrice,
        razorOrderId: data0?.razorOrderId,
        razorPaymentId: data0?.razorPaymentId,
        razorSignature: data0?.razorSignature,
        razorpay_plan_id: localStorage.getItem("vendorPlanId"),
        razorpay_subscription_id: localStorage.getItem("vendorSubsId")
      }
      const res = await axios.post("https://pro-api.idesign.market/user/changeVendorPlan", payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authTok
        }
      }).then((res) => {
        console.log(res)
        setShow(true);
      }).catch(err => console.warn(err))
    }
  };

  const handleShow = async () => {
    setIsLoading(true)
    placeOrderOnRazorpay()
    // const payload = {
    //     vendorSubPlanId: data?.plan._id,
    //     pricePaid: data?.totalPrice,
    //     razorOrderId: "666666",
    //     razorPaymentId: "666666fgfgfhfh",
    //     razorSignature: "ghghgvhgggh",
    //     razorpay_plan_id: "fyfyfytfyy",
    //     razorpay_subscription_id: "dtdtdtdrtrd"
    // }
    // const res = await axios.post("https://pro-api.idesign.market/user/changeVendorPlan", payload, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': authTok
    //     }
    // }).then((res) => {
    //     console.log(res)
    //     setShow(true);
    // }).catch(err => console.warn(err))
  }

  const handleBuy = () => {
    // navigate("/lead")
  }

  return (
    <React.Fragment>
      <div className="d-block d-md-none">
        <Modal show={show} onHide={handleClose} centered size="md">
          <Modal.Body>
            <div className="d-flex flex-column align-items-center p-3">
              <img src={congo} alt="" className="modal-blah" />
              <h2 className="ven-blah-blah" style={{ fontSize: "20px" }}>Congratulations,</h2>
              <p className="lan-ven-start" style={{fontSize: "14px"}}>Your vendor details has been submitted </p>
              <button className='rate_btn4 ' onClick={handleBuy}>Okay, got it</button>
            </div>
          </Modal.Body>
        </Modal>
        <Offcanvas
          show={gstOffcan}
          onHide={() => {
            setGstOffcan(false);
          }}
          placement="bottom"
          style={{ height: "fit-content", borderRadius: "1rem 1rem 0 0" }}
        >
          <Offcanvas.Header className="mb-0">
            <div
              className="w-100 d-flex justify-content-center"
              onClick={() => {
                setGstOffcan(false);
              }}
            >
              <img src={closeCanvasBar} />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-3">
            <div className="w-100 fs-4 fw-bold mb-3">GST Information</div>
            <div className="mb-3">
              <Form.Control type="text" value={gstData.companyName} name="companyName" placeholder="Company Name" onChange={gstDataInputHandler} />
              {gstData?.companyName?.length < 1 && <span style={{ color: "red", fontSize: "10px", fontWeight: "400" }}>{showError.companyNameError}</span>}
            </div>
            <div className="mb-3">
              <Form.Control type="text" maxLength={15} value={gstData.gstNum} name="gstNum" placeholder="GST Number" onChange={gstDataInputHandler} />
              {gstData?.gstNum?.length < 1 && <span style={{ color: "red", fontSize: "10px", fontWeight: "400" }}>{showError.gstNumError}</span>}
            </div>
            <div className="w-100 d-flex justify-content-center">
              <button style={{ width: "100%", padding: "0.5rem", border: "none", backgroundColor: "#176091", color: "white", borderRadius: "0.3rem" }} onClick={handleGstSubmit}>
                CONFIRM
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        
          <div>
          <div className="myCartContent w-100" >
              <div>
                <div className="myCart-container">
                  <div className="heading">
                    <Link to="/">
                      <img style={{ width: "5px", height: "10px", marginRight: "0.5rem" }} src={left} />
                    </Link>
                    <div style={{ fontSize: "18px", fontWeight: "600", padding: "6px 0" }}>My Cart</div>
                  </div>
                  <div className="priceDetailsCard">
                    <h6>Price Details</h6>
                    <div className="leadPrice-container p">
                      <div className="price" style={{ fontSize: "14px", fontWeight: "400" }}>
                         Price (1)
                      </div>
                      <div className="actualPrice" style={{ fontSize: "14px", fontWeight: "600" }}>
                      ₹ {data?.planPrice}
                      </div>
                    </div>
                    {/* <div className="discount-container p">
                      <div className="discount" style={{ fontSize: "14px", fontWeight: "400" }}>
                        Discount
                      </div>
                      <div className="actualDiscount" style={{ color: "#176091", fontSize: "14px", fontWeight: "600" }}>
                        4444
                      </div>
                    </div> */}

                    <div className="gst-container p">
                      <div className="gst" style={{ fontSize: "14px", fontWeight: "400" }}>
                        GST-18%
                      </div>
                      <div className="actualGst" style={{ fontSize: "14px", fontWeight: "600" }}>
                      ₹ {data?.gst}
                      </div>
                    </div>

                    <div className="totalPrice-container p">
                      <div className="totalprice mb-2">Total Price</div>
                      <div className="actualTotalPrice" style={{ fontSize: "14px", fontWeight: "600" }}>
                      ₹ {data?.totalPrice}
                      </div>
                    </div>
                  </div>

                  <div className="optionToAddPremiumPlan-container">
                    <div className="w-100 p-3 mt-3" style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
                      <div className="d-flex align-items-center">
                        <div
                          className="customCheckbox"
                          style={{ marginRight: "12px", userSelect: "none" }}
                          onClick={() => {
                            setGstOffcan(true);
                          }}
                          role="button"
                        >
                          {profileInfo[0]?.data?.data?.gstNumber ? <img src={check} /> : null}
                        </div>
                        <div>
                          <span style={{ fontSize: "14px", fontWeight: "600" }}>I have a GST number</span>
                          <span style={{ fontSize: "14px", fontWeight: "400" }}> (Optional)</span>
                        </div>
                      </div>
                      {profileInfo[0]?.data?.data?.gstCompanyName && profileInfo[0]?.data?.data?.gstNumber ? (
                        <div className="w-100 p-2 mt-2 d-flex justify-content-between" style={{ backgroundColor: "#F6F6F6" }}>
                          <div className="d-flex">
                            <div className="gstIcon-container">
                              <img src={gstDoc} />
                            </div>
                            <div>
                              <div style={{ fontSize: "10px" }}>{profileInfo[0]?.data?.data?.gstCompanyName}</div>
                              <div style={{ fontSize: "10px", color: "#000000" }}>{profileInfo[0]?.data?.data?.gstNumber}</div>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center"
                            onClick={() => {
                              setGstOffcan(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} color="#A7A7A7" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div style={{ paddingBottom: "12rem", backgroundColor: "#FAFAFA" }}>
                    <VendorCartSide />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "inherit", position: "fixed", bottom: "0" }}>
                <div className="card-footer-controls">
                  <div className="price-details">
                    <button style={{ justifyContent: "center", background: "white" }} className="d-flex flex-column">
                      <span style={{ fontSize: "16px", fontWeight: "500" }} className="fw-bold">
                      {data?.totalPrice}
                      </span>
                    <span style={{ color: "#407BFF", fontSize: "12px", fontWeight: "400" }}>View price details</span>
                    </button>
                  </div>
                  <div className="buy-button">
                  <button style={{ fontSize: "14px", fontWeight: "400" }} onClick={handleShow}>
                    {isLoading ? <Spinner animation="border" /> : "Place Order"}
                   
                    </button>
                  </div>
                </div>
              <BottomNav path={path} />
              </div>
            </div>
          </div>

      </div>
      <div className="d-none d-md-block">
        <VendorCart />
      </div>
    </React.Fragment>
  );
}
