import React, { useEffect, useState } from 'react'
import cadicon from './3dImages/cadicon.svg'
import pdficon from './3dImages/pdficon.svg'
import blank3d from './3dImages/blank3d.svg'
import img3d from './3dImages/3dimg.svg'
import details from './3dImages/details.svg'
import { Modal, Offcanvas } from 'react-bootstrap'
import makeone from './3dImages/MakeOne.svg'
import uploadAPdf from './3dImages/uploadAPdf.svg'
import close from './3dImages/close.svg'
import eye from './3dImages/eye.svg'
import gmeet from './3dImages/gmeet.svg'
import write from './3dImages/write.svg'
import file from './3dImages/file.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { fetchProfileData } from '../Lms/Actions'
import Completed3dMob from './Completed3dMob'
import { constants } from '../constants'

const Ordered3dMob = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const handleClose = () => { setShow(false) }
    const handleClose2 = () => { setShow2(false) }
    const handleShow = (id) => {
        // console.log(id)
        localStorage.setItem("apiId", id)
        setShow(true)
    }
    const [showId, setShowId] = useState();
    const navigate = useNavigate()
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])
    const payM = useSelector((state) => state.threedReducer.payMethod)

    const handlleMakeOne = () => {
        localStorage.setItem("makeOne", true)
        localStorage.setItem("saveLater", true)
        navigate("/3dMakePPT/MakeOne")
        localStorage.removeItem("tick")
        localStorage.removeItem("tick2")
    }
    const handlleUploadOne = () => {
        localStorage.setItem("makepdf", true)
        localStorage.setItem("saveLater", true)
        navigate("/3dMakePPT/UploadPdf")
        localStorage.removeItem("tick")
        localStorage.removeItem("tick2")
    }
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo)
    // console.log(profileInfo[0]?.data?.data?.planId?._id)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    useEffect(async () => {
        const res = await axios.get(`${constants.threeDApi}/api/room/inProcess-designs?designerId=${localStorage.getItem("userId")}`)
        // console.log(res.data)
        setData(res.data)
    }, [])
    // console.log(data)


    const [ofArr, setOfArr] = useState([])
    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    }
    // console.warn(ofArr)
    var tot = 0
    for (let i = 0; i < ofArr[0]?.room?.length; i++) {
        tot += ofArr[0]?.room[i]?.views
    }
  

    // console.log(ofArr)
    useEffect(() => {
        const b = data.filter((currElem) => {
            return currElem._id == showId
        })
        setOfArr(b)
    }, [showId])

    var date = new Date()
    function getD(item) {
        // let e = new Date(parseInt(item.substring(0, 4)), parseInt(item[6]) - 1, parseInt(item[9]))
        // let days = e.getTime()
        // return Math.round((date.getTime() - days) / (1000 * 3600 * 24))
        // console.log(item)
        let e = new Date(item)
        // console.log(e)
        let days = e.getTime()
        // console.log(days)
        return Math.round((date.getTime() - days) / (1000 * 3600 * 24))
    }


    var imageArr = []

    function getF(main) {
        var imageArr = []
        for (let i = 0; i < main?.room.length; i++) {
            if (main?.room[i].image.length == 0) {
                imageArr[i] = true
            }
            else if (main?.room[i].image.length != 0) {
                imageArr[i] = false
            }
            // console.log(main)
            // newArr = [imageArr[i], main?.room[i].image.length, main.amountToBePaid]
            // console.log(i, imageArr[i], main?.room[i].image.length, main.amountToBePaid)
            // console.log(imageArr)
        }
        // console.log(newArr)
        // console.log(imageArr)
        if (main?.pdf == null && imageArr.includes(false) == false) {
            return true
        }
    }

    
    return (
        <div>
            <Offcanvas show={show2} onHide={handleClose2} placement='end' size="md">
                <Offcanvas.Header closeButton>
                    <div>
                        <Offcanvas.Title>{profileInfo[0]?.data?.data?.companyName}</Offcanvas.Title>
                        <p style={{ fontSize: "14px", color: "#888888" }}>{profileInfo[0]?.data?.data?.city}</p>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ marginTop: "-40px" }}>
                    <div className="cart_file_sub2 pt-2 px-3">
                        {ofArr[0]?.cadFile?.fileName && <p className="file2"><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.cadFile?.fileName.slice(0, 9)}{"..."}</p>}
                        {ofArr[0]?.pdf?.fileName ? <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.pdf?.fileName.slice(0, 9)}{"..."}</p> : getF(ofArr[0]) ? <><p className="file3" style={{ marginLeft: "30px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded</p> </> : <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                    </div>
                    <div className='d-flex flex-wrap px-2'>
                        {ofArr[0]?.room?.map((item) => (
                            <button className='btn-off mx-2 mb-2'>{item.name} {item.views} <img src={eye} alt="" className="bbeye" /></button>
                        ))}
                        <p className='mx-2 mt-1 p-off'>Total {tot} views</p>
                    </div>
                    <div className='pt-2 px-3 d-flex'>
                        <div className="off-line d-flex flex-column">
                            <div className='d-flex flex-column'>
                                {ofArr[0]?.adminStatus?.block?.file ?
                                    <>
                                        <div className="d-flex ">
                                            <div className="ord_circ2 m-0" ></div>
                                            <h4 className="cart_head2 d-flex">Complete<span className='ord_span3'> (3D Block)</span></h4>
                                        </div>
                                        {ofArr[0]?.adminStatus?.block?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                        {ofArr[0]?.adminStatus?.block?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.block?.remark?.data} </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.remark?.date)).toString().substring(4, 15)}</span></div>}
                                        {ofArr[0]?.adminStatus?.block?.file && <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.file?.date)).toString().substring(4, 15)}</span></div>}
                                    </>
                                    : ofArr[0]?.adminStatus?.block && !ofArr[0]?.adminStatus?.block?.file ? <>
                                        <div className="d-flex ">
                                            <div className="ord_circ2 m-0" style={{ background: "#58A1FF" }}></div>
                                            <h4 className="cart_head2 d-flex">Active<span className='ord_span3'> (3D Block)</span></h4>
                                        </div>
                                        {ofArr[0]?.adminStatus?.block?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                        {ofArr[0]?.adminStatus?.block?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.block?.remark?.data}</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.remark?.date)).toString().substring(4, 15)}</span></div>}
                                    </> : <div className="d-flex mb-5">
                                        <div className="ord_circ2 m-0" style={{ background: "#FFAC0A" }}></div>
                                        <h4 className="cart_head2 d-flex ">Pending<span className='ord_span3'> (3D Block)</span></h4>
                                    </div>}
                                {ofArr[0]?.adminStatus?.lowRender?.file ? <> <div className="d-flex ">
                                    <div className="ord_circ2 m-0"></div>
                                    <h4 className="cart_head2 d-flex ">Complete<span className='ord_span3'> (Low render)</span></h4>
                                </div>
                                    {ofArr[0]?.adminStatus?.lowRender?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                    {ofArr[0]?.adminStatus?.lowRender?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.lowRender?.remark?.data}</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                    {ofArr[0]?.adminStatus?.lowRender?.file && <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.file?.date)).toString().substring(4, 15)}</span></div>}
                                </> : ofArr[0]?.adminStatus?.lowRender && !ofArr[0]?.adminStatus?.lowRender?.file ? <> <div className="d-flex ">
                                    <div className="ord_circ2 m-0" style={{ background: "#58A1FF" }}></div>
                                    <h4 className="cart_head2 d-flex ">Active<span className='ord_span3'> (Low render)</span></h4>
                                </div>
                                    {ofArr[0]?.adminStatus?.lowRender?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                    {ofArr[0]?.adminStatus?.lowRender?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.lowRender?.remark?.data}</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                </> : <div className="d-flex mb-5 mt-4">
                                    <div className="ord_circ2 m-0" style={{ background: "#FFAC0A" }}></div>
                                    <h4 className="cart_head2 d-flex ">Pending<span className='ord_span3'> (Low render)</span></h4>
                                </div>}
                                <div className="d-flex mb-5 mt-4">
                                    <div className="ord_circ2 m-0" style={{ background: "#FFAC0A" }}></div>
                                    <h4 className="cart_head2 d-flex ">Pending<span className='ord_span3'> (Final render)</span></h4>
                                </div>
                            </div>
                        </div>
                        <div className="_line"></div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div className="mob_start_main d-flex flex-column" style={{ borderBottom: "1px solid #DFDFDF" }}>
                {data.map((item2) => (
                    <div className="box_top d-flex mt-3" style={{ borderBottom: "1px solid #D9D9D9" }}>
                        <div style={{
                            background: "#F0F0F0",
                            borderRadius: "4px",
                            padding: "7px 7px",
                            height: "64px"
                        }}>
                            <img src={img3d} className="ordImg2" style={{
                                width: "50px",
                                height: "50px"
                            }} />
                        </div>
                        <div className="box_lines d-flex flex-column w-100 " style={{ marginLeft: "12px" }}>
                            <div className="ord_head d-flex">
                                <h4 className="cart_head d-flex flex-column">3D feature <span className='ord_span pt-1'>Requested {getD(item2.createdAt)} days ago</span></h4>
                                {item2.paidAmount && <span className="ord_span2 d-flex float-end">Paid <p className="cart_head px-2">₹ {Math.round(item2.paidAmount)}</p></span>}
                            </div>
                            {data && item2.cadFile.fileName && <p className="file2 mb-0"><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.cadFile.fileName.slice(0, 9)}{"..."}</p>}
                            {item2.pdf && item2.pdf.fileName ? <p className="file3 mb-0" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{item2.pdf.fileName.slice(0, 9)}{"..."}</p> : getF(item2) ? <><p className="file3 d-flex" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded</p>  </> : <p className="file2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                            <div className="ord_en d-flex mt-3">
                                {item2?.adminStatus?.block?.file && item2?.adminStatus?.lowRender?.file ? <><div className="ord_circ2"></div>
                                    <h4 className="cart_head2 d-flex">Complete<span className='ord_span3'>(Low Render)</span></h4></> : item2?.adminStatus?.block?.file && !item2?.adminStatus?.lowRender?.file ? <><div className="ord_circ"></div>
                                        <h4 className="cart_head2 d-flex">Active<span className='ord_span3'>(Low Render)</span></h4></> : item2?.adminStatus?.block?.file ? <><div className="ord_circ"></div>
                                            <h4 className="cart_head2 d-flex">Active<span className='ord_span3'>(3D Block)</span></h4></> : <><div className="ord_circ" style={{ background: "#FFAC0A" }}></div>
                                    <h4 className="cart_head2 d-flex">Pending<span className='ord_span3'>(3D Block)</span></h4></>}
                                <p className="ord_view" onClick={() => handleShow2(item2._id)}>View Details<img src={details} alt="" style={{
                                    height: "10px",
                                    width: "10px"
                                }} /></p>
                            </div>
                        </div>
                    </div>
                    // <button className="ord_upload" onClick={() => handleShow(item2._id)}>Upload</button>
                ))}
            </div>
            <Modal show={show} onHide={handleClose} centered size="md">
                <Modal.Body >
                    <img src={close} alt="" className='d-flex float-end' onClick={handleClose} style={{
                        opacity: "0.5",
                        height: "13px",
                        width: "13px",
                        cursor: "pointer"

                    }} />
                    <p className="mainRender_header mt-4 d-block" style={{ fontSize: "12px" }}>Upload/Add a reference images for each element of 3D</p>
                    <p className="mainRender_byline d-block" style={{ fontSize: "10px", textAlign: "center" }}>(This will help in getting work done faster)</p>
                    <div className="mainRender2 flex-column align-items-center mb-4">
                        <div className="mainRen_comp1" style={{ width: "75%" }} onClick={handlleMakeOne}>
                            <img src={makeone} className="upload_icons" alt="" style={{ height: "34px", width: "40px" }} />
                            <div className="makeOne_lines">
                                <p className='makOne_line1' style={{ fontSize: "12px" }}>Make one right now</p>
                                <p className="makOne_line2" style={{ fontSize: "10px" }}>Help in getting 3D done in 2 days</p>
                            </div>
                        </div>
                        <p className="makeOne_or">OR</p>
                        <div className="mainRen_comp2" style={{ width: "75%" }} onClick={handlleUploadOne}>
                            <img src={uploadAPdf} className="upload_icons" alt="" style={{ height: "34px", width: "40px" }} />
                            <div className="makeOne_lines">
                                <p className="makOne_line1_2" style={{ fontSize: "12px" }}>Upload a PDF</p>
                                <p className="makOne_line2_2" style={{ fontSize: "10px" }}>to be delivered in 3 days</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <Completed3dMob />
        </div>
    )
}

export default Ordered3dMob