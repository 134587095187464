import React, { useEffect, useState } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import "./3d.css";
import { Accordion } from "react-bootstrap";
import SideLandingPageFinal from "./SideLandingPageFinal";
import Ordered3d from "./Ordered3d";
import Completed3d from "./Completed3d";
import Final3dPageMob from "./Final3dPageMob";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";
const Final3dPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 599);
  const navigate = useNavigate();
  const payM = useSelector((state) => state.threedReducer.payMethod);
  const [userData, setUserData] = useState([]);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };
  const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [ordered, setOrdered] = useState(true);
  const [completed, setCompleted] = useState(false);

  const handleFinal = (tabType) => {
    if (tabType === "tab1") {
      setOrdered(true);
      setCompleted(false);
    }
    if (tabType === "tab2") {
      setCompleted(true);
      setOrdered(false);
    }
  };
  useEffect(async () => {
    const res3 = await axios.get(`https://3d-api.idesign.market/api/room/req-designs?designerId=${localStorage.getItem("userId")}`);
    // console.log(res3)
    setUserData(res3.data);
  }, []);

  // console.log(userData)
  // console.log(payM)
  useEffect(() => {
    if (payM.money === "full") {
      userData.map(async (item) => {
        const amountPaid = item.amountToBePaid;
        if (item.amountToBePaid) {
          const res4 = await axios
            .put(`https://3d-api.idesign.market/api/room/save-paid-amount?id=${item._id}`, { amountPaid: amountPaid })
            .then(() => {
              window.location.reload();
            })
            .catch((err) => console.warn(err));
        }
      });
    } else if (payM.money === "half") {
      userData.map(async (item) => {
        const amountPaid = item.amountToBePaid / 2;
        // console.log(item.amountToBePaid/2)
        if (item.amountToBePaid) {
          const res4 = await axios
            .put(`https://3d-api.idesign.market/api/room/save-paid-amount?id=${item._id}`, { amountPaid: amountPaid })
            .then(() => {
              window.location.reload();
            })
            .catch((err) => console.warn(err));
        }
      });
    }
  }, [userData]);

  const [data, setData] = useState([]);
  useEffect(async () => {
    const res = await axios.get(`https://3d-api.idesign.market/api/room/inProcess-designs?designerId=${localStorage.getItem("userId")}`);
    setData(res.data);
  }, []);

  const faqs = [
    {
      no: 1,
      faq: "For which kind of Spaces do you provide 3Ds ?",
      faqa: "We provide 3Ds for interior spaces of Home Interiors. We are currently not providing 3Ds for commercial spaces or facades",
    },
    {
      no: 2,
      faq: "Do you make 3Ds of furniture as per drawing or use library ?",
      faqa: "We use 3DMax or Sketch up library to provide the closest furniture to the reference image shared by you. Our team does some customized visualization also if its limited in nature.",
    },
    {
      no: 3,
      faq: "Is my ACAD Drawings safe with iDesign?",
      faqa: "We place highest amount of emphasis on data security and your ACAD drawings and 3Ds will be your property and never shared with any third party and will never be used by iDesign for any purpose other than providing you the service",
    },
    {
      no: 4,
      faq: "What if I am not satisfied with the Final 3D ?",
      faqa: "We will be showing you the 3D block and low renders before the final render and hence the final render is done only on your approval. If incase you are not happy with the final render, we will be happy to give you a fresh render. Also, we take only 50% payment as advance,so you pay the balance 50% only when you are totally comfortable with the process.",
    },
  ];

  return (
    <>
      <div className="d-none d-md-block">
        <HeaderNav />
        <div style={{ background: "White", display: "flex", width: "100%", overflow: "hidden" }}>
          <div className="sidBarHeight d-none d-md-block" style={{ paddingRight: "0" }}>
            <SidebarWebNew />
          </div>
          <div className="landing_main">
            <div className="landing_main2">
              <div className="landing_top">
                <h3 className="landing_header">
                  Get quick and high quality 3D views <br /> just at{" "}
                  {profileInfo[0]?.data?.data?.planId?.name === "Free" ? <b style={{ color: "#3B5998" }}> ₹1200/- </b> : <b style={{ color: "#3B5998" }}> ₹1000/- </b>} per view.{" "}
                </h3>
                <p className="landing_p">iDesign provides on time and value for money 3Ds to over 300 Designer and Architects across India</p>
                <button className="landing_started" onClick={() => navigate("/3dUploadCad")}>
                  Get Started
                </button>
                <span className="mt-2" style={{ fontSize: "12px", color: "#888888", fontWeight: "400" }}>
                  only for Residential Interiors
                </span>
              </div>
              <div className="final_tabs d-flex mt-5">
                <button
                  className={ordered ? "final_tab_active" : "final_tab_unactive"}
                  onClick={() => {
                    handleFinal("tab1");
                  }}
                >
                  Ordered 3Ds
                </button>
                {/* <button className={completed ? "final_tab_active" : "final_tab_unactive"} onClick={() => { handleFinal("tab2") }} style={{ pointerEvents: "none" }}>Completed 3Ds</button> */}
              </div>

              {ordered ? <Ordered3d /> : ""}
              <div className="landing_faqs">
                <p className="faqs_header">FAQs</p>
                <Accordion style={{ width: "88%" }}>
                  {faqs.map((item, i) => (
                    <Accordion.Item eventKey={item.no}>
                      <Accordion.Header>{item.faq}</Accordion.Header>
                      <Accordion.Body>{item.faqa}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
            <SideLandingPageFinal />
          </div>
        </div>
      </div>{" "}
      <Final3dPageMob />
    </>
  );
};

export default Final3dPage;
