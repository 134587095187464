import React, { useState } from "react";
import styles from "../Products/ProductsCss/productSec1.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { featureArr } from "../../../../Constants/arrayConstants";
import { useDispatch,useSelector } from "react-redux";
import { featureScroll } from "../../../Actions";
// import ScheduleADemo from "./ScheduleADemo";
// import img1 from "../Products/ProductsImg/fileImg/files.png";
// import back from "../Products/ProductsImg/fileImg/Rectangle 7476 (1).png";

const ProductSection1 = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [isFixed, setIsFixed] = useState(false);
  const dispatch = useDispatch();
  const { scrollToFeatures } = useSelector((state) => state.addToCartReducer);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const shouldBeFixed = scrollPosition > 150; // Adjust the value according to your needs
      setIsFixed(shouldBeFixed);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

    // scroll to top
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    window.addEventListener("scroll", toggleVisible);
  
    // stop scrolling
    const [showFixedDiv, setShowFixedDiv] = useState(false);
  
    function ifUp(event) {
      if (event.wheelDelta) {
        return event.wheelDelta > 0;
      }
      return event.deltaY < 0;
    }

    function checkScrollDirection(event) {
      if (ifUp(event)) {
        return true
      } else {
        return false
      }
    }

    useEffect(() => {
      let oldValue = 0;
      let newValue =  window.pageYOffset
      if (isFixed) {
        // let isScrolling = null;
  
        // const handleScroll = () => {
        //   clearTimeout(isScrolling);
  
        //   setShowFixedDiv(true);
  
        //   isScrolling = setTimeout(() => {
        //     setShowFixedDiv(false);
        //   }, 1000); // Adjust the timeout value as needed
        // };
  
        // window.addEventListener("scroll", handleScroll);
        // return () => {
        //   window.removeEventListener("scroll", handleScroll);
        // };

        window.onwheel = (e) => {
          if(window.pageYOffset > 100){
            setIsFixed(checkScrollDirection(e))
            setShowFixedDiv(checkScrollDirection(e))
          }
        }
      }
    }, [isFixed]);
  
    // scroll to feature
    useEffect(() => {
      console.log(scrollToFeatures);
      if (scrollToFeatures) {
        const targetDiv = document.getElementById(scrollToFeatures);
        const offset = 360;
        const targetOffset = targetDiv.offsetTop - offset;
  
        window.scrollTo({
          top: targetOffset,
          behavior: "smooth",
        });
  
        dispatch(featureScroll(""));
        return () => {
          dispatch(featureScroll(""));
        };
      }
    }, [scrollToFeatures]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.heading}>Tools for every project and team</div>
          <div className={styles.para}>
            Our platform offers a range of powerful tools that will help
            streamline <br />
            your operations, increase efficiency, and drive profitability.
          </div>
        </div>
        {showFixedDiv ? (
              <div className={isFixed ? styles.btn_div : ""}>
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    marginTop: "1rem",
                    flexWrap: "wrap",
                    padding: "1rem 12rem",
                    justifyContent: "center",
                  }}
                >
                  {featureArr.map((curelem) => {
                    return (
                      <a href={curelem.link} style={{textDecoration:'none'}}>
                        <div
                          style={{
                            backgroundColor: curelem.background,
                            color: curelem.textColor,
                            borderRadius: "30px",
                            padding: "0.4rem 1rem",
                            fontWeight: "600",
                            fontSize:"14px",
                          }}
                        >
                          {curelem.label}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className={styles.btn_div1}> 
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    // marginTop: "1rem",
                    flexWrap: "wrap",
                    padding: "1rem 12rem",
                    justifyContent: "center",
                  }}
                >
                {featureArr.map((curelem) => {
                  return (
                    <a href={curelem.link} style={{textDecoration:'none'}}>
                      <div
                        style={{
                          backgroundColor: curelem.background,
                          color: curelem.textColor,
                          borderRadius: "30px",
                          padding: "0.4rem 1rem",
                          fontWeight: "600",
                          fontSize:"14px",
                        }}
                      >
                        {curelem.label}
                      </div>
                    </a>
                  );
                })}
              </div>
              </div>
             )} 
      </div>
    </>
  );
};

export default ProductSection1;
