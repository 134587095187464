import React from 'react';
import TopDash from './TopDash';
import NavbarNew from './NavbarNew';
import SideBarNew from './SideBarNew';
import Bottom from './Bottom';

const MainComp = () => {
  return (
   <>
    <NavbarNew/>
      <div style={{display:'flex'}}>
        <SideBarNew/>
      <div style={{padding:'2rem', width:"82vw"}}>
        <TopDash/>
        <Bottom/>
      </div>  
   </div>
   </>
  )
}

export default MainComp;