import React, { useState } from "react";

import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ScheduleADemo from "./ScheduleADemo";
import styles from "./ProductsCss/productSec4.module.css";
// import img4 from "./ProductsImg/timeline/timelinee.png";
// import back from "./ProductsImg/timeline/Rectangle 7481.png";
import icon1 from "./ProductsImg/timeline/ballot.svg";
import icon2 from "./ProductsImg/timeline/event_upcoming.svg";
import icon3 from "./ProductsImg/timeline/notifications_active.svg";

const ProductSection4 = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className={styles.container} id="time">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>TIMELINES</div>
            <div className={styles.file_para}>
            Create project schedules and timelines & keep projects on track and ensure that deadlines are met. Get real-time updates on the timeline from site
            </div>

            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icons} />
                </div>
                <div className={styles.icon_para}>
                  Allocate resources <br />
                  Effectively
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icons} />
                </div>
                <div className={styles.icon_para}>
                  Schedule tasks & <br />
                  keep track of it.
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.icons} />
                </div>
                <div className={styles.icon_para}>
                  Get Real-time <br />
                  Updates
                </div>
              </div>
            </div>
          </div>
          <div className={styles.img_div}>

            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/timeNew.svg" alt="" className={styles.mainImg} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection4;
