import React from "react";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/leads/manage_accounts.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/leads/motion_photos_auto.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/leads/database.svg";
import styles from "./productMobCss/productSec8.module.css";

const ProductSec8 = () => {
  return (
    <>
      <div className={styles.container} id="leads">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>LEAD MANAGEMENT</div>
            <div className={styles.file_para}>
              Centralized database for all lead <br /> information, including contact
              details, <br /> project requirements, and status updates.
            </div>
            <div className={styles.img_div}>
              <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207468%20%284%29.svg" alt="" className={styles.img} />
            </div>
            <div className={styles.icon_div}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Centralized lead Database</div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Automated lead Nurturing</div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Sales pipeline management</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSec8;
