import React,{useState, useEffect} from 'react'
import Icon from "./Images/Icon.svg";
import right from "./Images/right.svg";
import web from "./Images/web.svg";
import app from "./Images/app.svg";
import pro from "./Images/pro.svg";
import caretRight from "./Images/CaretRight.svg";
import checkCircle from "./Images/CheckCircle.svg";
import styles from "./BoxOne.module.css";
import {mockDataBoxOne} from "./mockData";
import {additionalFeatures} from "./mockData";
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";

const BoxOne = ({scrollRef}) => {
  localStorage.setItem("fromCountry", "India")

  const navigateTo= useNavigate();
    const [price, setPrice] = useState("25,000");
    const [selectedBillingType, setSelectedBillingType] = useState("Quarterly");
  
    const handleBillingTypeChange = (event) => {
      const newBillingType = event.target.value;
      setSelectedBillingType(newBillingType);
      if (newBillingType === 'Quarterly') {
          setPrice(25000);
localStorage.setItem("price", 25000)
        } else if (newBillingType === 'Yearly') {
          setPrice(20000);
         localStorage.setItem("price", 20000)
        }
    };
    useEffect(() => {
      localStorage.setItem("paymentPlan",selectedBillingType )
      localStorage.setItem("price", price);
    }, [price]);

    function generateHashedLink(token, userId, paymentPlan, fullName, cId, price, password) {
      const encryptedData = {};
      const encryptionKey = "18B69E843399F1A2AFC5853129299";
    
      const encryptField = (field) => {
        const fieldValue = field?.toString() || ''; // Convert value to string, handle undefined
        return CryptoJS.AES.encrypt(fieldValue, encryptionKey).toString();
      };
    
      encryptedData.token = encryptField(token);
      encryptedData.userId = encryptField(userId);
      encryptedData.paymentPlan = encryptField(paymentPlan);
      encryptedData.cId = encryptField(cId);
      encryptedData.fullName = encryptField(fullName);
      encryptedData.price = encryptField(price);
      encryptedData.password = encryptField(password);
    
      const now = new Date();
      const timestamp = now.toISOString();
      const encryptedTimestamp = CryptoJS.AES.encrypt(timestamp, encryptionKey).toString();
      encryptedData.timestamp = encryptedTimestamp;
    
      const encryptKey = (key) => {
        return CryptoJS.AES.encrypt(key, encryptionKey).toString();
      };
      const url = `https://www.idesign.market/payment?${encryptKey("userId")}=${encryptedData.userId}&${encryptKey("cId")}=${encryptedData.cId}&${encryptKey("token")}=${encryptedData.token}&${encryptKey("fullName")}=${encryptedData.fullName}&${encryptKey("price")}=${encryptedData.price}&${encryptKey("paymentPlan")}=${encryptedData.paymentPlan}&${encryptKey("timestamp")}=${encryptedData.timestamp}&${encryptKey("password")}=${encryptedData.password}&currency=INR`;
      return url;  
    }



    return (
      <div>
        <div className={styles.Mainbox_div}>
          {/* <Box1 /> */}
          <div className={styles.box1}>
            <div className={styles.contentBox1}>
              <div className={styles.innerHeadingBox1}>Pick your plan</div>
              <div className={styles.billingBox}>
                <label className={styles.billingOption} style={{cursor:"pointer"}}>
                  <input className={styles.radioBtn}
                    type="radio"
                    value="Quarterly"
                    checked={selectedBillingType === "Quarterly"}
                    onChange={handleBillingTypeChange}
                  />
                  <div className={styles.billingTypeText} style={{userSelect:"none"}}>Quaterly Billing</div>
                </label>
  
                <label className={styles.billingOption} style={{cursor:"pointer"}}>
                  <input className={styles.radioBtn}
                    type="radio"
                    value="Yearly"
                    checked={selectedBillingType === "Yearly"}
                    onChange={handleBillingTypeChange}
                  />
                  <div className={styles.billingTypeText} style={{gap:"0.5rem"}}>
                  <span style={{userSelect:"none"}}>Yearly Billing</span>
                  <div className={styles.save25btn}>
                    <span className={styles.save25btnText}>Save 20%</span>
                  </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* <Box2 />
            <Box3 /> */}
          <div className={styles.box2}>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              {/* <div className={styles.buttons}>
                <span className={styles.buttonsText}>SOLO DESIGNERS</span>
              </div> */}
              <div className={styles.buttons}>
                <span className={styles.buttonsText}>Small-mid Teams</span>
              </div>
            </div>
            <div style={{ marginTop: "1.12rem" }}>
              <div className={styles.inBoxHeading}>
              Professional
              <img src={pro} alt="pro" className={styles.proImage} />
              <span className={styles.pro}>Pro</span>
              <div className={styles.mostPopularBtn}><span className={styles.mostPopularText}>Most Popular</span></div>
              </div>
            </div>
            <div style={{marginTop:"2.13rem"}}>
                  <span className={styles.amount}>₹ {price.toLocaleString('en-IN')}</span>
                  <span className={styles.afterAmount} style={{marginLeft:"0.5rem"}}>billed monthly</span>
            </div>
            <div style={{marginTop:"2rem"}}>
            <div className={styles.blueButtonContainer}>
                <div className={styles.blueButton} 
                 style={{cursor:"pointer", userSelect:"none"}}
                 onClick={()=>
                 {
                   if (localStorage.getItem("pricing") === "true") {
                   navigateTo("/signup");
                 } else {
                  //  navigateTo("/payment");
                  window.location.assign(generateHashedLink(localStorage.getItem("token"),
                  localStorage.getItem("userId"),
                  localStorage.getItem("paymentPlan"),
                  localStorage.getItem("fullName"),
                  localStorage.getItem("cId"),
                  localStorage.getItem("price"),
                  localStorage.getItem("password")
                  )) 
                 }
                 }
                }
                >
                  <div className={styles.blueButtonText}
                   >Get Started</div>
                  <img src={caretRight} className={styles.checkCircle} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div className={styles.inBoxSummary}>
                Unlimited Users | 1 TB Storage limit{" "}
              </div>
            </div>
            <div style={{marginTop:"1.12rem"}}>
            <div style={{marginBottom:"0.5rem"}} className={styles.arrayHeadings}>
             <img src={app} alt="app" style={{verticalAlign:"middle",  marginRight:"0.5rem"}}/>
             App</div>
              {mockDataBoxOne.map((x, index) => (
                <div key={index} className={styles.arrayData} style={{display:"flex", marginBottom:"0.5rem"}}> <img src={right} alt="right" style={{marginRight:"0.75rem"}} /> <div>{x}</div></div>
              ))}
            </div>
            <div style={{marginTop:"2.25rem"}}>
            <div style={{marginBottom:"0.5rem"}} className={styles.arrayHeadings}>
             <img src={web} alt="web" style={{verticalAlign:"middle", marginRight:"0.5rem"}} />Web for any browser</div>
             
                  <div className={styles.arrayData}></div>
              <div>
              <div className={styles.arrayData} style={{display:"flex"}}>
              <img src={right} alt="right" style={{marginRight:"0.75rem"}} />
              <div>Unlimited free Users</div>
              </div>
              <img src={Icon} alt="" style={{marginTop:"-24px", marginLeft:"28px"}} />
              </div>
               <div className={styles.arrayData} style={{display:"flex", marginTop:"-16px"}}> 
               <img src={right} alt="right" style={{marginRight:"0.75rem", marginTop:"-25px"}} />
              <div style={{marginBottom:"0.5rem", marginTop:"0.3rem"}}>Inspect designs, Create Timelines and Execute handoffs to Stakeholders</div>
              </div>
              <div className={styles.arrayData} style={{display:"flex"}}>
               <img src={right} alt="right" style={{marginRight:"0.75rem"}} />
                <div>Proper Analytics Visuals </div>
               </div>
             
            </div>
            <div className={styles.showFeaturesBtn}
            style={{cursor:"pointer", marginTop:"3rem"}}
             onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}>Show features</div>
          </div>
          <div className={styles.box2}>
            <div style={{ display: "flex" }}>
              <div className={styles.buttons}>
                <span className={styles.buttonsText}>Large firms</span>
              </div>
            </div>
            <div style={{ marginTop: "1.12rem" }}>
              <div className={styles.inBoxHeading}>Enterprise</div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div className={styles.inBoxSummary}>
                Bespoke system connected with existing platform and databases for
                seamless project management{" "}
              </div>
            </div>
            
            <div style={{marginTop:"1.4rem"}}>
            <div
                className={styles.blueButtonContainer}
                style={{ marginBottom: "2rem" }}
              >
                <div className={styles.blueBorderButton}
                onClick={()=>navigateTo("/contact")}
                style={{cursor:"pointer", userSelect:"none"}}
                >
                  <div className={styles.blueBorderButtonText}>Let's Talk!</div>
                </div>
              </div>
            <div className={styles.additionalFeatures}>Additional features</div>
            <div >
                {additionalFeatures.map((x,index)=>{
                   return <div key={index} style={{marginBottom:"0.5rem", display:"flex"}}>
                   <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
                  <span style={{marginLeft:"0.75rem", fontSize:"0.875rem", fontWeight:"500"}}>{x}</span>
                  </div>
                })}
            </div>
            <div style={{marginTop:"1.5rem"}}>
            <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
            <span className={styles.yoursForeverText} style={{marginLeft:"0.75rem"}}>Yours to keep, forever.</span>
          </div>
          <img src={Icon} alt="" style={{marginTop:"-25px", marginLeft:"30px"}} />
          <div classname={styles.allBasicProText} style={{ display:"flex", gap:"0.5rem"}}>
          <img src={checkCircle} alt="right" style={{ verticalAlign:"middle"}} />
          <span >All basic Pro features</span>
          </div>
          {/* <div style={{marginTop:"0.7rem"}}>
          <div className={styles.blueButtonContainer} style={{visibility:"hidden"}}>
              <div className={styles.blueButton}>
                <div className={styles.blueButtonText}
                 style={{cursor:"pointer"}}
                >Get Started</div>
                <img alt="" src={caretRight} className={styles.checkCircle} />
              </div>
            </div>
          <div
                className={styles.blueButtonContainer}
                style={{ marginBottom: "2rem" }}
              >
                <div className={styles.blueBorderButton}>
                  <div className={styles.blueBorderButtonText}
                  onClick={()=>navigateTo("/contact")}
                  style={{cursor:"pointer", userSelect:"none"}}
                  >Let's Talk!</div>
                </div>
              </div>
              </div> */}
              <div className={styles.showFeaturesBtn}
               style={{cursor:"pointer",  marginTop:"1.7rem"}}
             onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}
              >Show features</div>
          </div>
          </div>
        </div>
      </div>
    );
}

export default BoxOne