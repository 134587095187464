import React, { useEffect } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import "./3d.css";
import { useState } from "react";
import TabButtonsMob from "./TabButtonsMob";
import completed from "./3dImages/completed.svg";
import locked from "./3dImages/locked.svg";
import { useNavigate } from "react-router-dom";
import PhotoUpload from "./PhotoUpload";
import SelectRoom from "./SelectRoom";
import UploadRefMain from "./UploadRefMain";
import { useSelector } from "react-redux";
import UploadReference0 from "./UploadReference0";
import UploadReference1 from "./UploadReference1";
import TabButtonsMob2 from "./TabButtonsMob2";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";

const TabButtons2 = () => {
  const autocadFile = useSelector((state) => state.threedReducer.autocadFile);
  // console.log(autocadFile)
  const pdfFile = useSelector((state) => state.threedReducer.pdfFile);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 599);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      <div className="d-none d-md-block">
        <HeaderNav />
        <div className="tabDisplay">
          <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", paddingRight: "0" }}>
            <SidebarWebNew />
          </div>
          <div className="tab_main">
            <div className="tab_main2">
              <button className="tab_active2">
                1. Upload AutoCAD file {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : ""}
              </button>
              <button className="tab_active">2. Select room & views </button>
              <button className="tab_active2">3. Upload reference PPT </button>
            </div>
            {<SelectRoom />}
          </div>
        </div>
      </div>{" "}
      <TabButtonsMob2 />
    </>
  );
};

export default TabButtons2;
