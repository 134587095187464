import React from "react";
import { Table } from "react-bootstrap";
import styles from "../../../onBoardingCss/DashboardCssNew/manageLeadsCss/interested.module.css";
import { useState, useEffect } from "react";
import axios from "axios";

const Interested = () => {
  const [interested, setInterested] = useState([]);
  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const getInterested = async () => {
    await axios
      .get(`https://pro-api.idesign.market/user/newleads?pageNo=0&type=3`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (response) {
        // console.log(response?.data?.data?.data);
        setInterested(response?.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInterested();
  }, []);
  return (
    <>
      <div style={{ marginTop: "1.5rem" }}>
        <Table className="border border-0 overflow-y-scroll">
          <thead>
            <tr className="border border-0">
              <th
                style={{
                  border: "none",
                  // paddingLeft: "1rem",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#272727",
                  fontSize: " 14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                  width:"16%",
                }}
              >
                Name
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#272727",
                  fontSize: " 14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Location
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Date
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Budget
                <img
                  src="https://idesign-office.s3.amazonaws.com/Sort.svg"
                  alt=""
                  style={{ marginLeft: "0.5rem" }}
                />
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Status
                <img
                  src="https://idesign-office.s3.amazonaws.com/Sort.svg"
                  alt=""
                  style={{ marginLeft: "0.5rem" }}
                />
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  color: "#272727",
                  fontSize: "14px",
                  cursor: "pointer",
                  lineHeight: "19px",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody style={{borderRight:"none",borderLeft:"none"}}>  
             {interested?.length > 0 ? interested?.map((curelem) => {
                return (
                  <>
                  <tr>
                    <td className={styles.eachCol} style={{ fontWeight: "500" }}>
                    {curelem?.name}
                    </td>
                    <td className={styles.eachCol}>
                     {curelem?.address}
                    </td>
                    <td className={styles.eachCol}>
                    {new Date(curelem?.createdAt).toLocaleDateString("en-En", { year: "numeric", month: "short", day: "numeric" })}
                    </td>
                    <td className={styles.eachCol}>
                    {curelem?.budget?.length>0 ? curelem?.budget :"_"}
                    </td>
                    <td className={styles.eachCol}>
                      <button className={styles.btn}>Interested</button>
                    </td>
                    <td className={styles.eachCol}>
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector%20%282%29.svg"
                        alt=""
                      />
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/Vector%20%283%29.svg"
                        alt=""
                        style={{ marginLeft: "1rem" }}
                      />
                    </td>
                    </tr>
                  </>
                );
              }):<div style={{paddingTop:"1rem",paddingBottom:"1rem",paddingLeft:'0.5rem',fontWeight:"600",fontSize:"18px"}}>No Data</div>}        
             
         
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Interested;
