import React, { useEffect } from 'react'
import "../../App.css";
import image from "../../Images/perRoomChargePage.svg";
import close from "../../Images/close.png";
import vector from "../../Images/Vector.png";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Offcanvas } from "react-bootstrap";

import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import { updateUserProfile } from '../../Apis';
import { useLocation } from 'react-router-dom';

export default function DesignerPerRoomWeb() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation();


  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  function handleChange(e) {
    if (e.target.value.length <= 6 && e.target.value >= 0) {
      setPrice(e.target.value);
    }
  }


  function handleSubmit() {
    // alert(price.length)
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);
      // context.setData((pre)=>({...pre,perRoomDesignCharge:price}))
      console.log(context.data);
      let d = [];
      const url = "";
      const service = localStorage.getItem("services").split(",");
      // const designtype = localStorage.getItem("designtype").split(",");
      const consultation = localStorage.getItem("consultation");
      const designFee = localStorage.getItem("designFee");
      const perroomfee = price;
      // const serviceArray = service.split(',');
      // console.log(service)
      // console.warn(localStorage.getItem('designtype'));
      // console.warn(localStorage.getItem('consultation'));
      // console.warn(localStorage.getItem('designFee'));
      // console.warn(price);
      // d.push(context.data.userData);
      // d.push(context.data.typeOfService);
      // d.push(context.data.designStyles);
      // d.push(context.data.consultationCharges);
      // d.push(context.data.designFeePerSqFt);
      // d.push(context.data.perRoomDesignCharge);

      const payload = new FormData();
      let services = JSON.stringify(service);
      // let designStyle = JSON.stringify(designtype);
      const fees = {
        zoomPrice: consultation,
        designAreaPrice: designFee,
        designRoomPrice: perroomfee,
      };
      payload.append("services", [services]);
      // payload.append("designStyle", [designStyle]);
      payload.append("fees", JSON.stringify(fees));

      updateUserProfile(payload).then((res) => {
        navigateTo("/addproject");
      });
      navigateTo("/addproject");
    }
  }
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  return (
    <>
      {/* {showWorking && ( */}
      <Modal className="web-virtualWorking" show={showModal} onHide={() => { setShowModal(false) }} centered>
        <Modal.Body className="offcanvasBody" style={{ paddingLeft: "0" }} >
          <img src={close} alt="" className='d-flex float-end' onClick={() => setShowModal(false)} style={{
            opacity: "0.5",
            height: "13px",
            width: "13px",
            cursor: "pointer"
          }} />
          <div style={{ fontSize: "16px" }}>
            <p className="howZoomWorks">How Does Per Room Design Fee work?</p>
          </div>
          <div>
            <ul className="offcanvasList">
              <li>
                Applicable for<b> Apartments, Builder Floors or Bungalows only.</b>
              </li>
              <li>
                Fee is charged <b>irrespective of Room sizes upto 250 Sqft.</b> Even Toilet or Balcony Design is charged on per room
              </li>
              <li>
                <b>Easy to understand </b>for homeowners and gets good response
              </li>
            </ul>
            <p style={{paddingLeft: "16px"}}>Note : All prices exclusive of GST</p>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <div className="main-professionContainer-web d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
        <div style={{ background: "white", borderRadius: "10px" }} className="main-div row">
          <div style={{ fontSize: "1.2rem", fontWeight: "500", position: "relative" }} className="chooseprodessionweb col d-flex flex-column  align-items-center">
            <img style={{
              marginTop: "3rem", width: "50%", height: "32%"
            }} src={image} />
            <p className="onoarding_header" style={{ textAlign: "center", fontWeight: "500", marginTop: "1rem", color: "#121212" }}>
              How much would you charge <br /> for designing One Room in a <br /> Home ?
            </p>
            {/* <input className="text-center" style={showError ? { borderColor: "red" } : null} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" /> */}


            <div style={{ position: "absolute", bottom: "2rem" }} className="designerweb-virtualInfo" onClick={() => {
              setShowWorking(true);
              setShowModal(true)
            }}>
              <button
                className="p-0"
                
                style={{ width: "0.7rem", height: "0.7rem" }}
              >
                <img style={{ width: "100%", height: "100%", marginTop: "3px" }} src={vector} />
              </button>
              <span style={{cursor: "pointer"}}>How Does Fees Per room work? </span>
            </div>

          </div>

          <div style={{ background: "#F7F7F7", position: "relative", borderRadius: "0 10px 10px 0" }} className="col d-flex justify-content-center align-items-center flex-column">

            <input className="web-consultation-charges" name="price" style={showError ? { borderColor: "red" } : null} type="number" onChange={handleChange} placeholder="Please enter the amount in INR" onKeyDown={(e) =>
              exceptThisSymbols.includes(e.key) && e.preventDefault()} value={price} required />
            <span style={{ color: "red", textAlign: "center", fontSize: "12px", alignSelf: "center", marginTop: "4px" }} className={`${showError ? "" : "d-none"}`}>
              You cannot leave this field empty
            </span>
            <button onClick={handleSubmit} className="webdesigner-nextbutton">
              Next
            </button>
          </div>

        </div>
      </div>

    </>
  )
}

