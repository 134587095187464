import React, { useEffect } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import "./3d.css";
import { useState } from "react";
import TabButtonsMob from "./TabButtonsMob";
import completed from "./3dImages/completed.svg";
import locked from "./3dImages/locked.svg";
import { useNavigate } from "react-router-dom";
import PhotoUpload from "./PhotoUpload";
import SelectRoom from "./SelectRoom";
import UploadRefMain from "./UploadRefMain";
import { useSelector } from "react-redux";
import UploadReference0 from "./UploadReference0";
import UploadReference1 from "./UploadReference1";
import TabButtonsMob3 from "./TabButtonsMob3";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";

const TabButtons3 = () => {
  const autocadFile = useSelector((state) => state.threedReducer.autocadFile);
  // console.log(autocadFile)
  const pdfFile = useSelector((state) => state.threedReducer.pdfFile);
  const [boolClicked, setBoolClicked] = useState(false);
  const [boolClicked2, setBoolClicked2] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 599);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const naviagte = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("livingInput1") &&
      !localStorage.getItem("bedroomInput1") &&
      !localStorage.getItem("bathroomInput1") &&
      !localStorage.getItem("kitchenInput1") &&
      !localStorage.getItem("dInput1") &&
      !localStorage.getItem("bathroomInput1") &&
      !localStorage.getItem("dInput2") &&
      !localStorage.getItem("dInput3") &&
      !localStorage.getItem("dInput4")
    ) {
      // console.log("yes")
      naviagte("/3dCart");
    }
  }, []);

  const navigate = useNavigate();
  const handleNext = () => {
    // if (autocadFile.length > 0) {
    //     if (localStorage.getItem("a") == 1) {
    //         setBoolClicked(true)
    //         console.warn(1)
    //     }
    //     setActive__tab1(false)
    //     setActive__tab2(true)

    // }

    // if (localStorage.getItem("lcount") > 0 || localStorage.getItem("bcount") > 0 || localStorage.getItem("bacount") > 0 || localStorage.getItem("kcount") > 0) {
    //     if (localStorage.getItem("a") == 1) {
    //         setBoolClicked2(true)
    //     }
    //     setActive__tab1(false)
    //     setActive__tab2(false)
    //     setActive__tab3(true)
    // }

    navigate("/3dCart");
  };

  return (
    <>
      <div className="d-none d-md-block">
        <HeaderNav />
        <div className="tabDisplay">
          <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", paddingRight: "0" }}>
            <SidebarWebNew />
          </div>
          <div className="tab_main">
            <div className="tab_main2">
              <button className="tab_active2">
                1. Upload AutoCAD file {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : ""}
              </button>
              <button className="tab_active2">
                2. Select room & views {localStorage.getItem("tick") ? <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} /> : ""}
              </button>
              <button className="tab_active">3. Upload reference PPT </button>
            </div>
            {<UploadRefMain />}
          </div>
        </div>
      </div>{" "}
      <TabButtonsMob3 />
    </>
  );
};

export default TabButtons3;
