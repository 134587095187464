import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ContsantHeaderMob = () => {
    const path = useLocation()
    const navigateTo = useNavigate()
  return (
      <div className="w-100" style={{ backgroundColor: "#ffffff", position: "fixed", borderBottom: "1px solid #D7D7D7", zIndex: "1" }}>
          <div className="d-flex" style={{ justifyContent: "space-between", width: "93%", marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}>
              <div
                  role="button"
                  style={{ fontSize: "12px", paddingBottom: "10px" }}
                  className={path.pathname === "/myprofile" ? "active" : "inactive"}
                  onClick={() => {
                   
                      navigateTo("/myprofile");
                  }}
              >
                  My Profile
              </div>

              <div
                  role="button"
                  onClick={() => {
                    
                      navigateTo("/newplan");
                  }}
                  style={{ fontSize: "12px", paddingBottom: "10px" }}
                  className={path.pathname === "/newplan" ? "active" : "inactive"}
              >
                  My Plan
              </div>

              <div
                  role="button"
                  style={{ fontSize: "12px", paddingBottom: "10px" }}
                  className={path.pathname === "/changepass" ? "active" : "inactive"}
                  onClick={() => {
                      
                      navigateTo("/changepass");
                  }}
              >
                  Change Password
              </div>
              <div
                  role="button"
                  style={{ fontSize: "12px", paddingBottom: "10px" }}
                  className={path.pathname === "/myreview" ? "active" : "inactive"}
                  onClick={() => {
                 
                      navigateTo("/myreview");
                  }}
              >
                  Reviews
              </div>
          </div>
      </div>
  )
}

export default ContsantHeaderMob
