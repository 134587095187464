import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ContsantHeader = () => {
    const path = useLocation()
    const navigate = useNavigate()

  return (
      <div className="" style={{ backgroundColor: "#ffffff", position: "fixed", width: "80%", height: "3rem", zIndex: "1", borderBottom: "1px solid #D7D7D7" }}>
          <div className="d-flex" style={{ justifyContent: "space-between", width: "38%", alignItems: "center", paddingLeft: "1rem" }}>
              <div
                  role="button"
                  style={{ paddingBottom: "14px", paddingTop: "10px", fontSize: "14px" }}
                  className={path.pathname === "/myprofile" ? "activee" : "inactivee"}
                  onClick={() => {
                      
                      navigate("/myprofile");
                  }}
              >
                  My Profile
              </div>

              <div
                  role="button"
                  onClick={() => {
                     
                      navigate("/newplan");
                  }}
                  style={{ paddingBottom: "14px", paddingTop: "10px", fontSize: "14px" }}
                  className={path.pathname === "/newplan" ? "activee" : "inactivee"}
              >
                  My Plan
              </div>

              <div
                  role="button"
                  style={{ paddingBottom: "14px", paddingTop: "10px", fontSize: "14px" }}
                  className={path.pathname === "/changepass" ? "activee" : "inactivee"}
                  onClick={() => {
                     
                      navigate("/changepass");
                  }}
              >
                  Change Password
              </div>
              <div
                  role="button"
                  style={{ paddingBottom: "14px", paddingTop: "10px", fontSize: "14px" }}
                  className={path.pathname === "/myreview" ? "activee" : "inactivee"}
                  onClick={() => {
                     
                      navigate("/myreview");
                  }}
              >
                  Reviews
              </div>
          </div>
      </div>
  )
}

export default ContsantHeader