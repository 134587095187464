import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import header from "./Images/header.svg";
// import { useState } from 'react';
import { AiOutlineDown } from "react-icons/ai";
import loggo from "../SaasOnboarding/NewLandingPage/imagesAnj/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";

export default function HamburgerMenu(props) {
  const [show, setShow] = useState(false);
  const [isToggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dropdownOpen, setdropdownOpen] = useState("");
  const [box, setbox] = useState("All");
  const [changeBorder, setchangeBorder] = useState("");

  const handleNew = () => {
    props.changeTab2();
  };
  const handleNew2 = () => {
    props.changeTab();
  };

  //   useEffect(() => {
  //     if (localStorage.getItem("pathname") === null) {
  //       if (
  //         localStorage.getItem("token") != "null" &&
  //         localStorage.getItem("token") != null
  //       ) {
  //         navigate("/lead")
  //         setIsLoggedIn(true)
  //       }
  //     }
  //      else {
  //       const path = localStorage.getItem("pathname");
  //       navigate(`${path}`);
  //     }
  //   }, []);

  const handleSignIn = () => {
    navigate("/login");
  };
  return (
    <div>
      <section className="top-nav">
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <div>
            <input id="menu-toggle" type="checkbox" />

            <Button variant="primary" onClick={handleShow}>
              <label
                className="menu-button-container"
                htmlFor="menu-toggle"
                onClick={() => setToggle(false)}
              >
                <div className="menu-button" />
              </label>
            </Button>
          </div>

          <div
            className="d-flex"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "flex-end",
            }}
          >
            <img
              src={loggo}
              style={{ width: "8.4rem", objectFit: "contain" }}
              onClick={() => navigate("/")}
            />
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose} style={{ borderRadius: 0 }}>
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title> </Offcanvas.Title> */}
            <img
              src={loggo}
              style={{ width: "10rem", height: "1.5rem", objectFit: "contain" }}
              onClick={() => navigate("/")}
            />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className=""
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: "500",
                textDecoration: "none",
                padding: "0.5rem",
                marginLeft: "1rem",
              }}
              onClick={() => navigate("/products")}
            >
              Features
            </div>

            <div
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: "500",
                padding: "0 0.5rem",
                background: "#f7f7f7 ",
              }}
              role="button"
            >
              <Accordion style={{ boxShadow: "none" }}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="py-0"
                  style={{ backgroundColor: "#f7f7f7" }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    className="d-flex align-items-center"
                  >
                    Who we Serve
                    <BsChevronDown className="ms-3" />
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{ backgroundColor: "#f7f7f7" }}
                  className="ps-4"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                      onClick={() => navigate("/architect")}
                    >
                      Architects
                    </div>
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                      onClick={() => navigate("/contractor")}
                    >
                      Construction Companies
                    </div>
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                      onClick={() => navigate("/design")}
                    >
                      Design and Build
                    </div>
                    <div
                      style={{
                        color: "#000",

                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                      onClick={() => navigate("/estate")}
                    >
                      Real Estate Builders
                    </div>
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                      onClick={() => navigate("/retail")}
                    >
                      Retail Companies
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div
              className=""
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: "500",
                padding: "0.5rem",
                marginLeft: "1rem",
                // border:"2px solid red",
              }}
              onClick={() => navigate("/about")}
              role="button"
            >
              Teams
            </div>

            <div
              className=""
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: "500",
                padding: "0.5rem",
                marginBottom: "2rem",
                marginLeft: "1rem",
              }}
              onClick={() => navigate("/contact")}
              role="button"
            >
              Contact
            </div>
            {/* <div
              className=""
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: "500",
                padding: "0.5rem",
                marginBottom: "2rem",
                marginLeft: "1rem",
              }}
              // onClick={() => navigate("/pricing")}
              onClick={() => {
                localStorage.setItem("pricing", true)
                  localStorage.setItem("cameFrom", "b2b");
                  navigate("/pricing");
                }}
              role="button"
            >
              Pricing
            </div> */}

            <div
              className="d-flex mt-0"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn-login"
                style={{
                  borderRadius: "8px",
                  background: "#E1E5E6",
                  boxShadow: "0px 0.2px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  border: "none",
                }}
                onClick={() => navigate("/schedule")}
              >
                <div
                  style={{
                    color: "#1A1A1A",
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: 700,
                  }}
                >
                  Schedule a Demo
                </div>
              </button>
            </div>

            <div
              className="d-flex mt-0"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn-login"
                style={{
                  borderRadius: "8px",
                  background: "#0084EF",
                  boxShadow: "0px 0.2px 4px 0px rgba(0, 0, 0, 0.25)",
                  color: "#ffffff",
                  display: "flex",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  border: "none",
                }}
                onClick={() =>
                  window.location.assign("https://pro.idesign.market/")
                }
              >
                <div
                  style={{
                    color: "#ffffff",
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: 700,
                  }}
                >
                  Login
                </div>
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <ul className="menu">
          {/* <li className='' style={{ fontSize: '15px', color: 'black', borderBottom: '1px solid #D7D7D7',fontWeight: "500" }} role="button" onClick={() => navigate('/pricing')}>Pricing</li> */}

          {/* <li
            className=""
            style={{
              fontSize: "15px",
              color: "black",
              borderBottom: "1px solid #D7D7D7",
              fontWeight: "500",
            }}
            role="button"
            onClick={() =>
              window.location.assign("https://magazine.idesign.market/")
            }
          >
            Magazine
          </li> */}
          {/* <li className='d-flex justify-content-between p-0' style={{ borderBottom: '1px solid #D7D7D7' }}>
                        <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }}>About  </li>

                        {isToggle ? <li role="button" onClick={() => setToggle(!isToggle)}><AiOutlineDown style={{ transform: 'rotate(180deg)' }} />
                        </li>

                            : <li role="button" onClick={() => setToggle(!isToggle)}><AiOutlineDown /></li>}

                    </li>
                    {isToggle ? <>
                        <ul style={{ listStyleType: 'none', width: '92%', paddingLeft: '0px', }}>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/aboutidesign" style={{color: "black", textDecoration: "none"}}>
                                About Us
                                </Link></li>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/terms" style={{ color: "black", textDecoration: "none" }}>
                                Terms & Condition
                            </Link></li>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/privacy-policy" style={{ color: "black", textDecoration: "none" }}>
                                Privacy Policy
                            </Link></li>
                            <li style={{ fontSize: '16px', color: 'black', borderBottom: '1px solid #D7D7D7' }} > <Link to="/contact" style={{ color: "black", textDecoration: "none" }}>
                                Contact Us
                            </Link></li>
                        </ul></> : ""} */}
          {/* <li className='btn_back flex-column'>
                        <button className='btn-login' style={{ background: '#3B5998', color: '#ffffff', margin: '0rem', width: '100%', fontSize: "13px", fontWeight: "500", height: '2rem' }} onClick={() => navigate('/signup')}>Create an Account</button>
                    </li> */}
        </ul>
      </section>
    </div>
  );
}
