import React, { useEffect } from "react";
import "../App.css";
import image from "../Images/perSquareftPage.svg";
import dropImage from "../Images/removeWorking.png";
import vector from "../Images/Vector.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useContext } from "react";
import { OnboardingContext } from "../Context/Context";
import { useNavigate } from "react-router";
import DesignerPerSqFtWeb from "./OnboardingWebScreens/DesignerPerSqFtWeb";
import { useLocation } from "react-router-dom";

export default function DesignFee() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);
  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation()

  const [show, setShow] = useState(false);
  function handleChange(e) {
    if (e.target.value.length <= 5 && e.target.value >= 0) {
      setPrice(e.target.value);
    }
  }
  
  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])


  function handleSubmit() {
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);
      localStorage.setItem("designFee", price);
      context.setData((pre) => ({ ...pre, designFeePerSqFt: price }));
      console.log(context.data);
      navigateTo("/perroomfee");
    }
  }
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const handleClose = () => setShow(false)
  return (
    <React.Fragment>
      {window.screen.width > "768" ?
        <DesignerPerSqFtWeb />
        :
        <div className="designFee-container">
          <div className="main-consultation min-vh-100 justify-content-between position-fixed bottom-0">
            <br />
            <div className="d-flex w-100 h-75 flex-column align-items-center" style={{ marginTop: "-90px" }}>
              <img src={image} />
              <p>
                What’s your{" "}
                <b>
                  Design Fees <br /> per Sq.ft
                </b>{" "}
                basis for{" "}
                <b>
                  {" "}
                  Home <br />
                  Interiors ?
                </b>
              </p>
              <input value={price} className="text-center fs-5" style={showError ? { borderColor: "red" } : null} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              } />
              <span style={{ color: "red", fontSize: "10px", width: "60%", alignSelf: "center", marginTop: "4px", alignItems: "center" }} className={`${showError ? "" : "d-none"}`}>
                You cannot leave this field empty
              </span>
            </div>
            <div className="w-100 d-flex flex-column justify-content-center " >
              <div className="virtualInfo">
                <button
                  onClick={() => {
                    setShowWorking(true);
                  }}
                >
                  <img src={vector} />
                </button>
                <span>How Does Fees Per Sq.ft work? </span>
              </div>
              <div className="d-flex justify-content-center w-100">
                {/* <Link to="/perroomfee"> */}
                <button onClick={handleSubmit} className="nextbutton">
                  Next
                </button>
              </div>
              {/* </Link> */}
            </div>
            <Offcanvas className="virtualWorking " show={showWorking} onHide={() => setShowWorking(false)} placement="bottom">
              <Offcanvas.Header className="offcanvasBtn mb-0">
                <button
                  onClick={() => {
                    setShowWorking(false);
                  }}
                  className="removeWorking"
                >
                  <img src={dropImage} />
                </button>
              </Offcanvas.Header>
              <Offcanvas.Body className="offcanvasBody" style={{ paddingLeft: "16px", height: "fit-content" }}>
                <div>
                  <p className="howZoomWorks">How Does Fees per Sqft Work?</p>
                </div>
                <div>
                  <ul className="offcanvasList px-3 pr-0 br-8">
                    <li className="my-3">
                      Provide a per sqft fees for all interior design services - <b>including drawings, visits, 3Ds, GFCs etc</b>
                    </li>
                    <li>
                      Connect with Homeowners and<b> provide Quotations and Explain Offerings</b>
                    </li>
                  </ul>
                  <p className="cl-grey1">Note : All prices exclusive of GST</p>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      }
    </React.Fragment>
  );
}