import React from "react";
import styles from "../AboutPageCss/leadership.module.css";

const LeaderShip = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>Leadership</div>
        <div className={styles.para}>Meet the iDesign.market team.</div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "50%",
              display: "flex",
              paddingTop: "4rem",
              paddingLeft: "4rem",
            }}
          >
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/ashish.svg"
              alt=""
              style={{height:"10rem",width:"10rem"}}
            />
            <div style={{ paddingLeft: "2rem" }}>
            <div style={{display:'flex'}}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207489.svg"
                  alt=""
                  
                />
                <div style={{paddingLeft:"0.5rem"}}>
                  <div className={styles.name}>Ashish Dhingra</div>
                  <div className={styles.name}>CO-Founder and CEO</div>
                </div>
              </div>
              <div className={styles.large_para}>
              Ashish comes with over 20 years of experiences across AEC and financial industry. He is a graduate of School of Planning & Architecture Delhi, and has been an entrepreneur for over 10 years in the Interior and Construction Space before iDesign.Market. 
              </div>
            </div>
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              paddingTop: "4rem",
              paddingLeft: "5rem",
            }}
          >
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/sunil.svg"
              alt=""
              style={{height:"10rem",width:"10rem"}}
            />
            <div style={{ paddingLeft: "2rem" }}>
              <div style={{display:'flex'}}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207489.svg"
                  alt=""
                />
                <div style={{paddingLeft:"0.5rem"}}>
                  <div className={styles.name}>Sunil Bhakuni</div>
                  <div className={styles.name}>CO-Founder and CEO</div>
                </div>
              </div>

              <div className={styles.large_para}>
              Sunil has an experience of over 20 years in IT having worked in senior roles such as Senior Solution Architect in AWS, Founding Team Member Jabong and has been a part of multiple startsups like Lenskart and Cardekho. 
              </div>
            </div>
          </div>
        </div>

 
      
      </div>
    </>
  );
};

export default LeaderShip;
