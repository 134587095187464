import { TextField } from '@mui/material';
import React from 'react'
import LoginHeader from './LoginHeader';
import bgmain from "./SaasAssets/bg-main.svg";

const Verification = () => {
  return (
    <>
     <LoginHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          wifth: "100%",
          backgroundImage: `url(${bgmain})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "100%",
          backgroundPositionY: "center",
        }}
      >
        <div style={{ width: "40%", border: "1px solid #E0E3E6", borderRadius: "12px", backgroundColor: "#ffffff" }}>
          <div style={{ padding: "24px", borderBottom: "1px solid #E0E3E6", color: "#40454A", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: 700, lineHeight: "28px" }}>
            Reset Password
          </div>
          <div style={{ padding: "24px 24px 28px 24px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}>
            <div style={{ color: "#999", fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px" }}>
              Please enter email associated with your account and we will send you an OTP
            </div>
            <div style={{ borderRadius: "12px", width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Email"
                size="small"
                variant="outlined"
                className="saas-form"
                type="email"
                name="email"
                // value={email}
                // onChange={(event) => setEmail(event.target.value)}
                style={{ borderRadius: "12px"}}
              />
            </div>

            <div style={{ width: "100%"}}>
              <button style={{ borderRadius: "8px", border: "1px solid #0084EF", background: "#0084EF", width: "100%", padding: "10px 14px", color: "#ffffff" }} >
                Get OTP
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Verification;