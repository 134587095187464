import React from "react";
import { useState } from "react";
import img from "../../Images/addImageIcon.png";
import editImage from "../../Images/editIcon.png";
// import AddProjectDetails from "./AddProjectDetails";
import { useContext } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { OnboardingContext } from "../../Context/Context";
import { useRef } from "react";
import OemSelectedImgWeb from "./oemWadrobImgWeb";
// import AddProjectPicsDesignerWeb from "../OnboardingWebScreens/AddProjectPicsDesignerWeb";
// import AddProjectPicsDesignerWeb from "../../OnboardingWebScreens/AddProjectPicsDesignerWeb";

export default function OemUploadImg() {
  const addImageRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const context = useContext(OnboardingContext);
  // const [title, setTitle] = useState(context.data.projects[context.data.projects.length - 1].details.name);
  const title = "Upload images of Wardrobe"
  //   const [projectImages, setProjectImages] = useState([]);
  const navigateTo = useNavigate();

  function handlePics(e) {
    //  console.log(e.target.files)
    // let tmppath = URL.createObjectURL(e.target.files[0]);
    // console.log(tmppath)
    let obj = e.target.files;
    let filesArr = [];

    for (let i = 0; i < obj.length; i++) {
      filesArr.push({ file: obj[i], path: URL.createObjectURL(obj[i]) });
    }

    // console.log(filesArr);
    // setProjectImages([...filesArr]);
    let tempArr = context.data.projects || [];
    let arrObj = tempArr[tempArr.length - 1] || {};
    arrObj.images = filesArr;
    tempArr[tempArr.length - 1] = arrObj;
    context.setData((pre) => ({ ...pre, projects: tempArr }));

    navigateTo("/oemsavewardrobeprojectpics");
  }

  function handleClick() {
    console.log(isEditMode);
    setIsEditMode(true);
  }

  function handleTitle(e) {
    let d = context.data.projects;
    d[d.length - 1].details.name = e.target.value;
    // setTitle(e.target.value);
    context.setData((pre) => ({ ...pre, projects: d }));
  }

  const clickToAddImages = () => {
    addImageRef.current.click();
  }

  return (
    <>
    {window.screen.width > "768" ?
      <h1>hello</h1>
    :
      <div className="addProjectPics-container">
        <div className="main-addprojectpicsContainer">
          <div className="header w-100">
            {!isEditMode && <div style={{ marginLeft: "5%", fontSize: "16px", fontWeight: "400" }}>{title}</div>}
            {isEditMode && <input onChange={handleTitle} type="text" value={title} />}
          </div>
          <div className="body" onClick={clickToAddImages}>
            <button className="clicktoaddpics">
              <input className="d-none" onChange={handlePics} ref={addImageRef} type="file" multiple /> <img src={img} />
            </button>
            <div style={{fontSize: "14px", fontWeight: "400", margin: "40px 0 4px 0"}}>Please upload your project photos</div>
            <div style={{fontSize: "14px", fontWeight: "400"}}> Accepted format jpeg,png </div>
          </div>
          <div></div>
          {/* {isEditMode && <AddProjectDetails />} */}
        </div>
      </div>
    }
    </>
  );
}
