import React, { useEffect, useRef } from "react";
import styles from "./HamburgerPage.module.css";
import { useState } from "react";
import { menuData } from "./hamburgerMenuData";
import { useNavigate } from "react-router-dom";

function HamburgerPage({ openHamburger, setOpenHamburger }) {
    const [clickedItem, setClickedItem] = useState(null);
    const [ddToshow, setDdToShow] = useState(null);
    const navigate = useNavigate();
    const headerRef = useRef(null);
    const handleClickOutside = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
            // Clicked outside the header, close the dropdown
            setDdToShow(0);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    console.log("menudata", menuData);
    const handleMenuClicked = (clickedItem2) => {
        if (clickedItem == clickedItem2) {
            setClickedItem(null)
        } else {
            setClickedItem(clickedItem2)
        }
    }
    return (
        <div className={styles.hamburgerPage} >
            <div className={styles.innerContainer}>
                <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/logo.png"
                    alt="logo"
                    className={styles.logo}
                />
                <img
                    onClick={() => setOpenHamburger(!openHamburger)}
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Vector.png"
                    alt="hamburger"
                    className={styles.ham}
                />
            </div>
            <div className={styles.menuItemsContainer}>
                {menuData.map((item, index) => {
                    if (item.type === "megamenu") {
                        return (
                            <div className={styles.menuItemsParent}>
                                <div className={styles.menuItems} onClick={() => handleMenuClicked(item.nav_name)}>
                                    <div className={styles.menuText}>{item.nav_name}</div>
                                    <div style={{ zIndex: '-1' }}>
                                        {" "}
                                        <img
                                            onClick={() =>
                                                ddToshow != 1 ? setDdToShow(1) : setDdToShow(0)
                                            }
                                            src={
                                                (clickedItem === item.nav_name)
                                                    ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/blueDown.svg"
                                                    : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/down.svg"
                                            }
                                            alt="down"
                                            className={styles.downArrowButton}
                                            style={{
                                                transform: clickedItem === item.nav_name ? "rotate(180deg)" : "none",
                                            }}
                                        />
                                    </div>
                                </div>
                                {clickedItem == item.nav_name && <div className={styles.subMenuContainer}>
                                    {
                                        item.sub_nav.map((sub_nav_item, subIndex) => {
                                            return <div className={styles.subMenuContainerInner}>
                                                <div className={styles.subMenuTitle}>
                                                    <img
                                                        src={sub_nav_item.icon}
                                                        alt="hamburger"
                                                        className={styles.ham}
                                                    />
                                                    <div className={styles.subMenuTitleText}>{sub_nav_item.title}</div>
                                                </div>
                                                <div className={styles.subMenuItemsContainer}>
                                                    {sub_nav_item.data.map((data, index) => {
                                                        return <div onClick={() =>{
                                                             navigate(data.route)
                                                             setOpenHamburger(!openHamburger)
                                                            }} className={styles.subMenuItemData}>{data.name}</div>
                                                    })}</div>
                                            </div>
                                        })
                                    }
                                </div>}
                            </div>
                        );
                    } else if (item.type === "megamenu2") {
                        return (
                            <div className={styles.menuItemsParent}>
                                <div className={styles.menuItems} onClick={() => handleMenuClicked(item.nav_name)}>
                                    <div className={styles.menuText}>{item.nav_name}</div>
                                    <div>
                                        {" "}
                                        <img
                                            onClick={() =>
                                                ddToshow != 1 ? setDdToShow(1) : setDdToShow(0)
                                            }
                                            src={
                                                (clickedItem === item.nav_name)
                                                    ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/blueDown.svg"
                                                    : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/down.svg"
                                            }
                                            alt="down"
                                            className={styles.downArrowButton}
                                            style={{
                                                transform: ddToshow === 1 ? "rotate(180deg)" : "none",
                                            }}
                                        />
                                    </div>
                                </div>
                                {clickedItem == item.nav_name && <div className={styles.subMenuContainer}>
                                    {
                                        item.sub_nav.map((sub_nav_item, subIndex) => {
                                            return <div className={styles.subMenuContainerInner}>
                                                <div onClick={() =>{
                                                      setOpenHamburger(!openHamburger)
                                                     navigate(sub_nav_item.route)
                                                     }} className={styles.subMenuTitle}>
                                                    <img
                                                        src={sub_nav_item.icon}
                                                        alt="hamburger"
                                                        className={styles.ham}
                                                    />
                                                    <div className={styles.subMenuTitleText}>{sub_nav_item.title}</div>
                                                </div>
                                                <div className={styles.subMenuItemsContainer}>
                                                    <span className={styles.mega2Description}> {sub_nav_item.descriptions}</span>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>}
                            </div>
                        );
                    } else if (item.type === "pricing") {
                        return (
                            <div className={styles.menuItems}>
                                <div onClick={() => {
                                    navigate(item.route)
                                    setOpenHamburger(!openHamburger)
                                    }} className={styles.menuText}>{item.nav_name}</div>
                            </div>
                        );
                    } else if (item.type === "blogs") {
                        return (
                            <div className={styles.menuItems}>
                                <div onClick={() => {
                                    window.location.assign(item.route)
                                    setOpenHamburger(!openHamburger)
                                    }} className={styles.menuText}>{item.nav_name}</div>
                            </div>
                        );
                    }
                    })}
            </div>
            <div className={styles.btnContainer}>
                <div className={styles.scheduleDemoBtn}>
                    <div
                        className={styles.scheduleDemoBtnText}
                        onClick={() => navigate("/schedule")}
                    >
                        Schedule a Demo
                    </div>
                </div>
                <div className={styles.signUp}>
                    <div
                        className={styles.signUpText}
                        onClick={() => navigate("/signup")}
                    >
                        Sign Up
                    </div>
                </div>
                <div className={styles.signIn}>
                    <div
                        className={styles.signInText}
                        onClick={() =>
                            window.location.assign("https://pro.idesign.market/")
                        }
                    >
                        Sign In
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HamburgerPage;
