import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './Onboarding.css'
import con_img4 from './On_Images/con_img4.svg'
import vector from './On_Images/vector.svg'
import cross_main from './On_Images/cross.svg'
import { Modal } from "react-bootstrap";
import close from "../../Images/close.png";

const WardrobeWeb = () => {
    const [wardrobe_fees, setWardrobe_fees] = useState("")
    const [showError, setShowError] = useState(false);
    const [showWorking, setShowWorking] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();



    useEffect(()=>{
        localStorage.setItem("path", location.pathname)
    },[])

    const handleChange = (e) => {
        if (e.target.value.length <= 4 && e.target.value >= 0) {
        setWardrobe_fees(e.target.value)
        }
    }

    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        if (wardrobe_fees.length < 1) {
            setShowError(true);
        } else {
            setShowError(false); 
        navigate("/kitchen")
        localStorage.setItem("wardrobe", wardrobe_fees)
        }
    }
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const handleSkip = () => {
        navigate("/kitchen")
    }

    return (
        <>
         <Modal className="web-virtualWorking" show={showModal} onHide={() => { setShowModal(false) }} centered>
                <Modal.Body className="offcanvasBody" style={{ paddingLeft: "6px" }} >
                    <img src={close} alt="" className='d-flex float-end' onClick={() => setShowModal(false)} style={{
                        opacity: "0.5",
                        height: "13px",
                        width: "13px",
                        cursor: "pointer"
                    }} />
                    <div style={{ fontSize: "16px" }}>
                        <p className="howZoomWorks mt-3 ">What are these Services we are talking about?</p>
                    </div>
                    <div>
                        <ul className="offcanvasList">
                            <li>
                                Rates to be provided without GST. GST will be extra as applicable
                            </li>
                            <li>
                                Rates should not include cartiage. Transport & Packing will be agreed between you and the client basis distance
                            </li>
                            <li>
                                iDesign does not take any commission from your Business
                            </li>
                        </ul>
                        <p className="note">Note : All prices exclusive of GST</p>
                    </div>
                </Modal.Body>
            </Modal>
   
        <div className="fees_main">
            <div className="fees_main2 vh-100">
                <div className="fees_center2">
                    <div className="fees_left">
                        <div className="fees_left1">
                            <img src={con_img4} alt="" className="fees_img4" />
                            <h2 className="fees_header">How much fees would you charge for Wardrobes?</h2>
                                <div className="fees_bottom" onClick={() => {
                                    setShowWorking(true);
                                    setShowModal(true)
                                }}>
                                <img src={vector} alt="" className="fees_vector"  />
                                    <p className="fees_byline" style={{ cursor: "pointer" }}>What are these services we are talking about?</p>
                            </div>
                        </div>
                    </div>
                    <div className="fees_right">
                        <form className="fees_form" >
                            <input type="number" className="fees_amount" value={wardrobe_fees} onChange={handleChange} style={showError ? { borderColor: "red", textAlign: "center" } : { textAlign: "center" }} placeholder='Please enter the amount in INR' onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) && e.preventDefault()
                            } required /> <span style={{ color: "red", textAlign: "center", fontSize: "12px", alignSelf: "center", marginTop: "4px" }} className={`${showError ? "" : "d-none"}`}>
                                You cannot leave this field empty
                            </span>
 
                            <div className="btns">
                                <button type="submit" className="fees_submit" id='skip' onClick={handleSkip}>Skip</button>
                                <button type="submit" className="fees_submit" onClick={handleSubmit}>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default WardrobeWeb;