import React, { useEffect } from 'react'
import { useState } from "react";
import img from "../../Images/addImageIcon.png";
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { addMoreProjectImages, addProjectImages, setProjectDetailsRedux } from '../../Lms/Actions';
import { useRef } from 'react';
import OemAddPhotosMob from './oemAddPhotosMob';
import { useLocation } from 'react-router-dom';


export default function OemAddPhotos() {
  const addImagesRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const projName = "Upload Images of Wardrobe";
  const [title, setTitle] = "Upload Images of Wardrobe";
  const navigateTo = useNavigate();
  const location = useLocation();


  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])


  function handlePics(e) {
    let obj = e.target.files;
    let filesArr = [];

    for (let i = 0; i < obj.length; i++) {
      filesArr.push({ file: obj[i], path: URL.createObjectURL(obj[i]) });
    }

    dispatch(addProjectImages(filesArr));

    navigateTo("/oemsavephotos");
  }

  function handleClick() {
    setIsEditMode(!isEditMode);
  }

  function handleTitle(e) {
    // setTitle(e.target.value);
    dispatch(setProjectDetailsRedux(e.target.value, projName?.city));
  }

//   useEffect(() => {
//     setTitle(projName?.name);
//   }, [projName])

//   useEffect(() => {
//     if(!title){
//       navigateTo("/addproject")
//     }
//   }, [])
  return (
    <>
    {window.screen.width < "768" ?
     <OemAddPhotosMob />
     :
      <div className="main-professionContainer-web d-flex justify-content-center align-items-center">
        <div style={{ background: "white" }} className="main-div row">

          <div className="main-addprojectpicsContainer-web">
            <div className="header" style={{ boxShadow: 'none', justifyContent: 'start' }}>
            <p style={{color: "#282828", fontWeigth: "400", fontSize: "17px"}}>Upload Images of Wardrobe</p>
              {/* {!isEditMode && <p className='fs-5 mx-3' style={{ marginLeft: "1%", marginTop: "3%", }}>{title}</p>}
              {isEditMode && <input style={{ marginTop: "3%", border: "none" }} onChange={handleTitle} type="text" value={title} />}
              <button onClick={handleClick}>
                <img src={editImage} alt="" />
              </button> */}
            </div>
            <div className="body" role="button" onClick={() => {addImagesRef.current.click()}}>
              <button className="clicktoaddpics">
                <input className="d-none" onChange={handlePics} ref={addImagesRef} type="file" multiple /> <img src={img} />
              </button>
              <h2 style={{ textAlign: "center", color: "#3D3D3D" }}>Please upload your project photos</h2>
              <p style={{ textAlign: "center" }}> Accepted format jpeg,png </p>
            </div>
            <div></div>
            {/* {isEditMode && <AddProjectDetails />} */}
          </div>

        </div>
      </div>
    }
    </>
  )
}
