import React from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import UploadPdfMob from "./UploadPdfMob";
import UploadReference0 from "./UploadReference0";
import completed from "./3dImages/completed.svg";
import locked from "./3dImages/locked.svg";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";
const UploadPdf2 = () => {
  return (
    <>
      <div className="d-none d-md-block">
        <HeaderNav />
        <div className="tabDisplay">
          <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", paddingRight: "0" }}>
            <SidebarWebNew />
          </div>
          <div className="tab_main">
            <div className="tab_main2">
              <button className="tab_active2">
                1. Upload AutoCAD file{" "}
                {localStorage.getItem("tick") ? (
                  <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                ) : localStorage.getItem("makeOne") || localStorage.getItem("makepdf") ? (
                  <img src={locked} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                ) : (
                  ""
                )}
              </button>
              <button className="tab_active2">
                2. Select room & views
                {localStorage.getItem("tick") ? (
                  <img src={completed} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                ) : localStorage.getItem("makeOne") || localStorage.getItem("makepdf") ? (
                  <img src={locked} alt="" style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                ) : (
                  ""
                )}
              </button>
              <button className="tab_active">3. Upload reference PPT </button>
            </div>
            {<UploadReference0 />}
          </div>
        </div>
      </div>
      <UploadPdfMob />
    </>
  );
};

export default UploadPdf2;
