import React from 'react';
import { useState, useEffect } from "react";
import HeaderMob from "../onboardingMob/HeaderMob";
import CreateProject from "../onBoarding/CreateProject";
import CreateMob5 from "../onboardingMob/CreateMob5";

const CreateCombined = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
    <>
    {isDesktop?<CreateProject/>:<>
    <HeaderMob/>
    <CreateMob5/>
    
    </>}
    
    </>
  )
}

export default CreateCombined;