import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import side from './VendorImgs/side.svg'
import clock from './VendorImgs/Clock.svg'
import hourglass from './VendorImgs/hourglass.svg'
import { useSelector } from 'react-redux'


const VendorReqApprovedMob = () => {
    const navigate = useNavigate()
    const handleRequest = () => {
        navigate("/myprofile")
    }
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    return (
        <div className='d-block d-md-none'>
            <Link className="d-flex align-items-center" to="/" style={{ textDecoration: "none", color: "black", height: "50px", background: "white" }}>
                <span className="d-flex align-items-center">
                    <div className="me-3 d-flex align-items-center">
                        <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                    </div>
                    <div className="page-Heading">Vendor Subscription</div>
                </span>
            </Link>
            <div className="d-flex flex-column w-100 mt-3" style={{ background: "white", height: "90vh" }}>
                <div className="d-flex flex-column p-4">
                    <div className='d-flex'>
                        <img src={clock} alt="" style={{ height: "120px", width: "120px" }} />
                        <img src={hourglass} alt="" style={{ marginBottom: "auto", height: "40px", width: "40px" }} />
                    </div>
                    <div className="d-flex flex-column py-4">
                        <h3 className="list_head" style={{ fontSize: "16px" }}>Get Started with just 3 easy steps</h3>
                        <span className="list_start2" style={{ fontSize: "14px" }}> We will review your profile and rate list, once approved will get confirmation on your registered mail Id {profileInfo[0]?.data?.data?.email}</span>
                        <span className="list_start2 mt-5" style={{ fontSize: "14px" }}> Kindly wait for some time, we will contact you after your evaluation</span>
                        <button className='ven_btn mt-4 w-100' onClick={handleRequest}>Go to Profile</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorReqApprovedMob