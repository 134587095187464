import React from "react";
import styles from "../AboutMobCss/mainMob.module.css";

const MainMob = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>About us</div>
        <div className={styles.para}>
          <div style={{ marginBottom: "1rem" }}>
            iDesign.Market is a tech platform providing Cloud based software
            solutions to Interior and Construction companies to manage their
            projects & business from start to end. Our platform offers a range
            of powerful tools that will help streamline operations, increase
            efficiency, and drive profitability. With our robust suite of tools,
            including the innovative Quotation, Timeline feature, File Manager,
            DPRs, and Task Manager, iDesign Pro revolutionizes the way your
            teams collaborate and interact with clients. Our intuitive features
            facilitate seamless communication and cooperation, ensuring that
            everyone is on the same page throughout the project lifecycle.{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainMob;
