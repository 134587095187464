import React, { useState } from 'react';
import SideBarWeb from '../SideBarWeb';
import Left from "../../Images/leftarrow.png";
import summaryImage1 from '../../../Assets/profile/ProjectImage1.png';
import summaryImage2 from '../../../Assets/profile/ProjectImage2.png';
import { Link } from 'react-router-dom';
import { Routes, Route, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import EditProjectMob from './EditProjectMob';
import SidebarWebNew from '../SidebarNew/SidebarWebNew';
import HeaderNav from '../HeaderNav/HeaderNav';

export default function EditProject() {

    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
        setIsDesktop(window.screen.width > 767);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });


    const { id } = useParams();
    const projectData = useSelector((state) => state.addToCartReducer.projectList);
    const [project, setProject] = useState();
    const data = [
        { tag: summaryImage1 },
        { tag: summaryImage2 },
        { tag: summaryImage2 },
        { tag: summaryImage2 },
        { tag: summaryImage2 },

    ];


    useEffect(() => {
        setProject(
            projectData[0]?.data?.data?.data.filter((curElem) => {
                return curElem._id === id;
            })
        )
    }, [projectData])
    const navigate = useNavigate();
    const goToDifferentScreen = () => {
        navigate('/myprofile')
    }

    return (
        <>
            {isDesktop ? <div>
                <HeaderNav />
                <div className='d-flex'>
                    <div><SidebarWebNew /></div>
                    <div className='' style={{ backgroundColor: 'white', width: '100%', marginTop: '10px' }}>
                        <section className='projectImages_content d-flex justify-content-between' style={{
                            background: '#ffffff',
                            padding: '5px',
                            width: '76vw', borderBottom: '1px solid #D7D7D7'
                        }}>
                            <div className='d-flex' style={{ marginLeft: '20px' }} onClick={goToDifferentScreen}>
                                <img style={{ width: '10px', height: "10px", objectFit: 'contain', marginTop: '7px' }} src={Left} />
                                <div className='main_heading' style={{ marginLeft: '12px', cursor: 'pointer' }}> {localStorage.getItem('name')}</div>
                            </div>
                            <Link to={`/sumaryimages/${id}`}
                                className='edit_text' style={{
                                    fontFamily: 'Inter',
                                    color: 'rgb(59, 89, 152)',
                                    fontWeight: '400',
                                    fontHize: '18px',
                                    lineHeight: '25px', marginRight: '20px', textDecoration: 'none'
                                }}>Edit

                            </Link>

                        </section>
                        <div className='d-flex mt-4' style={{
                            overflow: 'scroll',
                            marginLeft: '20px', flexWrap: 'wrap', background: 'white',
                            width: '75vw'
                        }}>
                            {project && project[0]?.data[0]?.images.map((data, index) => (
                                <img alt="summaryImage1" style={{ width: '190px', height: '127px', margin: '10px', borderRadius: '5px' }} className="" src={data?.original} />
                            ))}

                        </div>
                    </div>
                </div >
            </div> : <EditProjectMob />}
        </>

    )
}