import React from 'react'
import styles from './Common.module.css'
import { useNavigate } from 'react-router-dom'

const CommonTols = ({ heading, subHeading, image }) => {
    const navigate = useNavigate()
    const tools = [
        {
            name: "Project Management",
            tools: [
                {title:"File Manager", route: "/fileManager"},
                {title:"Task Manager",  route: "/taskManager"},
               {title: "Minutes of Meeting", route: "/mom"},
                {title:"Timeline & Timesheet", route: "/timelineTimesheet"},
                {title:"RFI", route: "/rfi"},
                {title:"Material Request",  route: "/materialRequest"},
            ],
            icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Gear.svg"
        },
        {
            name: "Cost Management",
            tools: [
               {title: "Quotation", route: "/quotation",},
                {title:"Accounts",  route: "/account"},
                {title:"Vendor & PO", route: "/vendorPO"},
            ],
            icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CurrencyDollar.svg"
        },
        {
            name: "Client Management",
            tools: [
               {title: "Leads", route: "/newLead"},
                {title:"Chat",  route: "/chat"},
            ],
            icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/UsersThree.svg"
        },
        {
            name: "Additional Features",
            tools: [
                {title:"Library & Templates", route: "/libraryTemplates"},
               {title: "Item Library & Inventory",  route: "/itemLibraryInventory"},
                {title:"Attendance", route: "/attendance"},
               {title: "Company Managemnt",  route: "/companyManagement"},
               {title: "Team Management", route: "/teamManagement"},
               {title: "Native Mobile App", route: "/mobile"},
                {title:"Extras", route: "/extras"}
            ],
            icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CirclesThreePlus.svg"
        }
    ]
    return (
        <div className={styles?.tools} style={{ backgroundImage: `url('${image}')` }}>
            <div className={styles?.toolTop}>
                <p className={styles?.toolHeading}>{heading}</p>
                <p className={styles?.toolSubHead}>{subHeading}</p>
            </div>
            <div className={styles?.toolsFlex}>
                {
                    tools?.map((tool, index) => <div className={styles?.toolCard}>
                        <div><img src={tool?.icon} alt="" style={{ marginRight: '5px'
                        // , scale: '1.2'
                         }} /> {tool?.name}</div>
                        {
                            tool?.tools?.map(name => <li style={{userSelect:"none"}}
                                 onClick={()=> navigate(name?.route)}>{name?.title}</li>)
                        }
                    </div>)
                }
            </div>
            <div className={styles?.tbtns}>
                <button onClick={() => navigate('/schedule')}>Schedule a Demo</button>
                <button onClick={() => navigate('/signup')}>Get Started</button>
            </div>
        </div>
    )
}

export default CommonTols
