import React from "react";
import styles from "./Testmonial.module.css";

const Testmonial=({testimonialData})=>{
    return(
        <>
        <div style={{marginTop:"3.1rem"}}></div>
        <div className={styles.mainContainer}>
            <div className={styles.innerContainer}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/QuotesTwo.svg"
         alt="" className={styles.quotesImg} style={{marginTop:"1.75rem"}} />
        <div className={styles.testimonialText}>
          {testimonialData?.text}
        {/* iDesign is a very user-friendly platform. It is very easy for me to track things, review orders, manage hierarchy and even maintain accounts. Love it! */}
        {/* I've been using this project management platform for the past six months, and it has truly transformed the way we operate in the our company. */}
        </div>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/QuotesOne.svg"
         alt="" className={styles.quotesImg} style={{marginTop:"0.52rem", }} />
        <div className={styles.testimonialName}>
        {/* Himanshu Negi */}
        {testimonialData?.name}
        </div>
        <div className={styles.testimonialRole}>
        {/* Architect */}
        {testimonialData?.role}
        </div>
        <div>
        {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Star.svg"
                  alt={`star${index + 1}`}
                  className={styles.star}
                />
              ))}
        </div>
      
        </div>
        </div>
        </>
    )
}

export default Testmonial;