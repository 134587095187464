import React, { useState, useEffect } from "react";
import styles from "./NewTestimonials.module.css";
import Mayank from "./Images/Mayank.jpg"
import Pmanagement from "./Images/Pmanagement.jpg"

const peopleData = [
  {
    name: "Austin Knight",
    roleFirst: "Project Manager",
    roleLast:"Cradle Design",
    imageSrc: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test1.svg",
    headline:"Game-Changer: iDesign Connects All",
    desc:  "iDesign.Market is a great platform for businesses to connect, grow and support each other. It provides accurate results quickly and has a very user-friendly interface.",
  },
  {
    name: "Mayank Aggarwal",
    roleFirst: "Architect",
    roleLast:"Rajiv Associates",
    imageSrc:`${Mayank}`,
    headline:"Effortless Management: iDesign Love",
    desc:  "Queries from idesign have been very frequent within my team.They are very clear with the ideas and time line .It's a great experience working for them",
  },
  {
    name: "Tanya Khanduja",
    roleFirst: "Interior Designer",
    roleLast:"Interia",

    imageSrc: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test3.svg",
    headline:"On-Time, On-Budget: iDesign Success",
    desc:  "Working as a supplier of furnishing fabrics to I-Design has been an absolute pleasure.Extremely professional team at the helm and an exemplary track record on payments."
  },
];

const testimonials = {
  "Austin Knight":
  "Idesign.market has been a game-changer for my business. Their platform helps me stay connected with both my team members and clients. From managing vendors, files, PO to BOM and material selection, I can manage everything in one single platform.",
  "Mayank Aggarwal":
  "iDesign is a very user-friendly platform. It is very easy for me to track things, review orders, manage hierarchy and even maintain accounts. Love it!",
  "Camila Garcia":
  "It's fairly affordable, easy to use and has all the tools required to manage my interior designing business. I can deliver on time and on budget projects because now I have iDesign."
};

const NewTestimonials = () => {
  const [clickedPerson, setClickedPerson] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(
    testimonials["Austin Knight"]
  );

  useEffect(() => {
    handlePersonClick("Austin Knight");
  }, []);
  const handlePersonClick = (personName) => {
    setClickedPerson(personName);
    setSelectedPerson(testimonials[personName]);
  };

  function getHeadlineByName(name) {
    const person = peopleData.find(person => person.name === name);
    return person ? person.headline : "Name not found";
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.testimonialsHeading}>Testimonials</div>
      <div className={styles.testimonialsTextContainer}>
        <div className={styles.testimonialsText}>
        Hear from the people who use our product to streamline and manage their projects
        </div>
      </div>
      <div className={styles.testimonialFlexContainer}>
        <div className={styles.testimonialPeopleContainer} style={{cursor:"pointer"}}>
          {peopleData.map((person, index) => (
            <div
              key={index}
              className={`${styles.testimonialPeople} ${
                clickedPerson === person.name ? styles.clicked : ""
              }`}
              onClick={() => {
                handlePersonClick(person.name)
                getHeadlineByName(person.name)
              }}
            >
              <img
                alt={person.name}
                src={person.imageSrc}
                className={styles.testimonialPeopleImage}
              />
              <div className={styles.testimonialPeopleDetails}>
                <span className={styles.testimonialPeopleName}>
                  {person.name}
                </span>
                <div className={styles.testimonialPeopleRole} style={{display:"flex",alignItems:"center",gap:"5px"}} >
                  <span>           {person.roleFirst}</span>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
  <circle cx="3.5" cy="3.5" r="3.5" fill="#999999"/>
</svg></span>
                  <span>{person.roleLast}</span>
       
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.testimonialsRightSide}>
          <div className={styles.insideTestRightSide}>
          <div className={styles.testimonialRightSideHead}>
            <div className={styles.testimonialsRightSideHeading}>
              {/* Transforming Project Management - A Seamless Journey */}
              {getHeadlineByName(clickedPerson)}
            </div>
            <div className={styles.starContainer}>
              {/* <img src={star} alt="star" /> */}
              {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Star.svg"
                  alt={`star${index + 1}`}
                  className={styles.star}
                />
              ))}
            </div>
          </div>
          <div className={styles.testimonialPeoplesText}>{selectedPerson}</div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default NewTestimonials;
