import React from "react";
import PersonalDetails from "../onBoarding/PersonalDetails";
import PersonalMob1 from "../onboardingMob/PersonalMob1";
import { useState, useEffect } from "react";
import HeaderMob from "../onboardingMob/HeaderMob";

const PersonalCombined = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return <>
  {/* {isDesktop?<PersonalDetails/>:
  <>
  <HeaderMob/>
  <PersonalMob1 />
  </>
 
  } */}
  <PersonalDetails/>
  </>
};

export default PersonalCombined;
