import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { designerFeeSchema } from "../../../../Constants/formSchema";
import { updateUserProfile } from "../../../../Apis";
import { toast } from "react-hot-toast";
import { useState } from "react";

const fields = [
  {
    label: "Zoom Consultation",
    key: "zoomPrice",
    priceType: "/Hour",
  },
  {
    label: "Design Fee Charges per Sq.ft basis",
    key: "designAreaPrice",
    priceType: "/Sqft",
  },
  {
    label: "Design Fee Charges for One Room",
    key: "designRoomPrice",
    priceType: "/Room",
  },
];

const DesigningFees = ({ fees }) => {
  const [edit, setEdit] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(designerFeeSchema),
  });

  useEffect(() => {
    if (fees) reset(fees);
  }, [fees, reset]);

  const onUpdate = (data) => {
    const payload = new FormData();
    payload.append("fees", JSON.stringify(data));
    updateUserProfile(payload).then((res) => {
      setEdit(false);
      toast.success("Fee prices updated!");
    });
  };

  return (
    <div className="col-md-12 mt-5">
      <div className={styles.titleContainer}>
        <h2 className={styles.cardTitle}>My Designing Fees</h2>
        <span className={styles.edit} onClick={() => setEdit((prev) => !prev)}>
          {edit ? "Cancel" : "Edit"}
        </span>
      </div>
      <div className="checkout-content">
        <form onSubmit={handleSubmit(onUpdate)}>
          {fields.map((field, index) => (
            <div className={styles.fieldsContainer}>
              <h6>{field.label}</h6>
              <div className="zoom-input" key={index}>
                <span>INR</span>
                <FormControl
                  type="number"
                  min={1}
                  key={field.key}
                  className="disableErrorIcon interFont"
                  isInvalid={!!errors[field.key]}
                  disabled={!edit}
                  {...register(field.key)}
                />
                <span>{field.priceType}</span>
              </div>
            </div>
          ))}
          {!!edit && (
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-edit">
                Update
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default DesigningFees;
