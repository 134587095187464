import React from "react";
import styles from "./productMobCss/productSec7.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/task/all_match.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/task/auto_delete.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/task/vital_signs.svg"; 
// import quo from "./productMobImg/task/task.png";

const ProductSec7 = () => {
  return (
    <>
      <div className={styles.container} id="taskk">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>TASK MANAGEMENT</div>
            <div className={styles.file_para}>
              Optimize resource usage and ensures  <br /> that projects are completed
              efficiently <br /> and on time.
            </div>
            <div className={styles.img_div}>
              <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%282%29.svg" alt="" className={styles.img} />
            </div>
            <div className={styles.icon_div}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Track Dependencies</div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Manage pending & Delayed Tasks
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Prioritize Important Tasks
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSec7;
