import React from "react";
import "../../App.css";
import image from "../../Images/kitchen.png";
import dropImage from "../../Images/removeWorking.png";
import vector from "../../Images/Vector.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useContext } from "react";
import { OnboardingContext } from "../../Context/Context";
import { useNavigate } from "react-router";
import KitchenWeb from "./kitechenWeb";
import axios from "axios";
import { updateUserProfile } from "../../Apis";
import { useLocation } from "react-router-dom";

export default function Kitchen() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);
  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  useEffect(() => {
    context.setData((pre) => ({ ...pre, perRoomDesignCharge: price }));
    // console.log("hi",context.data.userData)
  }, [price]);

  function handleChange(e) {
    if (e.target.value.length <= 4 && e.target.value >= 0) {
      setPrice(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);
      const popFee = localStorage.getItem("falsecelling");
      // const designtype = localStorage.getItem("designtype").split(",");
      const tilesFixFee = localStorage.getItem("tiles");
      const paintFee = localStorage.getItem("emulsion");
      const wardrobeAlmiraFee = localStorage.getItem("wardrobe");
      const kitchenFee = localStorage.getItem("kitchen");

      const payload = new FormData();
      // let designStyle = JSON.stringify(designtype);
      const fees = {
        popFee: popFee,
        tilesFixFee: tilesFixFee,
        paintFee: paintFee,
        wardrobeAlmiraFee: wardrobeAlmiraFee,
        kitchenFee: kitchenFee
      };
      // payload.append("designStyle", [designStyle]);
      payload.append("fees", JSON.stringify(fees));

      updateUserProfile(payload).then((res) => {
        console.log(res)
        localStorage.removeItem("falsecelling");
        localStorage.removeItem("tiles");
        localStorage.removeItem("emulsion");
        localStorage.removeItem("wardrobe");
        localStorage.removeItem("kitchen");
        navigateTo("/addproject");
      });
    //   navigateTo("/addproject");
    }
  }

  function handleSkip(e) {
      const popFee = localStorage.getItem("falsecelling");
      // const designtype = localStorage.getItem("designtype").split(",");
      const tilesFixFee = localStorage.getItem("tiles");
      const paintFee = localStorage.getItem("emulsion");
      const wardrobeAlmiraFee = localStorage.getItem("wardrobe");
      // const kitchenFee = localStorage.getItem("kitchen");

      const payload = new FormData();
      // let designStyle = JSON.stringify(designtype);
      const fees = {
        popFee: popFee,
        tilesFixFee: tilesFixFee,
        paintFee: paintFee,
        wardrobeAlmiraFee: wardrobeAlmiraFee,
      };
      // payload.append("designStyle", [designStyle]);
      payload.append("fees", JSON.stringify(fees));

      updateUserProfile(payload).then((res) => {
        console.log(res)
        localStorage.removeItem("falsecelling");
        localStorage.removeItem("tiles");
        localStorage.removeItem("emulsion");
        localStorage.removeItem("wardrobe");
        navigateTo("/addproject");
      });
    //   navigateTo("/addproject");
    
  }

  return (
    <>
    {window.screen.width > "768" ?
      <KitchenWeb />
      : 
    <div className="main-falseceiling">
      <div className="falseceiling-upper d-flex flex-column align-items-center">
      <img src={image} />
      <p style={{fontSize:"26px",textAlign:"center",fontWeight:"500", marginTop: "2rem" }}>
      How much fees would you <br /> charge for <strong>Kitchen</strong> 
      </p>
            <input style={showError ? { borderColor: "red", textAlign: "center", width: "68%", marginTop: "7%" } : { textAlign: "center", width: "68%", marginTop: "7%" }} value={price} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" />
            <span style={{ color: "red", fontSize: "10px",  alignSelf: "center", marginTop: "4px", alignItems: "center" }} className={`${showError ? "" : "d-none"}`}>You cannot leave this field empty</span>
      </div>
      <div  className="d-flex flex-column align-items-center">
            <div className="virtualInfo1" onClick={() => {
              setShowWorking(true);
            }}>
        <button
        >
          <img src={vector} />
        </button>
        <span style={{color:"#888888"}}>What are these Services we are <br />
         talking about? </span>
      </div>
            <div className="btn-container2" style={{ boxShadow: "none" }}>
        {/* <Link to="/addproject"> */}
       {/* <Link to="/addproject"> <button className="skip-btn" onClick={handleSkip()}>skip</button> </Link> */}
       <button onClick={handleSkip} className="skip-btn">skip</button>
        <button onClick={handleSubmit} className="nextbutton2">
          Next
        </button>
        {/* </Link> */}
      </div>
      </div>
      {showWorking && (
        <Offcanvas className="virtualWorking" show={true} placement="bottom">
          <Offcanvas.Header className="offcanvasBtn">
            <button
              onClick={() => {
                setShowWorking(false);
              }}
              className="removeWorking"
            >
              <img src={dropImage} />
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvasBody1" style={{
                paddingRight: "16px",
                paddingLeft: " 4px"}}>
            <div>
              <p className="howZoomWorks">What are these Services we are talking about?</p>
            </div>
            <div>
              <ul className="offcanvasList">
                <li>
                Rates to be provided without GST. GST will be extra as applicable
                </li>
                <li>
                Rates should not include cartiage. Transport & Packing will be agreed between you and the client basis distance
                </li>
                <li>
                iDesign does not take any commission from your Business
                </li>
              </ul>
              <p className="note">Note : All prices exclusive of GST</p>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
    }
    </>
  );
}

