import React from "react";
import "../App.css";
import image from "../Images/perRoomChargePage.svg";
import dropImage from "../Images/removeWorking.png";
import vector from "../Images/Vector.png";
import { useState, useEffect } from "react";
import { updateUserProfile } from "../Apis";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useContext } from "react";
import { OnboardingContext } from "../Context/Context";
import { useNavigate } from "react-router";
import axios from "axios";
import DesignerPerRoomWeb from "./OnboardingWebScreens/DesignerPerRoomWeb";
import { useLocation } from "react-router-dom";

export default function PerRoomCharge() {
  const [showWorking, setShowWorking] = useState(false);
  const [price, setPrice] = useState("");
  const [showError, setShowError] = useState(false);
  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();
  const location = useLocation();


  useEffect(()=>{
    localStorage.setItem("path", location.pathname)
},[])

  useEffect(() => {
    context.setData((pre) => ({ ...pre, perRoomDesignCharge: price }));
    // console.log("hi",context.data.userData)
  }, [price]);

  function handleChange(e) {
    if (e.target.value.length <= 6 && e.target.value >= 0) {
      setPrice(e.target.value);
    }
  }

  function handleSubmit() {
    if (price.length < 1) {
      setShowError(true);
    } else {
      setShowError(false);
      // context.setData((pre)=>({...pre,perRoomDesignCharge:price}))
      console.log(context.data);
      let d = [];
      const url = "";
      const service = localStorage.getItem("services").split(",");
      // const designtype = localStorage.getItem("designtype").split(",");
      const consultation = localStorage.getItem("consultation");
      const designFee = localStorage.getItem("designFee");
      const perroomfee = price;
      // const serviceArray = service.split(',');
      // console.log(service)
      // console.warn(localStorage.getItem('designtype'));
      // console.warn(localStorage.getItem('consultation'));
      // console.warn(localStorage.getItem('designFee'));
      // console.warn(price);
      // d.push(context.data.userData);
      // d.push(context.data.typeOfService);
      // d.push(context.data.designStyles);
      // d.push(context.data.consultationCharges);
      // d.push(context.data.designFeePerSqFt);
      // d.push(context.data.perRoomDesignCharge);

      const payload = new FormData();
      let services = JSON.stringify(service);
      // let designStyle = JSON.stringify(designtype);
      const fees = {
        zoomPrice: consultation,
        designAreaPrice: designFee,
        designRoomPrice: perroomfee,
      };
      payload.append("services", [services]);
      // payload.append("designStyle", [designStyle]);
      payload.append("fees", JSON.stringify(fees));

      updateUserProfile(payload).then((res) => {
        navigateTo("/addproject");
      });
      navigateTo("/addproject");
    }
  }
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <React.Fragment>
      {window.screen.width > "768" ?
        <DesignerPerRoomWeb />
        :
        <div className="perRoomCharge-container">
          <div className="main-consultation min-vh-100 justify-content-between position-fixed bottom-0">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-2">
              <img src={image} />
              <p>
                How much would you <br />
                charge for{" "}
                <b>
                  Designing
                  <br />
                  One Room{" "}
                </b>{" "}
                in a Home ?
              </p>
              <input value={price} className="text-center fs-5" style={showError ? { borderColor: "red" } : null} onChange={handleChange} type="number" placeholder="Please enter the amount in INR" onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              } />
              <span style={{ color: "red", fontSize: "10px", width: "60%", alignSelf: "center", marginTop: "4px", alignItems: "center" }} className={`${showError ? "" : "d-none"}`}>
                You cannot leave this field empty
              </span>
            </div>
            <div className="w-100">
              <div className="virtualInfo">
                <button
                  onClick={() => {
                    setShowWorking(true);
                  }}
                >
                  <img src={vector} />
                </button>
                <span>How Does Fees Per Room work? </span>
              </div>
              <div className="d-flex justify-content-center w-100">
                {/* <Link to="/addproject"> */}
                <button onClick={handleSubmit} className="nextbutton ">
                  Next
                </button>
              </div>
              {/* </Link> */}
            </div>
          </div>
          <Offcanvas className="virtualWorking" show={showWorking} onHide={() => setShowWorking(false)} placement="bottom">
            <Offcanvas.Header className="offcanvasBtn mb-0">
              <button
                onClick={() => {
                  setShowWorking(false);
                }}
                className="removeWorking"
              >
                <img src={dropImage} />
              </button>
            </Offcanvas.Header>
            <Offcanvas.Body className="offcanvasBody pe-2">
              <div>
                <p className="howZoomWorks">How Does Per Room Design Fee work?</p>
              </div>
              <div>
                <ul className="offcanvasList">
                  <li>
                    Applicable for<b> Apartments, Builder Floors or Bungalows only.</b>
                  </li>
                  <li>
                    Fee is charged <b>irrespective of Room sizes upto 250 Sqft.</b> Even Toilet or Balcony Design is charged on per room
                  </li>
                  <li>
                    <b>Easy to understand </b>for homeowners and gets good response
                  </li>
                </ul>
                <p className="note">Note : All prices exclusive of GST</p>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      }
    </React.Fragment>
  );
}
