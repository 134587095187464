import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import styles from "../AboutMobCss/awardmob.module.css";

const AwardsMob = () => {
  return (
    <>
    <div style={{marginTop:"2rem"}}>
        <div className={styles.heading}>Recognitions & Awards</div>
        <Carousel
          nextIcon=""
          nextLabel=""
          prevIcon=""
          prevLabel=""
          style={{ width: "100%",marginTop:"2rem" }}
          className={styles.carso}
        >
          <Carousel.Item>
            <div
           style={{display:'flex',justifyContent:'center'}}
            >
            <a href="https://www.deccanherald.com/brandspot/pr-spot/amit-kumar-joins-as-advisor-to-tech-startup-idesignmarket-1111982.html" target="_blank">
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/deccan.svg"
              alt=""
              style={{cursor:'pointer',width:'90%',marginLeft:'1rem'}}
            />
            </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
            style={{display:'flex',justifyContent:'center'}}
            >
              <a href="https://www.zeebiz.com/small-business/news-tech-startup-idesignmarket-launches-software-for-interior-designing-professionals-178661" target="_blank">
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/zee.svg"
                alt=""
                style={{cursor:'pointer',width:'90%',marginLeft:'1rem'}}
              />
              </a>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
             style={{display:'flex',justifyContent:'center'}}
            >
               <a href="https://www.constructionworld.in/latest-construction-news/real-estate-news/interiors/tech-startup-idesign.market-launches-software-for-interior-designers/32732" target="_blank">
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/const.svg"
                alt=""
                style={{cursor:'pointer',width:'90%',marginLeft:'1rem'}}   
              />
             
              </a>
            </div>
          </Carousel.Item>

         
        </Carousel>
    </div>
    </>
  )
}

export default AwardsMob;