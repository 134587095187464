import React, { useEffect, useState } from 'react';
import img3d from '../3dComponents/3dImages/3dimg.svg'
import cadicon from '../3dComponents/3dImages/cadicon.svg'
import file from "./Images/cad.svg"
import { Modal, Offcanvas } from 'react-bootstrap'
import makeone from '../3dComponents/3dImages/MakeOne.svg'
import uploadAPdf from '../3dComponents/3dImages/uploadAPdf.svg'
import close from '../3dComponents/3dImages/close.svg'
import eye from '../3dComponents/3dImages/eye.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { fetchProfileData } from '../Lms/Actions'
import '../3dComponents/3d.css';
import threed from "./Images/threeD.svg"
import gmeet from "./Images/google_meet.svg"
import pdficon from "./Images/filee.svg"
import write from "./Images/write.svg"
import { Link } from 'react-router-dom';
import cross from "./Images/close_button.svg"
import sstar from "./Images/star.svg"
import yellow from './Images/yellowstar.svg'

export default function CompleteThreeDOrders() {
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);


    const updateMedia = () => {
        setDesktop(window.innerWidth > 650);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    const [textarea, setTextarea] = useState();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showId, setShowId] = useState();

    const handleClose = () => { setShow(false) }
    const handleClose2 = () => { setShow2(false) }
    const [id, setId] = useState();
    const [showwid, setShowwid] = useState("");

    const handleShow = (showwid) => {
        localStorage.setItem("showwid", showwid)
        setShow(true)
        console.log(showwid)
    }


    const navigate = useNavigate()

    // console.log(localStorage.getItem("saveLater"))
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const [data, setData] = useState([])
    const [star, setStar] = useState(0);
    const payM = useSelector((state) => state.threedReducer.payMethod)

    const handlleMakeOne = () => {
        localStorage.setItem("makeOne", true)
        navigate("/3dMakePPT/MakeOne")
        localStorage.removeItem("tick")
        localStorage.removeItem("tick2")
    }
    const handlleUploadOne = () => {
        localStorage.setItem("makepdf", true)
        navigate("/3dMakePPT/UploadPdf")
        localStorage.removeItem("tick")
        localStorage.removeItem("tick2")
    }
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo)
    const dispatch = useDispatch()

    // useEffect(async () => {
    //     const res = await axios.get(`https://3d-api.idesign.market/api/room/completed-designs?designerId=${localStorage.getItem("userId")}`);
    //     console.log(res)
    //     setUserData(res?.data)
    // }, [])

    useEffect(async () => {
        const res = await axios.get(`https://3d-api.idesign.market/api/room/completed-designs?designerId=${localStorage.getItem("userId")}`);
        console.log(res?.data)
        setData(res?.data)

    }, [])

    console.log(data)

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    const handleClick = (id) => {
        localStorage.setItem("halfPayId", id)
        console.log(id)
        navigate('/3dCartHalf')
    }

    const [ofArr, setOfArr] = useState([])
    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    }
    // console.warn(ofArr)
    var tot = 0
    for (let i = 0; i < ofArr[0]?.room?.length; i++) {
        tot += ofArr[0]?.room[i]?.views
    }

    console.log(ofArr)
    useEffect(() => {
        const b = data.filter((currElem) => {
            return currElem._id == showId
        })
        setOfArr(b)
    }, [showId])

    const handleChange = (e) => {
        if (e.target.value.length <= 200) {
            setTextarea(e.target.value);
        }
    }
    const postFeedback = async (e) => {
        setShow(false)
        console.log(showwid)

        const response = await axios.post('https://pro-api.idesign.market/user/postFeedback',
            {
                _3dId: localStorage.getItem('showwid'),
                feedBack: textarea,
                rating: star
            },
            {
                headers: {
                    authorization: authToken
                },
            }

        ).then((res) => {
            console.log(res);

        }).catch((err) => {
            console.log(err)
        })

    }
    console.log(star)
    return (
        <>
            <div className='d-none d-md-block'>
                <div className='d-flex'>
                    <Offcanvas show={show2} onHide={handleClose2} placement='end' size="md">
                        <Offcanvas.Header closeButton>
                            <div>
                                <Offcanvas.Title>{profileInfo[0]?.data?.data?.companyName}</Offcanvas.Title>
                                {/* <p style={{ fontSize: "14px", color: "#888888" }}>Delhi · 7h ago</p> */}
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body >
                            <div className="cart_file_sub2 pt-2 px-3">
                                {ofArr[0]?.cadFile?.fileName && <p className="file2"><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.cadFile?.fileName.slice(0, 9)}{"..."}</p>}
                                {ofArr[0]?.pdf?.fileName ? <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.pdf?.fileName.slice(0, 9)}{"..."}</p> : localStorage.getItem("saveLater") ? <><p className="file3" style={{ marginLeft: "30px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded</p> </> : <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                            </div>
                            <div className='d-flex flex-wrap px-2'>
                                {ofArr[0]?.room?.map((item) => (
                                    <button className='btn-off mx-2 mb-2'>{item.name} {item.views} <img src={eye} alt="" className="bbeye" /></button>
                                ))}
                                <p className='mx-2 mt-1 p-off'>Total {tot} views</p>
                            </div>
                            <div className='px-3 d-flex'>
                                <div className="off-line d-flex flex-column">
                                    <div className="d-flex">
                                        <div className="ord_circ2 "></div>
                                        <h4 className="cart_head2 d-flex " >Complete<span className='ord_span3'> (3D Block)</span>{ofArr[0]?.paidAmount === ofArr[0]?.amountToBePaid ? <button className="ord-off px-3 mx-2" onClick={() => {
                                            window.open(ofArr[0]?.adminStatus?.block?.file?.url, "_blank")
                                        }}>Downlaod File</button> : ""} </h4></div>
                                    <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.meet?.date)).toString().substring(4, 15)}</span></div>
                                    <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>meeting is completed sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.remark?.date)).toString().substring(4, 15)}</span></div>
                                    <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.file?.date)).toString().substring(4, 15)}</span></div>
                                    <div className="d-flex">
                                        <div className="ord_circ2 m-0" ></div>
                                        <h4 className="cart_head2 d-flex ">Complete<span className='ord_span3'> (Low render)</span>{ofArr[0]?.paidAmount === ofArr[0]?.amountToBePaid ? <button className="ord-off px-3 mx-2" onClick={() => {
                                            window.open(ofArr[0]?.adminStatus?.lowRender?.file?.url, "_blank")
                                        }}>Downlaod File</button> : ""}</h4></div>
                                    <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                                    <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>meeting is completed sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                                    <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.file?.date)).toString().substring(4, 15)}</span></div>
                                    <div className="d-flex">
                                        <div className="ord_circ2 m-0"></div>
                                        <h4 className="cart_head2 d-flex ">Complete<span className='ord_span3'> (Final render)</span>{ofArr[0]?.paidAmount === ofArr[0]?.amountToBePaid ? <button className="ord-off px-3 mx-2" onClick={() => {
                                            window.open(ofArr[0]?.adminStatus?.finalRender?.file?.url, "_blank")
                                        }}>Downlaod File</button> : ""}</h4></div>
                                    <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.finalRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                                    <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>meeting is completed sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.finalRender?.meet?.date)).toString().substring(4, 15)}</span></div>
                                    <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.finalRender?.file?.date)).toString().substring(4, 15)}</span></div>
                                </div>
                                <div className="_line2"></div>

                            </div>
                            <div className=' feed mx-5 w-10'>
                                <button className='but_n' onClick={() =>

                                    handleShow()} > Feedback</button>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div className='w-100' style={{ height: 'fitContent' }}>
                        {data && data.map((ele, index) => {
                            return (
                                <div className="ordered_main d-flex p-2" style={{ borderBottom: "1px solid #DFDFDF" }}>
                                    <div style={{
                                        background: "#F0F0F0",
                                        borderRadius: "4px",
                                        padding: '1rem', marginLeft: '10px', marginTop: '10px'

                                    }}>
                                        <img src={threed} style={{ width: '66px', height: "80px" }} alt="" className="ordImg" onClick={() => handleShow2(ele._id)} />
                                    </div>
                                    <div className="ord_upper d-flex flex-column w-100 px-4 ">
                                        <div className="ord_head d-flex mt-2">
                                            <div className="cart_head d-flex flex-column">3D_0000{index + 1} <span className='ord_span pt-1'>Last Contacted {ele.createdAt.slice(0, 7)}</span></div>
                                            <div className='d-flex' style={{ marginLeft: 'auto', height: '25px', borderRadius: '50px', width: '50px' }}>
                                                {ele.amountToBePaid === ele.paidAmount ? <div className="ord_span2 d-flex float-end" style={{
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: '#26AA63',
                                                    marginLeft: '8px', backgroundColor: 'rgba(38, 170, 99, 0.15)'
                                                }}>Paid</div> :

                                                    <div className="ord_span2 d-flex float-end justify-content-center" style={{
                                                        fontFamily: 'Inter', height: '23px', borderRadius: '50px', width: '68px',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        color: ' #EDA416',
                                                        marginLeft: '-12px', backgroundColor: 'rgba(237, 164, 22, 0.15)'
                                                    }}> 50% Paid</div>

                                                }

                                            </div>
                                        </div>
                                        <div className="cart_file_sub2 mt-2">
                                            <div className="file2"><img src={file} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ele?.cadFile?.fileName.slice(0, 9)}{"..."}</div>
                                            {ele.pdf && ele.pdf.fileName ? <div className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ele.pdf.fileName.slice(0, 9)}{"..."}</div> : localStorage.getItem("saveLater") ? <><p className="file3" style={{ marginLeft: "30px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded</p> <button className="ord_upload" onClick={handleShow}>Upload</button> </> : <div className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</div>}
                                        </div>
                                        <div className="ord_en d-flex">
                                            {/* <div className="ord_circ mt-1 mx-1" style={{ background: "#58A1FF", padding: '0px', alignSelf: 'auto', marginTop: '0px' }}></div> */}
                                            {ele.status === "completed" ? <><div className="ord_circ2" style={{ marginTop: '8px' }}></div>
                                                <div className="cart_head2 d-flex" style={{ fontSize: "13px" }}>Complete<span className='ord_span3' style={{ fontSize: "13px" }}> (High Render)</span></div></> : ''}
                                            {ele.amountToBePaid !== ele.paidAmount ? <><div className="cart_head2 d-flex" style={{ fontSize: "13px", marginLeft: 'auto' }}>To download pay<span style={{
                                                color: "#26AA63",
                                                paddingLeft: "6px"
                                            }}> ₹ {ele.paidAmount}/-</span></div>
                                                <div style={{ border: 'none',cursor:"pointer" }} className="paynow" onClick={() => handleClick(ele._id)}>Pay Now</div> </> :
                                                ele?.adminStatus?.block?.file?.url.length === 0 ? <div role="button" style={{ marginLeft: 'auto', fontSize: '14px' }} className="paynow" onClick={() =>

                                                    handleShow(ele?._id)
                                                }>Feedback </div> :  <Link to={ele?.adminStatus?.block?.file?.url} style={{ marginLeft: 'auto', fontSize: '14px' }} className="paynow" onClick={() => {
                                                    window.open(ele?.adminStatus?.block?.file?.url, "_blank")
                                                }}>Downlaod File</Link> 
                                                   }

                                            {ele?.pdf && ele.pdf.fileName.length === 0 && <Link style={{ textDecoration: 'underline' }} to="/3dMakePPT" className="ord_view p-0 " >Upload File</Link>
                                            }

                                        </div>
                                    </div>
                                </div>
                            )
                        })}





                        <Modal show={show} onHide={handleClose} centered size="md">

                            <Modal.Body>
                                <div>
                                    <div className='d-flex' style={{ borderBottom: '1px solid #DFDFDF' }}>
                                        <div className='feedback'>Feedback</div>
                                        <div className='close_button' style={{ marginLeft: 'auto' }}>
                                            <img role="button" src={cross} onClick={() => handleClose()} />
                                        </div>

                                    </div>
                                    <div className={star === 0 ? 'd-flex justify-content-evenly w-40' : 'd-none'} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img role="button" onClick={() => setStar(1)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(2)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(3)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(4)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(5)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div className={star === 4 ? 'd-flex justify-content-evenly w-40' : 'd-none'} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img role="button" onClick={() => setStar(1)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(2)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(3)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(4)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(5)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div className={star === 1 ? 'd-flex justify-content-evenly w-40' : 'd-none'} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img role="button" onClick={() => setStar(1)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(2)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(3)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(4)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(5)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div className={star === 2 ? 'd-flex justify-content-evenly w-40' : 'd-none'} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img role="button" onClick={() => setStar(1)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(2)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(3)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(4)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(5)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div className={star === 3 ? 'd-flex justify-content-evenly w-40' : 'd-none'} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img role="button" onClick={() => setStar(1)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(2)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(3)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(4)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(5)} src={sstar} style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div className={star === 5 ? 'd-flex justify-content-evenly w-40' : 'd-none'} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <img role="button" onClick={() => setStar(1)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(2)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(3)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(4)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                        <img role="button" onClick={() => setStar(5)} src={yellow} style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div style={{ background: '#FFFFFF' }}>
                                        <textarea className="text" value={textarea} onChange={handleChange} placeholder='Write something that’s on your mind..' >
                                        </textarea>

                                    </div>
                                    <div>
                                        <p className="number">{textarea?.length > 0 ? textarea?.length : 0} /200</p>
                                    </div>
                                    <div>
                                        <button className="buttonn" onClick={postFeedback}>Submit</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>



        </>
    )
} 