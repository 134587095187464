import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ScheduleADemo from "./ScheduleADemo";
import styles from "./ProductsCss/productSec3.module.css";
// import back from "../Products/ProductsImg/po/Rectangle 7475.png";
// import img3 from "../Products/ProductsImg/po/peo.svg";
import icon1 from "./ProductsImg/po/group.svg";
import icon2 from "./ProductsImg/po/price_change.svg";
import icon3 from "./ProductsImg/po/stream.svg";

const ProductSection3 = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className={styles.container} id="po">
        <div className={styles.files_contain}>
          <div className={styles.img_div}>
            
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/poNew.svg" alt="" className={styles.mainImg}/>
          </div>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>PURCHASE ORDER</div>
            <div className={styles.file_para}>
            Automate the purchasing process and ensuring that  all necessary information is included in the purchase order.
            </div>
              
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Integrated with Quotation and Accounting
                </div>
              </div>

              <div className={styles.eachBox}> 
                <div>
                  <img src={icon1} alt="" className={styles.icon}/>
                </div>
                <div className={styles.icon_para}>
                Manage Vendors <br /> Across Projects
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img
                    src={icon3}
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>Streamlined<br /> procurement</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection3;
