import React, { useEffect } from "react";
import SideBarWeb from "../Lms/LmsWebComponents/SideBarWeb";
import "./3d.css";
import { useState } from "react";
import TabButtonsMob from "./TabButtonsMob";
import completed from "./3dImages/completed.svg";
import locked from "./3dImages/locked.svg";
import { useNavigate } from "react-router-dom";
import PhotoUpload from "./PhotoUpload";
import SelectRoom from "./SelectRoom";
import UploadRefMain from "./UploadRefMain";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import SidebarWebNew from "../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../Lms/LmsWebComponents/HeaderNav/HeaderNav";
import { constants } from "../constants";

const TabButtons = () => {
  const autocadFile = useSelector((state) => state.threedReducer.autocadFile);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [cross, setCross] = useState(true);

  const handleErr = () => {
    setError("Upload a valid file");
  };
  const handleErr2 = () => {
    setError("");
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  // const profileData = useSelector((state) => state.addToCartReducer.pro)

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  // console.log(autocadFile?.name?.includes(".dwg"))

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNext = async () => {
    if (autocadFile?.name?.includes(".dwg")) {
      // alert("lol")
      localStorage.setItem("tick", true);
      const file = new FormData();
      setIsLoading(true);
      file.append("files", autocadFile.fullFile);
      await axios
        .post(`${constants.threeDApi}/api/room/request-design?designerId=${localStorage.getItem("userId")}`, file)
        .then((res) => {
          localStorage.setItem("apiId", res.data._id);
          setIsLoading(false);
          navigate("/3dSelectRoom");
        })
        .catch((err) => setError(err));
    } else {
      // alert("mo")
      setError("Add a cad file");
    }
  };

  // console.log(api_id)
  const handleBack = () => {
    navigate("/3dLanding");
  };

  return (
    <>
      <div className="d-none d-md-block">
        <HeaderNav />
        <div className="tabDisplay">
          <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", paddingRight: "0" }}>
            <SidebarWebNew />
          </div>
          <div className="tab_main">
            <div className="tab_main2">
              <button className="tab_active">1. Upload AutoCAD file </button>
              <button className="tab_active2">2. Select room & views </button>
              <button className="tab_active2">3. Upload reference PPT </button>
            </div>
            {<PhotoUpload err={error} function1={handleErr} function2={handleErr2} />}
            <div className="tab_button">
              {isLoading ? (
                <button className="tab_next" disabled>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </button>
              ) : (
                <button className="tab_next" onClick={handleNext}>
                  Next
                </button>
              )}
              <button className="tab_back" onClick={handleBack}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <TabButtonsMob />
    </>
  );
};

export default TabButtons;
