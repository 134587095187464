import React from "react";
import styles from "./productMobCss/productSec6.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/accounts/credit_score.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/accounts/currency_exchange.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/accounts/newspaper.svg";
// import quo from "./productMobImg/accounts/acc.png";

const ProductSec6 = () => {
  return (
    <>
      <div className={styles.container} id="acn">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>ACCOUNTS</div>
            <div className={styles.file_para}>
              Monitor expenses, identify areas for cost savings, and ensure that
              their accounting records are accurate.
            </div>
            <div className={styles.img_div}>
              <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207466%20%282%29.svg" alt="" className={styles.img} />
            </div>
            <div className={styles.icon_div}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Create Invoices</div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Create payment Requests</div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Track Expenses & Receipts</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSec6;
