import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import img1 from "../Components/StyleImages/scandinavian.png";
import img2 from "../Components/StyleImages/glam.png";
import img3 from "../Components/StyleImages/bohemian.png";
import img4 from "../Components/StyleImages/ModernMinimal.png";
import img5 from "../Components/StyleImages/Indian.png";
import img6 from "../Components/StyleImages/Rustic.png";
import img7 from "../Components/StyleImages/classic.png";
import img8 from "../Components/StyleImages/Industrial.png";
import bluetick from "../Images/blueTick.png";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../Context/Context";
import { useNavigate } from "react-router";

const picArr = [
  [img1, "Scandinavian"],
  [img2, "Glam & Luxurious"],
  [img3, "Bohemian"],
  [img4, "Modern Minimal"],
  [img5, "Indian"],
  [img6, "Rustic"],
  [img7, "Classic"],
  [img8, "Industrial"],
];

export default function DesignStyles() {
  const [currentIds, setCurrentIds] = useState([]);

  const context = useContext(OnboardingContext);
  const navigateTo = useNavigate();

  useEffect(() => {
    context.setData((pre) => ({ ...pre, designStyles: currentIds }));
  }, [currentIds]);

  function handleClick(e) {
    let flag = false;
    for (let i = 0; i < currentIds.length; i++) {
      if (currentIds[i] == e.target.id) {
        flag = true;
      }
    }
    let ids = currentIds.filter((id, i) => {
      return id != e.target.id;
    });

    if (flag == false) {
      setCurrentIds([...currentIds, e.target.id]);
    } else {
      setCurrentIds([...ids]);
    }
    console.log(currentIds);
    context.setData((pre) => ({ ...pre, designStyles: currentIds }));
  }

  function handleSubmit() {
    //  context.setData((pre)=>({...pre,designStyles:currentIds}))
    localStorage.setItem("designtype", currentIds);
    console.log(context.data);

    navigateTo("/consultation");
  }

  return (
    <div className="designStyles-container">
      <div className="designstyles">
        <div className="w-100 d-flex flex-column align-items-center">
          <h2>
            Which Design Styles <br />
            are your “forte” ?
          </h2>
          <p style={{ fontSize: "1rem" }}>(Select any three)</p>

          <div className="allstylesContainer">
            {picArr.map((pic, i) => {
              return (
                <div
                  onClick={handleClick}
                  className="cb"
                  key={pic[1]}
                  id={pic[1]}
                  style={{
                    backgroundImage: `url(${pic[0]})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    position: "relative",
                    width: "40%",
                    height: "8rem",
                    margin: "0.5rem",
                  }}
                >
                  {currentIds.includes(pic[1]) && (
                    <img
                      key={pic[1]}
                      id={pic[1]}
                      className="bluetick"
                      src={bluetick}
                      style={{
                        position: "absolute",
                        top: "15px",
                        right: "10px",
                        backgroundColor: "blue",
                      }}
                    />
                  )}
                  <p
                    id={pic[1]}
                    style={{
                      background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.42) 41.17%, #000000 114.37%)",
                      marginTop: "5.6rem",
                      borderRadius: "20px",
                    }}
                  >
                    {pic[1]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {currentIds.length >= 3 && (
          <div className="btn-container my-3">
            {/* <Link to="/consultation"> */}
            <button onClick={handleSubmit} className="nextbutton" style={{ width: "90%" }}>
              Next
            </button>
            {/* </Link> */}
          </div>
        )}
      </div>
    </div>
  );
}
