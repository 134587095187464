import React, { useEffect } from 'react'
import img1 from '../../Images/DesignerProfessionPic.png'
import img2 from '../../Images/ContractorProfessionPic.png'
import img3 from '../../Images/ModularFactoryPic.png'
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { OnboardingContext } from "../../Context/Context";
import ChooseProfessionMob from './chosseProfessionMob'
import { useContext } from "react";
import { useLocation } from 'react-router-dom'


export default function ChooseProfessionWeb() {
    const [ele, setEle] = useState()
    const context = useContext(OnboardingContext);
    const navigateTo = useNavigate()
    const location = useLocation()

    useEffect(()=>{
        localStorage.setItem("path", location.pathname)
    },[])

    function handleProf(e) {
        setEle(e.target.id)
    }
    // console.log(ele)

    const handleSubmit = (e) => {
        console.warn(e.target.value)
        let typeOfProf = ele
        console.log("event", typeOfProf)
        context.setData((pre) => ({ ...pre, userData: { ...pre.userData, prof: typeOfProf } }));
        //   // console.log("Mandeep's inner context",context.data)

        //    navigateTo("/designerprofileweb")
        console.log("inside handleSubmit", context.data);
        if (typeOfProf === "designer") {
            window.localStorage.setItem("type", 1)
            navigateTo('/profilebuild')
        } else if (typeOfProf === "contractor") {
            window.localStorage.setItem("type", 2)
            navigateTo('/contractorprofilebuild')
        } else {
            window.localStorage.setItem("type", 3)
            navigateTo('/oemprofilebuild')
        }
    }
    // console.log("Mandeep's outer context",context.data)
    return (
        <>
            {window.screen.width < "768" ?
                <ChooseProfessionMob />
                :
                <div className="main-professionContainer-web d-flex justify-content-center align-items-center">
                    <div style={{ background: "white" }} className="main-div row">
                        <div style={{ fontSize: "32px", fontWeight: "500", cursor: "pointer" }} className="chooseprodessionweb col d-flex justify-content-center align-items-center">Choose your Profession</div>
                        <div style={{ background: "#F7F7F7" }} className="col d-flex justify-content-center align-items-center flex-column">
                            <div onClick={handleSubmit} id="designer" style={{ border: ele === "designer" ? "2px solid #3B5998" : "1px solid #BDBDBD", cursor: "pointer" }} onMouseEnter={(e) => { handleProf(e) }} onMouseLeave={() => setEle()} className="col-8 common designer d-flex">
                                <img src={img1} alt="" />
                                <h6>Interior Designer / Architect</h6>
                            </div>
                            <div onClick={handleSubmit} id="contractor" style={{ border: ele === "contractor" ? "2px solid #3B5998" : "1px solid #BDBDBD", cursor: "pointer" }} onMouseEnter={(e) => { handleProf(e) }} onMouseLeave={() => setEle()} className="col-8 common contractor d-flex">
                                <img src={img2} alt="" />
                                <h6>Contractor</h6>
                            </div>
                            <div
                                onClick={handleSubmit}
                                id="oem" style={{ border: ele === "oem" ? "2px solid #3B5998" : "1px solid #BDBDBD", position: "relative", cursor: "pointer" }} onMouseEnter={(e) => { handleProf(e) }} onMouseLeave={() => setEle()} className="col-8 common oem d-flex">
                                {/* <p className='coming-soon' style={{position:"absolute",top:"0.5rem",right:"0.7rem",fontSize:"8px"}}>Coming Soon</p> */}
                                <img src={img3} alt="" />
                                <h6>Modular Factory</h6>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
