import React from "react";
import styles from "./productMobCss/ProductSec9.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/meeting/assignment_add.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/meeting/inventory.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/meeting/quick_reference_all.svg";

const ProductSec10 = () => {
  return (
    <>
      <div className={styles.container} id="chats">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>Minutes of Meeting</div>
            <div className={styles.file_para}>
              Document important meeting information, including discussions,
              decisions, and action items.
            </div>
            <div className={styles.img_div}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207468%20%285%29.svg"
                alt=""
                className={styles.img}
              />
            </div>
            <div className={styles.icon_div}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Document your meetings</div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Search functionality</div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Keep track of your Notes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSec10;
