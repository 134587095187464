import React, { useEffect, useState } from "react";
import Header from "../../NewHome/Header";
import NewFooter from "../../NewHome/NewFooter";
import JoinOnMobile from "../../NewHome/JoinOnMobile";
import FAQ from "../../NewHome/FAQ";
import Testmonial from "../Testimonial";
import CommonHero from "./CommonHero";
import CommonSecondContainer from "./CommonSecondContainer";
import styles from "./Common.module.css";
import { useNavigate } from "react-router-dom";
import AboveFooter from "./AboveFooter";
import {
  fileManagerArray,
  taskManagerArray,
  momArray,
  materialRequestArray,
  rfiArray,
  timelineTimesheetArray,
  quotationArray,
  accountsArray,
  VendorPOArray,
  leadArray,
  chatArray,
  libraryTemplatesArray,
  itemLibraryInventory,
  attendanceArray,
  companyManagementArray,
  teamManagementArray,
  mobileArray,
  extraArray,
  testimonialsArray
} from "./mockData";
import HeaderMob from "../../NewHome/HeaderMob";
import QueriesContainer from "./QueriesContainer";
import HamburgerPage from "../../NewHome/hamburgerMenus/HamburgerPage";

const MainPageAllFeatures = () => {
  const navigate = useNavigate();
  const [openHamburger, setOpenHamburger] = useState(false);
  const [btnHeading, setBtnHeading] = useState("");
  const [heading, setHeading] = useState("");
  const [desc, setDesc] = useState("");
  const [secMainHeading, setSecMainHeading] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [testimonialData, setTestimonialData] = useState();
  const [subHeading, setSubHeading] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (window?.location?.pathname?.includes("fileManager")) {
        setDataArray(fileManagerArray)
      setBtnHeading("FILE MANAGEMENT SIMPLIFIED");
      setHeading("Experience Effortless and Seamless File Management");
      setDesc(
        "Seamlessly upload files and glide through a structured file hierarchy with ease, ensuring your documents are always organised and accessible."
      );
      setSecMainHeading("Experience Effortless File Management");
      setTestimonialData(testimonialsArray[0])
      setSubHeading("Streamline your project organisation with our ERP's file manager, tailored for seamless collaboration and easy access to blueprints, designs, and project specifications, ensuring efficiency from concept to completion.")
    }
    if (window?.location?.pathname?.includes("taskManager")) {
        setDataArray(taskManagerArray)
      setBtnHeading("STREAMINED TASK MANAGEMENT");
      setHeading("Enhanced Task Tracking at Your Fingertips");
      setDesc(
        "Monitor team progress with Task Manager screen and effortlessly create, assign and update tasks. Also generate detailed reports for informed decision-making."
      );
      setSecMainHeading("Streamlined Task Management");
      setTestimonialData(testimonialsArray[1]);
      setSubHeading("Stay on top of deadlines and milestones effortlessly with a task manager, offering intuitive assignment tracking and progress monitoring, to optimise project workflows and deliver exceptional results.")
    }
    if (window?.location?.pathname?.includes("mom")) {
        setDataArray(momArray)
      setBtnHeading("EFFORTLESS MEETING ORGANISING");
      setHeading("Comprehensive Meeting Organization Suite Solution");
      setSecMainHeading("Effortless Meeting Organising");
      setDesc(
        "Generate Meeting Minutes (MOM), notes, and agendas effortlessly. Schedule meetings seamlessly. Simplify material selection from the item library."
      );
      setTestimonialData(testimonialsArray[2])
      setSubHeading("Maximise collaboration and organisation with access to meeting agendas, material selections, personal notes, and meeting minutes, to stay aligned, informed, and productive throughout every project phase.")
    }
    if (window?.location?.pathname?.includes("rfi")) {
        setDataArray(rfiArray)
      setBtnHeading("EFFECTIVE RFI MANAGEMENT");
      setHeading("RFI Management: Streamlined Requests, Efficient Responses");
      setSecMainHeading("Effective RFI Management");
      setDesc(
        "Effortlessly manage RFIs with our streamlined system. Create and track multiple RFIs effortlessly, ensuring better project management and smooth workflows"
      );
      setTestimonialData(testimonialsArray[0])
      setSubHeading("Streamline communication and resolve queries swiftly with RFI to submit, track, and respond to inquiries efficiently, fostering collaboration and ensuring project clarity for seamless execution.")
    }
    if (window?.location?.pathname?.includes("materialRequest")) {
        setDataArray(materialRequestArray)
      setBtnHeading("MATERIAl REQUEST MANAGEMENT");
      setHeading("Optimised Material Request: Streamline, Track, Fulfil");
      setSecMainHeading("Material Request Management");
      setDesc(
        "Streamline material requests and orders like a pro. Access past order history and pending orders effortlessly, issuing purchase orders for requested materials with ease."
      );
      setTestimonialData(testimonialsArray[1])
      setSubHeading("Accelerate project timelines and integrate procurement workflows facilitating swift material sourcing and allocation, ensuring optimal resource utilisation and project efficiency.")
    }
    if (window?.location?.pathname?.includes("timelineTimesheet")) {
        setDataArray(timelineTimesheetArray)
      setSecMainHeading("Timeline and Timesheet Management");
      setBtnHeading("Timeline and Timesheet Management");
      setHeading("Dynamic Time Tracking and Management Solution");
      setDesc(
        "Easily create and allocate hours with Timesheets. Track project progress with multiple views. Create detailed timelines with predecessors, subtasks, and checklists."
      );
      setTestimonialData(testimonialsArray[2])
      setSubHeading("Efficiently track project progress and optimise resource allocation to log hours, manage schedules, and monitor project milestones effortlessly, ensuring timely completion and client satisfaction.")
    }
    if (window?.location?.pathname?.includes("quotation")) {
        setDataArray(quotationArray)
      setSecMainHeading("Seamless Quotation Management");
      setBtnHeading("SEAMLESS QUOTATION Management");
      setHeading("Streamlined Quotation Management System ");
      setDesc(
        "Effortlessly create quotations from an item library. Compare and manage quotations efficiently. Streamline budget creation and purchase orders."
      );
      setTestimonialData(testimonialsArray[0])
      setSubHeading("Generate accurate and detailed quotations tailored to each project's specifications providing transparent pricing and enhancing client communication for seamless project planning and execution.")
    }
    if (window?.location?.pathname?.includes("account")) {
        setDataArray(accountsArray)
      setSecMainHeading("Comprehensive Accounts Management");
      setBtnHeading("COMPREHENSIVE ACCOUNTS Management");
      setHeading("Efficient Accounts Management System");
      setDesc(
        "Navigate the complexities of accounts management. Create detailed journals and project-level ledgers, keeping your finances in check. Seamlessly link payments with project timelines and tasks.."
      );
      setTestimonialData(testimonialsArray[1])
      setSubHeading("Manage finances with precision facilitating easy tracking of expenses, invoicing, and project budgeting to maintain financial clarity and make informed decisions for project profitability and success.")
    }
    if (window?.location?.pathname?.includes("vendorPO")) {
        setDataArray(VendorPOArray)
      setSecMainHeading("Vendor and Purchase Order Management");
      setBtnHeading("VENDOR AND PURCHASE ORDER Management");
      setHeading("Simplify Procurement, Boost Efficiency, Delightfully");
      setDesc(
        "Effectively manage vendors and their catalogues. Streamline creation and viewing of purchase orders. Facilitate material requests and issuance of purchase orders."
      );
      setTestimonialData(testimonialsArray[2])
      setSubHeading("Track suppliers, purchases, and delivery schedules, ensuring to access quality materials on time, every time, for efficient project execution and client satisfaction.")
    }
    if (window?.location?.pathname?.includes("newLead")) {
        setDataArray(leadArray)
      setSecMainHeading("Streamlined Lead Management");
      setBtnHeading("STREAMLINED LEAD Management");
      setHeading("Lead Management: Streamline, Nurture, Convert Effectively");
      setDesc(
        "Streamline lead management. Add, edit, and assign leads, seamlessly integrating with social media for maximum reach. Simplify follow-ups and status changes for enhanced productivity."
      );
      setTestimonialData(testimonialsArray[0])
      setSubHeading("Streamline your client acquisition process to track, nurture, and convert leads into projects seamlessly, fostering stronger client relationships and driving business growth effortlessly.")
    }
    if (window?.location?.pathname?.includes("chat")) {
        setDataArray(chatArray)
      setSecMainHeading("Client Communication Simplified");
      setBtnHeading("CLIENT COMMUNICATION SIMPLIFIED");
      setHeading(
        "Effortless Client Communication: Chat, Channels, Integration"
      );
      setDesc(
        "Communicate with clients and team members effortlessly. Organise discussions with channels and group chats. Share project-related documents and files directly through chat."
      );
      setTestimonialData(testimonialsArray[1])
      setSubHeading("Enhance client engagement and satisfaction incorporating a chat service for seamless communication to address client inquiries, provide updates, and gather feedback in real-time, fostering stronger relationships and ensuring project success.")
    }
    if (window?.location?.pathname?.includes("libraryTemplates")) {
        setDataArray(libraryTemplatesArray)
      setSecMainHeading("Customisable Library and Templates");
      setBtnHeading("CUSTOMIsABLE LIBRARY AND TEMPLATES");
      setHeading("Resource Repository for Streamlined Organisation");
      setDesc(
        "Access a library of templates for various features. Create and manage lead forms effortlessly. Customise templates for inventory reports, kick-off reports, and more."
      );
      setTestimonialData(testimonialsArray[2])
      setSubHeading("Unlock creativity and efficiency with a comprehensive repository of pre-defined templates, item masters, budgets, and customizable forms creation, alongside a rich material library and streamlined purchase order management. ")
    }
    if (window?.location?.pathname?.includes("itemLibraryInventory")) {
        setDataArray(itemLibraryInventory)
      setSecMainHeading("Item Library and Inventory Management");
      setBtnHeading("ITEM LIBRARY AND INVENTORY MANAGEMENT");
      setHeading("Centralised Inventory: Organise, Track and Optimise");
      setDesc(
        "Optimise your inventory management with ease. Create Bills of Material (BOM) and manage item lists and categories seamlessly. Keep your material library up-to-date with easy additions and exports."
      );
      setTestimonialData(testimonialsArray[0])
      setSubHeading("Efficiently track and manage all project materials and have real-time visibility into stock levels, procurement needs, and material usage, optimising resource allocation and project timelines for seamless execution and client satisfaction.")
    }
    if (window?.location?.pathname?.includes("attendance")) {
        setDataArray(attendanceArray)
      setSecMainHeading("Simplified Attendance Tracking");
      setBtnHeading("SIMPLIFIED ATTENDANCE TRACKING");
      setHeading("Attendance Tracking: Streamline, Monitor, Analyze");
      setDesc(
        "Mark attendance conveniently, whether on-site or off-site. Effortlessly view team or individual attendance and generate reports for effective workforce management."
      );
      setTestimonialData(testimonialsArray[1])
      setSubHeading("Streamline workforce management to easily clock in and out, track hours worked, and manage schedules, ensuring accurate payroll processing and optimised project staffing with ease.")
    }
    if (window?.location?.pathname?.includes("companyManagement")) {
        setDataArray(companyManagementArray)
      setSecMainHeading("Effective Company Management");
      setBtnHeading("EFFECTIVE COMPANY MANAGEMENT");
      setHeading("Efficient Location and Schedule Management Solution");
      setDesc(
        "Manage your team effortlessly with our intuitive tools. Create roles, designations and manage permissions, visualising organisational structure with clarity."
      );
      setTestimonialData(testimonialsArray[2])
      setSubHeading("Oversee company operations providing comprehensive tools for resource allocation, financial tracking, and performance analysis ensuring streamlined management and sustainable growth.")
    }
    if (window?.location?.pathname?.includes("teamManagement")) {
        setDataArray(teamManagementArray)
      setSecMainHeading("Efficient Team Management");
      setBtnHeading("EFFICIENT TEAM MANAGEMENT");
      setHeading(
        "Efficient Organisation: Coordination, Delegation, Optimization"
      );
      setDesc(
        "Manage roles, designations, and permissions. Visualize organizational structure with an organization chart. Access all employee, lead, client, and vendor info in one place."
      );
      setTestimonialData(testimonialsArray[0])
      setSubHeading("Optimise team coordination and collaboration to facilitate seamless assignment of tasks, communication, and progress tracking for achieving project goals efficiently and effectively, while ensuring alignment and accountability across the team.")
    }
    if (window?.location?.pathname?.includes("mobile")) {
        setDataArray(mobileArray)
      setSecMainHeading("Mobile App for On-The-Go Management");
      setBtnHeading("MOBILE APP FOR ON-THE-GO MANAGEMENT");
      setHeading("Enhanced Productivity: Mobile App Management Solution");
      setDesc(
        "Stay connected and productive wherever you go. Access project and lead overviews conveniently, engage in real-time chats, and approve requests on the fly."
      );
      setTestimonialData(testimonialsArray[1])
      setSubHeading("Seamless access to project updates, task assignments, and communication channels on your smartphone to manage projects efficiently, even while on the move.")
    }
    if (window?.location?.pathname?.includes("extras")) {
        setDataArray(extraArray)
      setSecMainHeading("Additional Features for Enhanced Functionality");
      setBtnHeading("ADDITIONAL FEATURES FOR ENHANCED FUNCTIONALITY");
      setHeading(
        "Empowerment Through Knowledge: Robust Support & Versatile Training Solutions."
      );
      setDesc(
        "Seamlessly integrate with popular platforms. Access robust support and comprehensive online-offline training opportunities"
      );
      setTestimonialData(testimonialsArray[2])
      setSubHeading("Elevate efficiency enabling integration with popular platforms with comprehensive online and offline training opportunities to maximise productivity and stay ahead in their projects.")
    }
  }, [window?.location?.pathname]);

  const scrollToTop=()=>{
    window.scrollTo({
      top:0,
      // behavior:"smooth"
    });
}

useEffect(()=>{
   scrollToTop()
})

  return (
    <>
   {openHamburger && <HamburgerPage openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />}
     {!openHamburger &&(isDesktop? <Header /> :
      <HeaderMob openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />)
      }
      { !openHamburger && <CommonHero btnHeading={btnHeading} heading={heading} desc={desc} />}
      {!openHamburger && <CommonSecondContainer
        secMainHeading={secMainHeading}
        dataArray={dataArray}
        subHeading={subHeading}
      />}
      {!openHamburger && <QueriesContainer />}
      {!openHamburger && <Testmonial testimonialData={testimonialData} />}
      {!openHamburger && <FAQ />}
      {!openHamburger && <JoinOnMobile />}
     {!openHamburger && <AboveFooter />}
     {!openHamburger && <NewFooter />}
    </>
  );
};

export default MainPageAllFeatures;
