import React from "react";
import Header from "../../Components/Common/Header/index";
import Footer from "../../Components/Common/Footer/index"

const RefundsReturns = () => {
    return (
        <>
        <Header />
            <section className="privacy-police">
                <div className="container">
                    <div className="row" style={{textAlign:"justify"}}>
                        <div className="col-md-12 mt-5 pt-4">
                            <h2 className="mb-4">Return, Refund, and Cancellation Policy</h2>
                            <h3>Cancellation request</h3>
                            <p>
                                You may cancel your subscription within 7 days of receipt of your request subject to no project
                                inquiries received, in each case provided that the request was made in accordance with these cancellations instructions. We
                                will confirm the cancellation of your subscription within 3 business days after receipt of your cancellation request.
                            </p>

                            <h3>Refund request</h3>

                            <p>
                                At iDesign.Market, we offer a 7-day money-back guarantee according to the terms described herein. If you
                                cancel your subscription in accordance with this Policy within 7 calendar days of placing your subscription order, we will, upon
                                your written request, refund your prepaid fees within 30 calendar days. The refund will be processed through the same
                                method as the original payment. Any bank fees and charges shall be borne solely by you.
                                We reserve the right to reject your refund request if your request is submitted after a project inquiry is initiated to your
                                account or for some other reasons as could be described in the iDesign Terms of Service
                            </p>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default RefundsReturns;
