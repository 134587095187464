import React, { useEffect, useState } from 'react'
import plus from './3dImages/plus.svg'
import cross from './3dImages/cross.svg'
import sofa_img from './3dImages/sofa_img.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useRef } from 'react'
import { stateChange, deleteRefImg, uploadRefImg, saveInput } from '../Lms/Actions/threedActions'

const UploadReference2 = (props) => {

    const dispatch = useDispatch();
    const uploadImageRef2 = useRef()

    const refImgArr = useSelector((state) => state.threedReducer.uploadRefImg)
    const refImgArr1 = useSelector((state) => state.threedReducer.uploadRefImg1)
    const refImgArr2 = useSelector((state) => state.threedReducer.uploadRefImg2)
    const refImgArr3 = useSelector((state) => state.threedReducer.uploadRefImg3)
    const refImgArr4 = useSelector((state) => state.threedReducer.uploadRefImg4)
    const refImgArr5 = useSelector((state) => state.threedReducer.uploadRefImg5)
    const refImgArr6 = useSelector((state) => state.threedReducer.uploadRefImg6)
    const refImgArr7 = useSelector((state) => state.threedReducer.uploadRefImg7)
    const stateC1 = useSelector((state) => state.threedReducer.stateChange)

    const [inArray, setInArray] = useState({})
    const [inArray2, setInArray2] = useState({})
    const [inArray3, setInArray3] = useState({})
    const [inArray4, setInArray4] = useState({})
    const [inArray5, setInArray5] = useState({})
    const [inArray6, setInArray6] = useState({})
    const [inArray7, setInArray7] = useState({})
    const [inArray8, setInArray8] = useState({})

    const [nState, setNState] = useState("")
    const [nState2, setNState2] = useState("")
    const [nState3, setNState3] = useState("")
    const [nState4, setNState4] = useState("")
    const [nState5, setNState5] = useState("")
    const [nState6, setNState6] = useState("")
    const [nState7, setNState7] = useState("")
    const [nState8, setNState8] = useState("")

    const [error1, setError1] = useState(false)
    const [error2, setError2] = useState(false)
    const [error3, setError3] = useState(false)
    const [error4, setError4] = useState(false)
    const [error5, setError5] = useState(false)
    const [error6, setError6] = useState(false)
    const [error7, setError7] = useState(false)
    const [error8, setError8] = useState(false)

    const handleInput = (e) => {
        if (stateC1 === "state1") {
            setInArray((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState(e.target.name)
        }
        if (stateC1 === "state2") {
            setInArray2((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState2(e.target.name)
        }
        if (stateC1 === "state3") {
            setInArray3((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState3(e.target.name)
        }
        if (stateC1 === "state4") {
            setInArray4((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState4(e.target.name)
        }
        if (stateC1 === "state5") {
            setInArray5((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState5(e.target.name)
        }
        if (stateC1 === "state6") {
            setInArray6((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState6(e.target.name)
        }
        if (stateC1 === "state7") {
            setInArray7((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
            setNState7(e.target.name)
        }
        if (stateC1 === "state8") {
            setInArray8((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
        setNState8(e.target.name)
    }

    useEffect(() => {
        if (stateC1 === "state1") {
            dispatch(saveInput(nState.slice(6, nState.length), inArray[`input-${nState.slice(6, nState.length)}`], stateC1))
        }
        if (stateC1 === "state2") {
            dispatch(saveInput(nState2.slice(6, nState2.length), inArray2[`input-${nState2.slice(6, nState2.length)}`], stateC1))
        }

        if (stateC1 === "state3") {
            dispatch(saveInput(nState3.slice(6, nState3.length), inArray3[`input-${nState3.slice(6, nState3.length)}`], stateC1))
        }
        if (stateC1 === "state4") {
            dispatch(saveInput(nState4.slice(6, nState4.length), inArray4[`input-${nState4.slice(6, nState4.length)}`], stateC1))
        }
        if (stateC1 === "state5") {
            dispatch(saveInput(nState5.slice(6, nState5.length), inArray5[`input-${nState5.slice(6, nState5.length)}`], stateC1))
        }
        if (stateC1 === "state6") {
            dispatch(saveInput(nState6.slice(6, nState6.length), inArray6[`input-${nState6.slice(6, nState6.length)}`], stateC1))
        }
        if (stateC1 === "state7") {
            dispatch(saveInput(nState7.slice(6, nState7.length), inArray7[`input-${nState7.slice(6, nState7.length)}`], stateC1))
        }
        if (stateC1 === "state8") {
            dispatch(saveInput(nState8.slice(6, nState2.length), inArray8[`input-${nState8.slice(6, nState8.length)}`], stateC1))
        }

    }, [inArray, inArray2, inArray3, inArray4, inArray5, inArray6, inArray7, inArray8])


    const handleUploadImg = (e) => {
        // console.log(e.target.files)
        if (stateC1 === "state1") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError1(true)
                }
            }
        }
        if (stateC1 === "state2") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError2(true)
                }
            }
        }
        if (stateC1 === "state3") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError3(true)
                }
            }
        }
        if (stateC1 === "state4") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError4(true)
                }
            }
        }
        if (stateC1 === "state5") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError5(true)
                }
            }
        }
        if (stateC1 === "state6") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError6(true)
                }
            }
        }
        if (stateC1 === "state7") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError7(true)
                }
            }
        }
        if (stateC1 === "state8") {
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jpeg") || e.target.files[i]?.name?.includes(".png") || e.target.files[i]?.name?.includes(".jpg") || e.target.files[i]?.name?.includes(".jfif") || e.target.files[i]?.name?.includes(".pjp") || e.target.files[i]?.name?.includes(".pjpeg")) {
                    const x = {
                        name: e.target.files[i].name,
                        path: URL.createObjectURL(e.target.files[i]),
                        desc: "",
                        fullFile: e.target.files[i]
                    }
                    dispatch(uploadRefImg(x, stateC1))
                }
                else {
                    setError8(true)
                }
            }
        }
    }
    const handleDelete = (element) => {
        if (stateC1 === "state1") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state2") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state3") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state4") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state5") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state6") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state7") {
            dispatch(deleteRefImg(element, stateC1))
        }
        if (stateC1 === "state8") {
            dispatch(deleteRefImg(element, stateC1))
        }
    }

    console.log(props)

    return (
        <>
            <div className="uploadRef_card">
                <div className="card_start">
                    <div className="uploadRef_addMore" onClick={() => { uploadImageRef2.current.click() }}>
                        <input type="file" className="addMore_input d-none" ref={uploadImageRef2} accept="image/png, image/jpeg, image/jpg" onChange={handleUploadImg} multiple />
                        <img src={plus} alt="" className="addMore_plus" />
                        <p className="addMore_line">Add More Images</p>
                        {/* {(error1 || error2 || error3 || error4 || error5 || error6 || error7 || error8)  ? <span style={{ color: "red" }}>Upload a valid image type</span> : ""} */}
                    </div>
                    {stateC1 === "state1" ? refImgArr?.map((item, index) => {
                        return (
                            <div className="uploadRef_card2" >
                                <div className="card-main">
                                    <div className="coross_btn_main">
                                        <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                    </div>
                                    <img src={item.image.path} alt="" className="uploadRef-card_img" />
                                    {(inArray[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                                </div>
                            </div>
                        )
                    }) : stateC1 === "state2" ? refImgArr1?.map((item, index) => {
                        return (
                            <div className="uploadRef_card2" >
                                <div className="card-main">
                                    <div className="coross_btn_main">
                                        <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                    </div>
                                    <img src={item.image.path} alt="" className="uploadRef-card_img" />
                                    {(inArray2[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray2[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                                </div>
                            </div>)
                    }) : stateC1 === "state3" ? refImgArr2.map((item, index) => (
                        <div className="uploadRef_card2" >
                            <div className="card-main">
                                <div className="coross_btn_main">
                                    <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                </div>
                                <img src={item.image.path} alt="" className="uploadRef-card_img" />

                                {(inArray3[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray3[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                            </div>
                        </div>)) : stateC1 === "state4" ? refImgArr3.map((item, index) => (
                            <div className="uploadRef_card2" >
                                <div className="card-main">
                                    <div className="coross_btn_main">
                                        <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                    </div>
                                    <img src={item.image.path} alt="" className="uploadRef-card_img" />

                                    {(inArray4[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray4[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                                </div>
                            </div>)) : stateC1 === "state5" ? refImgArr4.map((item, index) => (
                                <div className="uploadRef_card2" >
                                    <div className="card-main">
                                        <div className="coross_btn_main">
                                            <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                        </div>
                                        <img src={item.image.path} alt="" className="uploadRef-card_img" />

                                        {(inArray5[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray5[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}

                                    </div>
                                </div>)) : stateC1 === "state6" ? refImgArr5.map((item, index) => (
                                    <div className="uploadRef_card2" >
                                        <div className="card-main">
                                            <div className="coross_btn_main">
                                                <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                            </div>
                                            <img src={item.image.path} alt="" className="uploadRef-card_img" />
                                            {(inArray6[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray6[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                                        </div>
                                    </div>)) : stateC1 === "state7" ? refImgArr6.map((item, index) => (
                                        <div className="uploadRef_card2" >
                                            <div className="card-main">
                                                <div className="coross_btn_main">
                                                    <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                                </div>
                                                <img src={item.image.path} alt="" className="uploadRef-card_img" />
                                                {(inArray7[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray7[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                                            </div>
                                        </div>)) : stateC1 === "state8" ? refImgArr7.map((item, index) => (
                                            <div className="uploadRef_card2" >
                                                <div className="card-main">
                                                    <div className="coross_btn_main">
                                                        <button className="cross_btn" onClick={() => handleDelete(item.image.name)}><img src={cross} alt="" className="cross_img" style={{ width: "16px", height: "20px" }} /></button>
                                                    </div>
                                                    <img src={item.image.path} alt="" className="uploadRef-card_img" />

                                                    {(inArray8[`input-${item.image.name}`] != undefined) ? <input type="text" name={`input-${item.image.name}`} value={inArray8[`input-${item.image.name}`]} className="card_input" placeholder='Enter the description' onChange={handleInput} /> : <input type="text" name={`input-${item.image.name}`} value='' className="card_input" placeholder='Enter the description' onChange={handleInput} />}
                                                </div>
                                            </div>)) : ""}
                </div>
                
            </div>
            {/* {props.boolError ? <span className='err-line' style={{ width: "35%", marginLeft: "36px" }}>Upload a valid file</span> : props.wordError !== "" ? <span className='err-line' style={{marginLeft: "36px"}}>{props.wordError}</span> : ""} */}
        </>
    )
}

export default UploadReference2