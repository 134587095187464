import React from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

 export const sendEmailSuccess = async (subject, data, email) => {
        let fullName = localStorage.getItem("fullName");
        try {
          let res = await axios.post(
            `https://api.idesign.market/email/api/e-service/send-email`,
            {
              subject: subject,
              email: email,
              html: data,
            }
          );
          if (res && !res.error) {
            // toast.success("Email sent successfully");
          } else {
          }
        } catch {}
      };
