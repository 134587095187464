import React, { useEffect, useState } from "react";
import { getLeadsData } from "../../analytics";
import lead from "./Images/lead.svg";
import threed from "./Images/threed.svg";
import quot from "./Images/quotation.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData, setGstDetails } from "../../Lms/Actions";

const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";

const Count = (props) => {
  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  const [data, setData] = useState([]);

  const [receivedData, setReceivedData] = useState([]);
  const dispatch = useDispatch();
  const [leadCount, setLeadCount] = useState([]);

  useEffect(() => {
    dispatch(fetchProfileData(authTok));
  }, []);

  const ReceivedOrder = async () => {
    const res = await axios.get(`https://3d-api.idesign.market/api/room/completed-designs?designerId=${localStorage.getItem("userId")}`);
    setReceivedData(res.data);
  };

  const TotalLead = async () => {
    await axios
      .get(
        "https://pro-api.idesign.market/user/leads?pageNo=0",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authTok,
          },
        }
      )
      .then((res) => {
        setLeadCount(res?.data?.data);
      });
  };

  var count1 = 0,
    count2 = 0;
  for (let i = 0; i <= leadCount?.data?.length; i++) {
    if (leadCount?.data[i]?.leadSource === 2) {
      count1++;
    }
    if (leadCount?.data[i]?.leadSource === 3) {
      count2++;
    }
  }

  return (
    <div className="count" style={{ marginLeft: "10px", marginRight: "10px", width: "58vw" }}>
      <div className="d-flex" style={{ backgroundColor: "#ffffff", width: "28vw", borderRadius: "8px", height: "25vh", border: '1px solid #888888' }}>
        <div className="lead-count d-flex flex-column justify-content-between" style={{border: "none"}}>
          <img src={lead} style={{ width: "30px", height: "30px" }} />
          {/* <div style={{ borderRadius: '50px', border: '7px solid #888888', height: '100px', width: '100px', marginTop: '20px', marginRight: '15px' }}> */}
          <div className="d-flex">
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "30px",
                color: "#000000",
              }}
            >
              0{profileInfo[0]?.data?.data?.freeLeads + count2}/
            </span>
            <div style={{ textAlign: "center", display: "flex", alignItems: "flex-end", marginBottom: "0.3rem" }}>
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "#000000",
                }}
              >
                {profileInfo[0]?.data?.data?.planId?._id === "6200c35b083dd9b8c3f69391" && (
                  <div>
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#000000",
                      }}
                    >
                      02
                    </div>
                  </div>
                )}
                {profileInfo[0]?.data?.data?.planId?._id === "62ebaa44b4de66a62839e0e5" && (
                  <div>
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#000000",
                      }}
                    >
                      12
                    </div>
                  </div>
                )}
                {profileInfo[0]?.data?.data?.planId?._id === "6200c35b083dd9b8c3f69392" && (
                  <div>
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#000000",
                      }}
                    >
                      08
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>
          {/* </div> */}
          {/* <h1 style={{
            textAlign: 'left', marginTop: '15px', fontFamily: 'Inter',
            color: '#000000',
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '53px',
          }}>{props.leadCount?.count}</h1> */}
          <div>
            <div
              style={{
                fontFamily: "Inter",
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              Total Leads
            </div>
          </div>
        </div>
        {/* <div style={{ borderRadius: '50px', border: '7px solid #888888', height: '100px', width: '100px', marginTop: '20px', marginRight: '15px' }}>
          <div className="d-flex" style={{ justifyContent: 'center' }}>
            <span style={{
              marginTop: '30px', fontFamily: 'Inter', fontWeight: '500',
              fontSize: '22px', color: '#000000', textAlign: 'center'
            }}>{props.leadCount?.count}/</span>
            <div style={{ marginTop: '35px', textAlign: 'center' }}>
              <span style={{
                fontFamily: 'Inter', fontWeight: '500',
                fontSize: '14px', color: '#000000'
              }}>00</span>
            </div>
          </div>
        </div> */}
      </div>
      <div className="d-flex mb-0" style={{ backgroundColor: "#ffffff", width: "27.5vw", borderRadius: "8px", height: "25vh", justifyContent: "space-between", border: '1px solid #888888' }}>
        <div className="lead-count d-flex flex-column justify-content-between" style={{ borderRight: "none" }}>
          <img src={threed} style={{ width: "30px", height: "30px" }} />
          <div className="d-flex">
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "30px",
                color: "#000000",
                textAlign: "center",
              }}
            >
              0{props.threed?.length}/
            </span>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "0.3rem" }}>
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#000000"
                }}
              >
                0{props.data?.length}
              </span>
            </div>
          </div>
          {/* <h1>{data && data.length}</h1> */}
          {/* <h1 style={{
            textAlign: 'left', marginTop: '15px', fontFamily: 'Inter',
            color: '#000000',
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '53px',
          }}>{data?.length}</h1> */}
          <div>
            <div
              style={{
                fontFamily: "Inter",
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              3Ds Ordered{" "}
            </div>
          </div>
        </div>
        {/* <div style={{ borderRadius: '50px', border: '7px solid #888888', height: '100px', width: '100px', marginTop: '20px', marginRight: '15px' }}>
          <div className="d-flex" style={{ justifyContent: 'center' }}>
            <span style={{
              marginTop: '30px', fontFamily: 'Inter', fontWeight: '500',
              fontSize: '22px', color: '#000000', textAlign: 'center'
            }}>{receivedData?.length}/</span>
            <div style={{ marginTop: '35px', textAlign: 'center' }}>
              <span style={{
                fontFamily: 'Inter', fontWeight: '500',
                fontSize: '14px', color: '#000000'
              }}>{data?.length}</span>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="d-flex mb-0" style={{ backgroundColor: '#F0F0F0', width: '19vw', borderRadius: '10px', height: '25vh' }}>
        <div className="lead-count" >
          <img src={quot} style={{ width: '25px', height: '25px', marginLeft: '5px' }} />
          <h1>{data && data.length}</h1>
          <h1 style={{
            textAlign: 'left', marginTop: '15px', fontFamily: 'Inter',
            color: '#000000',
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '53px',
          }}>00</h1>
          <p style={{
            fontFamily: 'Inter', color: '#000000',
            fontWeight: '600',
            fontSize: '12px',
          }}>Quotation Received </p>

        </div>
        <div style={{ borderRadius: '50px', border: '7px solid #888888', height: '100px', width: '100px', marginTop: '20px' }}>
          <div className="d-flex" style={{ justifyContent: 'center' }}>
            <span style={{
              marginTop: '30px', fontFamily: 'Inter', fontWeight: '500',
              fontSize: '22px', color: '#000000', textAlign: 'center'
            }}>00/</span>
            <div style={{ marginTop: '35px', textAlign: 'center' }}>
              <span style={{
                fontFamily: 'Inter', fontWeight: '500',
                fontSize: '14px', color: '#000000'
              }}>00</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Count;
