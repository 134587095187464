import React, { useEffect, useState } from 'react';
import profile from './Images/profilepic.svg'
import icon from "./Images/icon.svg"
import home from "./Images/homee.svg"
import LeadsHistoryMob from './LeadsHistoryMob';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function LeadsHistory() {
    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);
    const navigate = useNavigate();
    const [lead, setLead] = useState([]);
    const updateMedia = () => {
        setDesktop(window.innerWidth > 650);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);

    });
    async function TotalLead() {
        await axios
            .get("https://pro-api.idesign.market/user/leads?pageNo=0", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authTok,
                },
            })
            .then((res) => {
                console.log(res?.data?.data)
                setLead(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }
    console.log(lead)

    useEffect(() => {
        TotalLead();

    }, []);

    return (
        <>
            <div>
                {isDesktop ?

                    <div style={{ overflow:'scroll', height: "70vh" }}>
                        <div className='lead_main'style={{height: 'fitContent'}} >
                            {lead.data && lead.data.map((curElm) => {
                                return (
                                    <div className=' d-flex main_content'>
                                        <div style={{
                                            backgroundColor: "rgba(59, 89, 152, 0.1)",
                                            margin: "1px 6px",
                                            padding: '1.5rem', borderRadius: '5px',
                                            borderBottom: '1px #DFDFDF'
                                        }}>
                                            <img src={profile} style={{ width: '50px', height: '50px' }} />
                                        </div>
                                        <div className='d-flex w-100' style={{ marginLeft: '10px', marginTop: '10px' }}>
                                            <div style={{ width: '100%' }}>
                                                <div className="d-flex w-100">
                                                    <div className='d-flex flex-column w-100'>
                                                        <div className='name'>
                                                            {curElm.name}
                                                        </div>
                                                        <div className='time'>
                                                            Buyed on {curElm.createdAt?.slice(0, 10)}
                                                        </div>

                                                    </div>
                                                    <div className='paisa'>₹{curElm.leadPrice}</div>
                                                </div>
                                                <div className='d-flex' style={{}}>
                                                    <div className='d-flex' style={{ marginTop: '10px' }}>
                                                        <img src={icon} />
                                                        <div className='pricee' style={{ marginLeft: '5px' }}>{curElm.budget === '₹ 1,00,000 - ₹ 3,00,000' ? "1-3 Lacs" : curElm.budget === '₹ 3,00,000 - ₹ 5,00,000' ? "3-5 Lacs" : curElm.budget === 'Over ₹ 7,00,000' ? "over 7 Lacs" : curElm.budget === '₹ 3,00,000 - ₹ 7,00,000' ? '3-7 Lacs' : ''}</div>
                                                    </div>
                                                    <div className='d-flex' style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                        <img src={home} style={{ width: "17px", height: "20px" }} />
                                                        <div className='roomss' style={{ marginLeft: '5px' }}>{curElm.config}</div>
                                                    </div>

                                                    <Link className='vieww' to="/lead" style={{ marginLeft: 'auto', marginTop: 'auto' }}>View Details</Link>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div >

                    </div> : <LeadsHistoryMob />}
            </div>
        </>
    )
}