import React from "react";
import styles from "./ProductsCss/ProductSecfile.module.css";
import icon1 from "./ProductsImg/fileImg/lock.svg";
import icon2 from "./ProductsImg/fileImg/conversion_path.svg";
import icon3 from "./ProductsImg/fileImg/bookmark_manager.svg";
// import img1 from "./ProductsImg/fileImg/filee.svg";

const ProductSectionFile = () => {
  return (
    <>
      <div className={styles.files_contain} id="file">
        <div className={styles.img_div}>
          <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/filenew.svg" alt="" className={styles.mainImg}/>
        </div>
        <div className={styles.file_content_div}>
          <div className={styles.file_head}>FILE MANAGER</div>
          <div className={styles.file_para}>
            Easily organize and manage all of your important documents <br />
            with our file manager feature.
          </div>

          <div className={styles.iconDiv}>
            <div className={styles.eachBox}>
              <div>
                <img src={icon1} alt="" className={styles.icon} />
              </div>
              <div className={styles.icon_para}>
                Keep your Project <br />
                Files secure & safe
              </div>
            </div>

            <div className={styles.eachBox}>
              <div>
                <img src={icon3} alt="" className={styles.icon} />
              </div>
              <div className={styles.icon_para}>
                Get Easy feedback <br />
                on files from Client
              </div>
            </div>
            <div className={styles.eachBox}>
              <div>
                <img src={icon2} alt="" className={styles.icon} />
              </div>
              <div className={styles.icon_para}>
                Track and Control <br />
                files versions
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSectionFile;
